import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Field, FormikProvider, useFormik } from 'formik';
import { NonAuthRoutes } from '../../../routes/routes';
import { Link, useLocation } from 'react-router-dom';
import { InputAdornment, IconButton } from '@mui/material';
import st from '../Auth.module.scss';
import { ReactComponent as EyeIcon } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as EyeIconOff } from '../../../assets/images/icons/eye-off.svg';
import UIStore from "../../../store/UIStore";
import {postData} from "../../../api/api";


const NewPassword = () => {
    const intl = useIntl();
    const { localeUrl, setErrorAlert, alertTimers } = UIStore;
    const [showPassword, setShowPassword] = React.useState(false);
    const [verifyTokenSubmitError, setVerifyTokenSubmitError] = React.useState('');
    const [isVerifyTokenSubmission, setIsVerifyTokenSubmission] = React.useState(true);
    const [isVerifyTokenSubmissionIsSync, setIsVerifyTokenSubmissionIsSync] = React.useState(false);
    const [successNewPassIsActive, setSuccessNewPassIsActive] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };
    const query = new URLSearchParams(useLocation().search);
    const queryToken = query?.get('resetToken');

    React.useEffect(() => {
        postData('forgot-password/verify-reset-token', {"token": queryToken})
            .then(() => {
                setIsVerifyTokenSubmission(false);
                setIsVerifyTokenSubmissionIsSync(true);
            })
            .catch((error) => {
                setVerifyTokenSubmitError(error);
                setIsVerifyTokenSubmission(true);
                setIsVerifyTokenSubmissionIsSync(true);
            });
    }, [queryToken]);

    const validateNewPass = (values) => {
        const errors = {};

        if (!values.newPassword) {
            errors.newPassword = intl.formatMessage({id: 'required'});
        }

        if (values.newPassword && values.newPassword.length < 6) {
            errors.newPassword = intl.formatMessage({id: 'errors.passwordMinLength'});
        }

        if (!values.confirm_password) {
            errors.confirm_password = intl.formatMessage({id: 'required'});
        }

        if (values.confirm_password && values.confirm_password !== values.newPassword) {
            errors.confirm_password = intl.formatMessage({id: 'errors.passwordNotMatch'});
        }

        return errors;
    };

    const form = useFormik({
        initialValues: {
            newPassword: '',
            confirm_password: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: validateNewPass,
        onSubmit: (values) => {
            setErrorAlert('');
            postData('forgot-password/change-password', {
                "newPassword": values.newPassword,
                "token": queryToken,
            })
                .then(() => {
                    setSuccessNewPassIsActive(true);
                })
                .catch((errorMessage) => {
                    setErrorAlert(errorMessage, alertTimers.long);
                });
        },
    });

    return (
        <>
            {isVerifyTokenSubmission ? (
                <div>
                    {isVerifyTokenSubmissionIsSync && (
                        <div style={{marginBottom: '24px'}}>
                            <Typography variant="t3" className="error">
                            {verifyTokenSubmitError.length < 1
                                ? intl.formatMessage({id: 'auth.newPassword.notVerifyToken'})
                                : verifyTokenSubmitError}
                            </Typography>
                        </div>
                    )}

                    <Link to={localeUrl(NonAuthRoutes.signin)}>
                        <Button variant="contained" color="primary" autoFocus sx={{ width: '100%' }}>
                            {intl.formatMessage({ id: 'ok' })}
                        </Button>
                    </Link>
                </div>
            ) : (
                <div className={st.auth_form}>
                    {!successNewPassIsActive ? (
                        <FormikProvider value={form}>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={form.handleSubmit}
                                onChange={(event) => {
                                    if (form.errors) {
                                        form.setFieldError(event.target.name, '');
                                    }
                                }}
                            >
                                <Grid container spacing={2.5}>
                                    <Grid item xs={12}>
                                        <Typography
                                            component="h1"
                                            variant="h3"
                                            sx={{ mb: 3 }}
                                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.newPassword.title' }) }}
                                            gutterBottom
                                        ></Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            type={showPassword ? 'text' : 'password'}
                                            name="newPassword"
                                            children={(fieldProps) => (
                                                <TextField
                                                    {...fieldProps.field}
                                                    required
                                                    fullWidth
                                                    label={
                                                        <span>{intl.formatMessage({ id: 'auth.newPassword.password' })}</span>
                                                    }
                                                    type={showPassword ? 'text' : 'password'}
                                                    variant="outlined"
                                                    error={form.touched.newPassword && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={form.touched.newPassword && !form.isValid && fieldProps.meta.error}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                    className={st.button_eye}
                                                                    tabIndex={-1}
                                                                >
                                                                    {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            type={showPassword ? 'text' : 'password'}
                                            name="confirm_password"
                                            children={(fieldProps) => (
                                                <TextField
                                                    {...fieldProps.field}
                                                    required
                                                    fullWidth
                                                    label={
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: intl.formatMessage({ id: 'auth.newPassword.confirmPassword' }),
                                                            }}
                                                        ></span>
                                                    }
                                                    type={showPassword ? 'text' : 'password'}
                                                    variant="outlined"
                                                    error={form.touched.confirm_password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                    helperText={form.touched.confirm_password && !form.isValid && fieldProps.meta.error}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" sx={{ tabIndex: "-1" }}>
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                    className={st.button_eye}
                                                                    tabIndex={-1}
                                                                >
                                                                    {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button type="submit" fullWidth color="primary" variant="contained" sx={{ width: '100%', mt:4 }}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: intl.formatMessage({ id: 'save' }),
                                                }}
                                            ></span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            {/*<pre>{JSON.stringify(form.values, 0, 2)}</pre>*/}
                        </FormikProvider>
                    ) : (
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    sx={{ m: 0 }}
                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'success' }) }}
                                    gutterBottom
                                ></Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    component="p"
                                    variant="body1"
                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.newPassword.success.description' }) }}
                                ></Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Link to={localeUrl(NonAuthRoutes.signin)}>
                                    <Button variant="contained" color="primary" onClick={() => setSuccessNewPassIsActive(false)} autoFocus sx={{ width: '100%' }}>
                                        {intl.formatMessage({ id: 'auth.newPassword.success.button' })}
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                </div>
            )}
        </>
    );
};

export default observer(NewPassword);
