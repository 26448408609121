import { makeAutoObservable } from "mobx";
import structuredClone from "@ungap/structured-clone";
import React from "react";
import {Link} from "react-router-dom";
import {NonAuthRoutes} from "../routes/routes";
import UIStore from "./UIStore";
import {getData} from "../api/api";

const defaultUser = {
    id: undefined,
};

class DataStore {
    isUserAuthorized = false;
    isMyProject = false;
    user = structuredClone(defaultUser);
    userType = '';
    userID = '';
    userUrl = '';
    countries = [];
    cities = [];
    languages = [];
    firstSession = [{ title: "true" }, { title: "false" }];
    industry = [];
    timeZone = [];
    positions = [];
    positionsProject = [];
    services = [];
    companyFields = [];
    skillCategories = [];
    skillSubCategories = [];
    hardwareEquipments = [];
    softwareEquipments = [];
    faqCategories = [];
    faqPopular = [];
    categorizedQuestions = {};
    newsYears = [];
    otherNews = [];
    lastNews = [];
    newsByYears = {};
    activeNewsYear = null;
    refreshProject = false;
    auth = {
        verifyTokenSubmitError: "",
        isVerifyTokenSubmission: true,
        isVerifyTokenSubmissionIsSync: false,
        // emailConfirmationSubmitError: "",
        // isEmailConfirmationSubmission: true,
        // isEmailConfirmationSubmissionIsSync: false,
        successSignUpIsActive: false,
        // successForgotPassIsActive: false,
        successNewPassIsActive: false,
    }

    constructor () {
        makeAutoObservable(this);
        let auth = false;
        if (localStorage.getItem(process.env.REACT_APP_AT_NAME) && (localStorage.getItem(process.env.REACT_APP_AT_NAME) !== 'undefined')) {
            // console.log('jwt_decode(access_token)', jwt_decode(localStorage.getItem(process.env.REACT_APP_AT_NAME)));
            // const {id: id} = jwt_decode(localStorage.getItem(process.env.REACT_APP_AT_NAME));
            auth = true;
        }
        this.isUserAuthorized = auth;

/*        let userType = localStorage.getItem('fse_user_type');
        if (userType && (userType !== 'undefined')) {
            this.userType = userType;
        }*/
    }

    refreshUser = () => {
        getData('api/user-by-token')
            .then(({ data }) => {
                if (data.id) {
                    let userData = {...data, online: true};

                    if (this.user?.works?.length) {
                        userData.works = this.user.work;
                    }

                    this.updateUser(userData);
                }
            }).catch((error)=> console.log(error));
    }

    triggerRefreshProject = () => {
        this.refreshProject = !this.refreshProject;
    }

    refreshBalance = () => {
        const balanceId = this.user?.balance?.id;

        if (balanceId) {
            getData(`api/balances/${balanceId}`)
                .then(({data}) => {
                    this.updateUser({...this.user, balance: data});
                })
                .catch((error) => console.log(error));
        }
    }

    updateUser = (value) => {
        this.user = value;
        this.userID = value.id;

        if (value.roles?.length) {
            if (value.roles.includes('ROLE_CLIENT')) {
                this.userType = 'client';
                this.userUrl = `/clients/${ value.id }`;
            } else if (value.roles.includes('ROLE_EXPERT')) {
                this.userType = 'expert';
                this.userUrl = `/experts/${ value.id }`;
            } else if (value.roles.includes('ROLE_COMPANY')) {
                this.userType = 'company';
                this.userUrl = `/companies/${ value.id }`;
            }

            window.localStorage.setItem('fse_user_type', this.userType);
        }

        if (value.roles?.length && !value.roles.includes('ROLE_EXPERT')) {
            const unconfirmedZoomMeetings = value.zoomMeetings?.filter(item => item.completed && item.amount > 0 && !item.confirmed);

            if (unconfirmedZoomMeetings?.length) {
                UIStore.setSuccessAlert(
                    <>
                        {/*{intlProvider.props[locale]["notConfirmedSessions"]}<br />*/}
                        You have not confirmed payments by sessions:<br />

                        {unconfirmedZoomMeetings.map(item =>
                            <React.Fragment key={item.id}><Link to={`${NonAuthRoutes.projects}/${item.work?.id}`}>{item.work?.title}</Link><br/></React.Fragment>
                        )}
                    </>
                );

                UIStore.updateRunZoomIsBlock(true);
            } else {
                UIStore.updateRunZoomIsBlock(false);
            }
        }
    }

    updateCountries = (value) => {
        this.countries = value;
    }

    updateCities = (value) => {
        this.cities = value;
    }

    updateLanguages = (value) => {
        this.languages = value;
    }

    updatePositionsProject = (value) => {
        this.positionsProject = value;
    }

    updateFirstSession = (value) => {
        this.firstSession = value;
    }

    updateTimeZone = (value) => {
        this.timeZone = value;
    }

    updatePositions = (value) => {
        this.positions = value;
    }

    updateServices = (value) => {
        this.services = value;
    }

    updateCompanyFields = (value) => {
        this.companyFields = value;
    }

    updateIndustry = (value) => {
        this.industry = value;
    }

    updateSkillCategories = (value) => {
        this.skillCategories = value;
    }

    updateSkillSubCategories = (value) => {
        this.skillSubCategories = value;
    }

    updateFaqCategories = (value) => {
        this.faqCategories = value;
    }

    updateHardwareEquipments = (value) => {
        this.hardwareEquipments = value;
    }

    updateSoftwareEquipments = (value) => {
        this.softwareEquipments = value;
    }

    updateFaqPopular = (value) => {
        this.faqPopular = value;
    }

    updateCategorizedQuestions = (value, key) => {
        this.categorizedQuestions[key] = value;
    }

    updateNewsYears = (value) => {
        this.newsYears = value;
    }

    updateOtherNews = (value) => {
        this.otherNews = value;
    }

    updateLastNews = (value) => {
        this.lastNews = value;
    }

    updateNewsByYears = (value, key) => {
        this.newsByYears[key] = value;
    }

    updateActiveNewsYear = (value) => {
        this.activeNewsYear = value;
    }

    updateIsMyProject = (value) => {
        this.isMyProject = value;
    }

    resetUser = () => {
        this.user = structuredClone(defaultUser);
        this.isUserAuthorized = false;
        this.userType = '';
        window.localStorage.removeItem(process.env.REACT_APP_AT_NAME);
        window.localStorage.removeItem(process.env.REACT_APP_RT_NAME);
    }

    authorizeUser = (accessToken, refreshToken) => {
        this.isUserAuthorized = true;
        window.localStorage.setItem(process.env.REACT_APP_AT_NAME, accessToken);
        window.localStorage.setItem(process.env.REACT_APP_RT_NAME, refreshToken);
    };

    getFio() {
        return (this.user.profile?.firstName ?? '') + ' ' + (this.user.profile?.lastName ?? '');
    }

}

export default new DataStore();
