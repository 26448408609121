import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import { observer } from 'mobx-react';
import { AuthRoutes } from '../../routes/routes';
import st from './Auth.module.scss';
import DataStore from "../../store/DataStore";
import LandingHeader from "../../components/LandingHeader";
import {Alert} from "../../components/Alert";


const Auth = () => {
    const {isUserAuthorized} = DataStore;

    if (isUserAuthorized) {
        return <Navigate to={AuthRoutes.dashboard} />;
    }

    return (
        <div className={st.auth}>
            <LandingHeader />

            <div className={st.auth_container}>
                <main className={st.main}>
                    <Alert />

                    <div className={st.auth_box}>
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default observer(Auth);
