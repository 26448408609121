import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import st from './news.module.scss';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { NewsPreview } from '../../components/NewsPreview';
import { getData } from '../../api/api';
import DataStore from '../../store/DataStore';
import classNames from 'classnames';
import { NonAuthRoutes } from 'src/routes/routes';

const News = () => {
    const intl = useIntl();
    const query = new URLSearchParams(useLocation().search);
    const newsCategory = query?.get('categories');
    const [visibleNews, setVisibleNews] = useState([]);
    const [loading, setLoading] = useState(true);

    const { newsYears, newsByYears, updateNewsYears, updateNewsByYears, activeNewsYear, updateActiveNewsYear, lastNews, updateLastNews } = DataStore;

    const getNews = (year, news, updateNews) => {
        if (year && (!news[year] || news[year]?.length === 0)) {
            getData(`api/news?year=${year}&perPage=9`)
                .then(({ data }) => {
                    if (data.items?.length) {
                        setVisibleNews(data.items);
                        updateNews(data.items, year);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    function formatHumanDate(date) {
        const dateHuman = new Date(date);

        const monthDayUtc = dateHuman.getUTCDate();
        dateHuman.setUTCDate(monthDayUtc);

        const month = intl.formatDate(dateHuman, { month: 'long' });
        const day = intl.formatDate(dateHuman, { day: 'numeric' });
        const year = intl.formatDate(dateHuman, { year: 'numeric' });

        return `${month} ${day}, ${year}`;
    }

    useEffect(() => {
        let isMounted = true;

        if (newsYears.length === 0) {
            getData('api/news/available-years')
                .then(({ data }) => {
                    if (isMounted && data.length > 0) {
                        updateNewsYears(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (!newsCategory && activeNewsYear && newsByYears[activeNewsYear]?.length > 0) {
            setVisibleNews(newsByYears[activeNewsYear]);
            setLoading(false);
        } else if (!newsCategory && lastNews.length > 0) {
            setVisibleNews(lastNews);
            setLoading(false);
        } else {
            getData(`api/news?perPage=9${newsCategory ? `&categories=${newsCategory}` : ''}`)
                .then(({ data }) => {
                    if (data.items?.length) {
                        if (!newsCategory) {
                            updateLastNews(data.items);
                        }

                        if (isMounted) {
                            setVisibleNews(data.items);
                        }
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }

        return () => {
            isMounted = false;
            updateLastNews([]);
            setVisibleNews([]);
            setLoading(true);
        };
    }, [newsCategory]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!loading && visibleNews?.length < 1) {
        return <Navigate to={NonAuthRoutes.error} />;
    }

    return (
        <main className={st.main}>
            {newsYears.length > 0 && (
                <div className={st.timeline}>
                    <div className={st.timeline__points}>
                            {newsYears?.map((item) => (
                                <div
                                    key={item.year}
                                    className={classNames(st.timeline__point, activeNewsYear === item.year ? st.timeline__point_is_active : '')}
                                    onClick={() => {
                                        updateActiveNewsYear(item.year);
                                        getNews(item.year, newsByYears, updateNewsByYears);
                                    }}
                                >
                                    <div className={st.timeline__inner_point}>{item.year}</div>
                                </div>
                            ))}
                    </div>
                </div>
            )}

            <div className={st.grid}>
                <div className={st.grid__container}>
                    {visibleNews.length ? (
                        visibleNews.map((post, index) => (
                            <NewsPreview
                                index={index}
                                key={post.id}
                                id={post.id}
                                picture={post.picture}
                                date={formatHumanDate(post.createdAt)}
                                title={post.title}
                                tags={post.tags}
                                categories={post.categories}
                            />
                        ))
                    ) : (
                        <Stack spacing={2}>
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                            <Skeleton variant="rectangular" height={100} />
                        </Stack>
                    )}
                </div>
            </div>

            <div className={st.pagination}></div>
        </main>
    );
};

export default observer(News);
