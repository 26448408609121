// import React from 'react';
import st from './or.module.scss';
import { useIntl } from 'react-intl';


export const Or = () => {
    const intl = useIntl();

    return (
        <div className={st.container}>
            <div className={st.line}></div>
            <div className={st.title}>{intl.formatMessage({id: 'or'})}</div>
        </div>
    );
};
