import {makeAutoObservable, runInAction} from "mobx";
import { getData } from "src/api/api";
import isEmpty from 'lodash/isEmpty';
import {cleanObject} from "../helpers/helpers";
import qs from "qs";
import {NonAuthRoutes} from "../routes/routes";
import UIStore from "./UIStore";
import structuredClone from "@ungap/structured-clone";

export const searchParametersDefault = {
    rate: [0, 0],
    // jobs: [0, 500],
    "profile.country": [],
    "profile.city": [],
    positions: [],
    services: [],
    firstSessionFree: null,
    type: '', // Type of Work, only fo Projects list
    "profile.languages": [],
    "professionalSkills.skillCategory": null,
    "professionalSkills.skillSubCategories": [],
    softwareEquipments: [],
    hardwareEquipments: [],
};

const {localeUrl} = UIStore;

class FiltersStore {
    activeCountries = [];
    activeCities = [];
    activeLanguages = [];
    loading = false;
    filterActive = false;
    totalCountExperts = 0;
    totalCountProjects = 0;
    expertsList = [];
    projectsList = [];
    searchParameters = structuredClone(searchParametersDefault);

    constructor () {
        makeAutoObservable(this);
    }

    renamingForExperts = (params) => {
        return {
            ...params,
            'ratePer[between]': params?.rate ? `${params.rate[0]}..${params.rate[1]}` : null,
            rate: null,
        }
    }

    urlRenamingForProjects = (params) => {
        return {
            "professionalSkills.skillCategory": params?.['professionalSkills.skillCategory'] ?? null,
            "professionalSkills.skillSubCategories": params?.['professionalSkills.skillSubCategories'] ?? null,
            softwareEquipments: params?.softwareEquipments ?? null,
            hardwareEquipments: params?.hardwareEquipments ?? null,
            positions: params?.positions ?? null,
            services: params?.services ?? null,
            type: params?.type ?? null,
        }
    }

    renamingForProjects = (params) => {
        return {
            category: params?.['professionalSkills.skillCategory'] ?? null,
            subCategories: params?.['professionalSkills.skillSubCategories'] ?? null,
            software: params?.softwareEquipments ?? null,
            hardware: params?.hardwareEquipments ?? null,
            position: params?.positions ?? null,
            service: params?.services ?? null,
            type: params?.type ?? null,
        }
    }

    correctRange = (value) => {
        if (value?.rate[0] === value?.rate[1]) {
            return {...value , rate: null};
        }

        return value;
    }

    updateActiveCountries = (value) => {
        this.activeCountries = value;
    }

    updateActiveCities = (value) => {
        this.activeCities = value;
    }

    updateActiveLanguages = (value) => {
        this.activeLanguages = value;
    }

    setIsLoading = (state) => {
        this.loading = state;
    }

    updateSearchParameters = (values) => {
        this.searchParameters = values;
    }

    getExpertList = (parameters = undefined, isLoading = true) => {
        if (isLoading) {
            this.setIsLoading(true);
            UIStore.showPreloader();
        }

        if (!parameters) {
            this.filterActive = false;
        } else {
            this.filterActive = true;
        }

        getData(`api/experts?active=1${parameters ? ('&' + qs.stringify(cleanObject(this.renamingForExperts(parameters), true))) :''}`)
            .then(({ data }) => {
                runInAction(() => {
                    this.totalCountExperts = data.pagination.totalItems;
                    this.expertsList = data.items;
                })
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setIsLoading(false);
                UIStore.hidePreloader();
            });
    };

    getProjectList = (parameters) => {
        this.setIsLoading(true);
        UIStore.showPreloader();

        if (!parameters) {
            this.filterActive = false;
        } else {
            this.filterActive = true;
        }

        getData(`api/works?status=opened${parameters ? ('&' + qs.stringify(cleanObject(this.renamingForProjects(parameters), true))) :''}`)
            .then(({ data }) => {
                runInAction(() => {
                    this.totalCountProjects = data.pagination.totalItems;
                    this.projectsList = data.items;
                })
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setIsLoading(false);
                UIStore.hidePreloader();
            });
    };

    getList = (type, params = undefined) => {
        if (type === 'experts') {
            this.getExpertList(params);
        } else {
            this.getProjectList(params);
        }
    };

    setFilterOrSort = (parameter, value, address = 'experts', urlUpdate = true) => {
        let newUrl = ''
        if (parameter !== 'reset') {
            this.searchParameters[parameter] = value;

            if (urlUpdate) {
                newUrl = qs.stringify(cleanObject(
                    (address === 'experts' ? this.correctRange(this.searchParameters) : this.urlRenamingForProjects(this.searchParameters)),
                    true));
            }
        }

        const reset = (parameter === 'reset' || (!newUrl && isEmpty(value) && (parameter !== 'professionalSkills.skillCategory')));

        if (reset) {
            this.searchParameters = structuredClone(searchParametersDefault);
            this.filterActive = false;
            window.history.pushState({}, "", (address === 'experts' ? localeUrl(NonAuthRoutes.experts) : localeUrl(NonAuthRoutes.projects)));
        } else {
            this.filterActive = true;
            if (urlUpdate) {
                window.history.pushState({}, "", '?' + newUrl);
            }
        }

        if (urlUpdate) {
            if (address === 'experts') {
                if (reset) {
                    this.getExpertList(undefined);
                } else {
                    this.getExpertList(this.correctRange(this.searchParameters));
                }
            } else if (address === 'projects') {
                if (reset) {
                    this.getProjectList();
                } else {
                    this.getProjectList(this.searchParameters);
                }
            }
        }
    };
}

export default new FiltersStore();
