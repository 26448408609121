import React from 'react';
import st from './skeleton.module.scss';


export const Skeleton = ({ avatar = undefined }) => {

    return (
        <div className={st.item}>
            <div className={st.info}>
                {avatar &&
                    <div className={st.avatar}></div>
                }

                <div className={st.doctor_info}>
                    <div className={st.fill}></div>
                    <div className={st.fill}></div>
                    <div className={st.fill}></div>
                </div>
            </div>
        </div>
    );
};
