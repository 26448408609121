import React, { useEffect } from 'react';
import st from './CardPreview.module.scss';
// import { ReactComponent as StarIcon } from '../../assets/images/icons/star.svg';
// import { ReactComponent as LocationIcon } from '../../assets/images/icons/location.svg';
// import { ReactComponent as JobIcon } from '../../assets/images/icons/job.svg';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {
    Avatar,
    Badge,
    Button,
    ClickAwayListener,
    Dialog,
    Fade,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField,
} from '@mui/material';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/cross.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/images/icons/job.svg';
import { ReactComponent as SessionIcon } from '../../assets/images/icons/session.svg';
import { ReactComponent as EditSvg } from '../../assets/images/icons/edit.svg';
import { ReactComponent as MoreIcon } from '../../assets/images/icons/more.svg';
import { ReactComponent as FileIcon } from '../../assets/images/icons/flag-file.svg';
import { ReactComponent as MoneyIcon } from '../../assets/images/icons/money.svg';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import stPopup from '../Popup/popup.module.scss';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AuthRoutes, NonAuthRoutes } from 'src/routes/routes';
import axios from 'axios';
import { stringAvatar } from 'src/helpers/helpers';
import { postData } from 'src/api/api';
// import CrossIcon from '../Icons/CrossIcon';
import DataStore from 'src/store/DataStore';
import UIStore from 'src/store/UIStore';
import { LocalPopup } from '../LocalPopup';
import FiltersStore from '../../store/FiltersStore';

const ReplyButton = ({ data, setDataProject = undefined, dataProject = {} }) => {
    const intl = useIntl();
    const { user, userID, isUserAuthorized } = DataStore;
    const { tabletIs, openPopup, setErrorAlert, alertTimers } = UIStore;
    const { projectsList } = FiltersStore;
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleForm = () => {
        const dataReplay = {
            work: `api/works/${data.id}`,
            sender: `api/experts/${user.id}`,
            recipient: `api/${data.company ? `users/${data.company.id}` : `users/${data.client.id}`}`,
            message: {
                text: message,
                sender: `api/experts/${user.id}`,
            },
        };

        postData(`api/reply-chat-message`, dataReplay, 'post')
            .then(({ data }) => {
                data.from = user;

                if (setDataProject) {
                    setDataProject({ ...dataProject, replies: [...dataProject.replies, data] });
                } else {
                    const indexCurrentProject = projectsList.findIndex((item) => item.id === dataProject.id);
                    projectsList[indexCurrentProject].replies = [...projectsList[indexCurrentProject].replies, data];
                }

                setOpen(false);
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? error : intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
            });
    };

    return (
        <>
            <Button
                className={st.button}
                size="small"
                fullWidth
                variant="contained"
                color="primary"
                onClick={isUserAuthorized ? handleClickOpen : openPopup}
            >
                {intl.formatMessage({ id: 'projects.card.reply' })}
            </Button>

            <Dialog TransitionComponent={Fade} onClose={handleClose} open={open} scroll="body" className={classNames(stPopup.popup)}>
                <div className={classNames(stPopup.container, 'popup-container')}>
                    <IconButton className={stPopup.close} onClick={handleClose}>
                        <CrossIcon />
                    </IconButton>
                    {user && !user.active ? (
                        <>
                            <div
                                className={stPopup.title}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'projectsPost.popup.title' }) }}
                            />
                            <div
                                className={stPopup.text}
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({ id: 'projects.activate.title' }),
                                }}
                            />
                            <Link to={`/experts/${userID}/edit`}>
                                <Button sx={{ width: '100%' }} variant="contained">
                                    {intl.formatMessage({ id: 'projectsPost.popup.button' })}
                                </Button>
                            </Link>
                        </>
                    ) : (
                        <>
                            <div className={stPopup.title}>{intl.formatMessage({ id: 'projects.card.popup.title' }, { name: `${data.title}` })}</div>

                            <div className={stPopup.text}>
                                <TextField
                                    fullWidth
                                    placeholder={intl.formatMessage({ id: 'projects.card.popup.text' })}
                                    multiline
                                    minRows={3}
                                    maxRows={tabletIs ? 4 : 8}
                                    value={message}
                                    onChange={handleChangeMessage}
                                />
                            </div>

                            <Button variant="contained" color="primary" fullWidth size="large" onClick={handleForm}>
                                {intl.formatMessage({ id: 'projects.card.popup.send' })}
                            </Button>
                        </>
                    )}
                </div>
            </Dialog>
        </>
    );
};

const CardPreviewProject = ({
    data,
    type = undefined,
    setData = undefined,
    restrictedDisplay = false,
    complaint = undefined,
    cancelRefund = undefined,
    closeProject = undefined,
}) => {
    const intl = useIntl();
    const [open, setOpen] = React.useState(false);
    const [isOpenPopupCloseProject, setIsOpenPopupCloseProject] = React.useState(false);
    const [isOpenPopupCancelProject, setIsOpenPopupCancelProject] = React.useState(false);
    const [isOpenPopupReservedSum, setIsOpenPopupReservedSum] = React.useState(false);
    const menuRef = React.useRef(null);
    const { localeUrl, setSuccessAlert, setErrorAlert, alertTimers } = UIStore;
    const { userID, userType, user, updateUser } = DataStore;
    const [reservedAmount, setReservedAmount] = React.useState('');

    useEffect(() => {
        if (data?.fixedPrice && data?.workCost?.number) {
            setReservedAmount(data?.workCost?.number);
        }
    }, [data?.fixedPrice, data?.workCost?.number]);

    const handleChangeReservedAmount = (event) => {
        setReservedAmount(event.target.value);
    };

    const reservedSum = (amount) => {
        setSuccessAlert('');
        setErrorAlert('');

        if (amount <= 0) {
            setErrorAlert(intl.formatMessage({ id: 'greaterZero' }), alertTimers.long);
        } else {
            const amountInCent = amount * 100;
            postData(`api/reserve-balance`, { amount: amountInCent, work: `api/works/${data?.id}` })
                .then((response) => {
                    if (response.status === 201) {
                        setSuccessAlert(intl.formatMessage({ id: 'successReserved' }), alertTimers.long);
                        setData({ ...data, reserved: (data.reserved + amountInCent ) });
                        updateUser({ ...user, balance: { ...user.balance, available: (user.balance?.available - amountInCent) } });
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                    setErrorAlert(JSON.stringify(error));
                })
                .finally(() => {
                    setIsOpenPopupReservedSum(false);
                });
        }
    };

    const handlePopupCloseProject = () => {
        setIsOpenPopupCloseProject(false);
    };

    const handlePopupCancelProject = () => {
        setIsOpenPopupCancelProject(false);
    };

    const handlePopupReservedSum = () => {
        setIsOpenPopupReservedSum(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (menuRef.current && menuRef.current.contains(event?.target)) {
            return;
        }
        setOpen(false);
    };

    const checkOwner = (data) => {
        return (
            [data?.client?.id, data?.company?.id].includes(userID) ||
            data?.client === `/api/clients/${userID}` ||
            data?.company === `/api/companies/${userID}`
        );
    };

    if (!data) {
        return <></>;
    }

    return (
        <article className={classNames(st.container, type === 'singlePage' ? st.single_page : '')}>
            <div className={st.main}>
                <div className={st.main__content}>
                    <div className={st.name}>
                        {data.title}

                        {data.company && <div className={st.badge}>{intl.formatMessage({ id: 'enterprise' })}</div>}

                        {checkOwner(data) && (
                            <Link to={localeUrl(`${NonAuthRoutes.projects}/${data.id}/edit`)} className={st.edit}>
                                <IconButton aria-label="edit">
                                    <EditSvg />
                                </IconButton>
                            </Link>
                        )}
                    </div>

                    <div className={st.statuses}>
                        <div className={st.date}>{data.beautyCreatedAt}</div>

                        {type !== 'commonList' && (
                            <div
                                className={st.status__label}
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({ id: 'projects.card.status' }, { status: `<b>${data.status ?? 'opened'}</b>` }),
                                }}
                            />
                        )}
                    </div>

                    {type === 'singlePage' && !restrictedDisplay && (
                        <div className={st.more_menu} onClick={handleToggle} ref={menuRef}>
                            <IconButton className={st.more_menu__button}>
                                <MoreIcon height="16px" />
                            </IconButton>

                            <Popper open={open} anchorEl={menuRef.current} role={undefined} placement="bottom-end" transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow {...TransitionProps}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={open}>
                                                    {['opened', 'completed'].includes(data.status) && checkOwner(data) && (
                                                        <MenuItem onClick={() => setIsOpenPopupCloseProject(true)}>
                                                            {intl.formatMessage({ id: 'closeProject' })}
                                                        </MenuItem>
                                                    )}

                                                    <MenuItem onClick={() => (complaint ? complaint() : undefined)}>
                                                        {intl.formatMessage({ id: 'submitComplaint' })}
                                                    </MenuItem>

                                                    {userType === 'expert' && data.status === 'ongoing' && (
                                                        <MenuItem onClick={() => setIsOpenPopupCancelProject(true)}>
                                                            {intl.formatMessage({ id: 'cancelAndRefund' })}
                                                        </MenuItem>
                                                    )}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    )}
                </div>

                {type === 'singlePage' &&
                    !restrictedDisplay &&
                    data.type === 'projects' &&
                    !['closed', 'completed', 'cancelled', 'complained'].includes(data.status) && (
                        <>
                            {(userType !== 'expert' && [data.client?.id, data.company?.id].includes(userID)) && (
                                <div className={st.project_balance_buttons}>
                                    <Link to={AuthRoutes.balance}>
                                        <Button variant="outlined" color="primary">
                                            {intl.formatMessage({ id: 'topUpBalance' })}
                                        </Button>
                                    </Link>

                                    <Button variant="contained" color="primary" onClick={() => setIsOpenPopupReservedSum(true)}>
                                        {intl.formatMessage({ id: 'reserveFunds' })}
                                    </Button>
                                </div>
                            )}

                            <div className={classNames(st.money_not_reserved, data.reserved ? st.is_reserve : '')}>
                                <MoneyIcon />

                                <div>
                                    {data.reserved
                                        ? `${data.reserved / 100} ${intl.formatMessage({ id: 'reserved' })}`
                                        : intl.formatMessage({ id: 'moneyNotReserved' })}
                                </div>
                            </div>
                        </>
                    )}

                <div className={st.main__info}>
                    <div className={st.info}>
                        {data.workCost?.number && data.workCost?.number > 0 ? (
                            <div className={st.info__price}>
                                {data.workCost?.currency}&nbsp;
                                {data.fixedPrice ? (
                                    data.workCost?.number
                                ) : (
                                    <>
                                        {data.workCost?.number}&nbsp;
                                        {data.duration ? (
                                            <>
                                                {intl.formatMessage({ id: 'projects.card.inMin' }, { duration: data.duration })}{' '}
                                                <span>{intl.formatMessage({ id: 'projects.card.perMin' })}</span>
                                            </>
                                        ) : (
                                            <>/hr </>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : (
                            ''
                        )}

                        {data.type && data.type === 'projects' ? (
                            <div className={st.info__type}>
                                <ProjectsIcon />
                                {intl.formatMessage({ id: `projects.card.projects` })}
                            </div>
                        ) : (
                            <div className={classNames(st.info__type, st.info__type_session)}>
                                <SessionIcon />
                                {intl.formatMessage({ id: `projects.card.sessions` })}
                            </div>
                        )}

                        {data.category && (
                            <div className={st.info__profession}>
                                {data.category.title}:{' '}
                                {data.subCategories.length &&
                                    data.subCategories.map((item, index, array) => item.title + (index + 1 !== array.length ? ', ' : ''))}
                            </div>
                        )}
                    </div>
                </div>

                {data.description ? <p className={st.desc}>{data.description}</p> : ''}

                {data.position?.title?.length > 0 && (
                    <div className={st.main__list}>
                        <div className={st.main__list_label}>{intl.formatMessage({ id: 'expertType' })}</div>
                        <p className={st.main__list_desc}>{data.position.title}</p>
                    </div>
                )}

                {data.service?.title?.length > 0 && (
                    <div className={st.main__list}>
                        <div className={st.main__list_label}>{intl.formatMessage({ id: 'lookingFor' })}</div>
                        <p className={st.main__list_desc}>{data.service.title}</p>
                    </div>
                )}

                {data.file && (
                    <a target="_blank" rel="noopener noreferrer" href={axios.defaults.baseURL + data.file.contentUrl} className={st.file_link}>
                        <FileIcon />
                        {data.file.contentUrl.replace(/\/.*media\/.*?_/, '')}
                    </a>
                )}

                <div className={st.after_line}>
                    {data.software?.length > 0 && data.hardware?.length > 0 && (
                        <div className={st.skill_box}>
                            {data.software?.length > 0 && (
                                <div className={st.main__skills}>
                                    <div className={st.main__skills_label}>Softwares</div>

                                    <Stack direction="row" spacing={1}>
                                        {data.software.map((skill, i) => (
                                            <Chip key={i + 'softwares'} size="small" label={skill.title} />
                                        ))}
                                    </Stack>
                                </div>
                            )}

                            {data.hardware?.length > 0 && (
                                <div className={st.main__skills}>
                                    <div className={st.main__skills_label}>Hardwares</div>

                                    <Stack direction="row" spacing={1}>
                                        {data.hardware.map((skill, i) => (
                                            <Chip key={i + 'hardwares'} size="small" label={skill.title} />
                                        ))}
                                    </Stack>
                                </div>
                            )}
                        </div>
                    )}

                    {['commonList', 'singlePage'].includes(type) &&
                        userType === 'expert' &&
                        data.status === 'opened' &&
                        data.replies.filter((item) => item.from?.id === userID).length === 0 && (
                            <div className={st.reply}>
                                <ReplyButton data={data} setDataProject={setData} dataProject={data} />
                            </div>
                        )}
                </div>

                {data.expert && userType !== 'expert' && (
                    <div className={st.bottom}>
                        <div className={st.user}>
                            <div className={st.user__media}>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                    className={classNames(st.user__status, data.expert?.online ? st.user__status_online : st.user__status_offline)}
                                >
                                    <Avatar
                                        src={data.expert.profile?.avatar ? axios.defaults.baseURL + data.expert.profile?.avatar.contentUrl : ''}
                                        className={st.avatar}
                                        sx={{
                                            width: { xs: '48px' },
                                            height: { xs: '48px' },
                                        }}
                                        {...stringAvatar(`${data.expert.profile?.firstName} ${data.expert.profile?.lastName}`)}
                                    />
                                </Badge>
                            </div>

                            <div className={st.user__content}>
                                <div className={st.user__info}>{intl.formatMessage({ id: 'projects.card.assigned' })}</div>
                                <div className={st.user__name}>
                                    {data.expert.profile?.firstName} {data.expert.profile?.lastName}
                                </div>
                            </div>
                        </div>

                        {/*                        <div className={st.bottom__buttons}>
                            <Button className={st.bottom__button} fullWidth variant="contained" color="primary">
                                {intl.formatMessage({ id: 'projects.card.project' })}
                            </Button>
                            <Button className={st.bottom__button} fullWidth variant="contained" color="primary">
                                {intl.formatMessage({ id: 'projects.card.session' })}
                            </Button>
                        </div>*/}
                    </div>
                )}
            </div>

            {type !== 'singlePage' && <Link to={`${NonAuthRoutes.projects}/${data.id}`} className={st.link}></Link>}

            <LocalPopup isOpen={isOpenPopupCloseProject} close={handlePopupCloseProject}>
                <div className="popup-title">{intl.formatMessage({ id: 'closeProject' })}</div>

                <div className="popup-desc">{intl.formatMessage({ id: 'warningForCloseProject' })}</div>

                <div className="popup-buttons">
                    <Button variant="contained" color="primary" fullWidth size="large" onClick={closeProject}>
                        {intl.formatMessage({ id: 'confirm' })}
                    </Button>

                    <Button variant="outlined" color="primary" fullWidth size="large" onClick={() => setIsOpenPopupCloseProject(false)}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                </div>
            </LocalPopup>

            <LocalPopup isOpen={isOpenPopupCancelProject} close={handlePopupCancelProject}>
                <div className="popup-title">{intl.formatMessage({ id: 'cancelRefund' })}</div>

                <div className="popup-desc">{intl.formatMessage({ id: 'warningForCancel' })}</div>

                <div className="popup-buttons">
                    <Button variant="contained" color="primary" fullWidth size="large" onClick={() => cancelRefund('cancelled')}>
                        {intl.formatMessage({ id: 'confirm' })}
                    </Button>

                    <Button variant="outlined" color="primary" fullWidth size="large" onClick={() => setIsOpenPopupCancelProject(false)}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                </div>
            </LocalPopup>

            <LocalPopup isOpen={isOpenPopupReservedSum} close={handlePopupReservedSum}>
                <div className="popup-title">{intl.formatMessage({ id: 'reservedSum' })}</div>

                <div className="popup-desc">
                    {intl.formatMessage({ id: 'jobName' })}
                    <strong>{data?.title}</strong>
                </div>

                <div> {intl.formatMessage({ id: 'reservedSumDesc' })}</div>

                <TextField
                    className="input-number"
                    fullWidth
                    label={intl.formatMessage({ id: 'amount' })}
                    type="number"
                    InputProps={{
                        inputProps: { min: 0 },
                    }}
                    onKeyDown={(e) => {
                        if (isNaN(e?.key) && e?.key?.length === 1) {
                            e.preventDefault();
                        }
                    }}
                    value={reservedAmount}
                    onChange={handleChangeReservedAmount}
                />

                <div className="current-ballance">
                    {intl.formatMessage({ id: 'amountOnBalance' })}: <strong>{user?.balance?.available ? user.balance.available/100 : 0}</strong>
                </div>

                <div className="popup-buttons">
                    <Button variant="contained" color="primary" fullWidth size="large" onClick={() => reservedSum(Number(reservedAmount))}>
                        {intl.formatMessage({ id: 'ok' })}
                    </Button>

                    <Button variant="outlined" color="primary" fullWidth size="large" onClick={() => setIsOpenPopupReservedSum(false)}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                </div>
            </LocalPopup>
        </article>
    );
};

export default observer(CardPreviewProject);
