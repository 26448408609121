import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import st from './messagesList.module.scss';
import classNames from 'classnames';
import DataStore from 'src/store/DataStore';
import ChatStore from 'src/store/ChatStore';
import { observer } from 'mobx-react';
import stSession from '../../../pages/Session/session.module.scss';
import Textarea from '../Textarea/Textarea';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {ChatMessage} from "../../ChatMessage";


const MessagesList = () => {
    const intl = useIntl();
    const baseNextPage = 3;
    const { activeChat, chatList, chatListLength } = ChatStore;
    const { userID } = DataStore;
    const scrollbars = React.useRef(null);
    const [paginateMap, setPaginateMap] = React.useState({});
    const [paginateState, setPaginateState] = React.useState(false);

    const messages = chatList?.[activeChat?.channelName]?.messages.length;
    // подгрузка сообщений по скроллу в чате
    const handleScroll = (event) => {
        if (event.scrollTop < 1) {
            let nextPage = paginateMap[activeChat.channelName] ? paginateMap[activeChat.channelName] + 1 : baseNextPage;
            setPaginateState(true);

            ChatStore.getMoreMessages(activeChat, nextPage, 10).then((data) => {
                setPaginateMap((prev) => ({
                    ...prev,
                    [activeChat.channelName]: prev[activeChat.channelName] ? prev[activeChat.channelName] + 1 : baseNextPage,
                }));
                setTimeout(() => {
                    setPaginateState(false);
                }, 100);
            });
        }
    };

    useEffect(() => {
        if (!paginateState) {
            scrollbars?.current?.scrollToBottom();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollbars, messages, activeChat.id]);

    // useEffect(() => {
    //     if (paginateState) {
    //         scrollbars?.current?.scrollTop(paginateState);
    //         console.log(scrollbars?.current?.getValues(), paginateState);
    //     }
    // }, [scrollbars, paginateState]);

    if (!chatListLength) {
        return <></>;
    }
    // console.log(toJS(chatList?.[activeChat?.channelName]?.messages));
    return (
        <>
            <Scrollbars
                ref={scrollbars}
                autoHide
                renderView={(props) => <div {...props} className={stSession.view} />}
                className={classNames(stSession.scroller, st.scroller)}
                onScrollFrame={handleScroll}
            >
                <div className={stSession.messages}>
                    <div className={st.group}>
                        {Object.entries(
                            chatList[activeChat.channelName]?.messages
                                // сортируем даты по возрастанию
                                ?.slice()
                                .sort((a, b) => {
                                    return a.id - b.id;
                                })
                                // разделяем сообщения по дням
                                .reduce((res, el) => {
                                    const date = el.createdAt.split('T')[0];
                                    if (!res[date]) {
                                        res[date] = [];
                                    }

                                    res[date].push(el);

                                    return res;
                                }, {})
                        )?.map((message, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className={st.day}>
                                        {intl.formatDate(new Date(message[0]), {
                                            day: '2-digit',
                                            month: 'short',
                                        })}
                                    </div>

                                    <div className={st.dialog}>
                                        <div className={st.dialog__list}>
                                            {message[1].map((dialog, index) =>
                                                dialog.sender?.id === userID ? (
                                                    <ChatMessage key={index + 'a'} dialog={dialog} isMyMessage={true} />
                                                ) : (
                                                    <ChatMessage key={index + 'b'} dialog={dialog} />
                                                )
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </Scrollbars>

            <Textarea />
        </>
    );
};

export default observer(MessagesList);
