import React, { useState } from 'react';
import {Link} from "react-router-dom";
import st from './LandingHeader.module.scss';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import {NonAuthRoutes} from "../../routes/routes";


export const Dropdown = ({ submenus, dropdown, depthLevel }) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? st.dropdown__submenu : '';

    return (
        <ul className={classNames(st.dropdown, dropdownClass, dropdown ? st.show : '')}>
            {submenus.map((submenu, index) => (
                <MenuItems items={submenu} key={index} depthLevel={depthLevel} />
            ))}
        </ul>
    );
};

const MenuItems = ({ items, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false);

    return items.submenu ? (
        <ClickAwayListener onClickAway={() => setDropdown(false)}>
            <li className={st.menu__items}>
                <button
                    type="button"
                    aria-haspopup="menu"
                    aria-expanded={dropdown ? 'true' : 'false'}
                    onClick={() => setDropdown((prev) => !prev)}
                    className={classNames('btn', dropdown ? 'is-active' : '')}
                >
                    {items.title}
                    <ArrowIcon />
                </button>
                <Dropdown depthLevel={depthLevel} submenus={items.submenu} dropdown={dropdown} />
            </li>
        </ClickAwayListener>
    ) : (
        <li className={st.menu__items}>
            <Link to={items.url ? items.url : (items?.skillCategory?.id ? `${NonAuthRoutes.experts}/?professionalSkills.skillCategory=${items.skillCategory.id}&professionalSkills.skillSubCategories[0]=${items.id}` : "#/")}>
                {items.title}
            </Link>
        </li>
    );
};

export default MenuItems;
