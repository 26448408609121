import React from 'react';
import {Autocomplete, TextField,} from '@mui/material';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import FiltersStore from 'src/store/FiltersStore';
import st from './filters.module.scss';


export const FilterAutocomplete = ({
                                       values = [],
                                       multiple = true,
                                       name, options,
                                       label,
                                       callback = undefined,
                                       disabled = undefined,
                                       type = 'experts',
                                   }) => {
    const { setFilterOrSort } = FiltersStore;

    if (!multiple) {
        return (
            <Autocomplete
                fullWidth
                blurOnSelect
                disablePortal
                disableClearable
                disabled={disabled}
                id={name}
                name={name}
                options={options}
                className={st.input__box}
                popupIcon={<ArrowIcon />}
                value={values}
                onChange={(event, newValue) => {
                    setFilterOrSort(name, newValue ?? null, type);

                    if (callback) {
                        callback(newValue);
                    }
                }}
                getOptionLabel={(option) => (option.title ? option.title : '')}
                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                renderInput={(params) => <TextField {...params} {...params} fullWidth label={label} type="text" variant="outlined" />}
            />
        );
    }

    return (
        <Autocomplete
            multiple
            blurOnSelect
            disablePortal
            filterSelectedOptions
            disabled={disabled}
            id={name}
            name={name}
            options={options}
            className={st.input__box}
            popupIcon={<ArrowIcon />}
            value={values}
            onChange={(e, values) => {
                let arr = values?.map((item) => item.id);
                setFilterOrSort(name, arr ?? [], type);

                if (callback) {
                    callback(values);
                }
            }}
            getOptionLabel={(option) => (option.title ? option.title : '')}
            isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
            renderInput={(params) => <TextField {...params} label={label} type="text" variant="outlined" />}
        />
    );
};
