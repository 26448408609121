import React, { useEffect } from 'react';
import './settings/axios';
import './assets/styles/global/index.scss';
import {Routes, Route} from 'react-router-dom';
import AuthRoute from './routes/AuthRoute';
import { NonAuthRoutes, AuthRoutes } from './routes/routes';
import { observer } from 'mobx-react';
import Auth from './pages/Auth';
import { Home } from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import {IntlProvider} from 'react-intl';
import English from './lang/en.json';
import ErrorPage from './pages/ErrorPage';
import Preloader from './components/Preloader';
import { Helmet } from 'react-helmet-async';
import UIStore from './store/UIStore';
import { getData } from './api/api';
import LandingLayout from './layouts/LandingLayout';
import SignIn from './pages/Auth/SignIn/SignIn';
import SignUp from './pages/Auth/SignUp/SignUp';
import NewPassword from './pages/Auth/NewPassword/NewPassword';
import ForgotPassword from './pages/Auth/ForgotPassword/ForgotPassword';
import EmailConfirmation from './pages/Auth/EmailConfirmation/EmailConfirmation';
import CabinetLayout from './layouts/Cabinet/CabinetLayout';
import AccountSettings from './pages/Cabinet/AccountSettings/AccountSettings';
import Dashboard from './pages/Cabinet/Dashboard/Dashboard';
import Faq from './pages/Faq';
import FaqId from './pages/FaqId';
import ForEmployers from './pages/ForEmployers';
import Contact from './pages/Contact/Contact';
import Messages from './pages/Messages/Messages';
import DataStore from "./store/DataStore";
import ProfileEdit from "./pages/ProfileEdit/ProfileEdit";
import News from './pages/News';
import NewsId from './pages/NewsId';
import Experts from './pages/Experts';
import ExpertId from './pages/ExpertId';
import Projects from './pages/Projects';
import ProjectEdit from "./pages/Cabinet/ProjectEdit";
import MyProjects from './pages/MyProjects';
import Balance from './pages/Balance';
import ProjectId from './pages/ProjectId';
import OnlineStore from './store/OnlineStore';
import SessionHistory from './pages/SessionHistory';
import ReadyStore from "./store/ReadyStore";
import ChatStore from "./store/ChatStore";


const App = () => {
    const { locale, updateUserLoading, closeAllPopup,
        mediaQuery, mediaQueryTablet, setMobileIs, setTabletIs,
        setSuccessAlert,
        updateRunZoomIsBlock,
        WSclient,
    } = UIStore;
    const { user, isUserAuthorized, userID } = DataStore;
    const { chatList, chatListLength } = ChatStore;
    const intlProvider = new IntlProvider({ 'en': English, });

    window.onpopstate = (e) => {
        closeAllPopup();
    }

    useEffect(() => {
        let isMounted = true;

        if (isUserAuthorized) {
            updateUserLoading(true);

            getData('api/user-by-token')
                .then(({ data }) => {
                    if (isMounted && data.id) {
                        const userData = {...data, online: true};
                        DataStore.updateUser(userData);
                        UIStore.activateWSClient();

                        let userType = '';
                        let userId = data.id;

                        if (data.roles.includes('ROLE_CLIENT')) {
                            userType = 'client';
                        } else if (data.roles.includes('ROLE_EXPERT')) {
                            userType = 'expert';
                        } else if (data.roles.includes('ROLE_COMPANY')) {
                            userType = 'company';
                        }

                        if (userType) {
                            ChatStore.getChatList();
                            ChatStore.getWorkChatList();

                            getData(`api/works?${userType}=${userId}`)
                                .then(({ data }) => {
                                    if (isMounted) {
                                        DataStore.updateUser({ ...userData, works: data?.items });

                                        if (userType === 'expert') {
                                            const complainedJobs = data?.items?.filter(item => item.status === 'complained');

                                            if (complainedJobs?.length && complainedJobs.filter(item => item.chatWorkHistoryMessages?.filter(
                                                message => (message.historyStatus === 'complained' && message.sender?.id !== userId))?.length > 0)?.length) {
                                                updateRunZoomIsBlock(true);
                                            }
                                        }

                                        if (data?.items?.length && UIStore.WSclient !== null) {
                                            ReadyStore.subscribeToChannelReady(`session_ready_${userId}`);
                                            ReadyStore.subscribeToReadyStatus();
                                        }
                                    }
                                })
                                .catch((error) => {console.log(error)});
                        }
                    }
                })
                .finally(() => {
                    if (isMounted) {
                        updateUserLoading(false);
                    }
                });
        }

        return () => { isMounted = false; ChatStore.clearChat(); };
    }, [updateUserLoading, isUserAuthorized, setSuccessAlert, updateRunZoomIsBlock, ]);

    useEffect(() => {
        if (userID && chatListLength && WSclient !== null) {
            Object.values(chatList).map((chat, index) => {
                ChatStore.subscribeToChannel(chat.channelName);

                return false;
            });
        }
    }, [userID, WSclient, chatList, chatListLength]);

    useEffect(() => {
        if (userID && WSclient !== null) {
            OnlineStore.subscribeToChannelOnline(`user_online_${userID}`);
            OnlineStore.subscribeToEventOnline();
            OnlineStore.setOnlineStatus(true);
        }
    }, [userID, WSclient]);

    useEffect(() => {
        const mediaMobile = window.matchMedia(mediaQuery);
        const mediaTablet = window.matchMedia(mediaQueryTablet);

        const handleMobile = (query) => {
            setMobileIs(query.matches);
        };

        const handleTablet = (query) => {
            setTabletIs(query.matches);
        };

        handleMobile(mediaMobile);
        handleTablet(mediaTablet);

        if (mediaMobile?.addEventListener) {
            mediaMobile.addEventListener('change', handleMobile);
        } else {
            mediaMobile.addListener(handleMobile);
        }
        if (mediaTablet?.addEventListener) {
            mediaTablet.addEventListener('change', handleTablet);
        } else {
            mediaTablet.addListener(handleTablet);
        }

        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--vh', `${window.innerHeight}px`);
        };

        window.addEventListener('resize', appHeight);

        appHeight();

        return () => {
            window.removeEventListener('resize', appHeight);
            if (mediaMobile?.removeEventListener) {
                mediaMobile.removeEventListener('change', handleMobile);
            } else {
                mediaMobile.removeListener(handleMobile);
            }
            if (mediaTablet?.removeEventListener) {
                mediaTablet.removeEventListener('change', handleTablet);
            } else {
                mediaTablet.removeListener(handleTablet);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <IntlProvider messages={ English } locale={ locale } defaultLocale="en">
            <Helmet>
                <title>{ intlProvider.props[locale]["app.title"] }</title>
                {/*<link rel="canonical" href={process.env.REACT_APP_API_ADDRESS} />*/ }
            </Helmet>

            <Routes>
                {/* Обычные базовые страницы, без лишнего лэйаута, доступны без авторизации */ }
                <Route element={ <LandingLayout /> }>
                    <Route index element={ <Home /> } />
                    <Route path={ NonAuthRoutes.termsOfUse } element={ <TermsOfUse /> } />
                    <Route path={ NonAuthRoutes.faq } element={ <Faq /> } />
                    <Route path={ NonAuthRoutes.faqId } element={ <FaqId /> } />
                    <Route path={ NonAuthRoutes.blog } element={ <News /> } />
                    <Route path={ NonAuthRoutes.blogId } element={ <NewsId /> } />
                    <Route path={ NonAuthRoutes.forEmployers } element={ <ForEmployers /> } />
                    <Route path={ NonAuthRoutes.contacts } element={ <Contact /> } />
                    <Route path={ NonAuthRoutes.privacyPolicy } element={ <PrivacyPolicy /> } />
                    <Route path="*" element={ <ErrorPage /> } />
                </Route>

                {/* Страницы дэшборда; стр. проекта, с левым меню кабинета, доступны только при авторизации */ }
                <Route element={
                    <AuthRoute>
                        <CabinetLayout />
                    </AuthRoute>
                }>
                    <Route path={ AuthRoutes.dashboard } element={ <Dashboard /> } />
                    <Route path={ AuthRoutes.accountSettings } element={ <AccountSettings /> } />
                    <Route path={ AuthRoutes.projectsPost } element={ <ProjectEdit /> } />
                    <Route path={ AuthRoutes.projectsEdit } element={ <ProjectEdit /> } />
                    <Route path={ AuthRoutes.myProjects } element={ <MyProjects /> } />
                    <Route path={ AuthRoutes.balance } element={ <Balance /> } />
                    <Route path={ AuthRoutes.sessionHistory } element={ <SessionHistory /> } />
                    <Route path={ NonAuthRoutes.projectsId } element={ <ProjectId /> } />
                </Route>

                {/* Страницы дэшборда, без левого меню кабинета, доступны только при авторизации */ }
                <Route element={
                    <AuthRoute>
                        <CabinetLayout withoutSidebar />
                    </AuthRoute>
                }>
                    <Route path={ AuthRoutes.messages } element={ <Messages /> } />
                    {/* <Route path={ AuthRoutes.session } element={ <Session /> } /> */}
                    <Route path={ NonAuthRoutes.expertsId } element={ <ExpertId /> } />
                </Route>

                {/* Страницы поиска проектов/исполнителей + внутренние, с левым меню кабинета, доступны без авторизации */ }
                <Route element={ <CabinetLayout type='experts' /> }>
                    <Route path={ NonAuthRoutes.experts } element={ <Experts /> } />
                </Route>
                <Route element={ <CabinetLayout type='projects' /> }>
                    <Route path={ NonAuthRoutes.projects } element={ <Projects /> } />
                </Route>

                {/* Страницы редактирования профиля, доступны только при авторизации */ }
                <Route path={ AuthRoutes.clientEdit } element={ <AuthRoute>{ user.id ? <ProfileEdit /> : <Preloader /> }</AuthRoute> } />
                <Route path={ AuthRoutes.expertEdit } element={ <AuthRoute>{ user.id ? <ProfileEdit /> : <Preloader /> }</AuthRoute> } />
                <Route path={ AuthRoutes.companyEdit } element={ <AuthRoute>{ user.id ? <ProfileEdit /> : <Preloader /> }</AuthRoute> } />

                {/* Страницы авторизации */ }
                <Route element={ <Auth /> }>
                    <Route path={ NonAuthRoutes.signin } element={ <SignIn /> } />
                    <Route path={ NonAuthRoutes.signup } element={ <SignUp /> } />
                    <Route path={ NonAuthRoutes.forgotPassword } element={ <ForgotPassword /> } />
                    <Route path={ NonAuthRoutes.newPassword } element={ <NewPassword /> } />
                    <Route path={ NonAuthRoutes.emailConfirmation } element={ <EmailConfirmation /> } />
                </Route>


                <Route path="*" element={ <ErrorPage /> } />
            </Routes>
        </IntlProvider>
    );
};

export default observer(App);
