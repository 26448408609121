import classNames from 'classnames';

import React from 'react';
import st from './PersonCard.module.scss';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

const PersonCard = ({ icon, title, description, className }) => {
    return (
        <div className={classNames(st.card, className)}>
            <div className={st.icon}>
                {icon && <img loading="eager" src={icon} alt={title} />}
                <button className={classNames('btn', st.play)} >
                    <PlayArrowRoundedIcon />
                </button>
            </div>
            <h4 className={st.title}>{title}</h4>
            <p className={st.description}>{description}</p>
        </div>
    );
};

export default PersonCard;
