import React, {useEffect} from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import st from './Faq.module.scss';
import { NonAuthRoutes } from '../../routes/routes';
import SimpleCard from 'src/components/SimpleCard';
//import classNames from 'classnames';
//import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import { AskQuestion } from '../../components/AskQuestion';
import {getData} from "../../api/api";
import DataStore from "../../store/DataStore";
import {useIntl} from "react-intl";
import FaqPopular from "../../components/FaqPopular";
import UIStore from "../../store/UIStore";


const Faq = () => {
    const intl = useIntl();
    const { faqCategories, updateFaqCategories } = DataStore;
    const icons = ['flag', 'person', 'cursor', 'star'];
    const { showPreloader, hidePreloader, } = UIStore;

    useEffect(() => {
        let isMounted = true;

        if (faqCategories.length === 0) {
            showPreloader();

            getData('api/faq_categories')
                .then(({data}) => {
                    if (isMounted) {
                        updateFaqCategories(data.items);
                    }
                })
                .finally(() => {
                    if (isMounted) {
                        hidePreloader();
                    }
                });
        }

        return () => { isMounted = false };
    }, [faqCategories.length, updateFaqCategories, hidePreloader, showPreloader]);

    return (
        <section className={st.questions}>
            <div className={st.questions__header}>
                <span className={st.questions__subtitle}>{intl.formatMessage({id: 'faq'})}</span>
                <h2 className={st.questions__title}
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: "frequentlyAsked"}, {variable: "<span>Questions</span>"})}}>
                </h2>
            </div>

            {/*	<form className={st.search}>
                <TextField
                    fullWidth
                    placeholder="Search..."
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={st.search__field}
                    required
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon className={st.search__icon} />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button variant="contained" color="primary" className={classNames(st.search__submit, 'btn')} type="submit">
                    Search
                </Button>
            </form>*/}

            <div className={st.cards}>
                {faqCategories.length ?
                    faqCategories.map((item, index) =>
                        <Link to={`${NonAuthRoutes.faq}/${index + 1}`} key={index}>
                            <SimpleCard className={st.card} icon={`/images/faq/${icons[index]}.svg`} title={`${item.name}`} />
                        </Link>
                    )
                    :
                    <>
                        <SimpleCard className={st.card} icon="/images/faq/flag.svg" title="&nbsp;"/>
                        <SimpleCard className={st.card} icon="/images/faq/person.svg" title=""/>
                        <SimpleCard className={st.card} icon="/images/faq/cursor.svg" title=""/>
                        <SimpleCard className={st.card} icon="/images/faq/star.svg" title=""/>
                    </>
                }
            </div>

            <h2 className={st.questions__titlePopular}>{intl.formatMessage({id: 'popularQuestions'})}</h2>

            <FaqPopular />

            <AskQuestion />
        </section>
    );
};

export default observer(Faq);
