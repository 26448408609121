import { makeAutoObservable } from "mobx";
import { postData } from 'src/api/api';
import DataStore from "./DataStore";
import UIStore from "./UIStore";
import {NonAuthRoutes} from "../routes/routes";
import {Link} from "react-router-dom";
import React from "react";

class ReadyStore {
    channel = null;
    workThatIsCommonReady = 0;
    eventData = {executorRecord: true, creatorRecord: true, paid: null, creatorIsReady: null, executorIsReady: null};
    currentWork = null;

    constructor () {
        makeAutoObservable(this);
    }

    updateWorkThatIsCommonReady = (value) => {
        this.workThatIsCommonReady = value;
    }

    updateEventData = (value) => {
        this.eventData = value;
    }

    updatecurrentWork = (value) =>{
        this.currentWork = value;
    }

    // изменяем статус готовности пользователя
    setReadyStatus = (data, reset = false) => {
        postData(`api/session-ready`, (data))
            .then(response => console.log('response', response))
            .catch(error => console.log(error));
    }

    // подписались на ws канал
    subscribeToChannelReady = (name) => {
        // console.log('ReadyStore: name', name)
        this.channel = UIStore.WSclient.subscribe(name);
    }

    // подписались на ws эвент
    subscribeToReadyStatus = () => {
        this.channel.bind('session_ready.event', (data) => {
            // console.log('session_ready.event data', data)
            if (data.creatorIsReady && data.executorIsReady) {
                this.updateWorkThatIsCommonReady(data.workId);
            }

            if (this.currentWork?.id === data.workId) {
                DataStore.triggerRefreshProject();
            }

            if (data.creatorIsReady || data.executorIsReady) {
                let interlocutor;

                const work = DataStore.user?.works?.find(item => item.id === data.workId);

                if (DataStore.userType === 'expert' && data.creatorIsReady && !work?.creatorIsReady) {
                    interlocutor = work?.client ?? work?.company;
                } else if (data.executorIsReady && !work?.executorIsReady) {
                    interlocutor = work?.expert;
                }

                if (interlocutor) {
                    UIStore.setSuccessAlert(
                        <>{interlocutor?.profile?.firstName} {interlocutor?.profile?.lastName} ready to session by job: <Link
                            to={`${NonAuthRoutes.projects}/${work?.id}?tab=2`}
                            onClick={() => UIStore.setSuccessAlert('')}
                        >{work?.title}</Link></>);
                }
            }
        });
    }
}

export default new ReadyStore();
