import React from 'react';
import st from './Logo.module.scss';
import classNames from 'classnames';

let cx = classNames.bind(st);

const Logo = ({
    color = 'light',
    size = 'md'
}) => {
    return (
        <div className={cx({
            size_md: size === 'md',
            size_sm: size === 'sm',
        })}>
            { color === 'light' ? (
                <img src='/common/logo-light.svg' alt='' width='134px' height='14px' />
            ) : color === 'accent' ? (
                <img src='/common/logo-accent.svg' alt='' width='134px' height='14px' />
            ) : (
                <p>default Logo</p>
            ) }
        </div>
    )
}


export default Logo
