import { LocaleMap } from "../types";

const availableLocales = Object.keys(LocaleMap).join('|');

const AuthRoutes = {
    cabinet: "/cabinet",
    cabinetLocale: `/:locale(${availableLocales})/cabinet`,

    dashboard: "/cabinet/dashboard",
    dashboardLocale: `/:locale(${availableLocales})/cabinet/dashboard`,

    accountSettings: "/cabinet/account-settings",
    accountSettingsLocale: `/:locale(${availableLocales})/cabinet/account-settings`,

    referral: "/cabinet/referral",
    referralLocale: `/:locale(${availableLocales})/cabinet/referral`,

    clientEdit: "/clients/:id/edit",
    clientEditLocale: `/:locale(${availableLocales})/clients/:id/edit`,

    expertEdit: "/experts/:id/edit",
    expertEditLocale: `/:locale(${availableLocales})/experts/:id/edit`,

    companyEdit: "/companies/:id/edit",
    companyEditLocale: `/:locale(${availableLocales})/companies/:id/edit`,

    messages: "/messages",
    messagesLocale: `/:locale(${availableLocales})/messages`,

    session: "/session",
    sessionLocale: `/:locale(${availableLocales})/session`,

    balance: "/balance",
    balanceLocale: `/:locale(${availableLocales})/balance`,

    sessionHistory: "/sessionHistory",
    sessionHistoryLocale: `/:locale(${availableLocales})/sessionHistory`,

    myProjects: "/my-jobs",
    myProjectsLocale: `/:locale(${availableLocales})/my-jobs`,

    schedule: "/schedule",
    scheduleLocale: `/:locale(${availableLocales})/schedule`,

    projectsPost: "/jobs-post",
    projectPostLocale: `/:locale(${availableLocales})/jobs-post`,

    projectsEdit: "/jobs/:id/edit",
    projectsEditLocale: `/:locale(${availableLocales})/jobs/:id/edit`,
}

const NonAuthRoutes = {
    home: "/",
    homeLocale: `/(${availableLocales})/`,

    error: "/404",
    errorLocale: `/(${availableLocales})/404`,

    contacts: "/contact",
    contactsLocale: `/:locale(${availableLocales})/contact`,

    experts: "/experts",
    expertsLocale: `/:locale(${availableLocales})/experts`,

    expertsId: "/experts/:id",
    expertsIdLocale: `/:locale(${availableLocales})/experts/:id`,

    projects: "/jobs",
    projectsLocale: `/:locale(${availableLocales})/jobs`,

    projectsId: "/jobs/:id",
    projectsIdLocale: `/:locale(${availableLocales})/jobs/:id`,

    about: "/about",
    aboutLocale: `/:locale(${availableLocales})/about`,

    how: "/how",
    howLocale: `/:locale(${availableLocales})/how`,

    faq: "/faq",
    faqLocale: `/:locale(${availableLocales})/faq`,

    faqId: "/faq/:id",
    faqIdLocale: `/:locale(${availableLocales})/faq/:id`,

    blog: "/blog",
    blogLocale: `/:locale(${availableLocales})/blog`,

    blogId: "/blog/:id",
    blogIdLocale: `/:locale(${availableLocales})/blog/:id`,

    privacyPolicy: "/privacy-policy",
    privacyPolicyLocale: `/:locale(${availableLocales})/privacy-policy`,

    termsOfUse: "/terms-of-use",
    termsOfUseLocale: `/:locale(${availableLocales})/terms-of-use`,

    forEmployers: "/for-employers",
    forEmployersLocale: `/:locale(${availableLocales})/for-employers`,

    auth: "/auth",
    authLocale: `/:locale(${availableLocales})/auth`,

    signin: "/auth/signin",
    signinLocale: `/:locale(${availableLocales})/auth/signin`,

    signup: "/auth/signup",
    signupLocale: `/:locale(${availableLocales})/auth/signup`,

    newPassword: "/auth/new-password",
    newPasswordLocale: `/:locale(${availableLocales})/auth/new-password`,

    forgotPassword: "/auth/forgot-password",
    forgotPasswordLocale: `/:locale(${availableLocales})/auth/forgot-password`,

    passwordReset: "/auth/reset",
    passwordResetLocale: `/:locale(${availableLocales})/auth/reset`,

    emailConfirmation: "/email-confirmation",
}

export { AuthRoutes, NonAuthRoutes };
