import React from 'react';
import st from './ExpertCard.module.scss';
import { ReactComponent as StarIcon } from '../../assets/images/icons/star.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/icons/location.svg';
import { ReactComponent as VerifyIcon } from '../../assets/images/icons/shield.svg';
import { ReactComponent as JobIcon } from '../../assets/images/icons/job.svg';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useIntl } from 'react-intl';
import { NonAuthRoutes } from 'src/routes/routes';
import UIStore from 'src/store/UIStore';
import { Link } from 'react-router-dom';
import { Avatar, Badge, Button } from '@mui/material';
import classNames from 'classnames';
import axios from 'axios';
import { stringAvatar, kitcut } from 'src/helpers/helpers';
// import { toJS } from 'mobx';

const ExpertCard = ({ data }) => {
    const intl = useIntl();
    const { localeUrl } = UIStore;
    // console.log(toJS(data));

    return (
        <div className={st.container}>
            <header className={st.header}>
                <div className={st.photo}>
                    {/* {image && <img src={image} alt="" width="56px" height="56px" />} */}

                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        className={classNames('status', data.online ? 'status_online' : 'status_offline')}
                    >
                        <Avatar
                            src={data.profile?.avatar ? axios.defaults.baseURL + data.profile?.avatar.contentUrl : ''}
                            className={st.avatar}
                            sx={{
                                width: { xs: '48px', md: '56px' },
                                height: { xs: '48px', md: '56px' },
                            }}
                            {...stringAvatar(`${data.profile?.firstName} ${data.profile?.lastName}`)}
                        />
                    </Badge>
                </div>

                <div className={st.header__content}>
                    <div className={st.name}>
                        {data.profile.firstName} {data.profile.lastName}
                        {data.verified && <VerifyIcon className={st.name__verified} />}
                    </div>

                    {data.positions?.length > 0 ? (
                        <div className={st.position}>
                            {data.positions.map((el, index) => (
                                <span key={'prof' + index}>{el.title}</span>
                            ))}
                        </div>
                    ) : (
                        ''
                    )}

                    <div className={st.rating}>
                        {data.rate ? (
                            <>
                                <div className={st.rating__number}>{data.rate}</div>
                                <StarIcon className={st.rating__star} />
                            </>
                        ) : (
                            ''
                        )}

                        <div className={st.rating__text}>{intl.formatMessage({ id: 'expert.reviews.count' }, { count: data.reviewsCount })}</div>
                    </div>
                </div>
            </header>

            <main className={st.main}>
                <div className={st.main__info}>
                    {data.profile?.country && data.profile?.city && (
                        <div className={st.location}>
                            <LocationIcon />
                            <span>
                                {data.profile?.country?.title}, {data.profile?.city?.title}
                            </span>
                        </div>
                    )}
                    <div className={st.job}>
                        <JobIcon />
                        <span>
                            {data.jobsCount} {intl.formatMessage({ id: 'app.header.jobs' })}
                        </span>
                    </div>
                </div>

                {data.profile.about && <p className={st.desc}>{kitcut(data.profile.about, 130)}</p>}

                {data.softwareEquipments?.length > 0 && (
                    <div className={st.main__skills}>
                        <div className={st.main__skills_label}>{intl.formatMessage({ id: 'experts.filters.softWares' })}</div>

                        <Stack direction="row" spacing={1}>
                            {data.softwareEquipments.map((skill, i) => {
                                if (i >= 5) return false;
                                return <Chip key={i + 'softwares'} size="small" label={skill.title} />;
                            })}
                        </Stack>
                    </div>
                )}

                {data.hardwareEquipments?.length > 0 && (
                    <div className={st.main__skills}>
                        <div className={st.main__skills_label}>{intl.formatMessage({ id: 'experts.card.hardwares' })}</div>

                        <Stack direction="row" spacing={1}>
                            {data.hardwareEquipments.map((skill, i) => {
                                if (i >= 5) return false;
                                return <Chip key={i + 'hardwares'} size="small" label={skill.title} />;
                            })}
                        </Stack>
                    </div>
                )}
            </main>

            <footer className={st.footer}>
                <Link to={`${localeUrl(NonAuthRoutes.experts)}/${data.id}`} className={st.button}>
                    <Button color="secondary" variant="contained" fullWidth>
                        {intl.formatMessage({ id: 'moreDetails' })}
                    </Button>
                </Link>
            </footer>
        </div>
    );
};

export default ExpertCard;
