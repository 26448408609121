import * as React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import {NonAuthRoutes} from '../../../routes/routes';
import {Field, FormikProvider, useFormik} from 'formik';
import { InputAdornment, IconButton } from '@mui/material';

import { ReactComponent as EyeIcon } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as EyeIconOff } from '../../../assets/images/icons/eye-off.svg';
import st from '../Auth.module.scss';
import validator from "validator";
import UIStore from "../../../store/UIStore";
import {postData} from "../../../api/api";
import DataStore from "../../../store/DataStore";
import {Or} from "../../../components/Or";
import {GoogleAuth} from "../../../components/GoogleAuth";
import OnlineStore from 'src/store/OnlineStore';


const SignIn = () => {
    const intl = useIntl();
    const {localeUrl, alertTimers, setErrorAlert} = UIStore;
    const {authorizeUser} = DataStore;
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const validate = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = intl.formatMessage({id: 'required'})
        }

        if (values.email && !validator.isEmail(values.email)) {
            errors.email = intl.formatMessage({id: 'errors.email'});
        }

        if (!values.password) {
            errors.password = intl.formatMessage({id: 'required'});
        }

        if (values.password && values.password.length < 6) {
            errors.password = "At least 6 symbols";
        }

        return errors;
    };

    const form = useFormik({
        validate: validate,
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: (values, { setErrors }) => {
            setErrorAlert('');
            postData('auth', {email: values.email, password: values.password})
                .then(({data}) => {
                    OnlineStore.setOnlineStatus(true);
                    authorizeUser(data.token, data.refresh_token);
                })
                .catch((errors) => {
                    setErrorAlert(errors, alertTimers.long);
                });
        },
    });

    return (
        <Box className={st.auth_form}>
            <Typography
                component='h1'
                variant='h3'
                sx={{ mb: { xs: 3, md: 6 } }}
                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signin' }) }}
            />

            <FormikProvider value={form}>
                <Box
                    component='form'
                    noValidate
                    onSubmit={form.handleSubmit}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            form.handleSubmit();
                        }
                    }}
                >
                    <Grid container spacing={2.5}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <GoogleAuth isSignIn={true} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Or />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                name='email'
                                children={(fieldProps) => (
                                    <TextField
                                        {...fieldProps.field}
                                        fullWidth
                                        label={intl.formatMessage({id: "auth.signin.email"})}
                                        variant='outlined'
                                        error={form.touched.email && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.email && !form.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                type={showPassword ? 'text' : 'password'}
                                name='password'
                                children={(fieldProps) => (
                                    <TextField
                                        {...fieldProps.field}
                                        sx={{ mb: 2 }}
                                        fullWidth
                                        name='password'
                                        label={intl.formatMessage({id: "auth.signin.password"})}
                                        type={showPassword ? 'text' : 'password'}
                                        variant='outlined'
                                        error={form.touched.password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.password && !form.isValid && fieldProps.meta.error}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        edge='end'
                                                        className={st.button_eye}
                                                        tabIndex={-1}
                                                    >
                                                        {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Typography variant='label' sx={{fontSize: '14px', fontWeight: 500}}>
                                <Link
                                    to={localeUrl(NonAuthRoutes.forgotPassword)}
                                    className={st.forgot_link}
                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signin.forgotPassword' }) }}
                                />
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                onClick={() => {
                                    form.handleSubmit();
                                }}
                                fullWidth
                                color='primary'
                                variant='contained'
                                sx={{ width: '100%', mt: { md: 1.5 }, mb: 3 }}
                            >
                                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signin' }) }} />
                            </Button>

                            <Grid item xs={12} textAlign='center' sx={{fontWeight: 600}}>
                                <span className="color-dark">{intl.formatMessage({id: 'doNotHaveAccount'})} </span>

                                <Link to={localeUrl(NonAuthRoutes.signup)} className="color-accent">
                                    {intl.formatMessage({ id: 'auth.signup.button' })}
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(form.values, 0, 2)}</pre>*/}
            </FormikProvider>
        </Box>
    );
};

export default observer(SignIn);
