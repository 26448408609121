import React from 'react';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import stPopup from '../../components/Popup/popup.module.scss';
import st from './ForEmployers.module.scss';
import classNames from 'classnames';
import { NonAuthRoutes } from '../../routes/routes';
import UIStore from 'src/store/UIStore';
import SimpleCard from '../../components/SimpleCard';
import PlansCard from 'src/components/PlansCard';
import { Popup } from 'src/components/Popup';
import { Button } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import {useIntl} from "react-intl";
import 'swiper/scss';
import 'swiper/scss/pagination';

const ForEmployers = () => {
    const intl = useIntl();
    const { openPopup, closePopup, localeUrl } = UIStore;
    // eslint-disable-next-line no-unused-vars
    const [needLogin, setNeedLogin] = React.useState(false);

    return (
        <>
            <section className={st.hero_inner}>
                <div className={st.hero}>
                    <div className={st.hero__content}>
                        <div className={st.hero__label}>{intl.formatMessage({ id: 'employers.label' })}</div>
                        <div className={st.hero__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "employers.title" }) }}></div>
                        <div className={st.hero__subtitle}>{intl.formatMessage({ id: 'employers.subtitle' })}</div>
                        <div className={st.hero__text}>
                            {intl.formatMessage({ id: 'employers.text' })}
                        </div>

                        <Link to={localeUrl(NonAuthRoutes.signin)}>
                            <Button className={st.hero__button} fullWidth color="primary" variant="contained"  size="large">
                                {intl.formatMessage({ id: 'employers.button' })}
                            </Button>
                        </Link>
                    </div>

                    <div className={st.hero__picture}>
                        <img src="/images/for-employers.png" alt="" />
                    </div>
                </div>
            </section>

            <section className={st.features}>
                <div className={st.features__header}>
                    <span className={st.features__subtitle}>{intl.formatMessage({ id: 'features.subtitle' })}</span>
                    <h2 className={st.features__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "features.title" }) }}></h2>
                    <div className={st.features__cards}>
                    <Swiper
                            spaceBetween={16}
                            slidesPerView={1}
                            modules={[Pagination]}
                            pagination={{
                                el: '.features-pagination',
                                clickable: true,
                            }}
                            breakpoints={{
                                668: {
                                    slidesPerView: 2,
                                },
                                1040: {
                                    spaceBetween: 32,
                                    slidesPerView: 4,
                                    pagination: false,
                                },
                            }}
                            loop={true}
                            className={st.features__cards_slider}
                        >
                            <SwiperSlide className={st.features__card_slide}>
                                <SimpleCard
                                    className={st.features__card}
                                    icon="/images/features/persons.svg"
                                    title={intl.formatMessage({ id: 'features.card-1.name' })}
                                    description={intl.formatMessage({ id: 'features.card-1.desc' })}
                                />
                            </SwiperSlide>
                            <SwiperSlide className={st.features__card_slide}>
                                <SimpleCard
                                    className={st.features__card}
                                    icon="/images/features/stats.svg"
                                    title={intl.formatMessage({ id: 'features.card-2.name' })}
                                    description={intl.formatMessage({ id: 'features.card-2.desc' })}
                                />
                            </SwiperSlide>
                            <SwiperSlide className={st.features__card_slide}>
                                <SimpleCard
                                    className={st.features__card}
                                    icon="/images/features/supports.svg"
                                    title={intl.formatMessage({ id: 'features.card-3.name' })}
                                    description={intl.formatMessage({ id: 'features.card-3.desc' })}
                                />
                            </SwiperSlide>
                            <SwiperSlide className={st.features__card_slide}>
                                <SimpleCard
                                    className={st.features__card}
                                    icon="/images/features/moneys.svg"
                                    title={intl.formatMessage({ id: 'features.card-4.name' })}
                                    description={intl.formatMessage({ id: 'features.card-4.desc' })}
                                />
                            </SwiperSlide>
                        </Swiper>
                        <div className={classNames('features-pagination', 'swiper-pagination', st.features__pagination)} />
                    </div>
                </div>
            </section>

            <section className={st.plans}>
                <div className={st.plans__container}>
                    <div className={st.plans__header}>
                        <span className={st.plans__subtitle}>{intl.formatMessage({ id: 'plans.subtitle' })}</span>
                        <h2 className={st.plans__title}  dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "plans.title" }) }}></h2>
                        <div className={st.plans__cards}>
                            <PlansCard
                                className={st.plans__card}
                                icon="/images/plans/clover.svg"
                                title={intl.formatMessage({ id: 'plans.lite.title' })}
                                description={intl.formatMessage({ id: 'plans.lite.desc' })}
                                price="0"
                                includesTitle={intl.formatMessage({ id: 'plans.lite.includes' })}
                                includes={[intl.formatMessage({id: 'plans.lite.li-1'}), intl.formatMessage({id: 'plans.lite.li-2'}), intl.formatMessage({id: 'plans.lite.li-3'})]}
                                handleClick={openPopup}
                            />
                            <PlansCard
                                className={st.plans__card}
                                icon="/images/plans/proc.svg"
                                title={intl.formatMessage({ id: 'plans.pro.title' })}
                                popular
                                description={intl.formatMessage({ id: 'plans.pro.desc' })}
                                price="999"
                                pricePer={intl.formatMessage({id: 'plans.pro.price'})}
                                includesTitle={intl.formatMessage({ id: 'plans.pro.includes' })}
                                includes={[intl.formatMessage({id: 'plans.pro.li-1'}), intl.formatMessage({id: 'plans.pro.li-2'}), intl.formatMessage({id: 'plans.pro.li-3'}), intl.formatMessage({id: 'plans.pro.li-4'})]}
                                handleClick={openPopup}
                            />
                            <PlansCard
                                className={st.plans__card}
                                icon="/images/plans/volt.svg"
                                title={intl.formatMessage({ id: 'plans.premium.title' })}
                                description={intl.formatMessage({ id: 'plans.premium.desc' })}
                                priceText={intl.formatMessage({id: 'plans.premium.price'})}
                                includesTitle={intl.formatMessage({ id: 'plans.premium.includes' })}
                                includes={[intl.formatMessage({id: 'plans.premium.li-1'}), intl.formatMessage({id: 'plans.premium.li-2'}), intl.formatMessage({id: 'plans.premium.li-3'}), intl.formatMessage({id: 'plans.premium.li-4'})]}
                                handleClick={openPopup}
                            />
                        </div>
                        <Popup>
                            {needLogin ? (
                                <>
                                    <div className={stPopup.title}>{intl.formatMessage({ id: 'experts.popup.title' })}</div>
                                    <div className={stPopup.text}>{intl.formatMessage({ id: 'employers.popup.text1' })}</div>

                                    <div className={st.popup__buttons}>
                                        <Button fullWidth className={st.popup__button} onClick={closePopup} color="primary" variant="contained">
                                            {intl.formatMessage({ id: 'auth.signin' })}
                                        </Button>
                                        <Button fullWidth className={st.popup__button} onClick={closePopup} color="primary" variant="contained">
                                            {intl.formatMessage({ id: 'auth.signup.button' })}
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={stPopup.title}>{intl.formatMessage({ id: 'employers.popup.title2' })}</div>
                                    <div className={stPopup.text}>{intl.formatMessage({ id: 'employers.popup.text2' })}</div>

                                    <Button fullWidth className={st.popup__button} onClick={closePopup} color="primary" variant="contained"  size="large">
                                        {intl.formatMessage({ id: 'ok' })}
                                    </Button>
                                </>
                            )}
                        </Popup>
                    </div>
                </div>
            </section>
        </>
    );
};

export default observer(ForEmployers);
