import {
    IconButton,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Avatar,
    Dialog,
    Fade,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Button,
} from '@mui/material';
import React, { Fragment } from 'react';
import st from './toolbar.module.scss';
import { ReactComponent as MoreIcon } from '../../../assets/images/icons/more.svg';
import { ReactComponent as ReportIcon } from '../../../assets/images/icons/report.svg';
import { ReactComponent as SpamIcon } from '../../../assets/images/icons/spam.svg';
import { ReactComponent as SuggestIcon } from '../../../assets/images/icons/suggest.svg';
import { ReactComponent as CrossIcon } from '../../../assets/images/icons/cross.svg';
import { ReactComponent as ProjectsIcon } from '../../../assets/images/icons/job.svg';
import { ReactComponent as SessionIcon } from '../../../assets/images/icons/session.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/images/icons/angle-bracket-prev.svg';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { stringAvatar } from 'src/helpers/helpers';
import { observer } from 'mobx-react';
import ChatStore from 'src/store/ChatStore';
import DataStore from 'src/store/DataStore';
import UIStore from 'src/store/UIStore';
import { postData } from 'src/api/api';
import stPopup from 'src/components/Popup/popup.module.scss';
import { useNavigate } from 'react-router-dom';
import { NonAuthRoutes } from 'src/routes/routes';

const InvitePopup = ({ open, handleClick }) => {
    const intl = useIntl();
    const { user, userID } = DataStore;
    const { setErrorAlert, alertTimers } = UIStore;
    const { getChatList } = ChatStore;
    const [value, setValue] = React.useState('');
    const [disabled, setDisabled] = React.useState(true);

    const handleChange = (event) => {
        setValue(event.target.value);
        setDisabled(false);
    };

    const handleInvite = () => {
        const message = {
            invitationStatus: 'new',
            sender: `api/users/${userID}`,
            invitedGhost: `api/users/${ChatStore.activeChat.executor.id}`,
            work: `api/works/${value}`,
            systemMessage: false,
            chat: `api/chats/${ChatStore.activeChat.id}`,
            spam: false,
        };

        console.log(message);
        postData(`api/chat_work_invitation_messages`, message, 'post')
            .then(({ data }) => {
                getChatList();
                handleClick();
                // setSpinnerShow(false);
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? error : intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
                // setSpinnerShow(false);
            });
    };

    return (
        <Dialog TransitionComponent={Fade} onClose={handleClick} open={open} scroll="body" className={classNames(stPopup.popup)}>
            <div className={classNames(stPopup.container, 'popup-container')}>
                <IconButton className={stPopup.close} onClick={handleClick}>
                    <CrossIcon />
                </IconButton>

                <FormControl>
                    <div className={stPopup.title}>{intl.formatMessage({ id: `messages.invite.popup.title` })}</div>
                    <RadioGroup aria-labelledby="user project list" defaultValue="" name="radio-buttons-group" value={value} onChange={handleChange}>
                        {user.works.map((item, index) => {
                            if (item.status === 'opened') {
                                return (
                                    <FormControlLabel
                                        value={item.id}
                                        control={<Radio />}
                                        className={st.info}
                                        key={item.id + index}
                                        label={
                                            <>
                                                <div className={st.info__title}>{item.title}</div>
                                                {item.type && item.type === 'projects' ? (
                                                    <div className={st.info__type}>
                                                        <ProjectsIcon />
                                                        {intl.formatMessage({ id: `projects.card.projects` })}
                                                    </div>
                                                ) : (
                                                    <div className={classNames(st.info__type, st.info__type_session)}>
                                                        <SessionIcon />
                                                        {intl.formatMessage({ id: `projects.card.sessions` })}
                                                    </div>
                                                )}
                                            </>
                                        }
                                    />
                                );
                            }

                            return false;
                    })}
                    </RadioGroup>
                </FormControl>

                <Button sx={{ width: '100%', mt: 2 }} variant="contained" color="primary" disabled={disabled} onClick={handleInvite}>
                    {intl.formatMessage({ id: 'invite' })}
                </Button>
            </div>
        </Dialog>
    );
};

const Toolbar = () => {
    const intl = useIntl();
    const { tabletIs } = UIStore;
    const { userType } = DataStore;
    const [open, setOpen] = React.useState(false);
    const [openPopup, setOpenPopup] = React.useState(false);
    const anchorRef = React.useRef(null);
    const sender = ChatStore.sender;
    const navigate = useNavigate();
    // console.log(toJS(ChatStore));

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event?.target)) {
            return;
        }

        setOpen(false);
    };

    const handleClick = () => {
        if (sender.expert || sender.type === 'expert') {
            navigate(`${NonAuthRoutes.experts}/${sender.id}`);
        }
    };

    return (
        <div className={st.bar}>
            {tabletIs ? (
                <div className={st.back} onClick={() => ChatStore.setActiveChat({})}>
                    <ArrowIcon />
                </div>
            ) : (
                ''
            )}
            <div className={classNames(st.media, sender.expert || sender.type === 'expert' ? st.link : '')} onClick={handleClick}>
                <Avatar
                    src={sender.profile.avatar ? axios.defaults.baseURL + sender.profile.avatar.contentUrl : ''}
                    className={st.avatar}
                    sx={{
                        width: { xs: '40px', md: '48px' },
                        height: { xs: '40px', md: '48px' },
                    }}
                    {...stringAvatar(`${sender.profile.firstName} ${sender.profile.lastName}`)}
                />
            </div>

            <div className={classNames(st.content, sender.expert || sender.type === 'expert' ? st.link : '')} onClick={handleClick}>
                <div className={st.name}>
                    {`${sender.profile.firstName} ${sender.profile.lastName}`} <span>{sender.profile.timeZone}</span>
                </div>
                <div className={classNames(st.status, sender.online ? st.status_online : st.status_offline)}>
                    <svg viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3" cy="3" r="3" />
                    </svg>
                    {!tabletIs ? (sender.online ? 'online' : 'offline') : ''}
                </div>
            </div>

            <div className={st.dots} onClick={handleToggle} ref={anchorRef}>
                <IconButton className={st.dots__button}>
                    <MoreIcon height="16px" />
                </IconButton>

                <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end" transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow {...TransitionProps}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="chatsList-menu" aria-labelledby="chatsList-button" className={st.popup}>
                                        {userType !== 'expert' && (
                                            <MenuItem
                                                onClick={() => {
                                                    handleToggle();
                                                    handleOpenPopup();
                                                }}
                                            >
                                                <SuggestIcon />
                                                {intl.formatMessage({ id: 'messages.chat.inviteProject' })}
                                            </MenuItem>
                                        )}
                                        <MenuItem>
                                            <ReportIcon />
                                            {intl.formatMessage({ id: 'messages.chat.report' })}
                                        </MenuItem>
                                        <MenuItem>
                                            <SpamIcon />
                                            {intl.formatMessage({ id: 'messages.chat.spam' })}
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>

            {userType !== 'expert' && <InvitePopup open={openPopup} handleClick={handleClosePopup} />}
        </div>
    );
};

export default observer(Toolbar);
