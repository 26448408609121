import React from 'react'

import st from './Preloader.module.scss';
import Logo from '../../components/Logo';
import {observer} from "mobx-react";
import UIStore from "../../store/UIStore";
import classNames from "classnames";


const Preloader = ({isShow = false}) => {
    const {loading, userLoading} = UIStore;

    const inlineStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '101vw',
        height: 'var(--vh)',
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
        backgroundColor: '#f9f5ee',
        zIndex: 30,
    };

    const inlineStyleLogo = {
        position: 'fixed',
        top: '50%',
        left: '50%',
    };

    return (
        <div style={inlineStyle} className={classNames(st.preloader, ((loading || userLoading || isShow) ? st.is_visible : ""))}>
            <div style={inlineStyleLogo} className={st.logo}>
                <Logo size='md' color='accent'/>
            </div>
        </div>
    );
}

export default observer(Preloader);
