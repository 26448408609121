import React from 'react';
import classNames from 'classnames';

import { ReactComponent as Logo } from '../../assets/images/icons/logo.svg';
import { Link } from 'react-router-dom';
import st from './Mobile.module.scss';
import { observer } from 'mobx-react';
import UIStore from '../../store/UIStore';
import { NavMenu } from '../LandingHeader/NavMenu';
import Burger from '../Burger';
import { NonAuthRoutes } from '../../routes/routes';
import DataStore from '../../store/DataStore';
import { useIntl } from 'react-intl';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow-nav.svg';
import { ReactComponent as ExpertsIcon } from '../../assets/images/icons/people.svg';
import { ReactComponent as JobsIcon } from '../../assets/images/icons/bag.svg';


const MobileMenu = observer(({ pageType }) => {
    const intl = useIntl();
    const { user, isUserAuthorized } = DataStore;
    const [type, setType] = React.useState(user?.roles && user?.roles[0] === 'ROLE_EXPERT' ? 'jobs' : 'experts');
    const {
        mobileMenuIsOpen, toggleMobileMenu, closeMobileMenu,
        localeUrl,
        innerMenuIsOpen,
    } = UIStore;
    const classes = classNames(st.fullscreen_menu, {
        [st.is_hidden]: !mobileMenuIsOpen,
        [st.is_active]: mobileMenuIsOpen,
        [st.dashboard_style]: isUserAuthorized,
    });
    const [openSearch, setOpenSearch] = React.useState(false);
    const anchorSearchRef = React.useRef(null);

    const handleToggleSearch = () => {
        setOpenSearch((prevOpen) => !prevOpen);
    };

    const handleCloseSearch = (event) => {
        if (anchorSearchRef.current && anchorSearchRef.current.contains(event?.target)) {
            return;
        }

        setOpenSearch(false);
    };

    return (
        <div className={classes}>
            <div className={st.header__mobile}>
                <Link className={st.logo} to={localeUrl(NonAuthRoutes.home)} onClick={toggleMobileMenu}>
                    <Logo />
                </Link>

                <div className={st.burger}>
                    <Burger onClick={toggleMobileMenu} />
                </div>
            </div>

            <form className={classNames(st.search, (innerMenuIsOpen ? 'hidden' : ''))}>
                <input type="text" placeholder="Search" required className={st.search__input} autoFocus={true} />
                <button className={classNames(st.search__submit, 'btn')} type="submit">
                    <SearchIcon />
                </button>

                <div
                    className={classNames('btn', st.search__type)}
                    ref={anchorSearchRef}
                    id="search-button"
                    aria-controls={openSearch ? 'search-menu' : undefined}
                    aria-expanded={openSearch ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleSearch}
                >
                    <span className="btn__content">
                        {type}
                        <ArrowIcon />
                    </span>

                    <Popper open={openSearch} anchorEl={anchorSearchRef.current} role={undefined} placement="bottom-end" transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleCloseSearch}>
                                        <MenuList
                                            autoFocusItem={openSearch}
                                            id="search-menu"
                                            aria-labelledby="search-button"
                                            className={st.profile_menu}
                                        >
                                            <MenuItem
                                                className={classNames(st.search__type_menu, type === 'experts' ? st.search__type_active : '')}
                                                onClick={(event) => {
                                                    handleCloseSearch(event);
                                                    setType('experts');
                                                }}
                                            >
                                                <ExpertsIcon />
                                                <span>{intl.formatMessage({ id: 'experts' })}</span>
                                            </MenuItem>
                                            <MenuItem
                                                className={classNames(st.search__type_menu, type === 'jobs' ? st.search__type_active : '')}
                                                onClick={(event) => {
                                                    handleCloseSearch(event);
                                                    setType('jobs');
                                                }}
                                            >
                                                <JobsIcon />
                                                <span>{intl.formatMessage({ id: 'app.header.jobs' })}</span>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </form>

            <NavMenu classList={st.nav} forDevices/>

            <div className={st.buttons}>
                {!isUserAuthorized &&
                    <>
                        <Link className={st.sign_up} to={localeUrl(NonAuthRoutes.signup)} onClick={closeMobileMenu}>
                            <Button className={st.button_link} color="primary" size="small">
                                {intl.formatMessage({ id: 'auth.signup.button' })}
                            </Button>
                        </Link>

                        <Link className={st.sign_in} to={localeUrl(NonAuthRoutes.signin)} onClick={closeMobileMenu}>
                            <Button className={st.button_link} variant="contained" size="small">
                                {intl.formatMessage({ id: 'app.header.signIn' })}
                            </Button>
                        </Link>
                    </>
                }
            </div>
        </div>
    );
});

export default MobileMenu;
