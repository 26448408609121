// @ts-nocheck
import { createTheme } from '@mui/material';

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: 'rgba(246, 103, 0, 1)',
            dark: 'rgba(57, 62, 65)',
            light: 'rgba(255, 126, 33, 1)',
            light1: 'rgba(246, 103, 0, 0.08)',
            light2: 'rgba(241, 244, 248, 1)',
            light3: 'rgba(246, 103, 0, 0.12)',
            contrastText: 'rgba(255, 255, 255, 1)',
            error: '#F44336',
        },
        secondary: {
            main: 'rgba(57, 62, 65, 1)',
            light2: 'rgba(241, 244, 248, 1)',
        },
        action: {
            disabled: 'rgba(246, 103, 0, 0.08)',
        },
        error: {
            main: 'rgba(241, 244, 248, 1)',
        },
        light: {
            main: '#ffffff',
            light: 'rgba(255, 126, 33, 1)',
            dark: 'rgba(100 116 139, 1)',
            contrastText: 'rgba(246, 103, 0, 1)',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 668,
            md: 1023,
            lg: 1440,
            xl: 1920,
        },
    },
});

const primaryMuiTheme = createTheme({
    palette: {
        light: {
            main: defaultTheme.palette.light.main,
            light: defaultTheme.palette.light.light,
            dark: defaultTheme.palette.light.dark,
            contrastText: defaultTheme.palette.light.contrastText,
        },
    },
    typography: {
        fontFamily: 'SFProDisplay',
        fontWeight: '400',
        fontSize: 16,
        lineHeight: '24px',
        color: defaultTheme.palette.primary.dark,

        h1: {
            fontWeight: '700',
            fontSize: '56px',
            lineHeight: '60px',
            letterSpacing: '-0.01em',
            color: defaultTheme.palette.primary.dark,

            [defaultTheme.breakpoints.down('sm')]: {
                fontSize: '32px',
                lineHeight: '36px',
            },
        },
        h2: {
            fontWeight: '700',
            fontSize: '40px',
            lineHeight: '44px',
            letterSpacing: '-0.01em',
            color: defaultTheme.palette.primary.dark,

            [defaultTheme.breakpoints.down('sm')]: {
                fontSize: '28px',
                lineHeight: '32px',
            },
        },
        h3: {
            fontWeight: '700',
            fontSize: '32px',
            lineHeight: '36px',
            letterSpacing: '-0.01em',
            color: defaultTheme.palette.primary.dark,

            [defaultTheme.breakpoints.down('sm')]: {
                fontSize: '24px',
                lineHeight: '28px',
            },
        },
        h4: {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '28px',
            letterSpacing: '-0.01em',
            color: defaultTheme.palette.primary.dark,

            [defaultTheme.breakpoints.down('sm')]: {
                fontSize: '18px',
                lineHeight: '22px',
            },
        },
        h5: {
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '22px',
            letterSpacing: '-0.01em',
            color: defaultTheme.palette.primary.dark,

            [defaultTheme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '20px',
            },
        },
        h6: {
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '20px',
            color: defaultTheme.palette.primary.dark,

            letterSpacing: '-0.01em',
        },
        pretitle: {
            display: 'block',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '16px',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: defaultTheme.palette.primary.main,
        },
        body1: {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '22px',
        },
        pretitle2: {
            display: 'block',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '14px',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: defaultTheme.palette.primary.main,
            [defaultTheme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: '12px',
            },
        },
        button: { fontWeight: '600', fontSize: '16px', lineHeight: '16px', textTransform: 'none' },
        inputText: { fontWeight: '700', fontSize: '13px', lineHeight: '13px' },
        helpertext: { fontWeight: '700', fontSize: '13px', lineHeight: '13px' },
        label: { fontWeight: '700', fontSize: '13px', lineHeight: '18px' },
        staticLabel: { fontWeight: '700', fontSize: '18px', lineHeight: '20px' },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',

                    '&.Mui-expanded': {
                        // margin: "0",
                        borderRadius: '16px',
                        background: 'rgba(246, 103, 0, 0.08)',

                        '.edit &': {
                            background: 'white',
                        },

                        '&:before, & + div:before': {
                            content: 'none',
                        },
                    },

                    '&:before': {
                        right: '32px',
                        left: '32px',
                        background: 'rgba(57, 62, 65, 0.12)',
                        [defaultTheme.breakpoints.down('sm')]: {
                            right: '20px',
                            left: '20px',
                        },
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    margin: '0',
                    padding: '0 32px',
                    fontWeight: 600,
                    fontSize: '24px',
                    lineHeight: '28px',
                    transition: '0.4s ease-in-out',

                    '&:hover': {
                        color: defaultTheme.palette.primary.main,
                    },

                    [defaultTheme.breakpoints.down('md')]: {
                        '.edit &': {
                            padding: 0,

                            '.MuiAccordionSummary-expandIconWrapper': {
                                transform: 'rotate(270deg)',

                                '&.Mui-expanded': {
                                    transform: 'rotate(90deg)',
                                }
                            },

                            '& h4': {
                                margin: 0,
                            }
                        }
                    },

                    [defaultTheme.breakpoints.down('sm')]: {
                        padding: '0 20px',
                        fontSize: '18px',
                        lineHeight: '22px',
                    },
                },
                content: {
                    margin: '0',
                    padding: '32px 0',
                    color: defaultTheme.palette.secondary.main,
                    transition: '0.2s ease-in-out',

                    '&:hover': {
                        color: defaultTheme.palette.primary.main,
                    },

                    '&.Mui-expanded': {
                        margin: '0',
                    },

                    [defaultTheme.breakpoints.down('md')]: {
                        '.edit &': {
                            padding: '18px 0',
                        }
                    },

                    [defaultTheme.breakpoints.down('sm')]: {
                        padding: '25px 0',
                        marginRight: '5px',
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: '0 32px 32px',
                    fontWeight: 400,
                    fontSize: '18px',
                    lineHeight: '28px',
                    color: 'rgba(57, 62, 65, 0.64)',

                    [defaultTheme.breakpoints.down('sm')]: {
                        padding: '0 24px 24px',
                    },

                    '& p': {
                        fontSize: '18px',
                        lineHeight: '28px',

                        [defaultTheme.breakpoints.down('sm')]: {
                            fontSize: '16px',
                            lineHeight: '24px',
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    padding: '20px 56px',
                    height: '56px',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '16px',
                    color: '#fff',
                    textTransform: 'none',
                    boxShadow: 'none',
                    transition: '.2s ease',
                    '& svg': {
                        transition: '.2s ease',
                    },
                    ':disabled': {
                        // display: 'none'
                        background: 'transparent',
                        borderColor: '#AFB4C5',
                        color: '#AFB4C5',
                        cursor: 'not-allowed',
                    },
                    ':active': {
                        transform: 'scale(0.94)',
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'text', color: 'primary' },
                    style: {
                        color: 'rgba(57, 62, 65, 1)',
                        ':hover': {
                            backgroundColor: 'rgba(246, 103, 0, 0.08)',
                            color: defaultTheme.palette.primary.main,
                            boxShadow: 'none',
                        },
                    },
                },
                {
                    props: { variant: 'text', color: 'secondary' },
                    style: {
                        color: defaultTheme.palette.primary.main,
                        ':hover': {
                            backgroundColor: 'rgba(246, 103, 0, 0.08)',
                            color: defaultTheme.palette.primary.main,
                            boxShadow: 'none',
                        },
                    },
                },
                {
                    props: { variant: 'outlined', color: 'primary' },
                    style: {
                        textTransform: 'none',
                        backgroundColor: 'transparent',
                        border: `1px solid rgba(246, 103, 0, 0.4)`,
                        color: defaultTheme.palette.primary.main,
                        ':hover': {
                            borderColor: defaultTheme.palette.primary.main,
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    },
                },
                {
                    props: { variant: 'outlined', color: 'secondary' },
                    style: {
                        border: '1px solid rgba(57, 62, 65, 0.18)',
                        color: defaultTheme.palette.primary.dark,
                        ':hover': {
                            borderColor: defaultTheme.palette.primary.main,
                            color: defaultTheme.palette.primary.main,
                            backgroundColor: 'transparent',
                        },
                    },
                },
                {
                    props: { variant: 'contained', color: 'primary' },
                    style: {
                        textTransform: 'none',
                        backgroundColor: defaultTheme.palette.primary.main,
                        border: `1px solid ${ defaultTheme.palette.primary.main }`,
                        ':hover': {
                            boxShadow: 'none',
                            backgroundColor: defaultTheme.palette.primary.light,
                        },
                    },
                },
                {
                    props: { variant: 'contained', color: 'light' },
                    style: {
                        textTransform: 'none',
                        backgroundColor: '#ffffff',
                        color: defaultTheme.palette.light.contrastText,
                        ':hover': {
                            boxShadow: 'none',
                            backgroundColor: defaultTheme.palette.light.light,
                        },
                    },
                },
                {
                    props: { variant: 'contained', color: 'secondary' },
                    style: {
                        textTransform: 'none',
                        color: defaultTheme.palette.primary.main,
                        backgroundColor: defaultTheme.palette.primary.light3,
                        ':hover': {
                            backgroundColor: defaultTheme.palette.primary.main,
                            boxShadow: 'none',
                            color: '#fff',
                        },
                    },
                },
                {
                    props: { variant: 'contained', color: 'error' },
                    style: {
                        textTransform: 'none',
                        backgroundColor: defaultTheme.palette.primary.error,
                        border: `1px solid ${ defaultTheme.palette.primary.error }`,
                        ':hover': {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            color: defaultTheme.palette.primary.error,
                        },
                    },
                },
                {
                    props: { variant: 'circle', color: 'primary' },
                    style: {
                        textTransform: 'none',
                        backgroundColor: defaultTheme.palette.primary.main,
                        border: `1px solid ${ defaultTheme.palette.primary.main }`,
                        ':hover': {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            color: defaultTheme.palette.primary.main,
                        },
                    },
                },
                {
                    props: { variant: 'circle', color: 'secondary' },
                    style: {
                        textTransform: 'none',
                        backgroundColor: defaultTheme.palette.secondary.main,
                        border: `1px solid ${ defaultTheme.palette.secondary.main }`,
                        ':hover': {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            color: defaultTheme.palette.secondary.main,
                        },
                    },
                },

                // types
                {
                    props: { variant: 'circle', size: 'large' },
                    style: {
                        padding: '0px !important',
                        width: '56px !important',
                        height: '56px !important',
                    },
                },
                {
                    props: { variant: 'circle', size: 'medium' },
                    style: {
                        padding: '0 !important',
                        width: '48px !important',
                        height: '48px !important',
                    },
                },
                {
                    props: { variant: 'circle', size: 'small' },
                    style: {
                        padding: '0 !important',
                        width: '44px !important',
                        height: '44px !important',
                    },
                },

                // sizes
                {
                    props: { size: 'large' },
                    style: {
                        padding: '20px 64px',
                        height: '56px',
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        padding: '16px 48px',
                        height: '54px',
                    },
                },
                {
                    props: { size: 'small' },
                    style: {
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '16px',
                        padding: '14px 24px',
                        height: '44px',
                    },
                },
            ],
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    padding: '4px 0',
                    height: '26px',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '16px',
                    backgroundColor: '#f1f4f8',
                    color: defaultTheme.palette.secondary.main,

                    '& .MuiChip-deleteIcon': {
                        fontSize: '16px',
                    },
                },
                label: {
                    paddingRight: 10,
                    paddingLeft: 10,
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',

                    '& input': {
                        boxSizing: 'border-box',
                        borderRadius: '8px',
                        padding: '17px 16px',
                        height: '56px',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    lineHeight: 'unset',
                    '&.MuiInputLabel-standard.MuiInputLabel-shrink': {
                        transform: 'translate(0, -1.5px) scale(0.8)',
                    },
                    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                        transform: 'translate(15px, 2px) scale(0.81)',
                    },
                    '&.MuiInputLabel-filled.MuiInputLabel-shrink': {
                        transform: 'translate(12px, 7px) scale(0.8)',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: '4px 0 0 0',
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    textAlign: 'center',
                },
                img: {
                    borderRadius: '50%',
                }
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    height: 2,
                    padding: "8px 0",
                    color: defaultTheme.palette.primary.main,
                },
                thumb: {
                    width: 10,
                    height: 10,

                    "&.Mui-focusVisible": {
                        boxShadow: "0px 0px 0px 8px rgba(246, 103, 0, 0.2)",
                    },

                    "&.Mui-active": {
                        boxShadow: "0px 0px 0px 10px rgba(246, 103, 0, 0.2)",
                    },

                    "&:after": {
                        width: 20,
                        height: 20,
                    }
                },
                track: {
                }
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.MuiInputLabel-root": {
                        color: `rgba(57, 62, 65, 0.56)`,
                    }
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& input, label, textarea': {
                        boxSizing: 'border-box',
                        fontWeight: '400',
                        fontFamily: 'SFProDisplay',
                        fontSize: '16px',
                        lineHeight: '22px',
                        outline: defaultTheme.palette.primary.dark,
                        ':placeholder': {
                            color: `rgba(57, 62, 65, 0.48)`,
                        },
                    },
                    '& input': {
                        padding: '24px 16px 10px',
                        height: '56px',
                        color: `57, 62, 65`,
                    },
                    '& textarea': {
                        fontWeight: '300',
                        lineHeight: '1.44em',

                        [defaultTheme.breakpoints.down('sm')]: {
                            paddingBottom: '10px',
                            fontSize: '14px',
                        },
                    },
                    '& .MuiInput-underline:before': {
                        borderBottomColor: '#05144b',
                        opacity: '.12',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#05144b',
                    },
                    '& label.MuiFormLabel-filled, label.Mui-focused': {
                        opacity: '0.56',
                        fontSize: '18px',
                        letterSpacing: '-0.02em',
                        color: '#05144B',
                    },
                    '& label.MuiInputLabel-outlined': {
                        fontSize: '16px',
                    },
                    '& .MuiOutlinedInput-root fieldset': {
                        top: 0,
                        minHeight: '56px',
                        borderColor: 'rgba(57, 62, 65, 0.18)',
                        legend: {
                            display: 'none',
                        },
                    },
                    '& .MuiInputBase-multiline fieldset ': {
                        height: 'inherit',
                    },
                    '& .MuiSvgIcon-root ': {
                        fontSize: '18px',
                    },
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        transition: '0.2s ease-in-out',
                        // borderColor: 'transparent',
                        // boxShadow: '0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02)',
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                        borderColor: defaultTheme.palette.primary.main,
                    },
                    '& label': {
                        pointerEvents: 'none',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& input': {
                        height: '37px !important',
                    },
                    '& .MuiOutlinedInput-root fieldset': {
                        minHeight: '37px',
                    },
                    '& .MuiInputBase-formControl': {
                        paddingTop: '19px',
                        paddingBottom: 0,
                    },
                    '.MuiAutocomplete-tag': {
                        marginTop: 0,
                        marginBottom: 0,
                    },
                    label: {
                        fontWeight: '400',
                        fontFamily: 'SFProDisplay',
                        fontSize: '18px',
                        color: `rgba(57, 62, 65, 0.56)`,
                        // opacity: "0.56",
                    },
                    input: {
                        "&[disabled] ~ fieldset": {
                            borderColor: "rgba(0, 0, 0, 0.26) !important",
                        }
                    },

                    '& .Mui-disabled': {
                        // pointerEvents: 'none',
                        cursor: 'no-drop'
                    },

                    '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                        right: 16,
                    },
                    '& .MuiAutocomplete-endAdornment': {
                        top: 'calc(50% - 12px)',
                    },
                    '.MuiInputAdornment-root': {
                        color: defaultTheme.palette.primary.main,

                        ".MuiButtonBase-root": {
                            width: 28,
                            height: 28,
                            color: defaultTheme.palette.primary.main,
                        },

                        "svg path": {
                            fill: "currentColor",
                        }
                    },
                    '.MuiOutlinedInput-root': {
                        paddingRight: "12px",
                        paddingLeft: "12px",
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    padding: '16px 32px',

                    ':hover': {
                        // background: defaultTheme.palette.secondary.main,
                        backgroundColor: 'rgba(246, 103, 0, 0.08)',
                        color: defaultTheme.palette.primary.main,
                        boxShadow: 'none',
                        // '& .MuiListItemText-primary': {
                        //     color: '#fff',
                        // },
                    },
                    '& .MuiListItemText-primary': {
                        fontFamily: 'SFProDisplay',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '18px',
                        background: 'transparent',
                        color: defaultTheme.palette.primary.main,
                    },
                },
            },
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '20px 24px',
                    fontFamily: 'SFProDisplay',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '18px',
                    color: 'rgba(175, 180, 197, 1)',

                    '&:hover': {
                        color: defaultTheme.palette.secondary.main,
                    },

                    '&.Mui-selected': {
                        color: defaultTheme.palette.secondary.main,

                        '&:hover': {
                            color: defaultTheme.palette.secondary.main,
                            background: 'transparent !important',
                        }
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: defaultTheme.palette.primary.main
                }
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 36,
                    height: 20,
                    padding: 0,
                    display: 'flex',
                    marginRight: 16,
                    '&:active': {
                        '& .MuiSwitch-thumb': {
                            width: 16,
                            backgroundColor: '#FAFAFA',
                        },
                    },
                    '& .MuiSwitch-switchBase': {
                        padding: 2,
                        '&.Mui-checked': {
                            transform: 'translateX(16px)',
                            '& + .MuiSwitch-thumb': {
                                backgroundColor: '#FAFAFA',
                            },
                            '& + .MuiSwitch-track': {
                                opacity: 1,
                                backgroundColor: defaultTheme.palette.primary.main,
                            },
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        width: 16,
                        height: 16,
                        borderRadius: '50%',
                        bosShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
                        backgroundColor: '#FAFAFA',
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 10,
                        opacity: 0.24,
                        backgroundColor: defaultTheme.palette.secondary.main,
                        boxSizing: 'border-box',
                    },
                    '& .MuiTouchRipple-root': {
                        display: 'none',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    padding: '5px 10px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 8px 24px rgba(0, 20, 91, 0.08), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02)",
                },
            },
        },

        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&.MuiMenuItem-root": {
                        "&:hover": {
                            backgroundColor: "rgba(246, 103, 0, 0.12) !important",
                        },
                    },

                    "&.Mui-selected": {
                        color: defaultTheme.palette.primary.main,
                        backgroundColor: "transparent !important",

                        "&.MuiPickersDay-root": {
                            backgroundColor: "rgba(246, 103, 0, 1) !important",
                        },

                        "&:hover": {
                            backgroundColor: "rgba(246, 103, 0, 0.56) !important",
                        }
                    }
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
            },

            styleOverrides: {
                root: {
                    '&:hover, &.Mui-focusVisible': {
                        backgroundColor: 'transparent',
                    },
                    '& input + *': {
                        position: 'relative',
                        border: '1px solid #AFB4C5',
                        borderRadius: 4,
                        width: 20,
                        height: 20,
                    },
                    '& input:not(:checked):hover + *': {
                        borderColor: '#F66700',
                    },

                    '& input:checked + *': {
                        backgroundColor: '#F66700',
                        borderColor: '#F66700',

                        '&:before': {
                            content: "''",
                            position: 'absolute',
                            display: 'block',
                            left: 3.5,
                            top: 5,
                            width: 12,
                            height: 8,
                            backgroundImage:
                                `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 8' %3E%3Cpath` +
                                " d='M1.91666 4.58337L4.24999 6.91671L10.0833 1.08337'" +
                                ` stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E")`,
                            backgroundRepeat: 'no-repeat',
                        },
                    },

                    /*                    "& input:checked:hover + *": {
                                            opacity: 0.8,
                                        },*/
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: '#F66700',
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    '& .MuiCheckbox-root ~ .MuiTypography-root': {
                        alignSelf: 'flex-start',
                        lineHeight: '1em',
                        letterSpacing: '-0.02em',
                        transform: 'translateY(12px)',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.Mui-focused:after': {
                        borderBottomColor: '#F66700',
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '.MuiDialog-root, .MuiPaper-root': {
                        [defaultTheme.breakpoints.down('sm')]: {
                            margin: '20px',
                            maxHeight: 'calc(100% - 40px)',
                        }
                    }
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 668,
            md: 1023,
            lg: 1440,
            xl: 1920,
        },
    },
});

export default primaryMuiTheme;
export { defaultTheme };
