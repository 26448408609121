import React from "react";
import { observer } from "mobx-react";
import DataStore from "../store/DataStore";
import { Navigate, useLocation } from "react-router-dom";
import { NonAuthRoutes } from "./routes";


const AuthRoute = observer(({ children = undefined }) => {
    const location = useLocation();

    if (!DataStore.isUserAuthorized) {
        return <Navigate to={NonAuthRoutes.signin} state={{ from: location }} replace />;
    }

    return children;
});

export default AuthRoute;
