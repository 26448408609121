import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import st from './session.module.scss';
// import { ReactComponent as VideoIcon } from '../../assets/images/icons/video-icon.svg';
import DataStore from 'src/store/DataStore';
import {
    Autocomplete,
    Avatar,
    Badge,
    Button,
    Dialog,
    Fade,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
// import { useStopwatch } from 'react-timer-hook';
import { ProjectChat } from '../../components/ProjectChat';
import axios from 'axios';
import classNames from 'classnames';
import { stringAvatar } from 'src/helpers/helpers';
import { postData } from 'src/api/api';
import stylePopup from '../../components/Popup/popup.module.scss';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/cross.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import ChatStore from 'src/store/ChatStore';
import UIStore from 'src/store/UIStore';
import ReadyStore from 'src/store/ReadyStore';
import {AuthRoutes} from "../../routes/routes";
import {Link} from "react-router-dom";

/*const Digit = ({ value }) => {
    const leftDigit = value >= 10 ? value.toString()[0] : '0';
    const rightDigit = value >= 10 ? value.toString()[1] : value.toString();

    return (
        <>
            <span>{leftDigit}</span>
            <span>{rightDigit}</span>
        </>
    );
};*/

export const SessionUserCard = observer(({ data = { online: false, profile: {}, positions: [] } }) => {
    return (
        <div className={st.user}>
            <div className={st.user__media}>
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                    className={classNames('status', data.online ? 'status_online' : 'status_offline')}
                >
                    <Avatar
                        src={data.profile?.avatar ? axios.defaults.baseURL + data.profile?.avatar.contentUrl : ''}
                        className={st.avatar}
                        sx={{
                            width: { xs: '48px' },
                            height: { xs: '48px' },
                        }}
                        {...stringAvatar(`${data.profile?.firstName} ${data.profile?.lastName}`)}
                    />
                </Badge>
            </div>
            <div className={st.user__content}>
                <div className={st.user__name}>
                    {data.profile?.firstName} {data.profile?.lastName}
                </div>

                {data.positions?.length > 0 &&
                    <div className={st.user__info}>{data.positions[0]?.title}</div>}

                <div className={st.user__info}>
                    {data.profile?.country?.title} {data.profile?.city?.title} <span>{data.profile?.timeZone}</span>
                </div>
            </div>
        </div>
    );
});

const durationList = [
    {
        title: '1 minutes (Test only. Temporary.)',
        value: 1,
    },
    {
        title: '15 minutes',
        value: 15,
    },
    {
        title: '30 minutes',
        value: 30,
    },
    {
        title: '45 minutes',
        value: 45,
    },
    {
        title: '1 hour',
        value: 60,
    },
    {
        title: '1 hour 30 minutes',
        value: 90,
    },
    {
        title: '2 hour',
        value: 120,
    },
    {
        title: '3 hour',
        value: 180,
    },
    {
        title: '4 hour',
        value: 240,
    },
];

const Session = ({
    project = {
        workChats: [],
        workCost: {},
        fixedPrice: false,
        duration: undefined,
    },
    setProject
}) => {
    const intl = useIntl();
    const { user, userType } = DataStore;
    const {
        setReadyStatus,
        workThatIsCommonReady,
        updateWorkThatIsCommonReady,
    } = ReadyStore;
    const { /*sessionTimer,*/ setErrorAlert, alertTimers, runZoomIsBlock } = UIStore;
    const scrollbars = React.useRef(null);
    const [state, setState] = React.useState({
        paid: project?.type === 'sessions' && project?.paid !== false,
        record: project?.creatorRecord && project?.executorRecord,
        disabledRecord: false,
        disabledPaid: false,
        ready: userType === 'expert' ? project.executorIsReady : project.creatorIsReady,
    });
    const { activeChat } = ChatStore;
    const { workCost } = project;
    const priceByMinutes = workCost.number / 60;
    // указывать в минутах
    const [duration, setDuration] = React.useState(1);
    const [zoomPopupOpen, setZoomPopupOpen] = React.useState(false);
    const [zoomPopupHowOpen, setZoomPopupHowOpen] = React.useState(false);
    const [durationListValue, setDurationListValue] = React.useState(durationList[0]);
    const { record, paid, disabledRecord, disabledPaid, ready } = state;
    // const { seconds, minutes, hours, start, pause, reset } = useStopwatch({ autoStart: false });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

/*    const handleTimerStart = () => {
        start();
    };*/

    const handleZoomPopupToggle = () => {
        setZoomPopupOpen((prev) => !prev);
    };

    const handleZoomPopupHowToggle = () => {
        setZoomPopupHowOpen((prev) => !prev);
    };

    const handleZoomStart = () => {
        const message = {
            accountId: process.env.REACT_APP_ZOOM_ACCOUNTID,
            clientId: process.env.REACT_APP_ZOOM_CLIENTID,
            clientSecret: process.env.REACT_APP_ZOOM_CLIENTSECRET,
        };
        // создаем токен созвона
        postData(`zoom/get-token`, message)
            .then(({ data }) => {
                handleZoomPopupToggle();

                const token = data.access_token;
                const datas = {
                    accessToken: token,
                    topic: project.title,
                    duration: duration,
                    chat_id: activeChat.id,
                    amount: paid ? Math.floor(Number(priceByMinutes * duration * 100)) : 0,
                    autoRecording: record && project?.executorRecord && project?.creatorRecord ? 'cloud' : 'none',
                };

                // создаем ссылку на созвон
                postData(`zoom/meeting-create`, datas)
                    .then(({ data }) => {
                        updateWorkThatIsCommonReady(0);
                        window.open(data.start_url, '_blank');
                    })
                    .catch((error) => {
                        console.log(error);
                        setErrorAlert(<>{JSON.stringify(error)} {(JSON.stringify(error).indexOf('Insufficient balance') !== -1 ?
                                <Link to={AuthRoutes.balance}>{intl.formatMessage({id: 'linkToBalance'})}</Link> : '')}</>,
                            alertTimers.long
                        );
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleReadyToSession = (event) => {
        handleChange(event);

        if (event.target.name === 'ready') {
            if (event.target.checked) {
                if (userType !== 'expert' && project.executorIsReady && workThatIsCommonReady !== project.id) {
                    updateWorkThatIsCommonReady(project.id);
                }
            } else {
                updateWorkThatIsCommonReady(0);
            }
        }

        setReadyStatus({
            ready: event.target.name === 'ready' ? event.target.checked : state.ready,
            record: event.target.name === 'record' ? event.target.checked : state.record,
            paid: event.target.name === 'paid' ? event.target.checked : state.paid,
            work: `api/works/${project.id}`,
        });
    }

    React.useEffect(() => {
        const commonProperties = {
            paid: project.type === 'sessions' && project.paid !== false,
            record: project.creatorRecord && project.executorRecord,
        }

        if (userType === 'expert') {
            setState({
                ...commonProperties,
                disabledPaid: true,
                ready: project.executorIsReady,
            });
        } else {
            setState({
                ...commonProperties,
                disabledPaid: project.type !== 'sessions' || !project.expert?.firstSessionFree,
                ready: project.creatorIsReady,
            });
        }
    }, [userType, project.type, project.expert?.firstSessionFree, project.executorIsReady, project.creatorIsReady, project.paid, project.creatorRecord, project.executorRecord]);

    React.useEffect(() => {
        scrollbars?.current?.scrollToBottom();
    }, [scrollbars]);

/*    React.useEffect(() => {
        if (sessionTimer) {
            handleTimerStart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionTimer]);*/

    React.useEffect(() => {
        const balance = user.balance?.available ? user.balance.available/100 : 0;

        durationList.map((item, index) => {
            if (item.value * priceByMinutes < balance) {
                return (durationList[index].disabled = false);
            } else {
                return (durationList[index].disabled = true);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    React.useEffect(() => {
        if (userType !== 'expert' && workThatIsCommonReady === project.id) {
            setZoomPopupOpen(true);
        }
    }, [project.id, workThatIsCommonReady, userType]);

/*    const handleTimerClose = () => {
        reset();
        pause();
    };*/

    return (
        <section className={st.wrapper}>
            <main className={st.content}>
                <ProjectChat project={project} />
            </main>

            <aside className={st.sidebar}>
                <div className={classNames('green', st.disabling_record, ((project.creatorIsReady && userType === 'expert') ||
                    (project.executorIsReady && userType !== 'expert')) ? st.disabling_record_active : '')}>
                    {userType === 'expert' ?
                        project.creatorIsReady && intl.formatMessage({id: 'creatorIsReady'})
                        :
                        project.executorIsReady && intl.formatMessage({id: 'executorIsReady'})
                    }
                </div>

                <div className={st.checkbox}>
                    <FormControlLabel
                        className={runZoomIsBlock ? 'disabled' : ''}
                        label={intl.formatMessage({ id: 'readyStart' })}
                        control={<Switch checked={ready} name="ready" onChange={handleReadyToSession} />}
                        // control={<Switch checked={workThatIsCommonReady === project.id} name="ready" onChange={handleReadyToSession} />}
                    />
                    <FormHelperText>
                        {runZoomIsBlock && <span className={'red'}>{intl.formatMessage({id: `${userType}ZoomBlocked`})}<br /></span>}
                        {intl.formatMessage({ id: 'readyStartTip' })}
                    </FormHelperText>
                </div>

{/*                <div className={st.timer}>
                    <div className={st.timer__title}>{intl.formatMessage({ id: 'session.timer.title' })}</div>
                    <div className={st.timer__time}>
                        <Digit value={hours} />:<Digit value={minutes} />:<Digit value={seconds} />
                    </div>
                </div>*/}

                <div className={st.controls}>
                    {/*<div className={classNames('red', st.disabling_record, (!eventData?.executorRecord || !eventData?.creatorRecord) ? st.disabling_record_active : '')}>*/}
                    <div className={classNames('red', st.disabling_record, (!project?.executorRecord || !project?.creatorRecord) ? st.disabling_record_active : '')}>
                        {userType === 'expert' ?
                            !project.creatorRecord && intl.formatMessage({id: 'clientDisabledRecord'})
                            :
                            !project.executorRecord && intl.formatMessage({id: 'expertDisabledRecord'})
                        }
                    </div>

                    <div className={st.checkbox}>
                        <FormControlLabel
                            className={disabledRecord ? 'disabled' : ''}
                            label={intl.formatMessage({ id: 'session.record' })}
                            control={<Switch disabled={disabledRecord} checked={record} name="record" onChange={handleReadyToSession} />}
                        />
                        <FormHelperText>
                            {intl.formatMessage({ id: (record ? 'recordOn' : 'recordOff') })}
                            {intl.formatMessage({ id: 'chatWorkHistoryMessageOnAdd' }, {var_1: <Link className={st.link} to={AuthRoutes.sessionHistory}>Session History</Link>})}
                        </FormHelperText>
                    </div>


                    {userType === 'expert' &&
                        <div className={classNames('red', st.disabling_record, !project.paid ? st.disabling_record_active : '')}>
                            {intl.formatMessage({id: 'sessionPaidWorning'})}
                        </div>
                    }

                    <div className={st.checkbox}>
                        <FormControlLabel
                            className={disabledPaid ? 'disabled' : ''}
                            label={intl.formatMessage({ id: 'session.paid' })}
                            control={<Switch disabled={disabledPaid} checked={paid} name="paid" onChange={handleReadyToSession} />}
                        />
                        <FormHelperText>{intl.formatMessage({ id: 'session.paid.text' })}</FormHelperText>
                    </div>
                </div>
            </aside>

            <Dialog
                TransitionComponent={Fade}
                onClose={handleZoomPopupToggle}
                open={zoomPopupOpen}
                scroll="body"
                className={classNames(stylePopup.popup)}
            >
                <div className={classNames(stylePopup.container, 'popup-container')}>
                    <IconButton className={stylePopup.close} onClick={handleZoomPopupToggle}>
                        <CrossIcon />
                    </IconButton>
                    <div className={stylePopup.title}>{intl.formatMessage({ id: 'session.zoom.setTimeTitle' })}</div>

                    <div className={stylePopup.text}>
                        <Typography variant="body2" sx={{ opacity: 0.64 }}>
                            {intl.formatMessage({ id: 'zoomPopupText1' })}
                        </Typography>

                        <Autocomplete
                            name="selectDuration"
                            className={classNames(st.input__box, st.first_session_free)}
                            popupIcon={<ArrowIcon />}
                            disableClearable
                            blurOnSelect
                            getOptionLabel={(option) => (option.title ? option.title : '')}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            value={durationListValue}
                            options={durationList}
                            getOptionDisabled={(option) => option.disabled}
                            onChange={(e, obj) => {
                                setDuration(obj.value);
                                setDurationListValue(obj);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "session.zoom.setTimeText" }) }}></span>
                    </div>

                    <Button variant="contained" color="primary" fullWidth size="large" onClick={handleZoomStart}>
                        {intl.formatMessage({ id: 'session.zoom.start' })}
                    </Button>
                </div>
            </Dialog>
            <Dialog
                TransitionComponent={Fade}
                onClose={handleZoomPopupHowToggle}
                open={zoomPopupHowOpen}
                scroll="body"
                className={classNames(stylePopup.popup)}
            >
                <div className={classNames(stylePopup.container, 'popup-container')}>
                    <IconButton className={stylePopup.close} onClick={handleZoomPopupHowToggle}>
                        <CrossIcon />
                    </IconButton>
                    <div className={stylePopup.title}>{intl.formatMessage({ id: 'session.zoom.how.title' })}</div>

                    <div className={stylePopup.text} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'session.zoom.how.text' }) }} />

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        onClick={() => {
                            handleZoomPopupHowToggle();
                            handleZoomPopupToggle();
                        }}
                    >
                        {intl.formatMessage({ id: 'session.zoom.start' })}
                    </Button>
                </div>
            </Dialog>
        </section>
    );
};

export default observer(Session);
