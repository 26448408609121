import {useEffect, useState} from "react";
import st from './googleAuth.module.scss';
import { useIntl } from 'react-intl';
import {ReactComponent as GoogleIcon} from "../../assets/images/icons/social_google.svg";
import Button from "@mui/material/Button";
import * as React from "react";
import {postData} from "../../api/api";
import {loadScript} from "../../helpers/helpers";
import UIStore from "../../store/UIStore";
import DataStore from "../../store/DataStore";
import {NonAuthRoutes} from "../../routes/routes";
import {useNavigate} from "react-router-dom";


export const GoogleAuth = ({userType = '', isSignIn = false}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const {localeUrl} = UIStore;
    const {authorizeUser} = DataStore;
    const [client, setClient] = useState(undefined);

    const getToken = () => {
        client?.requestAccessToken();
    }

    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client';
        const id = process.env.REACT_APP_GOOGLE_ID;

        const handleCredentialResponse = response => {
            let authData = {accessToken: response?.access_token};

            if (userType) {
                authData.userType = userType;
            }

            postData('auth/google', authData)
                .then(({data}) => {
                    if (data.refresh_token && data.token) {
                        authorizeUser(data.token, data.refresh_token);
                    }
                })
                .catch((errorMessage) => {
                    console.log('errorMessage', errorMessage);
                    if (errorMessage?.hasOwnProperty('type') && errorMessage.type.length > 0) {
                        navigate(localeUrl(NonAuthRoutes.signup), {
                            state: {
                                googleAccessToken: response?.access_token,
                            }
                        });
                    }
                });
        };

        (isSignIn || userType) &&
            loadScript(src)
                .then(() => {
                    /*global google*/
                    setClient(
                        google.accounts.oauth2.initTokenClient({
                            client_id: id,
                            scope: 'openid email',
                            callback: handleCredentialResponse,
                        })
                    );
                })
                .catch(console.error)

    }, [userType, authorizeUser, isSignIn, localeUrl, navigate]);

    return (
        <Button className={st.signup_button} size="large" color="secondary" variant="outlined" sx={{ width: '100%' }}
                onClick={getToken}
        >
            <GoogleIcon width="24" />
            <span>{intl.formatMessage({ id: (isSignIn ? 'signinGoogle' : 'signupGoogle') })}</span>
        </Button>
    );
};
