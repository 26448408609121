import axios from 'axios';
import DataStore from "../store/DataStore";
// api path
axios.defaults.baseURL = process.env.REACT_APP_API_ADDRESS;

axios.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem(process.env.REACT_APP_AT_NAME);

        if (accessToken) {
            config.headers["Authorization"] = "Bearer " + accessToken;
        }

        config.headers["X-LOCALE"] = process.env.REACT_APP_DEFAULT_LOCALE;
        // config.headers["X-LOCALE"] = localStorage.getItem("localeFse") ?? process.env.REACT_APP_DEFAULT_LOCALE;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error?.config;

        const refreshToken = localStorage.getItem(process.env.REACT_APP_RT_NAME);

        if (
            refreshToken &&
            error?.response?.status === 401 &&
            !originalRequest._retry &&
            originalRequest.url !== `${process.env.REACT_APP_API_ADDRESS}auth` &&
            originalRequest.url !== `${process.env.REACT_APP_API_ADDRESS}token/refresh`
        ) {
            originalRequest._retry = true;

            return axios({
                method: "POST",
                url: axios.defaults.baseURL + "token/refresh",
                data: {refresh_token: refreshToken},
            })
                .then((response) => {
                    if (response.status === 200) {
                        DataStore.authorizeUser(response.data.token, response.data.refresh_token);
                        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;

                        return axios(originalRequest);
                    }
                })
                .catch((error) => {
                    window.localStorage.removeItem(process.env.REACT_APP_AT_NAME);
                    window.localStorage.removeItem(process.env.REACT_APP_RT_NAME);
                    return axios(originalRequest);
                });
        }

        return Promise.reject(error);
    }
);
