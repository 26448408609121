import React, {useState} from 'react';
import st from './chatMessage.module.scss';
import {useIntl} from "react-intl";
import DataStore from "../../store/DataStore";
import classNames from "classnames";
import {ReactComponent as ReportIcon} from "../../assets/images/icons/flag-report.svg";
import {ReactComponent as SpamIcon} from "../../assets/images/icons/flag-spam.svg";
import UIStore from "../../store/UIStore";
import {Button, Link as LinkMui} from "@mui/material";
import axios from "axios";
import {ReactComponent as FileIcon} from "../../assets/images/icons/flag-file.svg";
import ChatStore from "../../store/ChatStore";
import {postData} from "../../api/api";
import {Link} from "react-router-dom";
import {NonAuthRoutes} from "../../routes/routes";
import {observer} from "mobx-react";
import {formatBytes} from "../../helpers/helpers";


export const ChatMessage = observer(({ dialog, isMyMessage = false }) => {
    const intl = useIntl();
    const { userType, userID } = DataStore;
    const { tabletIs, setErrorAlert, alertTimers, } = UIStore;
    const stClass = isMyMessage ? 'content_my' : 'content_interlocutor';
    const [iAmJoning, setIAmJoining] = useState(false);

    const JoinMeeting = ({ link }) => {
        const amount = link.match(/amount:(.+?),/)?.[1]/100;
        const duration = link.match(/duration:(.+?),/)?.[1];
        const zoomLink = link.match(/link:(.+?),/)?.[1];
        const recording = link.match(/recording:(.+?),/)?.[1] === 'cloud';

        return (
            <div className={classNames(st.joinMeeting, st.session)}>
                <Button variant="outlined" color="primary" size={tabletIs ? 'small' : 'medium'} component={LinkMui} href={zoomLink} target="_blank">
                    {intl.formatMessage({ id: 'messages.joinMeeting' })}
                </Button>

                <div>Session type: <b>{Number(amount) > 0 ? intl.formatMessage({id: 'paid'}) : intl.formatMessage({id: 'free'})}</b>.
                    {Number(amount) > 0 ? intl.formatMessage({id: 'paidDuration'}, {var_1: duration}) : ''}
                    {recording ? intl.formatMessage({id: 'isRecord'}) : intl.formatMessage({id: 'notRecord'})}
                </div>
            </div>
        );
    };

// файл внутри сообщений
    const File = ({ dialog }) => {
        return (
            <div className={classNames(st.content, st.content_file, dialog.sender.id === userID ? st.content_my : '')}>
                <a
                    href={dialog.file.contentUrl ? axios.defaults.baseURL + dialog.file.contentUrl : ''}
                    target="_blank"
                    className={st.file}
                    rel="noreferrer"
                >
                    <div className={st.file__media}>
                        <FileIcon />
                    </div>

                    <div className={st.file__content}>
                        <div className={st.file__name}>{dialog.file.contentUrl.replace(/\/media\/.*?_/, '')}</div>
                        {dialog.file.fileSize && <div className={st.file__size}>{formatBytes(dialog.file.fileSize)}</div>}
                    </div>
                </a>
            </div>
        );
    };

// создание сессии
    const Session = ({ dialog }) => {
        const sender = ChatStore.sender.profile;

        const handleDecline = () => {
            const message = {
                invitationStatus: 'declined',
                sender: `api/users/${userID}`,
                invitedGhost: `api/users/${ChatStore.activeChat.executor.id}`,
                // work: `api/works/${value}`,
                systemMessage: false,
                chat: `api/chats/${ChatStore.activeChat.id}`,
                spam: false,
            };

            postData(`api/chat_work_invitation_messages/${dialog.id}`, message, 'put')
                .then(({ data }) => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlert(error ? error : intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
                });
        };

        const handleJoin = () => {
            const message = {
                invitationStatus: 'joined',
                sender: `api/users/${userID}`,
                invitedGhost: `api/users/${ChatStore.activeChat.executor.id}`,
                systemMessage: false,
                chat: `api/chats/${ChatStore.activeChat.id}`,
                spam: false,
            };

            postData(`api/chat_work_invitation_messages/${dialog.id}`, message, 'put')
                .then(({ data }) => {
                    console.log(data);
                    setIAmJoining(true);
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlert(error ? error : intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
                });
        };
        return (
            <div className={classNames(st.content__item, st.session)}>
                <div className={st.session__text}>
                    {userType === 'expert'
                        ? intl.formatMessage(
                            { id: 'messages.invite.expert' },
                            { type: dialog.work?.type === 'projects' ? 'project' : 'session', name: `${sender?.firstName} ${sender?.lastName}` }
                        )
                        : intl.formatMessage(
                            { id: 'messages.invite.client' },
                            { type: dialog.work?.type === 'projects' ? 'project' : 'session', name: `${sender?.firstName} ${sender?.lastName}` }
                        )}
                </div>

                <div className={st.session__work}>{dialog.work?.title}</div>


                {(userType !== 'expert' || dialog.work?.status === 'opened') &&
                    <div className={st.session__buttons}>
                        {(userType === 'expert' && !iAmJoning && dialog.work?.status === 'opened') &&
                            <>
                                <Button variant="outlined" color="primary" onClick={handleDecline} fullWidth={tabletIs}
                                        size={tabletIs ? 'small' : 'medium'}>
                                    {intl.formatMessage({id: 'messages.decline'})}
                                </Button>

                                <Button variant="contained" color="primary" onClick={handleJoin} fullWidth={tabletIs}
                                        size={tabletIs ? 'small' : 'medium'}>
                                    {intl.formatMessage({id: 'messages.join'})}
                                </Button>
                            </>
                        }

                        <Link to={`${NonAuthRoutes.projects}/${dialog.work?.id}`} target="_blank" style={{whiteSpace: 'nowrap'}}>
                            <Button variant="contained" color="secondary" fullWidth={tabletIs} size={tabletIs ? 'small' : 'medium'}>
                                {intl.formatMessage({id: 'messages.viewJob'})}
                            </Button>
                        </Link>
                    </div>
                }
            </div>
        );
    };

    return (
        <>
            {dialog.systemMessage ?
                <div className={st.systemMessage}>
                    {dialog.messageClassName === 'cron_zoom_completed' ?
                        intl.formatMessage({id: 'cronZoomCompleted'})
                    :
                        dialog.text
                    }
                </div>
                :
                dialog.messageClassName === 'ChatJoinMeetingMessage' ?
                    <JoinMeeting link={dialog.text}/>
                    : (
                        !['ChatWorkHistoryMessage', 'ChatFileMessage'].includes(dialog.messageClassName) &&
                        (dialog.messageClassName === 'ChatTextMessage' || (typeof dialog.workChat === 'string' && dialog.workChat?.includes('work_chats')))
                    ) ?
                        dialog.text &&
                        <>
                            <div className={classNames(st.content, st[stClass])}>
                                <div className={st.text} dangerouslySetInnerHTML={{__html: dialog.text}}/>

                                <div className={st.bottom}>
                                    {!isMyMessage &&
                                        <>
                                            <div className={classNames(st.tooltip, 'btn')}>
                                                <ReportIcon/>
                                                {intl.formatMessage({id: 'messages.tooltip.report'})}
                                            </div>

                                            <div className={classNames(st.tooltip, 'btn')}>
                                                <SpamIcon/>
                                                {intl.formatMessage({id: 'messages.tooltip.spam'})}
                                            </div>
                                        </>
                                    }
                                </div>

                                {/*<div className={st.time}>{intl.formatTime(dialog.createdAt, {hourCycle: 'h24'})}</div>*/}
                            </div>
                        </>
                        :
                        (dialog.messageClassName === 'ChatFileMessage') ?
                            <File dialog={dialog}/>
                            :
                            (dialog.messageClassName === 'ChatWorkInvitationMessage') ?
                                <Session dialog={dialog}/>
                                :
                                (dialog.messageClassName === 'ChatWorkHistoryMessage') ?
                                    <div className={st.systemMessage}>
                                        {intl.formatMessage({id: `chatWorkHistoryMessage_${dialog.historyStatus}`},
                                            {var_1: dialog.sender?.profile?.firstName + ' ' + dialog.sender?.profile?.lastName, var_2: (dialog.historyStatus === 'confirm_payment' ? dialog.amount / 100 : '')})}

                                    </div>
                                : ''
            }

            {(dialog.systemMessage || dialog.messageClassName !== 'ChatTextMessage' || dialog.text) &&
                <div className={classNames(st.time, st[stClass + '_time'])}>{intl.formatTime(dialog.createdAt, {hourCycle: 'h24'})}</div>
            }
        </>
    );

});
