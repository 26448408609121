import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import {NonAuthRoutes} from "../../routes/routes";
import st from './newsPreview.module.scss';
import axios from "axios";
import {Avatar} from "@mui/material";
import {ReactComponent as AvatarIcon} from "../../assets/images/icons/default-avatar.svg";

export const NewsPreview = ({ index, id, date, title, tags, picture, categories, isOtherNews = false }) => {
    return (
        <article className={classNames(st.card)}>

            <Link to={`${NonAuthRoutes.blog}/${id}`} className={classNames(st.link)}>
                {picture && (
                    <div className={st.media}>
                        
                            {picture?.contentUrl ?
                                <Avatar
                                    variant="square"
                                    src={axios.defaults.baseURL + picture.contentUrl}
                                    // className={st.avatar}
                                    alt="photo"
                                    sx={{width: {
                                            xs: '100%',
                                            sm: ((isOtherNews || [0, 8].includes(index)) ? '100%' : 155),
                                            md: ((isOtherNews || [0, 8].includes(index)) ? '100%' : 212),
                                            lg: ((isOtherNews || [0, 8].includes(index)) ? '100%' : 300),
                                        },
                                        height: {
                                            xs: 'auto',
                                            sm: ((isOtherNews || [0, 8].includes(index)) ? 'auto' : 120),
                                            md: ([0, 8].includes(index) ? 'auto' : 150),
                                            lg: ([0, 8].includes(index) ? 'auto' : 180)
                                        },
                                        maxHeight: {
                                            xs: 160,
                                            md: ([0, 8].includes(index) ? 'none' : 150),
                                            lg: ([0, 8].includes(index) ? 'none' : 180)
                                        }
                                    }}
                                />
                                :
                                <AvatarIcon />
                            }
                            {/*<img src={picture ? (axios.defaults.baseURL + picture.contentUrl) : ''} alt={title} />*/}
                    </div>
                )}
                <div className={st.content}>
                    <div className={st.title}>{title}</div>
                    <div className={st.info}>
                        <div className={st.date}>{date}</div>

                        {categories &&
                            <div className={st.tags}>
                                {/* {tags?.map((tag, index) => (
                                    <Link className={st.tag} to={tag.url} key={index}>
                                        {tag.name}
                                    </Link>
                                ))} */}
                                {categories?.map(item =>
                                    <span key={item.id} className={st.tag}>{item.title}</span>
                                )}
                            </div>
                        } 
                    </div>
                </div>
            </Link>
        </article>
    );
};
