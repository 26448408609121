import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import LandingFooter from '../../components/LandingFooter';
import LandingHeader from '../../components/LandingHeader/LandingHeader';
import st from './LandingLayout.module.scss';
import classNames from 'classnames';
import UIStore from '../../store/UIStore';
import Preloader from "../../components/Preloader";
import {Alert} from "../../components/Alert";


const LandingLayout = observer(() => {
    const {
        mobileMenuIsOpen,
        mobileMenuUserIsOpen,
        setDevices,
    } = UIStore;

    useEffect(() => {
        function handleResize() {
            setDevices(Boolean('ontouchstart' in window || navigator.maxTouchPoints > 0));
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    });


    return (
        <div className={classNames(st.container, { [st.scroll_is_disabled]: (mobileMenuIsOpen || mobileMenuUserIsOpen) })}>
            <Preloader />

            <LandingHeader />

            <main className={st.content}>
                <Alert />

                <Outlet />
            </main>

            <LandingFooter />
        </div>
    );
});

export default LandingLayout;
