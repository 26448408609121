import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import st from './messages.module.scss';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import classNames from 'classnames';
import ChatsList from 'src/components/Chat/ChatsList/ChatsList';
import Toolbar from 'src/components/Chat/Toolbar/Toolbar';
import MessagesList from 'src/components/Chat/MessagesList/MessagesList';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ChatStore from 'src/store/ChatStore';
import UIStore from 'src/store/UIStore';
import { Typography } from '@mui/material';
import DataStore from 'src/store/DataStore';


const Messages = () => {
    const intl = useIntl();
    const { tabletIs } = UIStore;
    const { userType } = DataStore;
    const { chatListLength } = ChatStore;
    const scrollbars = React.useRef(null);
    const [chatList, setChatList] = React.useState(ChatStore.chatList);

    const handleSearch = (e) => {
        setChatList(
            Object.values(ChatStore.chatList).filter((chat) => {
                if (e.target.value.trim() === '') {
                    return ChatStore.chatList;
                }

                // вычисляем кто собеседник и потом фильтруем
                let senderProfile = userType !== 'expert' ? chat.executor?.profile : chat.workOwner?.profile;

                if (chat.workChat && userType !== 'expert') {
                    senderProfile = chat.executor.profile;
                } else if (chat.workChat && userType === 'expert') {
                    senderProfile = chat.workOwner.profile;
                }

                const fieldSearch = `${senderProfile?.firstName?.toLowerCase()} ${senderProfile?.lastName?.toLowerCase()} ${
                    chat.lastMessage ? chat.lastMessage.text?.toLowerCase() : ''
                }`;

                return fieldSearch.includes(e.target.value.toLowerCase());
            })
        );
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Escape' && ChatStore.activeChat.id) {
            ChatStore.setActiveChat({});
        }
    };

    // Запрос чата и активация веб сокетов
    React.useEffect(() => {
        setChatList(ChatStore.chatList);
    }, [chatListLength]);

    // закрытие чата
    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyPress, false);

        return () => window.removeEventListener('keydown', handleKeyPress, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ChatStore.activeChat.id, scrollbars]);

    return (
        <section className={st.wrapper}>
            <aside className={classNames(st.sidebar, tabletIs && ChatStore.activeChat.id ? st.sidebar_hide : '')}>
                <Typography variant="h4" mt={0} mb={3} className={st.title}>
                    {intl.formatMessage({ id: 'messages.title' })}
                </Typography>

                <div className={st.search}>
                    <input type="text" placeholder="Search" required className={st.search__input} autoFocus={!tabletIs} onChange={handleSearch} />
                    <button className={classNames(st.search__submit, 'btn')} type="button" disabled>
                        <SearchIcon />
                    </button>
                </div>

                {chatList && (
                    <div className={st.list}>
                        <Scrollbars
                            autoHide
                            renderTrackHorizontal={(props) => <div {...props} style={{ display: 'none' }}></div>}
                            renderThumbHorizontal={(props) => <div {...props} style={{ display: 'none' }}></div>}
                            renderView={(props) => <div {...props} className={st.sidebar__view} />}
                            className={st.sidebar__scroller}
                        >
                            {Object.values(chatList)
                                // сортируем по убыванию дат
                                .slice()
                                .sort((a, b) => {
                                    return (
                                        new Date(b.lastMessage ? b.lastMessage.createdAt : b.createdAt) -
                                        new Date(a.lastMessage ? a.lastMessage.createdAt : a.createdAt)
                                    );
                                })
                                .map((chat, index) => (
                                    <ChatsList key={chat.id + chat.channelName} index={index} chat={chat} />
                                ))}
                        </Scrollbars>
                    </div>
                )}
            </aside>

            <main className={classNames(st.content, tabletIs && !ChatStore.activeChat.id ? st.content_hide : '')}>
                {ChatStore.activeChat.id ? (
                    <>
                        <Toolbar />

                        <MessagesList />
                    </>
                ) : (
                    <div className={st.content__empty}>
                        <h2 className={st.content__empty_title}>{intl.formatMessage({ id: 'messages.chat.empty' })}</h2>
                    </div>
                )}
            </main>
        </section>
    );
};

export default observer(Messages);
