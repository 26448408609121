import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Field, FormikProvider, useFormik} from 'formik';
import { NonAuthRoutes } from '../../../routes/routes';
import { Link } from 'react-router-dom';
import st from '../Auth.module.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/images/icons/angle-bracket-prev.svg';
import validator from "validator";
import {useState} from "react";
import {postData} from "../../../api/api";
import UIStore from "../../../store/UIStore";


const ForgotPassword = () => {
    const intl = useIntl();
    const { localeUrl, setErrorAlert, alertTimers } = UIStore;
    const [successForgotPassIsActive, setSuccessForgotPassIsActive] = useState(false);

    const validateForgotPass = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = intl.formatMessage({id: 'required'});
        }

        if (values.email && !validator.isEmail(values.email)) {
            errors.email = intl.formatMessage({id: 'errors.email'});
        }

        return errors;
    };

    const form = useFormik({
        initialValues: {
            email: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: validateForgotPass,
        onSubmit: (values) => {
            setErrorAlert('');
            postData('forgot-password/reset-password', {email: values.email})
                .then(() => {
                    setSuccessForgotPassIsActive(true);
                })
                .catch((errorMessage) => {
                    setErrorAlert(errorMessage, alertTimers.long);
                });
        },
    });

    return (
        <div className={st.auth_form}>
            {!successForgotPassIsActive ? (
                <FormikProvider value={form}>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={form.handleSubmit}
                        onChange={(event) => {
                            if (form.errors) {
                                form.setFieldError(event.target.name, '');
                            }
                        }}
                    >
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Link to={localeUrl(NonAuthRoutes.signin)}>
                                    <div className={st.back}>
                                        <ArrowIcon />
                                        {intl.formatMessage({ id: 'auth.forgotPassword.back' })}
                                    </div>
                                </Link>

                                <Typography
                                    component="h1"
                                    variant="h3"
                                    sx={{ m: 0 }}
                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.forgotPassword.title' }) }}
                                    gutterBottom
                                ></Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    component="p"
                                    variant="body1"
                                    dangerouslySetInnerHTML={{
                                        __html: intl.formatMessage({ id: 'auth.forgotPassword.description' }),
                                    }}
                                ></Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name='email'
                                    children={(fieldProps) => (
                                        <TextField
                                            {...fieldProps.field}
                                            fullWidth
                                            label={intl.formatMessage({id: "forgotPasswordEmail"})}
                                            variant='outlined'
                                            error={form.touched.email && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                            helperText={form.touched.email && !form.isValid && fieldProps.meta.error}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button type="submit" fullWidth color="primary" variant="contained" sx={{ width: '100%' }}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: intl.formatMessage({ id: 'auth.forgotPassword.button' }),
                                        }}
                                    ></span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </FormikProvider>
            ) : (
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h4"
                            sx={{ m: 0 }}
                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.forgotPassword.success.title' }) }}
                            gutterBottom
                        ></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            component="p"
                            variant="body1"
                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.forgotPassword.success.description' }) }}
                        ></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Link to={localeUrl(NonAuthRoutes.signin)}>
                            <Button variant="contained" color="primary" onClick={()=> setSuccessForgotPassIsActive(false)} autoFocus sx={{ width: '100%' }}>
                                {intl.formatMessage({ id: 'auth.forgotPassword.success.button' })}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default observer(ForgotPassword);
