import React, { useState, useEffect } from 'react';
import st from './LandingHeader.module.scss';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';

import { Dropdown } from './MenuItems';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { NonAuthRoutes } from 'src/routes/routes';
import UIStore from '../../store/UIStore';
import MobileMenuItems from './MobileMenuItems';
import { useIntl } from 'react-intl';
import { getData } from '../../api/api';
import DataStore from '../../store/DataStore';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';

export const NavMenu = observer(({ classList = undefined, type = undefined, forDevices = undefined }) => {
    const intl = useIntl();
    const [dropdownFind, setDropdownFind] = useState(false);
    const [dropdownAbout, setDropdownAbout] = useState(false);
    const [dropdownServices, setDropdownServices] = useState(false);
    const [categories, setCategories] = useState({
        title: intl.formatMessage({ id: 'findExpert' }),
        submenu: [],
    });
    const { closeMobileMenu } = UIStore;
    const { skillCategories, updateSkillCategories, skillSubCategories, updateSkillSubCategories, userType, isUserAuthorized } = DataStore;

    const pathname = useLocation().pathname.replace(/\//g, '');
    const findDisabledClass =
        (isUserAuthorized && userType !== 'expert' && NonAuthRoutes.experts.slice(1) === pathname) ||
        (isUserAuthorized && userType === 'expert' && NonAuthRoutes.projects.slice(1) === pathname)
            ? 'disabled'
            : '';

    const onMouseEnter = () => {
        if (window.innerWidth > 1024) {
            setDropdownFind(true);
            setDropdownAbout(true);
            setDropdownServices(true);
        }
    };

    const itemsAbout = {
        title: 'About',
        submenu: [
            // {
            //     title: 'How it works',
            //     url: NonAuthRoutes.how,
            // },
            {
                title: 'FAQ',
                url: NonAuthRoutes.faq,
            },
            {
                title: 'Blog',
                url: NonAuthRoutes.blog,
            },
            {
                title: ' Contact',
                url: NonAuthRoutes.contacts,
            },
        ],
    };

    const itemsServices = {
        title: 'Services',
        submenu: [
            {
                title: 'For Employers',
                url: NonAuthRoutes.forEmployers,
            },
        ],
    };

    useEffect(() => {
        if (isUserAuthorized && userType !== 'expert') {
            if (skillCategories.length === 0) {
                getData('api/skill_categories').then(({ data }) => {
                    updateSkillCategories(data.items);
                });
            }

            if (skillSubCategories.length === 0) {
                getData('api/skill_sub_categories?perPage=200').then(({ data }) => {
                    updateSkillSubCategories(data.items);
                });
            }
        } else {
            setCategories({
                title: intl.formatMessage({ id: 'app.header.jobs' }),
                submenu: [
                    {
                        title: intl.formatMessage({ id: 'app.header.projects' }),
                        url: `${NonAuthRoutes.projects}?type=projects`,
                    },
                    {
                        title: intl.formatMessage({ id: 'sessions' }),
                        url: `${NonAuthRoutes.projects}?type=sessions`,
                    },
                ],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userType, isUserAuthorized]);

    useEffect(() => {
        if (isUserAuthorized && userType !== 'expert' && skillCategories.length > 0 && skillSubCategories.length > 0) {
            let categoriesData = skillCategories.map((item, index) => ({
                title: item.title,
                submenu: skillSubCategories.filter((subItem) => subItem.skillCategory.id === item.id),
            }));

            setCategories((prev) => ({ ...prev, submenu: categoriesData }));
        }
    }, [skillCategories, skillSubCategories, isUserAuthorized, userType]);

    return forDevices ? (
        <nav className={classNames(st.mobileMenu, type !== 'dashboard' ? st.default_page : '', classList)}>
            {!isUserAuthorized && (
                <ul className={st.mobileMenu__list}>
                    <li className={st.mobileMenu__item}>
                        <Link to={NonAuthRoutes.experts} onClick={closeMobileMenu}>Find Experts</Link>
                    </li>
                </ul>
            )}

            <ul className={st.mobileMenu__list}>
                <MobileMenuItems items={categories} depthLevel={0} findExpertBlock={findDisabledClass} />
            </ul>

            <ul className={st.mobileMenu__list}>
                <MobileMenuItems items={itemsAbout} depthLevel={0} />
            </ul>

            <ul className={st.mobileMenu__list}>
                <MobileMenuItems items={itemsServices} depthLevel={0} />
            </ul>

            <Link to={NonAuthRoutes.contacts} className={classNames(st.mobileMenu__button, 'btn')} onClick={closeMobileMenu}>
                {intl.formatMessage({ id: 'help.title' })}
            </Link>
        </nav>
    ) : (
        <nav className={classNames(st.nav, type !== 'dashboard' ? st.default_page : '', classList)}>
            <div className={st.nav__list}>
                {!isUserAuthorized && (
                    <div className={st.nav__dropdown}>
                        <Link to={NonAuthRoutes.experts} className={classNames(st.nav__dropdown_btn, 'btn')}>
                            <span>Find Experts</span>
                        </Link>
                    </div>
                )}

                <ClickAwayListener onClickAway={() => setDropdownFind(false)}>
                    <div className={st.nav__dropdown}>
                        <button
                            onClick={() => {
                                onMouseEnter();
                                closeMobileMenu();
                            }}
                            className={classNames(st.nav__dropdown_btn, 'btn', dropdownFind ? 'is-active' : '', findDisabledClass)}
                        >
                            <span>{categories.title}</span>
                            <ArrowIcon />
                        </button>

                        <Dropdown depthLevel={0} submenus={categories.submenu} dropdown={dropdownFind} />
                    </div>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={() => setDropdownAbout(false)}>
                    <div className={st.nav__dropdown}>
                        <button
                            onClick={() => {
                                onMouseEnter();
                                closeMobileMenu();
                            }}
                            className={classNames(st.nav__dropdown_btn, 'btn', dropdownAbout ? 'is-active' : '')}
                        >
                            <span>{itemsAbout.title}</span>
                            <ArrowIcon />
                        </button>
                        <Dropdown depthLevel={0} submenus={itemsAbout.submenu} dropdown={dropdownAbout} />
                    </div>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={() => setDropdownServices(false)}>
                    <div className={st.nav__dropdown}>
                        <button
                            onClick={() => {
                                onMouseEnter();
                                closeMobileMenu();
                            }}
                            className={classNames(st.nav__dropdown_btn, 'btn', dropdownServices ? 'is-active' : '')}
                        >
                            <span>{itemsServices.title}</span>
                            <ArrowIcon />
                        </button>
                        <Dropdown depthLevel={0} submenus={itemsServices.submenu} dropdown={dropdownServices} />
                    </div>
                </ClickAwayListener>
            </div>
        </nav>
    );
});
