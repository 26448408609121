import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { getData, postData } from '../../../api/api';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import classNames from 'classnames';
import stCabinet from '../../../layouts/Cabinet/cabinetLayout.module.scss';
import st from './projectEdit.module.scss';
import { Autocomplete, Button, Dialog, Fade, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Tip } from '../../../components/Tip';
import DataStore from '../../../store/DataStore';
import UIStore from '../../../store/UIStore';
import { ReactComponent as InfoIcon } from '../../../assets/images/icons/info.svg';
import { ReactComponent as SelectIcon } from '../../../assets/images/icons/arrow.svg';
import { objectToArray } from '../../../helpers/helpers';
import Preloader from '../../../components/Preloader';
import { AuthRoutes, NonAuthRoutes } from '../../../routes/routes';
import { ReactComponent as ArrowIcon } from '../../../assets/images/icons/angle-bracket-prev.svg';
import { ReactComponent as AttachIcon } from '../../../assets/images/icons/attach.svg';
import CardPreviewExpert from '../../../components/CardPreview/CardPreviewExpert';
import axios from 'axios';
import mediaObjectApi from '../../../api/mediaObjectApi';
import stylePopup from '../../../components/Popup/popup.module.scss';
import ChatStore from "../../../store/ChatStore";

const ProjectEdit = () => {
    const assignTo = new URLSearchParams(useLocation().search)?.get('assignTo');
    const intl = useIntl();
    const navigate = useNavigate();
    // Is Edit or Post
    const projectId = useParams().id;
    const {
        user,
        userType,
        userUrl,
        updateUser,
        userID,
        skillCategories,
        updateSkillCategories,
        skillSubCategories,
        updateSkillSubCategories,
        positions,
        updatePositions,
        softwareEquipments,
        updateSoftwareEquipments,
        hardwareEquipments,
        updateHardwareEquipments,
        services,
        updateServices,
    } = DataStore;
    const { mobileIs, setSuccessAlert, setErrorAlert, alertTimers } = UIStore;
    const { getChatList } = ChatStore;
    const [projectData, setProjectData] = useState({});
    const [assignedExpertData, setAssignedExpertData] = useState({});
    const [uploadError, setUploadError] = useState(false);
    const [projectFile, setProjectFile] = useState('');

    const uploadFile = (e) => {
        if (user && !user.active) {
            return;
        }

        setSuccessAlert('');
        setErrorAlert('');

        if (e.target.files[0] === undefined) {
            return;
        } else if (e.target.files[0]?.size > 50000000) {
            setUploadError(true);
            return;
        } else {
            setUploadError(false);
        }

        mediaObjectApi
            .loadMedia(e.target.files[0])
            .then((response) => {
                if (response.status === 201 && response.data.id && response.data.contentUrl) {
                    if (projectFile?.id) {
                        mediaObjectApi.deleteMedia(`api/media_objects/${projectFile.id}`);
                    }

                    const fileIri = `api/media_objects/${response.data.id}`;
                    form.setFieldValue('file', fileIri);
                    setProjectFile(response.data);

                    if (projectId) {
                        postData(`api/${form.values.type}${projectId ? '/' + projectId : ''}`, { file: fileIri }, 'put')
                            .then(({ data }) => {
                                setSuccessAlert(intl.formatMessage({ id: 'successSave' }), alertTimers.short);
                            })
                            .catch((error) => {
                                console.log(error);
                                setErrorAlert(error ? JSON.stringify(error) : intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
                            });
                    }
                } else {
                    console.log(response);
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
            });
    };

    const inviteExpert = (projectIdInvite) => {
        const dataFirstChat = {
            sender: `api/users/${userID}`,
            recipient: `api/experts/${assignTo}`,
            message: {
                text: '',
                sender: `api/users/${userID}`,
            },
        };

        postData(`api/first-chat-message`, dataFirstChat, 'post')
            .then(({ data }) => {
                if (data?.message?.chat) {
                    const dataInvite = {
                        invitationStatus: 'new',
                        sender: `api/users/${userID}`,
                        invitedGhost: `api/users/${assignTo}`,
                        work: `api/works/${projectIdInvite}`,
                        systemMessage: false,
                        chat: `${data.message.chat.slice(1)}`,
                        spam: false,
                    };

                    postData(`api/chat_work_invitation_messages`, dataInvite, 'post')
                        .then(({ data }) => {
                            setSuccessAlert(intl.formatMessage({ id: 'success' }), alertTimers.short);
                            getChatList();
                        })
                        .catch((error) => {
                            console.log(error);
                            setErrorAlert(intl.formatMessage({ id: 'errorInviting' }), alertTimers.long);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(intl.formatMessage({ id: 'errorInviting' }), alertTimers.long);
            });
    };

    const validate = (values) => {
        const errors = {};

        if (!values.title.trim()) {
            errors.title = intl.formatMessage({ id: 'required' });
        }

        if (!values.description.trim()) {
            errors.description = intl.formatMessage({ id: 'required' });
        }

        if (!assignTo) {
            if (!values.position) {
                errors.position = intl.formatMessage({ id: 'required' });
            }

            if (!values.service) {
                errors.service = intl.formatMessage({ id: 'required' });
            }

            if (!values.category) {
                errors.category = intl.formatMessage({ id: 'required' });
            }

            if (values.subCategories.length === 0) {
                errors.subCategories = intl.formatMessage({ id: 'required' });
            }
        }

        if (!values.workCost) {
            errors.workCost = intl.formatMessage({ id: 'required' });
        }

        if (!values.duration && values.type === 'sessions') {
            errors.duration = intl.formatMessage({ id: 'required' });
        }

        if (values.duration && values.type === 'sessions') {
            if (values.duration === ',') {
                errors.duration = intl.formatMessage({ id: 'enterNumber' });
            } else if (!Number(values.duration) && values.duration === ',') {
                errors.duration = intl.formatMessage({ id: 'enterNumber' });
            }
        }

        if (values.workCost) {
            if (values.workCost === ',') {
                errors.workCost = intl.formatMessage({ id: 'enterNumber' });
            } else if (!Number(values.workCost) && values.workCost === ',') {
                errors.workCost = intl.formatMessage({ id: 'enterNumber' });
            }
        }

        if (Object.keys(errors).length) {
            console.log('errors', errors);
        }

        return errors;
    };

    const initialValues = {
        // sessionUniqueLink: projectData?.sessionUniqueLink ? `api/session_unique_links/${projectData?.sessionUniqueLink?.id}` : '',
        type: projectData?.type ?? 'sessions',
        title: projectData?.title ?? '',
        description: projectData?.description ?? '',
        position: projectData?.position ?? null,
        service: projectData?.service ?? null,
        category: projectData?.category ?? null,
        subCategories: projectData?.subCategories?.length ? objectToArray(projectData.subCategories) : [],
        software: projectData?.software?.length ? objectToArray(projectData.software) : [],
        hardware: projectData?.hardware?.length ? objectToArray(projectData.hardware) : [],
        fixedPrice: projectData?.fixedPrice ? 'true' : 'false',
        workCost: projectData?.workCost ? projectData.workCost.number : '',
        workMethod: projectData?.workMethod ?? 'remotely',
        duration: projectData?.duration ?? '',
        file: projectData?.file?.id ? `api/media_objects/${projectData.file.id}` : null,
    };

    const form = useFormik({
        validate: validate,
        initialValues: initialValues,
        // By editing object need reinitialaze values, after loading it
        enableReinitialize: true,
        // enableReinitialize: !!projectId,

        onSubmit: (values, { setErrors }) => {
            // postData(`api/works${projectId ? '/' + projectId : ''}`,
            if (user && !user.active) {
                return;
            }

            postData(
                `api/${values.type}${projectId ? '/' + projectId : ''}`,
                {
                    ...values,
                    client: userType === 'client' ? `api${userUrl}` : null,
                    company: userType === 'company' ? `api${userUrl}` : null,
                    position: values.position?.id ? `api/positions/${values.position.id}` : null,
                    service: values.service?.id ? `api/services/${values.service.id}` : null,
                    category: values.category?.id ? `api/skill_categories/${values.category?.id}` : null,
                    subCategories: values.subCategories?.length > 0 ? values.subCategories.map((item) => `api/skill_sub_categories/${item.id}`) : [],
                    software: values.software?.length > 0 ? values.software.map((item) => `api/software_equipments/${item.id}`) : [],
                    hardware: values.hardware?.length > 0 ? values.hardware.map((item) => `api/hardware_equipments/${item.id}`) : [],
                    duration: values.duration ? Number(values.duration) : 0,
                    workCost: { number: Number(values.workCost), currency: '$' },
                    fixedPrice: values.fixedPrice === 'true',
                    file: values.file ?? null,
                },
                projectId ? 'put' : 'post'
            )
                .then(({ data }) => {
                    setSuccessAlert(intl.formatMessage({ id: (projectId ? 'successSave' : 'jobPosted') }), alertTimers.short);

                    if (!projectId) {
                        updateUser({ ...user, works: [...user.works, data] });

                        if (assignTo) {
                            inviteExpert(data?.id);
                        }
                        navigate(`${NonAuthRoutes.projects}/${data.id}`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setErrorAlert(error ? JSON.stringify(error) : intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
                });
        },
    });

    useEffect(() => {
        let isMounted = true;
        if (projectId) {
            getData(`api/works/${projectId}`)
                .then(({ data }) => {
                    if (isMounted) {
                        setProjectData(data);
                        setProjectFile(data.file);
                    }
                })
                .catch((error) => console.log(error));
        } else {
            setProjectData({});
        }

        return () => {
            isMounted = false;
        };
    }, [projectId]);

    useEffect(() => {
        let isMounted = true;

        if (assignTo) {
            getData(`api${NonAuthRoutes.experts}/${assignTo}`)
                .then(({ data }) => {
                    if (isMounted) {
                        setAssignedExpertData(data);
                    }
                })
                .catch((error) => console.log(error));
        } else {
            setProjectData({});
        }

        return () => {
            isMounted = false;
        };
    }, [assignTo]);

    useEffect(() => {
        if (positions.length === 0) {
            getData(`api/positions`).then(({ data }) => {
                updatePositions(data.items);
            });
        }

        if (services.length === 0) {
            getData(`api/services`).then(({ data }) => {
                updateServices(data.items);
            });
        }

        if (skillCategories.length === 0) {
            getData('api/skill_categories').then(({ data }) => {
                updateSkillCategories(data.items);
            });
        }

        if (skillSubCategories.length === 0) {
            getData('api/skill_sub_categories?perPage=200').then(({ data }) => {
                updateSkillSubCategories(data.items);
            });
        }

        if (hardwareEquipments.length === 0) {
            getData('api/hardware_equipments?perPage=70').then(({ data }) => {
                updateHardwareEquipments(data.items);
            });
        }

        if (softwareEquipments.length === 0) {
            getData('api/software_equipments?perPage=70').then(({ data }) => {
                updateSoftwareEquipments(data.items);
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const FixedPrice = () => {
        return (
            <Field
                name="fixedPrice"
                children={(fieldProps) => (
                    <RadioGroup {...fieldProps.field} defaultValue="remotely" row={true} className={classNames(st.radio, st.pay_switcher)}>
                        <FormControlLabel
                            value="false"
                            className={classNames(
                                stCabinet.radio_button,
                                st.radio_button2,
                                form.values.fixedPrice === 'false' ? stCabinet.active : ''
                            )}
                            control={<Radio className={stCabinet.radio_button__radio} />}
                            label={<span>{intl.formatMessage({ id: 'pricePerHour' })}</span>}
                        />

                        <FormControlLabel
                            value="true"
                            className={classNames(
                                stCabinet.radio_button,
                                st.radio_button2,
                                form.values.fixedPrice === 'true' ? stCabinet.active : ''
                            )}
                            control={<Radio className={stCabinet.radio_button__radio} />}
                            label={intl.formatMessage({ id: 'fixedPrice' })}
                        />
                    </RadioGroup>
                )}
            />
        );
    };

    if (projectId && (!user.id || !projectData?.id)) {
        return <Preloader />;
    }

    if (projectId && user.id && projectData?.id && Number(user.id) !== Number(projectData?.[userType]?.id)) {
        return <div className="error">{intl.formatMessage({ id: 'authWarning' })}</div>;
    }

    return (
        <FormikProvider value={form}>
            {user && !user.active && (
                <Dialog TransitionComponent={Fade} open={true} className={stylePopup.popup} scroll="body">
                    <div className={stylePopup.container}>
                        <div
                            className={stylePopup.title}
                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'projectsPost.popup.title' }) }}
                        />
                        <div
                            className={stylePopup.text}
                            dangerouslySetInnerHTML={{
                                __html: intl.formatMessage({ id: 'projectsPost.popup.description' }),
                            }}
                        />
                        <Link to={`/experts/${userID}/edit`}>
                            <Button sx={{ width: '100%' }} variant="contained">
                                {intl.formatMessage({ id: 'projectsPost.popup.button' })}
                            </Button>
                        </Link>
                    </div>
                </Dialog>
            )}
            <Link to={AuthRoutes.dashboard} className={classNames(stCabinet.back, st.back)}>
                <ArrowIcon />
                {intl.formatMessage({ id: 'backToDashboard' })}
            </Link>

            {assignTo && <CardPreviewExpert small data={assignedExpertData} cardType="createWork" />}

            <form noValidate onSubmit={form.handleSubmit} className={st.form}>
                <section className={classNames(stCabinet.section, stCabinet.section_container)}>
                    <div className={st.section_main}>
                        <div className={stCabinet.field_title}>{intl.formatMessage({ id: 'typeOfProject' })}</div>

                        <div className={st.radio_tip}>
                            <Field
                                name="type"
                                children={(fieldProps) => (
                                    <RadioGroup {...fieldProps.field} defaultValue="sessions" row={true} className={st.radio}>
                                        <FormControlLabel
                                            value="sessions"
                                            className={classNames(
                                                stCabinet.radio_button,
                                                st.radio_button,
                                                form.values.type === 'sessions' ? stCabinet.active : '',
                                                projectId ? 'disabled' : ''
                                            )}
                                            control={<Radio className={stCabinet.radio_button__radio} />}
                                            label={intl.formatMessage({ id: 'session' })}
                                        />

                                        <FormControlLabel
                                            value="projects"
                                            className={classNames(
                                                stCabinet.radio_button,
                                                st.radio_button,
                                                form.values.type === 'projects' ? stCabinet.active : '',
                                                projectId ? 'disabled' : ''
                                            )}
                                            control={<Radio className={stCabinet.radio_button__radio} />}
                                            label={intl.formatMessage({ id: 'project' })}
                                        />
                                    </RadioGroup>
                                )}
                            />
                            <Tip target={<InfoIcon />} tipText="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo, illum!" />
                        </div>

                        <Field
                            name="title"
                            children={(fieldProps) => (
                                <TextField
                                    {...fieldProps.field}
                                    className={stCabinet.input_box}
                                    fullWidth
                                    label={`${intl.formatMessage({ id: 'sentenceSummary' })} *`}
                                    type="text"
                                    variant="outlined"
                                    error={form.touched.title && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                    helperText={form.touched.title && !form.isValid && fieldProps.meta.error}
                                />
                            )}
                        />

                        <Field
                            name="description"
                            children={(fieldProps) => (
                                <TextField
                                    {...fieldProps.field}
                                    className={stCabinet.input_box}
                                    multiline
                                    rows={`${mobileIs ? 2 : 4}`}
                                    fullWidth
                                    label={`${intl.formatMessage({ id: 'description' })} *`}
                                    type="text"
                                    variant="outlined"
                                    error={form.touched.description && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                    helperText={form.touched.description && !form.isValid && fieldProps.meta.error}
                                />
                            )}
                        />

                        <div className={st.upload_photo}>
                            {projectFile?.contentUrl && (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={st.file_link}
                                    href={axios.defaults.baseURL + projectFile.contentUrl.replace(/^\//, '')}
                                >
                                    {projectFile.contentUrl.replace(/\/.*media\/.*?_/, '')}
                                </a>
                            )}

                            <label className={st.upload}>
                                <input type="file" style={{ display: 'none' }} onChange={uploadFile} />

                                <div className={st.upload_text}>
                                    {projectData?.file?.contentUrl ? intl.formatMessage({ id: 'changeFile' }) : <AttachIcon />}
                                    <span>{intl.formatMessage({ id: 'maximumSize' })}</span>
                                </div>
                            </label>

                            {uploadError && <div className={classNames('error', st.upload_error)}>{intl.formatMessage({ id: 'bigFileError' })}</div>}
                        </div>
                    </div>

                    <div className={st.step_number}>1</div>
                </section>

                {!assignTo && (
                    <section className={classNames(stCabinet.section, stCabinet.section_container)}>
                        <div className={st.section_main}>
                            <Autocomplete
                                name={'position'}
                                className={stCabinet.input_box}
                                popupIcon={<SelectIcon />}
                                blurOnSelect
                                filterSelectedOptions
                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                options={positions?.filter((item) => item.type === 'expert')}
                                value={form.values.position ?? null}
                                onChange={(e, newValue) => {
                                    form.setFieldValue('position', newValue ?? null);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'expertType' })} *`}
                                        variant="outlined"
                                        error={form.touched.position && form.errors?.position ? true : undefined}
                                        helperText={form.touched.position && form.errors?.position}
                                    />
                                )}
                            />

                            <Autocomplete
                                name={'service'}
                                className={stCabinet.input_box}
                                popupIcon={<SelectIcon />}
                                blurOnSelect
                                filterSelectedOptions
                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                options={services}
                                value={form.values.service ?? null}
                                onChange={(e, newValue) => {
                                    form.setFieldValue('service', newValue ?? null);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'lookingFor' })} *`}
                                        variant="outlined"
                                        error={form.touched.service && form.errors?.service ? true : undefined}
                                        helperText={form.touched.service && form.errors?.service}
                                    />
                                )}
                            />

                            <Autocomplete
                                name={'category'}
                                className={stCabinet.input_box}
                                popupIcon={<SelectIcon />}
                                blurOnSelect
                                filterSelectedOptions
                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                options={skillCategories}
                                value={form.values.category ?? null}
                                onChange={(e, newValue) => {
                                    form.setFieldValue('category', newValue ?? null);
                                    form.setFieldValue('subCategories', []);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'category' })} *`}
                                        variant="outlined"
                                        error={form.touched.category && form.errors?.category ? true : undefined}
                                        helperText={form.touched.category && form.errors?.category}
                                    />
                                )}
                            />

                            <Autocomplete
                                name={'subCategories'}
                                className={classNames(stCabinet.input_box, form.values.category?.id ? '' : 'disabled')}
                                popupIcon={<SelectIcon />}
                                blurOnSelect
                                filterSelectedOptions
                                multiple
                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                options={skillSubCategories.filter((subItem) => subItem.skillCategory.id === form.values.category?.id)}
                                value={form.values.subCategories ?? null}
                                onChange={(e, newValue) => {
                                    form.setFieldValue('subCategories', newValue ?? null);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'subCategory' })} *`}
                                        variant="outlined"
                                        error={form.touched.subCategories && form.errors?.subCategories ? true : undefined}
                                        helperText={form.touched.subCategories && form.errors?.subCategories}
                                    />
                                )}
                            />

                            <Autocomplete
                                name={'software'}
                                className={stCabinet.input_box}
                                popupIcon={<SelectIcon />}
                                blurOnSelect
                                filterSelectedOptions
                                multiple
                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                options={softwareEquipments}
                                value={form.values.software ?? null}
                                onChange={(e, newValue) => {
                                    form.setFieldValue('software', newValue ?? null);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={intl.formatMessage({ id: 'software' })}
                                        variant="outlined"
                                        error={form.touched.software && form.errors?.software ? true : undefined}
                                        helperText={form.touched.software && form.errors?.software}
                                    />
                                )}
                            />

                            <Autocomplete
                                name={'hardware'}
                                className={stCabinet.input_box}
                                popupIcon={<SelectIcon />}
                                blurOnSelect
                                filterSelectedOptions
                                multiple
                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                options={hardwareEquipments}
                                value={form.values.hardware ?? null}
                                onChange={(e, newValue) => {
                                    form.setFieldValue('hardware', newValue ?? null);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={intl.formatMessage({ id: 'hardware' })}
                                        variant="outlined"
                                        error={form.touched.hardware && form.errors?.hardware ? true : undefined}
                                        helperText={form.touched.hardware && form.errors?.hardware}
                                    />
                                )}
                            />
                        </div>

                        <div className={st.step_number}>2</div>
                    </section>
                )}

                <section className={classNames(stCabinet.section, stCabinet.section_container)}>
                    <div className={st.section_main}>
                        {form.values.type === 'sessions' ? (
                            <>
                                <div className={stCabinet.field_title}>{intl.formatMessage({ id: 'payment' })}</div>

                                <FixedPrice />

                                <div className={stCabinet.input_box_half_container}>
                                    <Field
                                        name="duration"
                                        children={(fieldProps) => (
                                            <TextField
                                                {...fieldProps.field}
                                                className={classNames(stCabinet.input_box, stCabinet.input_box_half)}
                                                fullWidth
                                                label={`${intl.formatMessage({ id: 'duration' })} *`}
                                                type="tel"
                                                variant="outlined"
                                                onKeyUp={(event) => {
                                                    if (!/[0-9]|\.|,/g.test(event.key)) {
                                                        event.preventDefault();
                                                    } else if (event.key === ',') {
                                                        event.target.value = event.target.value?.replace(/,/g, '.');
                                                    }
                                                }}
                                                error={form.touched.duration && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.duration && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />

                                    <Field
                                        name="workCost"
                                        children={(fieldProps) => (
                                            <TextField
                                                {...fieldProps.field}
                                                className={classNames(stCabinet.input_box, stCabinet.input_box_half)}
                                                fullWidth
                                                label={
                                                    intl.formatMessage(
                                                        { id: form.values.fixedPrice === 'true' ? 'price' : 'rateHour' },
                                                        { var_1: form.values.duration }
                                                    ) + ' *'
                                                }
                                                type="tel"
                                                variant="outlined"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]|\.|,/g.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                error={form.touched.workCost && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.workCost && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={stCabinet.field_title}>{intl.formatMessage({ id: 'howYouLike' })}</div>

                                <div className={classNames(st.radio_tip, st.radio_tip_2)}>
                                    <Field
                                        name="workMethod"
                                        children={(fieldProps) => (
                                            <RadioGroup {...fieldProps.field} defaultValue="remotely" row={true} className={st.radio}>
                                                <FormControlLabel
                                                    value="remotely"
                                                    className={classNames(
                                                        stCabinet.radio_button,
                                                        st.radio_button2,
                                                        form.values.workMethod === 'remotely' ? stCabinet.active : ''
                                                    )}
                                                    control={<Radio className={stCabinet.radio_button__radio} />}
                                                    label={intl.formatMessage({ id: 'remotely' })}
                                                />

                                                <FormControlLabel
                                                    value="on_site"
                                                    className={classNames(
                                                        stCabinet.radio_button,
                                                        st.radio_button2,
                                                        form.values.workMethod === 'on_site' ? stCabinet.active : ''
                                                    )}
                                                    control={<Radio className={stCabinet.radio_button__radio} />}
                                                    label={intl.formatMessage({ id: 'onSite' })}
                                                />
                                            </RadioGroup>
                                        )}
                                    />

                                    {!mobileIs && (
                                        <Tip
                                            target={<InfoIcon />}
                                            tipText="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo, illum!"
                                        />
                                    )}
                                </div>

                                <div className={stCabinet.field_title}>{intl.formatMessage({ id: 'payment' })}</div>

                                <div className={st.fixed_price}>
                                    <FixedPrice />
                                </div>

                                <Field
                                    name="workCost"
                                    children={(fieldProps) => (
                                        <TextField
                                            {...fieldProps.field}
                                            className={classNames(stCabinet.input_box, stCabinet.input_box_half)}
                                            fullWidth
                                            label={
                                                intl.formatMessage(
                                                    { id: form.values.fixedPrice === 'true' ? 'price' : 'rateHour' },
                                                    { var_1: form.values.duration }
                                                ) + ' *'
                                            }
                                            type="tel"
                                            variant="outlined"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]|\.|,/g.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            error={form.touched.workCost && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                            helperText={form.touched.workCost && !form.isValid && fieldProps.meta.error}
                                        />
                                    )}
                                />
                            </>
                        )}
                    </div>

                    <div className={st.step_number}>3</div>
                </section>

                {user && user.active && (
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        className={classNames(st.save, user.id && user.active ? '' : 'disabled')}
                    >
                        {intl.formatMessage({ id: 'submit' })}
                    </Button>
                )}

                {/*<pre>{JSON.stringify(form.values, 0, 2)}</pre>*/}
            </form>
        </FormikProvider>
    );
};

export default observer(ProjectEdit);
