import { observer } from 'mobx-react';
import React, {useState} from 'react';
import { useIntl } from 'react-intl';
import st from './SessionHistory.module.scss';
import DataStore from 'src/store/DataStore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Link, Typography } from '@mui/material';
import { postData } from 'src/api/api';
import Preloader from "../../components/Preloader";

const message = {
    accountId: process.env.REACT_APP_ZOOM_ACCOUNTID,
    clientId: process.env.REACT_APP_ZOOM_CLIENTID,
    clientSecret: process.env.REACT_APP_ZOOM_CLIENTSECRET,
};


const SessionHistory = () => {
    const intl = useIntl();
    const { user, userType, userID } = DataStore;
    const [token, setToken] = React.useState('');
    const [meetList, setMeetList] = React.useState([]);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        let isMounted = true;

        if (!token) {
            // создаем токен созвона
            postData(`zoom/get-token`, message)
                .then(({ data }) => {
                    if (isMounted) {
                        setToken(data.access_token);
                    }
                })
                .catch((error) => {console.log(error)});
        }
        return () => { isMounted = false };
    }, [token]);

    React.useEffect(() => {
        let isMounted = true;

        if (token && userID && userType && (user?.expertZoomMeetings?.length || user?.zoomMeetings?.length)) {
            const zoomMeetings = ((userType === 'expert') ? user?.expertZoomMeetings : user?.zoomMeetings).filter(item => item.autoRecording === 'cloud');
            const datas = {
                accessToken: token,
                meetingIds: zoomMeetings.map((item) => {
                    return item.meetingId;
                }),
            };

            postData(`zoom/meeting-all-recordings-get`, datas)
                .then(({ data }) => {
                    console.log(data);
                    if (isMounted) {
                        setMeetList(data);
                    }
                })
                .catch((error) => {console.log(error)})
            .finally(() => setLoading(false));
        }
        return () => { isMounted = false };
    }, [token, userID, userType, user?.expertZoomMeetings, user?.zoomMeetings]);

    if (loading) {
        return <Preloader isShow={true} />;
    }

    return (
        <section className={st.wrapper}>
            <Typography variant="h4" component="h1" mb={2}>
                {intl.formatMessage({ id: 'sessionHistory' })}
            </Typography>

            {meetList.length > 0 && (
                <>
                    {meetList.map((item, index) => {
                        const date = new Date(item.start_time).toLocaleDateString('ru-RU', { hour: '2-digit', minute: '2-digit' });

                        return (
                            <Accordion key={item.id + index}>
                                <AccordionSummary
                                    expandIcon={
                                        <svg viewBox="0 0 24 24">
                                            <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                        </svg>
                                    }
                                    aria-controls={`content-${item.uuid}`}
                                    id={`header-${item.uuid}`}
                                >
                                    <div className="flex-space width-100">
                                        <Typography variant="body1"><strong>{item.topic}</strong></Typography>

                                        <Typography variant="body1" style={{whiteSpace: 'nowrap'}}>{date}</Typography>
                                    </div>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography>
                                        {intl.formatMessage({ id: 'sessionHistory.session.id' })} {item.id}
                                    </Typography>
                                    <Typography>{intl.formatMessage({ id: 'sessionHistory.session.duration' }, { time: item.duration })}</Typography>
                                    <Typography>
                                        {intl.formatMessage({ id: 'sessionHistory.session.start' })}{' '}
                                        {date}
                                    </Typography>
                                    <Typography mt={1}>{intl.formatMessage({ id: 'sessionHistory.session.recordList' })}</Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '16px',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {item.recording_files.map((file, index) => {
                                            return (
                                                <Button key={index} size="small" variant="contained" component={Link} target="_blank" href={file.download_url}>
                                                    {file.file_extension}, {(file.file_size / 1024).toFixed(2)}kb
                                                </Button>
                                            );
                                        })}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                    {/* <Button variant="contained" sx={{ mt: 4 }} onClick={handleLoadList}>
                        {intl.formatMessage({ id: 'loadList' })}
                    </Button> */}
                </>
            )}
        </section>
    );
};

export default observer(SessionHistory);
