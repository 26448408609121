import React from 'react';
import st from './Burger.module.scss';
import BurgerIcon from '../Icons/BurgerIcon';
import {ReactComponent as CrossIcon} from "../../assets/images/icons/cross.svg";
import UIStore from "../../store/UIStore";
import {observer} from "mobx-react";


const Burger = observer(({ onClick }) => {
    const {mobileMenuIsOpen, mobileMenuUserIsOpen} = UIStore;

    return (
        <div className={st.container} onClick={onClick}>
            {(mobileMenuIsOpen || mobileMenuUserIsOpen) ?
                <CrossIcon />
                :
                <BurgerIcon />
            }
        </div>
    );
});

export default Burger;
