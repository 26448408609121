import st from './faqPopular.module.scss';
import React, {useEffect, useState} from "react";
import {getData} from "../../api/api";
import DataStore from "../../store/DataStore";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {observer} from "mobx-react";
import Preloader from "../Preloader/Preloader";


const FaqPopular = ({notLoading = false}) => {
    const { faqPopular, updateFaqPopular } = DataStore;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;

        if (faqPopular.length === 0) {
            setIsLoading(true);

            getData('api/faqs?page=1&perPage=8&viewsCount=DESC')
                .then(({data}) => {
                    if (isMounted) {
                        updateFaqPopular(data.items);
                    }
                })
                .finally(() => {
                    if (isMounted) {
                        setIsLoading(false);
                    }
                });
        }

        return () => { isMounted = false };
    }, [faqPopular.length, updateFaqPopular]);

    return (
        <section className={st.list}>
            {(isLoading && !notLoading) ?
                <Preloader isShow={true} />
                :
                (faqPopular.length > 0) &&
                faqPopular.map((item, index) =>
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<div className={st.icon}><ExpandMoreIcon /></div>}
                            aria-controls={`panel-content-${index}`}
                            id={`panel-header-${index}`}
                        >
                            {item.title}
                        </AccordionSummary>

                        <AccordionDetails>{item.answer}</AccordionDetails>
                    </Accordion>
                )
            }
        </section>
    );
};

export default observer(FaqPopular);
