import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { observer } from 'mobx-react';
import axios from 'axios';
import { FormikProvider, Field, useFormik, FieldArray } from 'formik';
import { TextField, Button, Autocomplete, InputAdornment, Avatar, FormControlLabel, Checkbox, Switch } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Accordion, AccordionSummary } from '@mui/material';
import classNames from 'classnames';
import stCabinet from '../../layouts/Cabinet/cabinetLayout.module.scss';
import st from './profileEdit.module.scss';

import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/angle-bracket-prev.svg';
import { ReactComponent as SelectIcon } from '../../assets/images/icons/arrow.svg';
import { ReactComponent as AvatarIcon } from '../../assets/images/icons/default-avatar.svg';
import { ReactComponent as BinIcon } from '../../assets/images/icons/bin.svg';
import { ReactComponent as ExpandIcon } from '../../assets/images/icons/angle-bracket.svg';
import { ReactComponent as AttachIcon } from '../../assets/images/icons/attach.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import { ReactComponent as ShieldIcon } from '../../assets/images/icons/shield.svg';
import { ReactComponent as LeafIcon } from '../../assets/images/icons/leaf.svg';
import { ReactComponent as EaefIcon } from '../../assets/images/icons/eyeFile.svg';
// import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross-8.svg";

import DataStore from '../../store/DataStore';
import UIStore from '../../store/UIStore';
import LandingHeader from '../../components/LandingHeader';
import { getData, postData } from '../../api/api';
import mediaObjectApi from '../../api/mediaObjectApi';
import { AuthRoutes } from '../../routes/routes';
import { Alert } from '../../components/Alert';
import { toJS } from 'mobx';
import { ConditionalWrapper } from '../../components/ConditionalWrapper';
import { LocaleMap } from '../../types';
import { cleanObject, cleanObjectDeep, objectToArray } from '../../helpers/helpers';
import { ProgressBar } from '../../components/ProgressBar';
import { LocalPopup } from '../../components/LocalPopup';
import structuredClone from '@ungap/structured-clone';

const ProfileEdit = () => {
    const intl = useIntl();
    const {
        user,
        userType,
        userUrl,
        updateUser,
        countries,
        updateCountries,
        cities,
        updateCities,
        languages,
        updateLanguages,
        positions,
        updatePositions,
        services,
        updateServices,
        companyFields,
        updateCompanyFields,
        skillCategories,
        updateSkillCategories,
        skillSubCategories,
        updateSkillSubCategories,
        hardwareEquipments,
        updateHardwareEquipments,
        softwareEquipments,
        updateSoftwareEquipments,
    } = DataStore;
    const pageId = useParams().id;
    const { setErrorAlert, setSuccessAlert, alertTimers, tabletIs, mobileMenuIsOpen, mobileMenuUserIsOpen, mobileIs } = UIStore;
    const [uploadError, setUploadError] = useState(false);
    const [addCustomHard, setAddCustomHard] = useState(false);
    const [addCustomSoft, setAddCustomSoft] = useState(false);
    const [userLanguages, setUserLanguages] = useState(user?.profile?.languages);
    const [userPositions, setUserPositions] = useState(user?.positions);
    const [userCompanyField, setUserCompanyField] = useState(user?.companyFields);
    const [userServices, setUserServices] = useState(user?.services);
    const [userProfSkills, setUserProfSkills] = useState(user?.professionalSkills?.length ? user.professionalSkills : [{ skillSubCategories: [] }]);
    const [userHardwareEquipments, setUserHardwareEquipments] = useState(user?.hardwareEquipments);
    const [userSoftwareEquipments, setUserSoftwareEquipments] = useState(user?.softwareEquipments);
    const [expanded, setExpanded] = useState('');
    const [maxProgress, setMaxProgress] = useState(0);
    const [isCongratulations, setIsCongratulations] = useState(false);
    const numberEmployeesOptions = [
        { title: '1-10' },
        { title: '11-50' },
        { title: '51-200' },
        { title: '201-500' },
        { title: '501-1000' },
        { title: '1001-5000' },
        { title: '5001-10000' },
        { title: '10001+' },
    ];
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : '');

        setTimeout(() => {
            event.target.scrollIntoView({ behavior: 'smooth' });
        }, 400);
    };

    const resetCongratulations = () => {
        setIsCongratulations(false);
    };

    const updateUserObject = (data) => {
        setSuccessAlert('');
        setErrorAlert('');

        postData(`api${userUrl}`, data, 'put')
            .then(({ data }) => {
                if (!user.active && data.active) {
                    setIsCongratulations(true);
                }

                updateUser(data);
                setSuccessAlert(intl.formatMessage({ id: 'successSave' }), alertTimers.short);
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? error : intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
            });
    };

    const createHardWare = (value) => {
        // Check if custom value already exists
        const findNewValue = hardwareEquipments.find((item) => item.title === value.trim());

        if (findNewValue === undefined) {
            postData('api/hardware_equipments', { title: value })
                .then((response) => {
                    if (response.status === 201) {
                        updateHardwareEquipments([...hardwareEquipments, response.data]);
                        const newValue = [...formSkill.values.hardwareEquipments, `api/hardware_equipments/${response.data?.id}`];
                        formSkill.setFieldValue('hardwareEquipments', newValue);
                        setUserHardwareEquipments((prev) => [...prev, response.data]);
                        formSkill.setFieldValue('newHardware', '');
                    } else {
                        setErrorAlert(intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
                    }
                })
                .catch((errorMessage) => {
                    setErrorAlert(errorMessage, alertTimers.long);
                });
        } else if (!formSkill.values.hardwareEquipments.includes(`api/hardware_equipments/${findNewValue?.id}`)) {
            const newValue = [...formSkill.values.hardwareEquipments, `api/hardware_equipments/${findNewValue?.id}`];
            formSkill.setFieldValue('hardwareEquipments', newValue);
            setUserHardwareEquipments((prev) => [...prev, findNewValue]);
        }
    };

    const createSoftWare = (value) => {
        // Check if custom value already exists
        const findNewValue = softwareEquipments.find((item) => item.title === value.trim());

        if (findNewValue === undefined) {
            postData('api/software_equipments', { title: value })
                .then((response) => {
                    if (response.status === 201) {
                        // setSuccessAlert(intl.formatMessage({id: "successfullyAdded"}, {var_1: value}), alertTimers.short);
                        updateSoftwareEquipments([...softwareEquipments, response.data]);
                        const newValue = [...formSkill.values.softwareEquipments, `api/software_equipments/${response.data?.id}`];
                        formSkill.setFieldValue('softwareEquipments', newValue);
                        setUserSoftwareEquipments((prev) => [...prev, response.data]);
                    } else {
                        setErrorAlert(intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
                    }
                })
                .catch((errorMessage) => {
                    setErrorAlert(errorMessage, alertTimers.long);
                });
        } else if (!formSkill.values.softwareEquipments.includes(`api/software_equipments/${findNewValue?.id}`)) {
            const newValue = [...formSkill.values.softwareEquipments, `api/software_equipments/${findNewValue?.id}`];
            formSkill.setFieldValue('softwareEquipments', newValue);
            setUserSoftwareEquipments((prev) => [...prev, findNewValue]);
        }
    };

    const validatePersonal = (values) => {
        const errors = {};

        if (!values.firstName.trim()) {
            errors.firstName = intl.formatMessage({ id: 'required' });
        }

        if (!values.lastName.trim()) {
            errors.lastName = intl.formatMessage({ id: 'required' });
        }

        if (userType === 'expert') {
            if (values.linkedinLink.trim() && !values.linkedinLink.match(/^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/)) {
                errors.linkedinLink = intl.formatMessage({ id: 'incorrectLink' });
            }
        }

        if (userType === 'expert') {
            if (!values.country) {
                errors.country = intl.formatMessage({ id: 'required' });
            }

            if (!values.city) {
                errors.city = intl.formatMessage({ id: 'required' });
            }

            if (!values.birthDate) {
                errors.birthDate = intl.formatMessage({ id: 'required' });
            }

            if (values.languages.length === 0) {
                errors.languages = intl.formatMessage({ id: 'required' });
            }
        }

        if (Object.keys(errors).length) {
            console.log('errors', errors);
        }

        return errors;
    };

    let initialValuesPersonal = {
        id: user?.profile?.id,
        avatar: user?.profile?.avatar?.id ? `api/media_objects/${user.profile.avatar.id}` : null,
        firstName: user?.profile?.firstName ?? '',
        lastName: user?.profile?.lastName ?? '',
        country: user?.profile?.country ? user.profile.country : null,
        city: user?.profile?.city ? user.profile.city : null,
        birthDate: user?.profile?.birthDate ?? null,
        languages: user?.profile?.languages?.length ? user.profile.languages.map((item) => `api/languages/${item.id}`) : [],
        shareProfile: user.shareProfile ?? false,
    };

    if (userType !== 'expert') {
        initialValuesPersonal.shareProfile = user.shareProfile ?? false;
    } else {
        initialValuesPersonal.linkedinLink = user?.linkedinLink ?? '';
        initialValuesPersonal.about = user?.profile?.about ?? '';
    }

    const formPersonal = useFormik({
        validate: validatePersonal,
        initialValues: initialValuesPersonal,

        onSubmit: (values, { setErrors }) => {
            const profile = values['firstName']
                ? {
                      id: values.id ? `api/profiles/${values.id}` : null,
                      avatar: values.avatar,
                      lastName: values.lastName,
                      firstName: values.firstName,
                      country: values.country?.id ? `api/countries/${values.country.id}` : null,
                      city: values.city?.id ? `api/cities/${values.city.id}` : null,
                      birthDate: values.birthDate,
                      languages: values.languages,
                  }
                : undefined;

            let sendingData = { profile };

            if (userType !== 'expert') {
                sendingData.shareProfile = values.shareProfile;
            }

            if (userType === 'expert') {
                sendingData.linkedinLink = values.linkedinLink;
                sendingData.profile.about = values.about;
            }

            updateUserObject(sendingData);
        },
    });

    const validateSkill = (values) => {
        const errors = {};

        if (userType === 'expert') {
            errors.professionalSkills = {};

            if (values.positions?.length === 0) {
                errors.positions = intl.formatMessage({ id: 'required' });
            }

            if (values.services?.length === 0) {
                errors.services = intl.formatMessage({ id: 'required' });
            }

            if (values.hardwareEquipments?.length === 0) {
                errors.hardwareEquipments = intl.formatMessage({ id: 'required' });
            }

            if (values.softwareEquipments?.length === 0) {
                errors.softwareEquipments = intl.formatMessage({ id: 'required' });
            }

            values.professionalSkills.forEach((item, index) => {
                errors.professionalSkills[index] = {};

                if (!item.skillCategory?.title?.trim()) {
                    errors.professionalSkills[index].skillCategory = intl.formatMessage({ id: 'required' });
                }
                if (!item.skillSubCategories?.length) {
                    errors.professionalSkills[index].skillSubCategories = intl.formatMessage({ id: 'required' });
                }
            });
        } else if (userType === 'company') {
            if (values.positions?.length === 0) {
                errors.positions = intl.formatMessage({ id: 'required' });
            }

            if (values.companyFields?.length === 0) {
                errors.companyFields = intl.formatMessage({ id: 'required' });
            }

            if (!values.companyName) {
                errors.companyName = intl.formatMessage({ id: 'required' });
            }

            if (!values.city) {
                errors.city = intl.formatMessage({ id: 'required' });
            }

            if (!values.country) {
                errors.country = intl.formatMessage({ id: 'required' });
            }

            if (!values.numberOfEmployers) {
                errors.numberOfEmployers = intl.formatMessage({ id: 'required' });
            }
        }

        if (Object.keys(errors).length) {
            console.log('errors', errors);
        }

        return cleanObject(errors, true);
    };

    const emptyProfSkill = {
        skillCategory: null,
        skillSubCategories: [],
    };

    let initialValuesSkill = {
        positions: user?.positions?.length ? user.positions.map((item) => `api/positions/${item.id}`) : [],
        newHardware: '',
        newSoftware: '',
    };

    if (userType === 'expert') {
        initialValuesSkill.availableForFreelanceJob = user?.availableForFreelanceJob;
        initialValuesSkill.hardwareEquipments = objectToArray(user?.hardwareEquipments)?.length
            ? objectToArray(user.hardwareEquipments).map((item) => `api/hardware_equipments/${item.id}`)
            : [];
        initialValuesSkill.softwareEquipments = objectToArray(user?.softwareEquipments)?.length
            ? objectToArray(user.softwareEquipments).map((item) => `api/software_equipments/${item.id}`)
            : [];
    }

    if (userType === 'company') {
        let userNumberOfEmployersIndex = undefined;
        switch (user?.numberOfEmployersFrom) {
            case 1:
                userNumberOfEmployersIndex = 0;
                break;
            case 11:
                userNumberOfEmployersIndex = 1;
                break;
            case 51:
                userNumberOfEmployersIndex = 2;
                break;
            case 201:
                userNumberOfEmployersIndex = 3;
                break;
            case 501:
                userNumberOfEmployersIndex = 4;
                break;
            case 1001:
                userNumberOfEmployersIndex = 5;
                break;
            case 5001:
                userNumberOfEmployersIndex = 6;
                break;
            case 10001:
                userNumberOfEmployersIndex = 7;
                break;
            default: // Do nothing
        }

        initialValuesSkill.companyName = user?.companyName ?? '';
        initialValuesSkill.country = user?.country ? user.country : null;
        initialValuesSkill.city = user?.city ? user.city : null;
        initialValuesSkill.numberOfEmployers = userNumberOfEmployersIndex !== undefined ? numberEmployeesOptions[userNumberOfEmployersIndex] : null;
        initialValuesSkill.companyFields = user?.companyFields?.length ? user.companyFields.map((item) => `api/company_fields/${item.id}`) : [];
    } else {
        initialValuesSkill.services = objectToArray(user?.services)?.length
            ? objectToArray(user.services).map((item) => `api/services/${item.id}`)
            : [];
        initialValuesSkill.professionalSkills =
            objectToArray(user?.professionalSkills).length === 0
                ? [emptyProfSkill]
                : objectToArray(user.professionalSkills).map((item) => {
                      return {
                          skillCategory: item.skillCategory ? item.skillCategory : null,
                          skillSubCategories: item.skillSubCategories.map((item) => `api/skill_sub_categories/${item.id}`),
                      };
                  });
    }

    const formSkill = useFormik({
        validate: validateSkill,
        initialValues: initialValuesSkill,
        enableReinitialize: true,

        onSubmit: (values, { setErrors }) => {
            const sendData =
                userType === 'company'
                    ? {
                          country: values.country?.id ? `api/countries/${values.country.id}` : null,
                          city: values.city?.id ? `api/cities/${values.city.id}` : null,
                          numberOfEmployersFrom: values.numberOfEmployers ? Number(values.numberOfEmployers.title?.match(/(\d+)[-+]/)?.[1]) : null,
                          numberOfEmployersTill: values.numberOfEmployers
                              ? Number(values.numberOfEmployers.title?.match(/(\d+)[-+](\d+)/)?.[2])
                              : null,
                      }
                    : {
                          professionalSkills: isEqual(values.professionalSkills, emptyProfSkill)
                              ? []
                              : values.professionalSkills
                                    .filter((item) => !isEqual(item, emptyProfSkill))
                                    .map((item) => {
                                        return {
                                            ...item,
                                            skillCategory: `api/skill_categories/${item.skillCategory.id}`,
                                        };
                                    }),
                      };
            updateUserObject({
                ...values,
                ...sendData,
            });
        },
    });

    const initialValuesDoc = {
        id: null,
        documentFile: null,
        title: '',
    };

    const formDocuments = useFormik({
        initialValues: {
            documents:
                objectToArray(user.documents)?.length === 0
                    ? [structuredClone(initialValuesDoc)]
                    : objectToArray(user.documents)?.map((item) => {
                          return {
                              title: item.title ?? '',
                              documentFile: item.documentFile?.id ? `api/media_objects/${item.documentFile.id}` : null,
                              id: `api/expert_documents/${item.id}`,
                              contentUrl: item.documentFile?.contentUrl,
                          };
                      }),
            diplomas:
                objectToArray(user.diplomas)?.length === 0
                    ? [structuredClone(initialValuesDoc)]
                    : objectToArray(user.diplomas)?.map((item) => {
                          return {
                              title: item.title ?? '',
                              documentFile: item.documentFile?.id ? `api/media_objects/${item.documentFile.id}` : null,
                              id: `api/expert_diplomas/${item.id}`,
                              contentUrl: item.documentFile?.contentUrl,
                          };
                      }),
            appreciationLetters:
                objectToArray(user.appreciationLetters)?.length === 0
                    ? [structuredClone(initialValuesDoc)]
                    : objectToArray(user.appreciationLetters)?.map((item) => {
                          return {
                              title: item.title ?? '',
                              documentFile: item.documentFile?.id ? `api/media_objects/${item.documentFile.id}` : null,
                              id: `api/expert_appreciation_letters/${item.id}`,
                              contentUrl: item.documentFile?.contentUrl,
                          };
                      }),
        },

        onSubmit: (values, { setErrors }) => {
            updateUserObject(cleanObjectDeep(structuredClone(values)));
        },
    });

    const validateCertifications = (values) => {
        const errors = { certifications: {} };

        values.certifications.forEach((item, index) => {
            errors.certifications[index] = {};
            if (!item.title.trim()) {
                errors.certifications[index].title = intl.formatMessage({ id: 'required' });
            }
        });

        if (Object.keys(errors).length) {
            console.log('errors', errors);
        }

        return cleanObject(errors, true);
    };

    const initialValuesCertifications = {
        id: null,
        documentFile: null,
        title: '',
        year: null,
    };

    const formCertifications = useFormik({
        validate: validateCertifications,
        initialValues: {
            certifications:
                objectToArray(user.certifications)?.length === 0
                    ? [initialValuesCertifications]
                    : objectToArray(user.certifications)?.map((item) => {
                          return {
                              year: item.year ?? null,
                              title: item.title ?? '',
                              documentFile: item.documentFile?.id ? `api/media_objects/${item.documentFile.id}` : null,
                              id: `api/expert_certifications/${item.id}`,
                              contentUrl: item.documentFile?.contentUrl,
                          };
                      }),
        },

        onSubmit: (values, { setErrors }) => {
            updateUserObject(cleanObject(values));
        },
    });

    const validateEmployments = (values) => {
        const errors = { employmentHistories: {} };

        values.employmentHistories.forEach((item, index) => {
            errors.employmentHistories[index] = {};
            if (!item.jobTitle.trim()) {
                errors.employmentHistories[index].jobTitle = intl.formatMessage({ id: 'required' });
            }

            if (item.startDate && item.endDate && new Date(item.startDate) > new Date(item.endDate)) {
                errors.employmentHistories[index].startDate = intl.formatMessage({ id: 'startDateError' });
            }
        });

        if (Object.keys(errors).length) {
            console.log('errors', errors);
        }

        return cleanObject(errors, true);
    };

    const initialValuesEmployments = {
        id: null,
        jobTitle: '',
        employer: '',
        startDate: null,
        endDate: null,
        stillWorking: false,
    };

    const formEmployments = useFormik({
        validate: validateEmployments,
        initialValues: {
            employmentHistories:
                objectToArray(user.employmentHistories)?.length === 0
                    ? [initialValuesEmployments]
                    : objectToArray(user.employmentHistories)?.map((item) => {
                          return {
                              id: `api/employment_histories/${item.id}`,
                              jobTitle: item.jobTitle ?? '',
                              employer: item.employer ?? '',
                              startDate: item.startDate ?? null,
                              endDate: item.endDate ?? null,
                              stillWorking: item.stillWorking,
                          };
                      }),
        },

        onSubmit: (values, { setErrors }) => {
            updateUserObject(cleanObject(values));
        },
    });

    const formRatePer = useFormik({
        // validate: validateFormRatePer,
        initialValues: {
            ratePer: user.ratePer ?? '',
            firstSessionFree: user.firstSessionFree ?? false,
        },

        onSubmit: (values, { setErrors }) => {
            updateUserObject(cleanObject({ ratePer: values.ratePer ? Number(values.ratePer) : null, firstSessionFree: values.firstSessionFree }));
        },
    });

    const uploadAvatar = (e) => {
        setSuccessAlert('');
        setErrorAlert('');

        if (e.target.files[0] === undefined) {
            return;
        } else if (e.target.files[0]?.size > 6000000) {
            setUploadError(true);
            return;
        } else {
            setUploadError(false);
        }

        mediaObjectApi
            .loadMedia(e.target.files[0])
            .then((response) => {
                if (response.status === 201 && response.data.id && response.data.contentUrl) {
                    const avatarIri = `api/media_objects/${response.data.id}`;
                    formPersonal.setFieldValue('avatar', avatarIri);

                    updateUserObject({
                        profile: {
                            id: user?.profile?.id ? `api/profiles/${user.profile.id}` : null,
                            avatar: avatarIri,
                        },
                    });
                } else {
                    console.log(response);
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
            });
    };

    const uploadFile = (e, index, type, formName) => {
        mediaObjectApi
            .loadMedia(e.target.files[0])
            .then((response) => {
                if (response.status === 201 && response.data.contentUrl) {
                    let prevValue = formName ? formCertifications.values[type] : formDocuments.values[type];
                    prevValue[index].documentFile = `api/media_objects/${response.data.id}`;
                    prevValue[index].title = response.data.contentUrl.replace(/media\/.*?_/, '');
                    prevValue[index].contentUrl = response.data.contentUrl;
                    updateUserObject({ [type]: prevValue });
                } else {
                    console.log(response);
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
            });
    };

    const deleteFile = (mediaObjectIri, type, formName) => {
        mediaObjectApi
            .deleteMedia(mediaObjectIri)
            .then((response) => {
                if (response.status === 204) {
                    let documents = formName ? formCertifications.values[type] : formDocuments.values[type];
                    const deleteFileIndex = documents.findIndex((item) => item.documentFile === mediaObjectIri);
                    documents.splice(deleteFileIndex, 1);
                    // documents.splice(deleteFileIndex, 1, {...documents[deleteFileIndex], documentFile: null})

                    updateUserObject({ [type]: documents });
                } else {
                    console.log(response);
                    setErrorAlert(intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
            });
    };

    useEffect(() => {
        if (countries.length === 0) {
            getData('api/countries').then(({ data }) => {
                updateCountries(data.items);
            });
        }

        if (cities.length === 0) {
            getData('api/cities').then(({ data }) => {
                updateCities(data.items);
            });
        }

        if (languages.length === 0) {
            getData('api/languages?perPage=200').then(({ data }) => {
                updateLanguages(data.items);
            });
        }

        if (positions.length === 0) {
            getData(`api/positions`).then(({ data }) => {
                updatePositions(data.items);
            });
        }

        if (userType !== 'company') {
            if (skillCategories.length === 0) {
                getData('api/skill_categories').then(({ data }) => {
                    updateSkillCategories(data.items);
                });
            }

            if (skillSubCategories.length === 0) {
                getData('api/skill_sub_categories?perPage=200').then(({ data }) => {
                    updateSkillSubCategories(data.items);
                });
            }
        } else {
            if (companyFields.length === 0) {
                getData('api/company_fields').then(({ data }) => {
                    updateCompanyFields(data.items);
                });
            }
        }

        if (userType === 'expert') {
            if (hardwareEquipments.length === 0) {
                getData('api/hardware_equipments?perPage=70').then(({ data }) => {
                    updateHardwareEquipments(data.items);
                });
            }

            if (softwareEquipments.length === 0) {
                getData('api/software_equipments?perPage=70').then(({ data }) => {
                    updateSoftwareEquipments(data.items);
                });
            }

            if (services.length === 0) {
                getData(`api/services`).then(({ data }) => {
                    updateServices(data.items);
                });
            }
        }
    }, [userType]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let isMounted = true;
        let fillPercent = 0;
        const flatUser = { ...user, ...user.profile };

        for (let key in flatUser) {
            switch (key) {
                case 'firstName':
                    if (flatUser[key]) {
                        fillPercent += userType !== 'expert' ? 40 : 20;
                    }
                    break;
                case 'avatar':
                    if (flatUser[key]?.id) {
                        fillPercent += userType !== 'expert' ? 20 : 10;
                    }
                    break;
                case 'professionalSkills':
                    if (objectToArray(flatUser[key]).length) {
                        if (userType === 'client') {
                            fillPercent += 40;
                        } else if (userType === 'expert') {
                            fillPercent += 20;
                        }
                    }
                    break;
                case 'documents':
                    if (userType === 'expert' && objectToArray(flatUser[key]).length) {
                        fillPercent += 10;
                    }
                    break;
                case 'certifications':
                    if (userType === 'expert' && objectToArray(flatUser[key]).length) {
                        fillPercent += 10;
                    }
                    break;
                case 'employmentHistories':
                    if (userType === 'expert' && objectToArray(flatUser[key]).length) {
                        fillPercent += 10;
                    }
                    break;
                case 'ratePer':
                    if (userType === 'expert' && flatUser[key]) {
                        fillPercent += 20;
                    }
                    break;
                case 'companyName':
                    if (userType === 'company') {
                        if (flatUser[key]) {
                            fillPercent += 40;
                        }
                    }
                    break;
                default: // Do nothing
            }
        }

        if (isMounted) {
            setMaxProgress(fillPercent);
        }

        return () => {
            isMounted = false;
        };
    }, [user, userType]);

    return (
        <div className={classNames(mobileMenuIsOpen || mobileMenuUserIsOpen ? 'scroll-is-disabled' : '')}>
            <LandingHeader pageType="dashboard" />

            <LocalPopup isOpen={isCongratulations} close={resetCongratulations}>
                <div className="popup-title">{intl.formatMessage({ id: 'congratulationsTitle' })}</div>

                <div className="popup-desc">{intl.formatMessage({ id: 'congratulationsTextExpert' })}</div>

                <Button variant="contained" color="primary" fullWidth size="large" onClick={resetCongratulations}>
                    {intl.formatMessage({ id: 'close' })}
                </Button>
            </LocalPopup>

            <div className={classNames(stCabinet.container, 'with_styled_tags')}>
                <Alert />

                <aside className={classNames(stCabinet.sidebar, stCabinet.sidebar_visible, st.sidebar)}>
                    <Link to={AuthRoutes.dashboard} className={stCabinet.back}>
                        <ArrowIcon />
                        {intl.formatMessage({ id: 'backToDashboard' })}
                    </Link>

                    <h3 className={st.title_mobile}>{intl.formatMessage({ id: 'profile' })}</h3>
                    {userType === 'expert' && (
                        <Link to={`/experts/${pageId}`} className={classNames(st.title_profile, st.title_profile_mobile)}>
                            {intl.formatMessage({ id: 'profileSee' })}
                        </Link>
                    )}

                    <div id="uploadPhoto" className={classNames(st.avatar_box, stCabinet.section)}>
                        {user.profile?.avatar?.contentUrl ? (
                            <Avatar
                                src={axios.defaults.baseURL + user?.profile?.avatar?.contentUrl}
                                className={st.avatar}
                                alt="photo"
                                sx={{ width: { xs: 136, md: 240 }, height: { xs: 136, md: 240 } }}
                            />
                        ) : (
                            <AvatarIcon className={st.avatar} />
                        )}

                        <label className={st.upload}>
                            <input type="file" style={{ display: 'none' }} onChange={uploadAvatar} />

                            <Button type="button" fullWidth color="primary" variant="outlined">
                                {intl.formatMessage({ id: user.profile?.avatar?.contentUrl ? 'editPhoto' : 'uploadPhoto' })}
                            </Button>
                        </label>

                        <div className={st.upload_text}>{intl.formatMessage({ id: 'uploadText' })}</div>

                        {uploadError && <div className={classNames('error', st.upload_error)}>{intl.formatMessage({ id: 'bigFileError' })}</div>}
                    </div>

                    <div className={st.sticky_box}>
                        <div className={classNames(st.progress_box, stCabinet.section)}>
                            <h5>{intl.formatMessage({ id: 'progressTitle' })}</h5>

                            <div className={st.progress_desc}>{intl.formatMessage({ id: 'progressDesc' })}</div>

                            <ProgressBar value={maxProgress} />

                            <div className={classNames(st.details_item, user.profile?.firstName ? st.details_item_active : '')}>
                                <div className={st.details_item_check}>
                                    <CheckIcon />
                                </div>

                                <HashLink smooth to="#personalInformation">
                                    {intl.formatMessage({ id: 'personalInformation' })}
                                    {userType === 'expert' && <span className={st.required}> ({intl.formatMessage({ id: 'required' })})</span>}
                                </HashLink>
                            </div>

                            {userType === 'expert' && (
                                <div className={classNames(st.details_item, user.ratePer ? st.details_item_active : '')}>
                                    <div className={st.details_item_check}>
                                        <CheckIcon />
                                    </div>

                                    <HashLink to="#ratePer">
                                        {intl.formatMessage({ id: 'manageRate' })}
                                        {userType === 'expert' && <span className={st.required}> ({intl.formatMessage({ id: 'required' })})</span>}
                                    </HashLink>
                                </div>
                            )}

                            <div
                                className={classNames(
                                    st.details_item,
                                    (userType === 'company' && user.companyName) || objectToArray(user.professionalSkills)?.length
                                        ? st.details_item_active
                                        : ''
                                )}
                            >
                                <div className={st.details_item_check}>
                                    <CheckIcon />
                                </div>

                                <HashLink smooth to="#professionalSkills">
                                    {intl.formatMessage({ id: 'professionalSkills' })}
                                    {userType === 'expert' && <span className={st.required}> ({intl.formatMessage({ id: 'required' })})</span>}
                                </HashLink>
                            </div>

                            <div className={classNames(st.details_item, user.profile?.avatar?.contentUrl ? st.details_item_active : '')}>
                                <div className={st.details_item_check}>
                                    <CheckIcon />
                                </div>

                                <HashLink smooth to="#uploadPhoto">
                                    {intl.formatMessage({ id: 'uploadPhoto' })}
                                </HashLink>
                            </div>

                            {userType === 'expert' && (
                                <>
                                    <div className={classNames(st.details_item, objectToArray(user.documents)?.length ? st.details_item_active : '')}>
                                        <div className={st.details_item_check}>
                                            <CheckIcon />
                                        </div>

                                        <HashLink to="#documents">{intl.formatMessage({ id: 'documents' })}</HashLink>
                                    </div>

                                    <div
                                        className={classNames(
                                            st.details_item,
                                            objectToArray(user.certifications)?.length ? st.details_item_active : ''
                                        )}
                                    >
                                        <div className={st.details_item_check}>
                                            <CheckIcon />
                                        </div>

                                        <HashLink to="#certifications">{intl.formatMessage({ id: 'certificationsAndCourses' })}</HashLink>
                                    </div>

                                    <div
                                        className={classNames(
                                            st.details_item,
                                            objectToArray(user.employmentHistories)?.length ? st.details_item_active : ''
                                        )}
                                    >
                                        <div className={st.details_item_check}>
                                            <CheckIcon />
                                        </div>

                                        <HashLink to="#employmentHistory">{intl.formatMessage({ id: 'employmentHistory' })}</HashLink>
                                    </div>
                                </>
                            )}
                        </div>

                        {userType !== 'client' && (
                            <div className={classNames(stCabinet.section, st.with_border)}>
                                {userType === 'expert' ? (
                                    <>
                                        <div className={st.side_section_title}>
                                            <ShieldIcon />

                                            <div>{intl.formatMessage({ id: 'verification' })}</div>
                                        </div>

                                        <div className={st.side_section_text}>{intl.formatMessage({ id: 'verificationText' })}</div>

                                        <Button type="button" fullWidth color="primary" variant="contained">
                                            {intl.formatMessage({ id: 'passVerification' })}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <div className={classNames(st.side_section_title, st.upgrade_plan)}>
                                            <LeafIcon />

                                            <div>
                                                <div className={st.upgrade_title}>{intl.formatMessage({ id: 'plans.lite.title' })}</div>
                                                <div className={st.current_plan}>{intl.formatMessage({ id: 'currentPlan' })}</div>
                                            </div>

                                            {/*<div>{intl.formatMessage({id: 'currentPlan'})}</div>*/}
                                        </div>

                                        {/*<div className={st.side_section_text}>{intl.formatMessage({id: 'currentPlan'})}</div>*/}

                                        <Button type="button" fullWidth color="primary" variant="contained">
                                            {intl.formatMessage({ id: 'ugradeToPro' })}
                                        </Button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </aside>

                <main className={classNames(st.content, 'edit')}>
                    <div className={st.title_wrapper}>
                        <h3 className={st.title_desc}>{intl.formatMessage({ id: 'profile' })}</h3>

                        {userType === 'expert' && (
                            <Link to={`/experts/${pageId}`} className={classNames(st.title_profile, st.title_profile_desktop)}>
                                {intl.formatMessage({ id: 'profileSee' })}
                            </Link>
                        )}
                    </div>

                    <section className={classNames(stCabinet.section, stCabinet.section_accordion)}>
                        <ConditionalWrapper
                            condition={tabletIs}
                            wrapper={(children) => (
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    {children}
                                </Accordion>
                            )}
                        >
                            <ConditionalWrapper
                                condition={tabletIs}
                                wrapper={(children) => (
                                    <AccordionSummary expandIcon={<ExpandIcon />} aria-controls={'panel-content-1'} id={'panel-header-1'}>
                                        {children}
                                    </AccordionSummary>
                                )}
                            >
                                <h4 id="personalInformation">{intl.formatMessage({ id: 'personalInformation' })}</h4>
                            </ConditionalWrapper>

                            {/*<AccordionDetails>*/}
                            <FormikProvider value={formPersonal}>
                                <form noValidate onSubmit={formPersonal.handleSubmit} className={st.form}>
                                    <Field
                                        name="firstName"
                                        children={(fieldProps) => (
                                            <TextField
                                                {...fieldProps.field}
                                                className={st.input_box}
                                                fullWidth
                                                label={`${intl.formatMessage({ id: 'firstName' })} *`}
                                                type="text"
                                                variant="outlined"
                                                error={
                                                    formPersonal.touched.firstName && !formPersonal.isValid
                                                        ? Boolean(fieldProps.meta.error)
                                                        : undefined
                                                }
                                                helperText={formPersonal.touched.firstName && !formPersonal.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />

                                    <Field
                                        name="lastName"
                                        children={(fieldProps) => (
                                            <TextField
                                                {...fieldProps.field}
                                                className={st.input_box}
                                                fullWidth
                                                label={`${intl.formatMessage({ id: 'lastName' })} *`}
                                                type="text"
                                                variant="outlined"
                                                error={
                                                    formPersonal.touched.lastName && !formPersonal.isValid
                                                        ? Boolean(fieldProps.meta.error)
                                                        : undefined
                                                }
                                                helperText={formPersonal.touched.lastName && !formPersonal.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />

                                    <Autocomplete
                                        name="country"
                                        className={st.input_box}
                                        popupIcon={<SelectIcon />}
                                        blurOnSelect
                                        getOptionLabel={(option) => (option.title ? option.title : '')}
                                        isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                        options={countries}
                                        value={formPersonal.values.country}
                                        onChange={(e, newValue) => {
                                            formPersonal.setFieldValue('country', newValue ?? null);
                                            // changeDetector(true);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={intl.formatMessage({ id: 'country' }) + (userType === 'expert' ? ' *' : '')}
                                                type="text"
                                                variant="outlined"
                                                error={
                                                    formPersonal.touched.country && !formPersonal.isValid
                                                        ? 'country' in formPersonal.errors
                                                        : undefined
                                                }
                                                helperText={formPersonal.touched.country && !formPersonal.isValid && formPersonal.errors.country}
                                            />
                                        )}
                                    />

                                    <Autocomplete
                                        name="city"
                                        className={st.input_box}
                                        popupIcon={<SelectIcon />}
                                        blurOnSelect
                                        getOptionLabel={(option) => (option.title ? option.title : '')}
                                        isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                        options={cities}
                                        value={formPersonal.values.city}
                                        onChange={(e, newValue) => {
                                            formPersonal.setFieldValue('city', newValue ?? null);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={intl.formatMessage({ id: 'city' }) + (userType === 'expert' ? ' *' : '')}
                                                type="text"
                                                variant="outlined"
                                                error={formPersonal.touched.city && !formPersonal.isValid ? 'city' in formPersonal.errors : undefined}
                                                helperText={formPersonal.touched.city && !formPersonal.isValid && formPersonal.errors.city}
                                            />
                                        )}
                                    />

                                    {/*<LocalizationProvider dateAdapter={AdapterDateFns} locale={LocaleMap[intl.locale]}>*/}
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            name="birthDate"
                                            className={classNames(st.input_box, st.employment_date)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CalendarIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disableCloseOnSelect={false}
                                            label={intl.formatMessage({ id: 'birthDate' }) + (userType === 'expert' ? ' *' : '')}
                                            minDate={new Date('1935-01-01')}
                                            maxDate={new Date()}
                                            value={formPersonal.values.birthDate ? formPersonal.values.birthDate : null}
                                            onChange={(newValue) => {
                                                formPersonal.setFieldValue('birthDate', newValue);
                                                // changeDetector(true);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // helperText={null}
                                                    error={
                                                        formPersonal.touched.birthDate && !formPersonal.isValid
                                                            ? 'birthDate' in formPersonal.errors
                                                            : undefined
                                                    }
                                                    helperText={
                                                        formPersonal.touched.birthDate && !formPersonal.isValid && formPersonal.errors.birthDate
                                                    }
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>

                                    {userType === 'expert' && (
                                        <Field
                                            name="linkedinLink"
                                            children={(fieldProps) => (
                                                <TextField
                                                    {...fieldProps.field}
                                                    className={st.input_box}
                                                    fullWidth
                                                    label={`${intl.formatMessage({ id: 'linkedin' })}`}
                                                    type="text"
                                                    variant="outlined"
                                                    error={
                                                        formPersonal.touched.linkedinLink && !formPersonal.isValid
                                                            ? Boolean(fieldProps.meta.error)
                                                            : undefined
                                                    }
                                                    helperText={formPersonal.touched.linkedinLink && !formPersonal.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    )}

                                    <Autocomplete
                                        name="languages"
                                        className={classNames(st.input_box, userType === 'expert' ? st.input_box_large : '')}
                                        multiple
                                        popupIcon={<SelectIcon />}
                                        filterSelectedOptions
                                        getOptionLabel={(option) => (option?.title ? option.title : '')}
                                        isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                        options={languages}
                                        value={userLanguages?.length ? userLanguages : []}
                                        onChange={(e, newValue) => {
                                            formPersonal.setFieldValue(
                                                `languages`,
                                                newValue.map((item) => `api/languages/${item.id}`)
                                            );
                                            // user.languages = newValue;
                                            setUserLanguages(newValue);
                                            // changeDetector(true);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label={intl.formatMessage({ id: 'language' }) + (userType === 'expert' ? ' *' : '')}
                                                type="text"
                                                variant="outlined"
                                                error={
                                                    formPersonal.touched.languages && !formPersonal.isValid
                                                        ? 'languages' in formPersonal.errors
                                                        : undefined
                                                }
                                                helperText={formPersonal.touched.languages && !formPersonal.isValid && formPersonal.errors.languages}
                                            />
                                        )}
                                    />

                                    {userType !== 'expert' ? (
                                        <FormControlLabel
                                            label={<span>{intl.formatMessage({ id: 'shareProfile' })}</span>}
                                            className={classNames(st.switcher, st.switcher_last)}
                                            control={
                                                <Switch
                                                    name="shareProfile"
                                                    checked={formPersonal.values.shareProfile}
                                                    onChange={(e, newValue) => {
                                                        formPersonal.setFieldValue('shareProfile', newValue);
                                                    }}
                                                />
                                            }
                                        />
                                    ) : (
                                        <Field
                                            name="about"
                                            children={(fieldProps) => (
                                                <TextField
                                                    {...fieldProps.field}
                                                    className={classNames(st.input_box, st.input_box_large)}
                                                    multiline
                                                    rows={`${mobileIs ? 2 : 4}`}
                                                    label={intl.formatMessage({ id: 'about' })}
                                                    type="text"
                                                    variant="outlined"
                                                    error={
                                                        formPersonal.touched.description && !formPersonal.isValid
                                                            ? Boolean(fieldProps.meta.error)
                                                            : undefined
                                                    }
                                                    helperText={formPersonal.touched.description && !formPersonal.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />
                                    )}

                                    <Button type="submit" color="primary" variant="contained" className={st.save}>
                                        {intl.formatMessage({ id: 'save' })}
                                    </Button>

                                    {/*<pre>{JSON.stringify(formPersonal.values, 0, 2)}</pre>*/}
                                </form>
                            </FormikProvider>
                            {/*</AccordionDetails>*/}
                        </ConditionalWrapper>
                    </section>

                    {userType === 'expert' && (
                        <section className={classNames(stCabinet.section, stCabinet.section_accordion)}>
                            <ConditionalWrapper
                                condition={tabletIs}
                                wrapper={(children) => (
                                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                        {children}
                                    </Accordion>
                                )}
                            >
                                <ConditionalWrapper
                                    condition={tabletIs}
                                    wrapper={(children) => (
                                        <AccordionSummary
                                            // expandIcon={<div className={st.questions__icon}><ExpandMoreIcon /></div>}
                                            expandIcon={<ExpandIcon />}
                                            aria-controls={'panel-content-6'}
                                            id={'panel-header-6'}
                                        >
                                            {children}
                                        </AccordionSummary>
                                    )}
                                >
                                    <h4 id="ratePer">{intl.formatMessage({ id: 'manageRate' })}</h4>
                                </ConditionalWrapper>

                                <FormikProvider value={formRatePer}>
                                    <form noValidate onSubmit={formRatePer.handleSubmit} className={st.form}>
                                        <Field
                                            name="ratePer"
                                            children={(fieldProps) => (
                                                <TextField
                                                    {...fieldProps.field}
                                                    className={st.input_box}
                                                    fullWidth
                                                    label={`${intl.formatMessage({ id: 'rateHour' })} *`}
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e?.key === '-' || e?.key === '+') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    type="number"
                                                    variant="outlined"
                                                    error={
                                                        formRatePer.touched.ratePer && !formRatePer.isValid
                                                            ? Boolean(fieldProps.meta.error)
                                                            : undefined
                                                    }
                                                    helperText={formRatePer.touched.ratePer && !formRatePer.isValid && fieldProps.meta.error}
                                                />
                                            )}
                                        />

                                        <FormControlLabel
                                            label={<span>{intl.formatMessage({ id: 'firstSessionFree' })}</span>}
                                            className={classNames(st.switcher, st.switcher_last)}
                                            control={
                                                <Switch
                                                    name="firstSessionFree"
                                                    checked={formRatePer.values.firstSessionFree}
                                                    onChange={(e, newValue) => {
                                                        formRatePer.setFieldValue('firstSessionFree', newValue);
                                                    }}
                                                />
                                            }
                                        />

                                        <Button type="submit" color="primary" variant="contained" className={st.save}>
                                            {intl.formatMessage({ id: 'save' })}
                                        </Button>

                                        {/*<pre>{JSON.stringify(formRatePer.values, 0, 2)}</pre>*/}
                                    </form>
                                </FormikProvider>
                            </ConditionalWrapper>
                        </section>
                    )}

                    <section className={classNames(stCabinet.section, stCabinet.section_accordion)}>
                        <ConditionalWrapper
                            condition={tabletIs}
                            wrapper={(children) => (
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    {children}
                                </Accordion>
                            )}
                        >
                            <ConditionalWrapper
                                condition={tabletIs}
                                wrapper={(children) => (
                                    <AccordionSummary expandIcon={<ExpandIcon />} aria-controls={'panel-content-2'} id={'panel-header-2'}>
                                        {children}
                                    </AccordionSummary>
                                )}
                            >
                                <h4 id="professionalSkills">{intl.formatMessage({ id: 'professionalSkills' })}</h4>
                            </ConditionalWrapper>

                            <FormikProvider value={formSkill}>
                                <form noValidate onSubmit={formSkill.handleSubmit} className={st.form}>
                                    <Autocomplete
                                        name="positions"
                                        className={classNames(st.input_box, st.input_box_large)}
                                        multiple
                                        popupIcon={<SelectIcon />}
                                        filterSelectedOptions
                                        blurOnSelect
                                        getOptionLabel={(option) => (option.title ? option.title : '')}
                                        isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                        options={positions?.filter((item) => item.type === userType)}
                                        value={userPositions?.length ? userPositions : []}
                                        onChange={(e, newValue) => {
                                            formSkill.setFieldValue(
                                                `positions`,
                                                newValue.map((item) => `api/positions/${item.id}`)
                                            );
                                            setUserPositions(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label={
                                                    intl.formatMessage({ id: userType === 'company' ? 'yourPosition' : 'position' }) +
                                                    (userType !== 'client' ? ' *' : '')
                                                }
                                                type="text"
                                                variant="outlined"
                                                error={
                                                    formSkill.touched.positions && !formSkill.isValid ? 'positions' in formSkill.errors : undefined
                                                }
                                                helperText={formSkill.touched.positions && !formSkill.isValid && formSkill.errors.positions}
                                            />
                                        )}
                                    />

                                    {userType === 'expert' && (
                                        <>
                                            <Autocomplete
                                                name="services"
                                                className={classNames(st.input_box, st.input_box_large)}
                                                multiple
                                                popupIcon={<SelectIcon />}
                                                filterSelectedOptions
                                                blurOnSelect
                                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                                options={services}
                                                value={userServices?.length ? userServices : []}
                                                onChange={(e, newValue) => {
                                                    formSkill.setFieldValue(
                                                        `services`,
                                                        newValue.map((item) => `api/services/${item.id}`)
                                                    );
                                                    setUserServices(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label={intl.formatMessage({ id: 'services' }) + ' *'}
                                                        type="text"
                                                        variant="outlined"
                                                        error={
                                                            formSkill.touched.services && !formSkill.isValid
                                                                ? 'services' in formSkill.errors
                                                                : undefined
                                                        }
                                                        helperText={formSkill.touched.services && !formSkill.isValid && formSkill.errors.services}
                                                    />
                                                )}
                                            />

                                            <FormControlLabel
                                                label={<span>{intl.formatMessage({ id: 'availableFreelance' })}</span>}
                                                className={st.switcher}
                                                control={
                                                    <Switch
                                                        name="availableForFreelanceJob"
                                                        checked={formSkill.values.availableForFreelanceJob}
                                                        onChange={(e, newValue) => {
                                                            formSkill.setFieldValue('availableForFreelanceJob', newValue);
                                                        }}
                                                    />
                                                }
                                            />
                                        </>
                                    )}

                                    {userType !== 'company' ? (
                                        <FieldArray
                                            name="professionalSkills"
                                            render={(arrayHelpers) => (
                                                <>
                                                    {formSkill.values?.professionalSkills?.map((item, i) => (
                                                        <div key={i} className={st.array}>
                                                            <div className={st.array__main}>
                                                                <Autocomplete
                                                                    name={`professionalSkills.[${i}].skillCategory`}
                                                                    className={classNames(st.input_box, st.input_box_large)}
                                                                    popupIcon={<SelectIcon />}
                                                                    blurOnSelect
                                                                    filterSelectedOptions
                                                                    getOptionLabel={(option) => (option.title ? option.title : '')}
                                                                    isOptionEqualToValue={(option, value) =>
                                                                        value.title === '' || option.title === value.title
                                                                    }
                                                                    options={skillCategories
                                                                        .filter(
                                                                            (category) =>
                                                                                !formSkill.values?.professionalSkills
                                                                                    .map((value) => value?.skillCategory?.id)
                                                                                    .includes(category.id)
                                                                        )
                                                                        .concat(item.skillCategory ? item.skillCategory : [])}
                                                                    value={item?.skillCategory ?? null}
                                                                    onChange={(e, newValue) => {
                                                                        formSkill.setFieldValue(
                                                                            `professionalSkills.[${i}].skillCategory`,
                                                                            newValue ?? null
                                                                        );
                                                                        formSkill.setFieldValue(`professionalSkills.[${i}].skillSubCategories`, []);
                                                                        let prevValue = [...userProfSkills];
                                                                        prevValue[i] = [{ skillSubCategories: [] }];
                                                                        setUserProfSkills(prevValue);
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            fullWidth
                                                                            label={
                                                                                intl.formatMessage({ id: 'skillCategory' }) +
                                                                                (userType === 'expert' ? ' *' : '')
                                                                            }
                                                                            variant="outlined"
                                                                            error={
                                                                                formSkill.touched.professionalSkills?.[i]?.skillCategory &&
                                                                                formSkill.errors?.professionalSkills?.[i]?.skillCategory
                                                                                    ? true
                                                                                    : undefined
                                                                            }
                                                                            helperText={
                                                                                formSkill.touched.professionalSkills?.[i]?.skillCategory &&
                                                                                formSkill.errors?.professionalSkills?.[i]?.skillCategory
                                                                            }
                                                                        />
                                                                    )}
                                                                />

                                                                <Autocomplete
                                                                    name={`professionalSkills.[${i}].skillSubCategories`}
                                                                    className={classNames(
                                                                        st.input_box,
                                                                        st.input_box_large,
                                                                        item.skillCategory?.title ? '' : 'disabled'
                                                                    )}
                                                                    blurOnSelect
                                                                    filterSelectedOptions
                                                                    multiple
                                                                    popupIcon={<SelectIcon />}
                                                                    getOptionLabel={(option) => (option.title ? option.title : '')}
                                                                    isOptionEqualToValue={(option, value) =>
                                                                        value.title === '' || option.title === value.title
                                                                    }
                                                                    options={skillSubCategories.filter(
                                                                        (subItem) => subItem.skillCategory.id === item.skillCategory?.id
                                                                    )}
                                                                    value={
                                                                        toJS(userProfSkills)[i]?.skillSubCategories?.length
                                                                            ? userProfSkills[i].skillSubCategories
                                                                            : []
                                                                    }
                                                                    onChange={(e, newValue) => {
                                                                        formSkill.setFieldValue(
                                                                            `professionalSkills.[${i}].skillSubCategories`,
                                                                            newValue.map((item) => `api/skill_sub_categories/${item.id}`)
                                                                        );
                                                                        let prevValue = [...userProfSkills];
                                                                        prevValue[i].skillSubCategories = newValue;
                                                                        setUserProfSkills(prevValue);
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            fullWidth
                                                                            label={
                                                                                intl.formatMessage({ id: 'skillSubCategories' }) +
                                                                                (userType === 'expert' ? ' *' : '')
                                                                            }
                                                                            variant="outlined"
                                                                            error={
                                                                                formSkill.touched.professionalSkills?.[i]?.skillSubCategories &&
                                                                                formSkill.errors?.professionalSkills?.[i]?.skillSubCategories
                                                                                    ? true
                                                                                    : undefined
                                                                            }
                                                                            helperText={
                                                                                formSkill.touched.professionalSkills?.[i]?.skillSubCategories &&
                                                                                formSkill.errors?.professionalSkills?.[i]?.skillSubCategories
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </div>

                                                            <div className={classNames(st.array__delete)}>
                                                                <div
                                                                    className={st.bin}
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(i);
                                                                        let prevValue = [...userProfSkills];
                                                                        prevValue.splice(i, 1);
                                                                        setUserProfSkills(prevValue);
                                                                    }}
                                                                >
                                                                    <BinIcon />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <div
                                                        className={st.array__add}
                                                        onClick={() => {
                                                            if (userProfSkills.length !== 0) {
                                                                setUserProfSkills((prev) => [...prev, [{ skillSubCategories: [] }]]);
                                                            }
                                                            arrayHelpers.push(emptyProfSkill);
                                                        }}
                                                    >
                                                        <span>{intl.formatMessage({ id: 'addSkillCategory' })}</span>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    ) : (
                                        <>
                                            <Field
                                                name="companyName"
                                                children={(fieldProps) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        className={st.input_box}
                                                        fullWidth
                                                        label={intl.formatMessage({ id: 'companyName' })}
                                                        type="text"
                                                        variant="outlined"
                                                        error={
                                                            formSkill.touched.companyName && !formSkill.isValid
                                                                ? Boolean(fieldProps.meta.error)
                                                                : undefined
                                                        }
                                                        helperText={formSkill.touched.companyName && !formSkill.isValid && fieldProps.meta.error}
                                                    />
                                                )}
                                            />

                                            <Autocomplete
                                                name="country"
                                                className={st.input_box}
                                                popupIcon={<SelectIcon />}
                                                blurOnSelect
                                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                                options={countries}
                                                value={formSkill.values.country}
                                                onChange={(e, newValue) => {
                                                    formSkill.setFieldValue('country', newValue ?? null);
                                                    // changeDetector(true);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={intl.formatMessage({ id: 'country' }) + (userType === 'expert' ? ' *' : '')}
                                                        type="text"
                                                        variant="outlined"
                                                        error={
                                                            formSkill.touched.country && !formSkill.isValid
                                                                ? 'country' in formSkill.errors
                                                                : undefined
                                                        }
                                                        helperText={formSkill.touched.country && !formSkill.isValid && formSkill.errors.country}
                                                    />
                                                )}
                                            />

                                            <Autocomplete
                                                name="city"
                                                className={st.input_box}
                                                popupIcon={<SelectIcon />}
                                                blurOnSelect
                                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                                options={cities}
                                                value={formSkill.values.city}
                                                onChange={(e, newValue) => {
                                                    formSkill.setFieldValue('city', newValue ?? null);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={intl.formatMessage({ id: 'city' }) + (userType === 'expert' ? ' *' : '')}
                                                        type="text"
                                                        variant="outlined"
                                                        error={formSkill.touched.city && !formSkill.isValid ? 'city' in formSkill.errors : undefined}
                                                        helperText={formSkill.touched.city && !formSkill.isValid && formSkill.errors.city}
                                                    />
                                                )}
                                            />

                                            <Autocomplete
                                                name="numberOfEmployers"
                                                className={st.input_box}
                                                popupIcon={<SelectIcon />}
                                                blurOnSelect
                                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                                options={numberEmployeesOptions}
                                                value={formSkill.values.numberOfEmployers}
                                                onChange={(e, newValue) => {
                                                    formSkill.setFieldValue('numberOfEmployers', newValue ?? null);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={intl.formatMessage({ id: 'numberOfEmployees' })}
                                                        type="text"
                                                        variant="outlined"
                                                        error={
                                                            formSkill.touched.numberOfEmployers && !formSkill.isValid
                                                                ? 'numberOfEmployers' in formSkill.errors
                                                                : undefined
                                                        }
                                                        helperText={
                                                            formSkill.touched.numberOfEmployers &&
                                                            !formSkill.isValid &&
                                                            formSkill.errors.numberOfEmployers
                                                        }
                                                    />
                                                )}
                                            />

                                            <Autocomplete
                                                name="companyFields"
                                                className={classNames(st.input_box, st.input_box_large)}
                                                multiple
                                                popupIcon={<SelectIcon />}
                                                filterSelectedOptions
                                                blurOnSelect
                                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                                options={companyFields}
                                                value={userCompanyField?.length ? userCompanyField : []}
                                                onChange={(e, newValue) => {
                                                    formSkill.setFieldValue(
                                                        `companyFields`,
                                                        newValue.map((item) => `api/company_fields/${item.id}`)
                                                    );
                                                    setUserCompanyField(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label={intl.formatMessage({ id: 'field' })}
                                                        type="text"
                                                        variant="outlined"
                                                        error={
                                                            formSkill.touched.companyFields && !formSkill.isValid
                                                                ? 'companyFields' in formSkill.errors
                                                                : undefined
                                                        }
                                                        helperText={
                                                            formSkill.touched.companyFields && !formSkill.isValid && formSkill.errors.companyFields
                                                        }
                                                    />
                                                )}
                                            />
                                        </>
                                    )}

                                    {userType === 'expert' && (
                                        <>
                                            <h4 id="softwareAndHardware">{intl.formatMessage({ id: 'softwareAndHardware' })}</h4>

                                            <Autocomplete
                                                name="softwareEquipments"
                                                className={classNames(st.input_box, st.input_box_large)}
                                                multiple
                                                popupIcon={<SelectIcon />}
                                                filterSelectedOptions
                                                getOptionLabel={(option) => (option?.title ? option.title : '')}
                                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                                options={softwareEquipments}
                                                value={userSoftwareEquipments?.length ? userSoftwareEquipments : []}
                                                onChange={(e, newValue) => {
                                                    formSkill.setFieldValue(
                                                        `softwareEquipments`,
                                                        newValue.map((item) => `api/software_equipments/${item.id}`)
                                                    );
                                                    setUserSoftwareEquipments(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label={intl.formatMessage({ id: 'softwareEquipment' })}
                                                        type="text"
                                                        variant="outlined"
                                                        error={
                                                            formSkill.touched.softwareEquipments && !formSkill.isValid
                                                                ? 'softwareEquipments' in formSkill.errors
                                                                : undefined
                                                        }
                                                        helperText={
                                                            formSkill.touched.softwareEquipments &&
                                                            !formSkill.isValid &&
                                                            formSkill.errors.softwareEquipments
                                                        }
                                                    />
                                                )}
                                            />

                                            <div className={st.add_options}>
                                                <span
                                                    className={classNames(st.target, addCustomSoft ? st.target_active : '')}
                                                    onClick={() => setAddCustomSoft((prevState) => !prevState)}
                                                >
                                                    {intl.formatMessage({ id: 'addCustomValue' })}
                                                </span>
                                            </div>

                                            {addCustomSoft && (
                                                <div className={st.custom_ware}>
                                                    <Field
                                                        name="newSoftware"
                                                        children={(fieldProps) => (
                                                            <TextField
                                                                {...fieldProps.field}
                                                                label={intl.formatMessage(
                                                                    { id: 'noOptionsNew' },
                                                                    { var_1: intl.formatMessage({ id: 'software' }) }
                                                                )}
                                                                type="text"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />

                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => {
                                                            if (formSkill.values.newSoftware.trim().length) {
                                                                createSoftWare(formSkill.values.newSoftware.trim());
                                                            }
                                                        }}
                                                    >
                                                        {intl.formatMessage({ id: 'add' })}
                                                    </Button>
                                                </div>
                                            )}

                                            <Autocomplete
                                                name="hardwareEquipments"
                                                className={classNames(st.input_box, st.input_box_large)}
                                                multiple
                                                popupIcon={<SelectIcon />}
                                                filterSelectedOptions
                                                getOptionLabel={(option) => (option?.title ? option.title : '')}
                                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                                options={hardwareEquipments}
                                                value={userHardwareEquipments?.length ? userHardwareEquipments : []}
                                                onChange={(e, newValue) => {
                                                    formSkill.setFieldValue(
                                                        `hardwareEquipments`,
                                                        newValue.map((item) => `api/hardware_equipments/${item.id}`)
                                                    );
                                                    setUserHardwareEquipments(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label={intl.formatMessage({ id: 'hardwareEquipment' })}
                                                        type="text"
                                                        variant="outlined"
                                                        error={
                                                            formSkill.touched.hardwareEquipments && !formSkill.isValid
                                                                ? 'hardwareEquipments' in formSkill.errors
                                                                : undefined
                                                        }
                                                        helperText={
                                                            formSkill.touched.hardwareEquipments &&
                                                            !formSkill.isValid &&
                                                            formSkill.errors.hardwareEquipments
                                                        }
                                                    />
                                                )}
                                            />

                                            <div className={st.add_options}>
                                                <span
                                                    className={classNames(st.target, addCustomHard ? st.target_active : '')}
                                                    onClick={() => setAddCustomHard((prevState) => !prevState)}
                                                >
                                                    {intl.formatMessage({ id: 'addCustomValue' })}
                                                </span>
                                            </div>

                                            {addCustomHard && (
                                                <div className={st.custom_ware}>
                                                    <Field
                                                        name="newHardware"
                                                        children={(fieldProps) => (
                                                            <TextField
                                                                {...fieldProps.field}
                                                                label={intl.formatMessage(
                                                                    { id: 'noOptionsNew' },
                                                                    { var_1: intl.formatMessage({ id: 'hardware' }) }
                                                                )}
                                                                type="text"
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />

                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => {
                                                            if (formSkill.values.newHardware.trim().length) {
                                                                createHardWare(formSkill.values.newHardware.trim());
                                                            }
                                                        }}
                                                    >
                                                        {intl.formatMessage({ id: 'add' })}
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <Button color="primary" variant="contained" className={st.save} onClick={formSkill.handleSubmit}>
                                        {intl.formatMessage({ id: 'save' })}
                                    </Button>

                                    {/*<pre>{JSON.stringify(formSkill.values, 0, 2)}</pre>*/}
                                </form>
                            </FormikProvider>
                        </ConditionalWrapper>
                    </section>

                    {userType === 'expert' && (
                        <>
                            <section className={classNames(stCabinet.section, stCabinet.section_accordion)}>
                                <ConditionalWrapper
                                    condition={tabletIs}
                                    wrapper={(children) => (
                                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            {children}
                                        </Accordion>
                                    )}
                                >
                                    <ConditionalWrapper
                                        condition={tabletIs}
                                        wrapper={(children) => (
                                            <AccordionSummary expandIcon={<ExpandIcon />} aria-controls={'panel-content-3'} id={'panel-header-3'}>
                                                {children}
                                            </AccordionSummary>
                                        )}
                                    >
                                        <h4 id="documents">{intl.formatMessage({ id: 'documents' })}</h4>
                                    </ConditionalWrapper>

                                    <FormikProvider value={formDocuments}>
                                        <form noValidate onSubmit={formDocuments.handleSubmit} className={st.form}>
                                            <FieldArray
                                                name="documents"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {formDocuments.values.documents?.map((document, i) => (
                                                            <div key={i} className={st.documents}>
                                                                <Field
                                                                    name={`documents.[${i}].title`}
                                                                    children={(fieldProps) => (
                                                                        <TextField
                                                                            {...fieldProps.field}
                                                                            className={classNames(
                                                                                st.input_box,
                                                                                st.input_box_large,
                                                                                document.documentFile ? '' : 'disabled'
                                                                            )}
                                                                            fullWidth
                                                                            label={intl.formatMessage({ id: 'cv' })}
                                                                            variant="outlined"
                                                                            error={
                                                                                formDocuments.touched.documents?.[i]?.title && !formDocuments.isValid
                                                                                    ? Boolean(fieldProps.meta.error)
                                                                                    : undefined
                                                                            }
                                                                            helperText={
                                                                                formDocuments.touched.documents?.[i]?.title &&
                                                                                !formDocuments.isValid &&
                                                                                fieldProps.meta.error
                                                                            }
                                                                        />
                                                                    )}
                                                                />

                                                                <label className={st.attach}>
                                                                    {document.documentFile ? (
                                                                        <div className={st.file_bar}>
                                                                            <a
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href={axios.defaults.baseURL + document?.contentUrl}
                                                                            >
                                                                                <EaefIcon />
                                                                            </a>

                                                                            <BinIcon
                                                                                title={intl.formatMessage({ id: 'removeDocument' })}
                                                                                onClick={() => deleteFile(document.documentFile, 'documents')}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <AttachIcon />

                                                                            <input
                                                                                type="file"
                                                                                style={{ display: 'none' }}
                                                                                onChange={(e) => uploadFile(e, i, 'documents')}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </label>
                                                            </div>
                                                        ))}

                                                        <div
                                                            className={st.array__add}
                                                            onClick={() => {
                                                                arrayHelpers.push(structuredClone(initialValuesDoc));
                                                            }}
                                                        >
                                                            <span>{intl.formatMessage({ id: 'addCv' })}</span>
                                                        </div>
                                                    </>
                                                )}
                                            />

                                            <FieldArray
                                                name="diplomas"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {formDocuments.values.diplomas?.map((document, i) => (
                                                            <div key={i} className={st.documents}>
                                                                <Field
                                                                    name={`diplomas.[${i}].title`}
                                                                    children={(fieldProps) => (
                                                                        <TextField
                                                                            {...fieldProps.field}
                                                                            className={classNames(
                                                                                st.input_box,
                                                                                st.input_box_large,
                                                                                document.documentFile ? '' : 'disabled'
                                                                            )}
                                                                            fullWidth
                                                                            label={intl.formatMessage({ id: 'diploma' })}
                                                                            variant="outlined"
                                                                            error={
                                                                                formDocuments.touched.diplomas?.[i]?.title && !formDocuments.isValid
                                                                                    ? Boolean(fieldProps.meta.error)
                                                                                    : undefined
                                                                            }
                                                                            helperText={
                                                                                formDocuments.touched.diplomas?.[i]?.title &&
                                                                                !formDocuments.isValid &&
                                                                                fieldProps.meta.error
                                                                            }
                                                                        />
                                                                    )}
                                                                />

                                                                <label className={st.attach}>
                                                                    {document.documentFile ? (
                                                                        <div className={st.file_bar}>
                                                                            <a
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href={axios.defaults.baseURL + document?.contentUrl}
                                                                            >
                                                                                <EaefIcon />
                                                                            </a>
                                                                            <BinIcon
                                                                                title={intl.formatMessage({ id: 'removeDocument' })}
                                                                                onClick={() => deleteFile(document.documentFile, 'diplomas')}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <AttachIcon />

                                                                            <input
                                                                                type="file"
                                                                                style={{ display: 'none' }}
                                                                                onChange={(e) => uploadFile(e, i, 'diplomas')}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </label>
                                                            </div>
                                                        ))}

                                                        <div
                                                            className={st.array__add}
                                                            onClick={() => {
                                                                arrayHelpers.push(structuredClone(initialValuesDoc));
                                                            }}
                                                        >
                                                            <span>{intl.formatMessage({ id: 'addDiploma' })}</span>
                                                        </div>
                                                    </>
                                                )}
                                            />

                                            <FieldArray
                                                name="appreciationLetters"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {formDocuments.values.appreciationLetters?.map((document, i) => (
                                                            <div key={i} className={st.documents}>
                                                                <Field
                                                                    name={`appreciationLetters.[${i}].title`}
                                                                    children={(fieldProps) => (
                                                                        <TextField
                                                                            {...fieldProps.field}
                                                                            className={classNames(
                                                                                st.input_box,
                                                                                st.input_box_large,
                                                                                document.documentFile ? '' : 'disabled'
                                                                            )}
                                                                            fullWidth
                                                                            label={intl.formatMessage({ id: 'appreciationLetter' })}
                                                                            variant="outlined"
                                                                            error={
                                                                                formDocuments.touched.appreciationLetters?.[i]?.title &&
                                                                                !formDocuments.isValid
                                                                                    ? Boolean(fieldProps.meta.error)
                                                                                    : undefined
                                                                            }
                                                                            helperText={
                                                                                formDocuments.touched.appreciationLetters?.[i]?.title &&
                                                                                !formDocuments.isValid &&
                                                                                fieldProps.meta.error
                                                                            }
                                                                        />
                                                                    )}
                                                                />

                                                                <label className={st.attach}>
                                                                    {document.documentFile ? (
                                                                        <div className={st.file_bar}>
                                                                            <a
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href={axios.defaults.baseURL + document?.contentUrl}
                                                                            >
                                                                                <EaefIcon />
                                                                            </a>

                                                                            <BinIcon
                                                                                title={intl.formatMessage({ id: 'removeDocument' })}
                                                                                onClick={() =>
                                                                                    deleteFile(document.documentFile, 'appreciationLetters')
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <AttachIcon />

                                                                            <input
                                                                                type="file"
                                                                                style={{ display: 'none' }}
                                                                                onChange={(e) => uploadFile(e, i, 'appreciationLetters')}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </label>
                                                            </div>
                                                        ))}

                                                        <div
                                                            className={st.array__add}
                                                            onClick={() => {
                                                                arrayHelpers.push(structuredClone(initialValuesDoc));
                                                            }}
                                                        >
                                                            <span>{intl.formatMessage({ id: 'addAppreciationLetter' })}</span>
                                                        </div>
                                                    </>
                                                )}
                                            />

                                            <Button type="submit" color="primary" variant="contained" className={st.save}>
                                                {intl.formatMessage({ id: 'save' })}
                                            </Button>

                                            {/*<pre>{JSON.stringify(formDocuments.values, 0, 2)}</pre>*/}
                                        </form>
                                    </FormikProvider>
                                </ConditionalWrapper>
                            </section>

                            <section className={classNames(stCabinet.section, stCabinet.section_accordion)}>
                                <ConditionalWrapper
                                    condition={tabletIs}
                                    wrapper={(children) => (
                                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                            {children}
                                        </Accordion>
                                    )}
                                >
                                    <ConditionalWrapper
                                        condition={tabletIs}
                                        wrapper={(children) => (
                                            <AccordionSummary expandIcon={<ExpandIcon />} aria-controls={'panel-content-4'} id={'panel-header-4'}>
                                                {children}
                                            </AccordionSummary>
                                        )}
                                    >
                                        <h4 id="certifications">{intl.formatMessage({ id: 'certificationsAndCourses' })}</h4>
                                    </ConditionalWrapper>

                                    <FormikProvider value={formCertifications}>
                                        <form noValidate onSubmit={formCertifications.handleSubmit} className={st.form}>
                                            <FieldArray
                                                name="certifications"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {formCertifications.values.certifications?.map((document, i) => (
                                                            <div key={i} className={classNames(st.documents, st.documents_withYear)}>
                                                                <Field
                                                                    name={`certifications.[${i}].title`}
                                                                    children={(fieldProps) => (
                                                                        <TextField
                                                                            {...fieldProps.field}
                                                                            className={classNames(
                                                                                st.input_box,
                                                                                st.add_to_year,
                                                                                document.documentFile ? '' : 'disabled'
                                                                            )}
                                                                            fullWidth
                                                                            label={intl.formatMessage({ id: 'certificationsOrCourses' })}
                                                                            variant="outlined"
                                                                            error={
                                                                                formCertifications.touched.certifications?.[i]?.title &&
                                                                                !formCertifications.isValid
                                                                                    ? Boolean(fieldProps.meta.error)
                                                                                    : undefined
                                                                            }
                                                                            helperText={
                                                                                formCertifications.touched.certifications?.[i]?.title &&
                                                                                !formCertifications.isValid &&
                                                                                fieldProps.meta.error
                                                                            }
                                                                        />
                                                                    )}
                                                                />

                                                                <label className={st.attach}>
                                                                    {document.documentFile ? (
                                                                        <div className={st.file_bar}>
                                                                            <a
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href={axios.defaults.baseURL + document?.contentUrl}
                                                                            >
                                                                                <EaefIcon />
                                                                            </a>

                                                                            <BinIcon
                                                                                title={intl.formatMessage({ id: 'removeDocument' })}
                                                                                onClick={() =>
                                                                                    deleteFile(
                                                                                        document.documentFile,
                                                                                        'certifications',
                                                                                        'formCertifications'
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <AttachIcon />

                                                                            <input
                                                                                type="file"
                                                                                style={{ display: 'none' }}
                                                                                onChange={(e) =>
                                                                                    uploadFile(e, i, 'certifications', 'formCertifications')
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </label>

                                                                <LocalizationProvider
                                                                    dateAdapter={AdapterDateFns}
                                                                    adapterLocale={LocaleMap[intl.locale]}
                                                                >
                                                                    <MobileDatePicker
                                                                        name={`certifications.[${i}].year`}
                                                                        className={classNames(st.input_box, st.year)}
                                                                        views={['year']}
                                                                        label={intl.formatMessage({ id: 'year' })}
                                                                        minDate={new Date('1980')}
                                                                        maxDate={new Date()}
                                                                        value={document.year ? new Date(document.year, 1, 1) : null}
                                                                        onChange={(newValue) => {
                                                                            if (newValue) {
                                                                                formCertifications.setFieldValue(
                                                                                    `certifications.[${i}].year`,
                                                                                    newValue.getFullYear()
                                                                                );
                                                                            }
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                error={
                                                                                    formCertifications.touched?.certifications?.[i]?.year &&
                                                                                    formCertifications.errors?.certifications?.[i]?.year
                                                                                        ? true
                                                                                        : undefined
                                                                                }
                                                                                helperText={
                                                                                    formCertifications.touched?.certifications?.[i]?.year &&
                                                                                    formCertifications.errors?.certifications?.[i]?.year
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        ))}

                                                        <div
                                                            className={st.array__add}
                                                            onClick={() => {
                                                                arrayHelpers.push(initialValuesCertifications);
                                                            }}
                                                        >
                                                            <span>{intl.formatMessage({ id: 'addCertifications' })}</span>
                                                        </div>
                                                    </>
                                                )}
                                            />

                                            <Button type="submit" color="primary" variant="contained" className={st.save}>
                                                {intl.formatMessage({ id: 'save' })}
                                            </Button>

                                            {/*<pre>{JSON.stringify(formCertifications.values, 0, 2)}</pre>*/}
                                        </form>
                                    </FormikProvider>
                                </ConditionalWrapper>
                            </section>

                            <section className={classNames(stCabinet.section, stCabinet.section_accordion)}>
                                <ConditionalWrapper
                                    condition={tabletIs}
                                    wrapper={(children) => (
                                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                            {children}
                                        </Accordion>
                                    )}
                                >
                                    <ConditionalWrapper
                                        condition={tabletIs}
                                        wrapper={(children) => (
                                            <AccordionSummary expandIcon={<ExpandIcon />} aria-controls={'panel-content-5'} id={'panel-header-5'}>
                                                {children}
                                            </AccordionSummary>
                                        )}
                                    >
                                        <h4 id="employmentHistory">{intl.formatMessage({ id: 'employmentHistory' })}</h4>
                                    </ConditionalWrapper>

                                    <FormikProvider value={formEmployments}>
                                        <form noValidate onSubmit={formEmployments.handleSubmit} className={st.form}>
                                            <FieldArray
                                                name="employmentHistories"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {formEmployments.values.employmentHistories?.map((document, i) => (
                                                            <div key={i} className={st.array}>
                                                                <div className={classNames(st.array__main, st.array__main_row)}>
                                                                    <Field
                                                                        name={`employmentHistories.[${i}].jobTitle`}
                                                                        children={(fieldProps) => (
                                                                            <TextField
                                                                                {...fieldProps.field}
                                                                                className={classNames(st.input_box)}
                                                                                fullWidth
                                                                                label={intl.formatMessage({ id: 'jobTitle' }) + ' *'}
                                                                                variant="outlined"
                                                                                error={
                                                                                    formEmployments.touched.employmentHistories?.[i]?.jobTitle &&
                                                                                    !formEmployments.isValid
                                                                                        ? Boolean(fieldProps.meta.error)
                                                                                        : undefined
                                                                                }
                                                                                helperText={
                                                                                    formEmployments.touched.employmentHistories?.[i]?.jobTitle &&
                                                                                    !formEmployments.isValid &&
                                                                                    fieldProps.meta.error
                                                                                }
                                                                            />
                                                                        )}
                                                                    />

                                                                    <Field
                                                                        name={`employmentHistories.[${i}].employer`}
                                                                        children={(fieldProps) => (
                                                                            <TextField
                                                                                {...fieldProps.field}
                                                                                className={classNames(st.input_box)}
                                                                                fullWidth
                                                                                label={intl.formatMessage({ id: 'employer' })}
                                                                                variant="outlined"
                                                                                error={
                                                                                    formEmployments.touched.employmentHistories?.[i]?.employer &&
                                                                                    !formEmployments.isValid
                                                                                        ? Boolean(fieldProps.meta.error)
                                                                                        : undefined
                                                                                }
                                                                                helperText={
                                                                                    formEmployments.touched.employmentHistories?.[i]?.employer &&
                                                                                    !formEmployments.isValid &&
                                                                                    fieldProps.meta.error
                                                                                }
                                                                            />
                                                                        )}
                                                                    />

                                                                    <LocalizationProvider
                                                                        dateAdapter={AdapterDateFns}
                                                                        adapterLocale={LocaleMap[intl.locale]}
                                                                    >
                                                                        <MobileDatePicker
                                                                            name={`employmentHistories.[${i}].startDate`}
                                                                            className={classNames(st.input_box, st.employment_date)}
                                                                            views={['year', 'month']}
                                                                            label={intl.formatMessage({ id: 'startDate' })}
                                                                            minDate={new Date('1975')}
                                                                            maxDate={new Date()}
                                                                            value={document.startDate ? document.startDate : null}
                                                                            onChange={(newValue) => {
                                                                                if (newValue) {
                                                                                    formEmployments.setFieldValue(
                                                                                        `employmentHistories.[${i}].startDate`,
                                                                                        newValue
                                                                                    );
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <CalendarIcon />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    error={
                                                                                        formEmployments.touched?.employmentHistories?.[i]
                                                                                            ?.startDate &&
                                                                                        formEmployments.errors?.employmentHistories?.[i]?.startDate
                                                                                            ? true
                                                                                            : undefined
                                                                                    }
                                                                                    helperText={
                                                                                        formEmployments.touched?.employmentHistories?.[i]
                                                                                            ?.startDate &&
                                                                                        formEmployments.errors?.employmentHistories?.[i]?.startDate
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>

                                                                    <LocalizationProvider
                                                                        dateAdapter={AdapterDateFns}
                                                                        adapterLocale={LocaleMap[intl.locale]}
                                                                    >
                                                                        <MobileDatePicker
                                                                            name={`employmentHistories.[${i}].endDate`}
                                                                            className={classNames(st.input_box, st.employment_date)}
                                                                            views={['year', 'month']}
                                                                            label={intl.formatMessage({ id: 'endDate' })}
                                                                            minDate={new Date('1975')}
                                                                            maxDate={new Date()}
                                                                            value={document.endDate ? document.endDate : null}
                                                                            onChange={(newValue) => {
                                                                                if (newValue) {
                                                                                    formEmployments.setFieldValue(
                                                                                        `employmentHistories.[${i}].endDate`,
                                                                                        newValue
                                                                                    );
                                                                                    formEmployments.setFieldValue(
                                                                                        `employmentHistories[${i}].stillWorking`,
                                                                                        false
                                                                                    );
                                                                                }
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <CalendarIcon />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    error={
                                                                                        formEmployments.touched?.employmentHistories?.[i]?.endDate &&
                                                                                        formEmployments.errors?.employmentHistories?.[i]?.endDate
                                                                                            ? true
                                                                                            : undefined
                                                                                    }
                                                                                    helperText={
                                                                                        formEmployments.touched?.employmentHistories?.[i]?.endDate &&
                                                                                        formEmployments.errors?.employmentHistories?.[i]?.endDate
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>

                                                                    <FormControlLabel
                                                                        className={st.still_work}
                                                                        control={
                                                                            <Field
                                                                                name={`employmentHistories.[${i}].stillWorking`}
                                                                                children={(fieldProps) => (
                                                                                    <Checkbox
                                                                                        {...fieldProps.field}
                                                                                        checkedIcon={<span />}
                                                                                        icon={<span />}
                                                                                        checked={document.stillWorking}
                                                                                        onChange={(e) => {
                                                                                            formEmployments.handleChange(e);
                                                                                            formEmployments.setFieldValue(
                                                                                                `employmentHistories[${i}].endDate`,
                                                                                                null
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        }
                                                                        label={intl.formatMessage({ id: 'stillWorking' })}
                                                                    />
                                                                </div>

                                                                <div className={classNames(st.array__delete)}>
                                                                    <div
                                                                        className={st.bin}
                                                                        onClick={() => {
                                                                            arrayHelpers.remove(i);
                                                                        }}
                                                                    >
                                                                        <BinIcon />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        <div
                                                            className={st.array__add}
                                                            onClick={() => {
                                                                arrayHelpers.push(initialValuesEmployments);
                                                            }}
                                                        >
                                                            <span>{intl.formatMessage({ id: 'addEmployment' })}</span>
                                                        </div>
                                                    </>
                                                )}
                                            />

                                            <Button type="submit" color="primary" variant="contained" className={st.save}>
                                                {intl.formatMessage({ id: 'save' })}
                                            </Button>

                                            {/*<pre>{JSON.stringify(formEmployments.values, 0, 2)}</pre>*/}
                                        </form>
                                    </FormikProvider>
                                </ConditionalWrapper>
                            </section>
                        </>
                    )}
                </main>
            </div>
        </div>
    );
};

export default observer(ProfileEdit);
