import React from "react";
import st from './Contact.module.scss';
import { ReactComponent as MailIcon } from '../../assets/images/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/icons/phone.svg';
import {Field, FormikProvider, useFormik} from 'formik';
import { postData } from '../../api/api';
import {Box, Button, TextField} from '@mui/material';
import { Link } from 'react-router-dom';
import { NonAuthRoutes } from 'src/routes/routes';
import validator from "validator";
import {useIntl} from "react-intl";
import UIStore from "../../store/UIStore";
import {Alert} from "../../components/Alert";


const Contact = () => {
    const intl = useIntl();
    const { localeUrl, setSuccessAlert, setErrorAlert, alertTimers, mobileIs } = UIStore;

    const validate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = intl.formatMessage({id: 'required'});
        }

        if (!values.email) {
            errors.email = intl.formatMessage({id: 'required'});
        }

        if (values.email && !validator.isEmail(values.email)) {
            errors.email = intl.formatMessage({id: 'errors.email'});
        }

        if (!values.phone) {
            errors.phone = intl.formatMessage({id: 'required'});
        }

        if (values.phone && !validator.isMobilePhone(values.phone)) {
            errors.phone = intl.formatMessage({id: 'errors.phone'});
        }

        if (!values.text) {
            errors.text = intl.formatMessage({id: 'required'});
        }

        return errors;
    };

    const form = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            text: '',
        },
        validate: validate,
        onSubmit: (values, { setErrors }) => {
            setSuccessAlert('');
            setErrorAlert('');

            postData('api/contacts', values, 'post')
                .then(({ data }) => {
                    setSuccessAlert(intl.formatMessage({id: "messageSent"}), alertTimers.short);
                    console.log(data);
                })
                .catch((errorMessage) => {
                    setErrorAlert(intl.formatMessage({id: "errorSave"}), alertTimers.long);
                    console.log(JSON.stringify(errorMessage));
                });
        },
    });

    return (
        <section className={st.contact}>
            <div className="container">
                <div className={st.contact__container}>
                    <Alert />

                    <div className={st.contact__body}>
                        <p className={st.contact__pretitle}>{intl.formatMessage({ id: 'app.header.menu.contacts' })}</p>
                        <h2 className={st.contact__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "contacts.title" }) }}></h2>
                        <p className={st.contact__text}>
                            {intl.formatMessage({ id: 'contacts.description' })}
                        </p>
                        <div className={st.contact__link}>
                            <MailIcon />
                            <a href="mailto:contact@fsexpert.com">contact@fsexpert.com</a>
                        </div>
                        <div className={st.contact__link}>
                            <PhoneIcon />
                            <a href="tel:+11254475546">+1 125 447 55 46</a>
                        </div>
                    </div>

                    <FormikProvider value={form}>
                        <Box component='form' noValidate onSubmit={form.handleSubmit} className={st.contact__form}
                             onKeyDown={(event) => {
                                 if (event.key === 'Enter' && (event.target?.nodeName && event.target.nodeName !== 'TEXTAREA')) {
                                     form.handleSubmit();
                                 }
                             }}
                        >
                            <Field
                                name="name"
                                children={(fieldProps) => (
                                    <TextField
                                        {...fieldProps.field}
                                        fullWidth
                                        label={`${intl.formatMessage({id: "name"})} *`}
                                        type="text"
                                        variant="outlined"
                                        error={form.touched.name && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.name && !form.isValid && fieldProps.meta.error}
                                    />)}
                            />

                            <Field
                                name="email"
                                children={(fieldProps) => (
                                    <TextField
                                        {...fieldProps.field}
                                        fullWidth
                                        label={`${intl.formatMessage({id: "email"})} *`}
                                        variant="outlined"
                                        error={form.touched.email && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.email && !form.isValid && fieldProps.meta.error}
                                    />)}
                            />

                            <Field
                                name="phone"
                                children={(fieldProps) => (
                                    <TextField
                                        {...fieldProps.field}
                                        fullWidth
                                        label={`${intl.formatMessage({id: "phone"})} *`}
                                        variant="outlined"
                                        error={form.touched.phone && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.phone && !form.isValid && fieldProps.meta.error}
                                    />)}
                            />

                            <Field
                                name='text'
                                children={(fieldProps) => (
                                    <TextField
                                        {...fieldProps.field}
                                        fullWidth
                                        label={`${intl.formatMessage({id: "textMessage"})} *`}
                                        variant='outlined'
                                        type="text"
                                        multiline
                                        rows={`${mobileIs ? 1 : 4}`}
                                        error={form.touched.text && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.text && !form.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />

                            <div className={st.contact__form_bottom}>
                                <div className={st.terms}>
                                    <span>{intl.formatMessage({ id: 'contacts.form.terms.read' })} </span>
                                    <Link to={localeUrl(NonAuthRoutes.termsOfUse)} className={st.terms__link}>{intl.formatMessage({ id: 'contacts.form.terms.terms' })}</Link>
                                    <span> {intl.formatMessage({ id: 'contacts.form.terms.and' })} </span>
                                    <Link to={localeUrl(NonAuthRoutes.privacyPolicy)} className={st.terms__link}>{intl.formatMessage({ id: 'contacts.form.terms.privacy' })}</Link>
                                </div>

                                <Button fullWidth color='primary' variant='contained' size="large" className={st.contact__button} onClick={form.handleSubmit}>
                                    {intl.formatMessage({ id: 'send' })}
                                </Button>
                            </div>
                        </Box>
                    </FormikProvider>
                </div>
            </div>
        </section>
    );
};

export default Contact;
