import { useEffect, useState, useRef, useMemo } from 'react';
import isEmpty from "lodash/isEmpty";

export const formatTime = (time) => {
    time = Math.max(time, 0);

    const sec = Math.floor((time / 1000) % 60)
        .toString()
        .padStart(2, '0');
    const min = Math.floor((time / 1000 / 60) % 60)
        .toString()
        .padStart(2, '0');
    const hrs = Math.floor((time / 1000 / 60 / 60) % 24)
        .toString()
        .padStart(2, '0');

    return `${ hrs } : ${ min } : ${ sec }`;
};

export const cleanObject = (obj, extended) => {
    for (let propName in obj) {
        if (isObject(obj[propName])) {
            cleanObject(obj[propName]);
        }

        if (obj[propName] === null || obj[propName] === undefined ||
            (extended && (obj[propName] === '' || obj[propName] === [] || obj[propName] === {})) ||
            (isObject(obj[propName]) && isEmpty(obj[propName]))
        ) {
            delete obj[propName];
        }
    }

    return obj;
}

// If object includes arrays
export const cleanObjectDeep = (value) => {
    for (let propName in value) {
        if (isObject(value[propName])) {
            cleanObjectDeep(value[propName]);
        } else if (Array.isArray(value[propName])) {
            value[propName].map(item => cleanObjectDeep(item));
            value[propName] = value[propName].filter(item1 => !isEmpty(item1));
        }

        if (isEmpty(value[propName])) {
            delete value[propName];
        }
    }

    return value;
}

export const stringAvatar = (name) => {
    if (name.indexOf(' ') < 0 || name.length < 1) {
        return { children: name };
    }

    return {
        children: `${ name.split(' ')[0][0] }${ name.split(' ')[1][0] }`,
    };
};

export const objectToArray = (value) => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        return Object.values(value);
    }
    return value;
};

export const isObject = (value) => {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

export const neatNumber = (value, isPercent = false) => {
    let formatValue = value ? value.toFixed(2) : value === 0 ? 0 : '';
    if (value && isPercent) {
        formatValue += '%';
    }
    return formatValue;
};

export const useScrollbarWidth = () => {
    const didCompute = useRef(false);
    const widthRef = useRef(0);

    if (didCompute.current) return widthRef.current;

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    didCompute.current = true;
    widthRef.current = scrollbarWidth;

    return scrollbarWidth;
};

export const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, handler]);
};

export const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${ src }"]`)) {
            return resolve();
        }

        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = (err) => reject(err);
        document.body.appendChild(script);
    })
}

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
};

// Хук «useBeforeUnload()» используется для вывода сообщения или выполнения функции в момент перезагрузки или закрытия страницы (вкладки браузера)
export const useBeforeUnload = (value) => {
    const handleBeforeunload = (e) => {
        let returnValue;
        if (typeof value === 'function') {
            returnValue = value(e);
        } else {
            returnValue = value;
        }
        if (returnValue) {
            e.preventDefault();
            e.returnValue = returnValue;
        }
        return returnValue;
    }

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeunload);

        return () => window.removeEventListener('beforeunload', handleBeforeunload);
        // eslint-disable-next-line
    }, []);
}

export const kitcut = (text, limit) => {
    text = text.trim();

    if (text.length <= limit) {
        return text;
    }

    text = text.slice(0, limit); // тупо отрезать по лимиту

    let lastSpace = text.lastIndexOf(" ");

    if (lastSpace > 0) { // нашлась граница слов, ещё укорачиваем
        text = text.substr(0, lastSpace);
    }

    return text + "...";
}

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    // const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), []);


    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [observer, ref, ref.current]);

    return isIntersecting;
}
