import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import st from './textarea.module.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/images/icons/arrow-left.svg';
import { ReactComponent as FileIcon } from '../../../assets/images/icons/file.svg';
import { postData } from 'src/api/api';
import DataStore from 'src/store/DataStore';
import UIStore from 'src/store/UIStore';
import { Button } from '@mui/material';
import mediaObjectApi from 'src/api/mediaObjectApi';
import ChatStore from 'src/store/ChatStore';
import TextareaAutosize from 'react-textarea-autosize';
import {useOnScreen} from "../../../helpers/helpers";

const Textarea = () => {
    const intl = useIntl();
    const { setErrorAlert, alertTimers, tabletIs } = UIStore;
    const { userUrl } = DataStore;
    const { activeChat } = ChatStore;
    const [value, setValue] = React.useState('');
    const formRef = React.useRef(null);
    const textAreaRef = React.useRef(null);
    const ref = React.useRef(null);
    const isVisible = useOnScreen(ref);

    React.useEffect(() => {
        if (activeChat.isNotify && isVisible) {
            postData(`api/${activeChat.workChat ? 'work_chats' : 'chats'}/${activeChat.id}`,
                {[DataStore.userType === 'expert' ? 'notifyExecutor' : 'notifyOwner']: false},
                'put'
            )
                .then(({ data }) => {
                    ChatStore.updateChatList(data.channelName, 'isNotify', false);
                    ChatStore.checkNewMessage();
                })
                .catch((error) => {console.log(error);});
        }

    }, [isVisible, activeChat.id, activeChat.workChat, activeChat.isNotify]);

    React.useEffect(() => {
        setValue('');

        return () => {
            setValue('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ChatStore.activeChat.id]);

    const uploadFile = (event) => {
        const file = event.target.files[0];

        if (file.size > 30000000) {
            setErrorAlert(intl.formatMessage({id: 'fileUploadedWorning'}))
            return;
        }

        mediaObjectApi
            .loadMedia(file)
            .then((response) => {
                if (response.status === 201 && response.data.contentUrl) {
                    let sendingData = {
                        file: `api/media_objects/${response.data.id}`,
                        sender: `api${userUrl}`,
                        systemMessage: false,
                        spam: false,
                    };

                    if (ChatStore.activeChat.assignedWork) {
                        sendingData.workChat = `api/work_chats/${ChatStore.activeChat.id}`;
                    } else {
                        sendingData.chat = `api/chats/${ChatStore.activeChat.id}`;
                    }

                    postData(`api/chat_file_messages`, sendingData)
                        .then((data) => {
                            console.log(data);
                        })
                        .catch((error) => {
                            setErrorAlert(JSON.stringify(error), alertTimers.long);
                        });
                } else {
                    console.log(response);
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(JSON.stringify(error), alertTimers.long);
            });
    };

    const handleSubmitForm = (event) => {
        event.preventDefault();
        setErrorAlert('');
        textAreaRef.current?.focus();

        if (event.target.text.value) {
            let sendingData = {
                text: event.target.text.value,
                sender: `api${userUrl}`,
                systemMessage: false,
                spam: false,
            };

            if (ChatStore.activeChat.assignedWork) {
                sendingData.workChat = `api/work_chats/${ChatStore.activeChat.id}`;
            } else {
                sendingData.chat = `api/chats/${ChatStore.activeChat.id}`;
            }

            postData(`api/chat_text_messages`, sendingData)
                .then((data) => {
                    setValue('');

                    textAreaRef.current?.focus();
                })
                .catch((error) => {
                    setErrorAlert(JSON.stringify(error), alertTimers.long);
                });
        }
    };

    const changeTextArea = (event) => {
        // console.log(event)
        setValue(event.currentTarget.value);
    };
    const keyPressTextArea = (event) => {
        if (event.ctrlKey && (event.key === 'Enter' || event.key === '\n')) {
            setValue(value + '\n');
        }
        if (!event.shiftKey && !event.ctrlKey && !event.altKey && event.key === 'Enter') {
            event.preventDefault();
            formRef.current.requestSubmit();
        }
    };

    return (
        <div ref={ref} className={st.wrapper}>
            <div className={st.attach}>
                <Button className={st.file} variant="text" component="label" htmlFor="button-file">
                    <FileIcon />
                    <input name="file" type="file" onChange={uploadFile} hidden style={{ display: 'none' }} id="button-file" />
                </Button>
            </div>

            <form className={st.field} noValidate onSubmit={handleSubmitForm} ref={formRef}>
                <TextareaAutosize
                    minRows={1}
                    maxRows={tabletIs ? 3 : 6}
                    // autoFocus={!tabletIs}
                    autoComplete="off"
                    required
                    name="text"
                    className={st.input}
                    ref={textAreaRef}
                    placeholder={intl.formatMessage({ id: 'messages.input' })}
                    value={value}
                    onChange={changeTextArea}
                    onKeyPress={keyPressTextArea}
                    // onHeightChange={(height) => console.log(height)}
                />

                <Button className={st.send} variant="contained" color="primary" type="submit">
                    <ArrowIcon />
                </Button>
            </form>
        </div>
    );
};

export default observer(Textarea);
