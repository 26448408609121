import classNames from 'classnames';

import React from 'react';
import st from './SimpleCard.module.scss';

const SimpleCard = ({
    color = 'light',
    icon,
    title,
    description = undefined,
    className
}) => {
    return (
        <div className={classNames(
            st.container,
            {
                [st.color_light]: color === 'light',
                [st.color_accent]: color === 'accent'
            },
            className
        )}>
            <div className={st.icon}>
                {icon &&
                    <img loading="eager" src={icon} alt='' width='60px' height='60px'/>
                }
            </div>
            <h4 className={st.title}>{title}</h4>
            {description && (<p className={st.description}>{description}</p>)}
        </div>
    )
}


export default SimpleCard
