import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import st from './accountsettings.module.scss';
import { Field, FormikProvider, useFormik } from 'formik';
import Fade from '@mui/material/Fade';
import {
    Dialog,
    //Divider,
    IconButton,
    Typography,
    InputAdornment,
    Tab,
    Tabs,
    FormControlLabel,
    Switch,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import stylePopup from '../../../components/Popup/popup.module.scss';
import { observer } from 'mobx-react';
import { ReactComponent as CrossIcon } from '../../../assets/images/icons/cross.svg';
import { ReactComponent as EyeIcon } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as EyeIconOff } from '../../../assets/images/icons/eye-off.svg';
import isEmail from 'validator/lib/isEmail';
import UIStore from '../../../store/UIStore';
import DataStore from '../../../store/DataStore';
import { postData } from '../../../api/api';
import { TabContext, TabPanel } from '@mui/lab';
import { useEffect } from 'react';

const AccountSettings = () => {
    const intl = useIntl();
    const { setSuccessAlert, setErrorAlert, alertTimers } = UIStore;
    const { user } = DataStore;
    const [open, setOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [value, setValue] = useState('account');

    const validateChangePassword = (values) => {
        const errors = {};

        if (!values.currentPassword) {
            errors.currentPassword = intl.formatMessage({ id: 'required' });
        }

        if (!values.newPassword) {
            errors.newPassword = intl.formatMessage({ id: 'required' });
        }

        if (values.confirmNewPassword && values.confirmNewPassword !== values.newPassword) {
            errors.newPassword = intl.formatMessage({ id: 'errors.passwordNotMatch' });
        }

        if (!values.confirmNewPassword) {
            errors.confirmNewPassword = intl.formatMessage({ id: 'required' });
        }

        return errors;
    };

    const validateEditAccount = (values) => {
        const errors = {};

        if (values.email && !isEmail(values.email)) {
            errors.email = intl.formatMessage({ id: 'errors.email' });
        }

        return errors;
    };

    const accountForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: user.email ?? '',
        },
        validate: validateEditAccount,
        // @ts-ignore
        onSubmit: (values, { setErrors }) => {
            setSuccessAlert('', alertTimers.short);
            setErrorAlert('');

            postData(`api/users/${user.id}`, { email: values.email }, 'put')
                .then(() => {
                    setSuccessAlert(intl.formatMessage({ id: 'successSave' }));
                })
                .catch((errors) => {
                    console.log('errors', errors);
                    setErrorAlert(intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
                });
        },
    });

    const form = useFormik({
        initialValues: { currentPassword: '', newPassword: '', confirmNewPassword: '' },
        validate: validateChangePassword,
        validateOnBlur: false,
        validateOnChange: false,
        // @ts-ignore
        onSubmit: (values, { setErrors }) => {
            setSuccessAlert('', alertTimers.short);
            setErrorAlert('');

            postData('api/change-password', { currentPassword: values.currentPassword, newPassword: values.newPassword })
                .then(() => {
                    setSuccessAlert(intl.formatMessage({ id: 'successSave' }));
                })
                .catch((error) => {
                    setErrorAlert(JSON.stringify(error), alertTimers.long);
                });
        },
    });

    const deleteAccountForm = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: ' ',
            text: 'I want to delete my account',
        },
        validateOnBlur: false,
        validateOnChange: false,
        // @ts-ignore
        onSubmit: (values, { setErrors }) => {
            postData('api/contacts', values, 'post')
                .then(({ data }) => {
                    setSuccessAlert(intl.formatMessage({ id: 'cabinet.security.delete.popup.request' }), alertTimers.long);
                    console.log(data);
                    handleClose();
                })
                .catch((errorMessage) => {
                    setErrorAlert(intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
                    console.log(JSON.stringify(errorMessage));
                });
        },
    });

    useEffect(() => {
        deleteAccountForm.setFieldValue('name', DataStore.getFio() ?? ' ');
        deleteAccountForm.setFieldValue('email', user.email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    // @ts-ignore
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={st.wrapper}>
            {/*<Typography variant="h4" sx={{ mb: 8 }}
                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "accountSet" }) }}
            /> */}

            <TabContext value={value}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs for account" className={st.tabs}>
                    <Tab label={intl.formatMessage({ id: 'account.account' })} value="account" />
                    <Tab label={intl.formatMessage({ id: 'account.notification' })} value="notification" />
                </Tabs>
                {/* <pre style={{color: "saddlebrown", }}>{JSON.stringify(accountForm.values, 0, 2)}</pre>
            <pre style={{color: "saddlebrown", }}>{JSON.stringify(form.values, 0, 2)}</pre> */}

                <div className={st.list}>
                    <TabPanel value="account">
                        <FormikProvider value={accountForm}>
                            <Box component="form" noValidate onSubmit={accountForm.handleSubmit}>
                                <div className={st.block}>
                                    <Typography variant="h4" sx={{ mb: 4 }}>
                                        {intl.formatMessage({ id: 'account.contact.information' })}
                                    </Typography>

                                    <Grid container spacing={4} sx={{ mb: 4 }}>
                                        <Grid item xs={12}>
                                            <Field
                                                name="email"
                                                children={(fieldProps) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        fullWidth
                                                        type="email"
                                                        label={intl.formatMessage({ id: 'email' })}
                                                        variant="outlined"
                                                        error={!accountForm.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={!accountForm.isValid && fieldProps.meta.error}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Button type="submit" variant="contained">
                                        {intl.formatMessage({ id: 'cabinet.security.password.submit' })}
                                    </Button>
                                </div>
                            </Box>
                        </FormikProvider>

                        <FormikProvider value={form}>
                            <Box component="form" noValidate onSubmit={form.handleSubmit}>
                                <div className={st.block}>
                                    <Typography variant="h4" sx={{ mb: 4 }}>
                                        {intl.formatMessage({ id: 'cabinet.security.password.title' })}
                                    </Typography>

                                    <Grid container spacing={4} sx={{ mb: 4 }}>
                                        <Grid item xs={12}>
                                            <Field
                                                name="currentPassword"
                                                type={showPassword ? 'text' : 'password'}
                                                children={(fieldProps) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        required
                                                        fullWidth
                                                        type={showPassword ? 'text' : 'password'}
                                                        variant="outlined"
                                                        label={intl.formatMessage({ id: 'account.password.current' })}
                                                        error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={!form.isValid && fieldProps.meta.error}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end" sx={{ tabIndex: '-1' }}>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                        className={st.button_eye}
                                                                        tabIndex={-1}
                                                                    >
                                                                        {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field
                                                type={showPassword ? 'text' : 'password'}
                                                name="newPassword"
                                                children={(fieldProps) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        required
                                                        fullWidth
                                                        type={showPassword ? 'text' : 'password'}
                                                        label={intl.formatMessage({ id: 'account.password.new' })}
                                                        variant="outlined"
                                                        error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={!form.isValid && fieldProps.meta.error}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end" sx={{ tabIndex: '-1' }}>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                        className={st.button_eye}
                                                                        tabIndex={-1}
                                                                    >
                                                                        {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field
                                                type={showPassword ? 'text' : 'password'}
                                                name="confirmNewPassword"
                                                children={(fieldProps) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        required
                                                        fullWidth
                                                        type={showPassword ? 'text' : 'password'}
                                                        label={intl.formatMessage({ id: 'account.password.repeat' })}
                                                        variant="outlined"
                                                        error={!form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={!form.isValid && fieldProps.meta.error}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end" sx={{ tabIndex: '-1' }}>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                        className={st.button_eye}
                                                                        tabIndex={-1}
                                                                    >
                                                                        {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Button type="submit" variant="contained">
                                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.security.password.submit' }) }} />
                                    </Button>
                                </div>
                                <div className={st.block}>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.security.delete.title' }) }}
                                    />

                                    <Typography
                                        variant="body1"
                                        sx={{ mb: 4 }}
                                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.security.delete.description' }) }}
                                    />

                                    <Button type="button" variant="contained" onClick={handleClickOpen}>
                                        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.security.delete.title' }) }} />
                                    </Button>
                                </div>
                            </Box>
                        </FormikProvider>

                        <Dialog TransitionComponent={Fade} open={open} onClose={handleClose} className={stylePopup.popup} scroll="body">
                            <div className={stylePopup.container}>
                                <IconButton className={stylePopup.close} onClick={handleClose}>
                                    <CrossIcon />
                                </IconButton>

                                <FormikProvider value={deleteAccountForm}>
                                    <Box component="form" noValidate onSubmit={deleteAccountForm.handleSubmit}>
                                        <div
                                            className={stylePopup.title}
                                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'cabinet.security.delete.title' }) }}
                                        />
                                        <div
                                            className={stylePopup.text}
                                            dangerouslySetInnerHTML={{
                                                __html: intl.formatMessage({ id: 'cabinet.security.delete.popup.description' }),
                                            }}
                                        />
                                        <div className={stylePopup.buttons}>
                                            <Button sx={{ width: '100%' }} variant="contained" color="error" type="submit">
                                                {intl.formatMessage({ id: 'cabinet.security.delete.title' })}
                                            </Button>
                                            <Button sx={{ width: '100%' }} variant="contained" color="primary" onClick={handleClose}>
                                                {intl.formatMessage({ id: 'cancel' })}
                                            </Button>
                                        </div>
                                    </Box>
                                </FormikProvider>
                            </div>
                        </Dialog>
                    </TabPanel>
                </div>
                <TabPanel value="notification">
                    <div className={st.block}>
                        <div className={st.notification}>
                            <Typography variant="h4" sx={{ mb: 4 }}>
                                {intl.formatMessage({ id: 'account.email.notification' })}
                            </Typography>
                            <FormControlLabel
                                label={<span>{intl.formatMessage({ id: 'account.notification.switcher1' })}</span>}
                                className={st.switcher}
                                control={<Switch name="accountEmailNotification" />}
                            />
                            <FormControlLabel
                                label={<span>{intl.formatMessage({ id: 'account.notification.switcher2' })}</span>}
                                className={st.switcher}
                                control={<Switch name="accountEmailNotification2" />}
                            />
                        </div>
                        <div className={st.notification}>
                            <Typography variant="h4" sx={{ mb: 4 }}>
                                {intl.formatMessage({ id: 'account.browser.notification' })}
                            </Typography>
                            <FormControlLabel
                                label={<span>{intl.formatMessage({ id: 'account.notification.switcher3' })}</span>}
                                className={st.switcher}
                                control={<Switch name="accountBrowserNotification" />}
                            />
                            <FormControlLabel
                                label={<span>{intl.formatMessage({ id: 'account.notification.switcher4' })}</span>}
                                className={st.switcher}
                                control={<Switch name="accountBrowserNotification2" />}
                            />
                        </div>
                    </div>
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default observer(AccountSettings);
