import App from './App';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CssBaseline, ThemeProvider } from '@mui/material';
import primaryMuiTheme from './assets/themes/primaryMuiTheme';
import ScrollToTop from './components/ScrollToTop';
import React from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ThemeProvider theme={primaryMuiTheme}>
        <Router>
            <CssBaseline />

            <ScrollToTop />

            <HelmetProvider>
                <App />
            </HelmetProvider>
        </Router>
    </ThemeProvider>
);
