import { Avatar, AvatarGroup, Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import st from './experts.module.scss';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import { ReactComponent as HelpIcon } from '../../assets/images/icons/help.svg';
import UIStore from 'src/store/UIStore';
import DataStore from 'src/store/DataStore';
import FiltersStore from 'src/store/FiltersStore';
import CardPreviewExpert from 'src/components/CardPreview/CardPreviewExpert';
import Filters from 'src/components/Filters/Filters';
import { NonAuthRoutes } from 'src/routes/routes';
import { Link } from 'react-router-dom';
import {Skeleton} from "../../components/Skeleton";

const Experts = () => {
    const intl = useIntl();
    const { isUserAuthorized } = DataStore;
    const { mobileIs, tabletIs, openPopup } = UIStore;
    const { expertsList, loading } = FiltersStore;
    const [sortBy, setSortBy] = React.useState('order[profile.firstName]');

    const handleChangeSortBy = (e) => {
        setSortBy(e.target.value);
    };

    return (
        <section className={st.wrapper}>
            <main className={st.content}>
                <Typography variant="h4" component="h1">
                    {intl.formatMessage({ id: 'experts.title' })}
                </Typography>

                <Filters isWrapper={tabletIs} type="experts" />

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h4" component="h2">
                            {intl.formatMessage({ id: 'experts.counts' }, { count: FiltersStore.totalCountExperts ? FiltersStore.totalCountExperts : '' })}
                        </Typography>
                    </Grid>

                    <Grid item xs="auto">
                        <Select
                            MenuProps={{ disableScrollLock: true }}
                            className={st.filters__sorting_by}
                            value={sortBy}
                            id="filters-sorting-by"
                            onChange={handleChangeSortBy}
                            defaultValue="order[profile.firstName]"
                            IconComponent={ArrowIcon}
                        >
                            <MenuItem
                                value="order[profile.popular]"
                                // onClick={() => ExpertsViewModel.setFilterOrSort('order[profile.popular]', 'ASC', 'experts')}
                            >
                                <span>{intl.formatMessage({ id: 'experts.sortOption.popular' })}</span>
                            </MenuItem>

                            <MenuItem
                                value="order[profile.firstName]"
                                // onClick={() => ExpertsViewModel.setFilterOrSort('order[profile.firstName]', 'ASC', 'experts')}
                            >
                                <span>{intl.formatMessage({ id: 'experts.sortOption.alphabet' })}</span>
                            </MenuItem>

                            <MenuItem
                                value="order[profile.firstName.reverse]"
                                // onClick={() => ExpertsViewModel.setFilterOrSort('order[profile.firstName]', 'DESC', 'experts')}
                            >
                                <span>{intl.formatMessage({ id: 'experts.sortOption.alphabetReverse' })}</span>
                            </MenuItem>

                            <MenuItem
                                value="order[hourCost]"
                                // onClick={() => ExpertsViewModel.setFilterOrSort('order[hourCost]', 'ASC', 'experts')}
                            >
                                <span>{intl.formatMessage({ id: 'experts.sortOption.price' })} &uarr;</span>
                            </MenuItem>

                            <MenuItem
                                value="order[hourCost.reverse]"
                                // onClick={() => ExpertsViewModel.setFilterOrSort('order[hourCost]', 'DESC', 'experts')}
                            >
                                <span>{intl.formatMessage({ id: 'experts.sortOption.price' })} &darr;</span>
                            </MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <div className={st.list}>
                    {loading ?
                        [...Array(6)].map((x, i) =>
                            <Skeleton key={`skeleton_${i}`} avatar />
                        )
                    :
                    expertsList.length ? (
                        expertsList?.map((item, index) => {
                            if (!isUserAuthorized && index >= 5) {
                                return false;
                            }

                            return <CardPreviewExpert key={item.id} data={item} />;
                        })
                    ) : (
                        <Typography textAlign="center" variant="h4">
                            {intl.formatMessage({ id: 'noResults' })}
                        </Typography>
                    )}
                </div>

                {!isUserAuthorized && (
                    <div className={st.sign_up}>
                        <div className={st.sign_up__title}>
                            <span>{intl.formatMessage({ id: 'experts.signup.title' })}</span>
                            <AvatarGroup spacing={16} max={3} className={st.sign_up__avatars}>
                                <Avatar alt="Remy Sharp" src="/images/experts/first.png" />
                                <Avatar alt="Travis Howard" src="/images/experts/second.png" />
                                <Avatar alt="Cindy Baker" src="/images/experts/third.png" />
                            </AvatarGroup>
                        </div>

                        <Button
                            variant="contained"
                            color="light"
                            size={mobileIs ? 'medium' : 'large'}
                            className={st.sign_up__btn}
                            onClick={openPopup}
                        >
                            {intl.formatMessage({ id: 'experts.join.button' })}
                        </Button>
                    </div>
                )}

                {tabletIs && (
                    <Link to={NonAuthRoutes.contacts} className={st.help}>
                        <HelpIcon className={st.help__icon} />
                        <Typography variant="h5" component="h4" className={st.help__title}>
                            {intl.formatMessage({ id: 'help.title' })}
                        </Typography>
                        <div className={st.help__text}>{intl.formatMessage({ id: 'help.contact' })}</div>
                    </Link>
                )}
            </main>
        </section>
    );
};

export default observer(Experts);
