import React from "react";
import st from './progressBar.module.scss';


export const ProgressBar = ({value}) => {
    return (
        <div className={st.progress_visual}>
            <div className={st.progress_bar}>
                <div className={st.progress_bar_static}></div>
                <div className={st.progress_bar_dynamic} style={{width: `${value}%`}}></div>
            </div>

            <div className={st.progress_percent}>{value}%</div>
        </div>
    );
};
