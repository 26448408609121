import React, {useEffect} from 'react';

import { observer } from 'mobx-react';
import {Link, Navigate, useParams} from 'react-router-dom';

import st from './faqId.module.scss';
import { AskQuestion } from '../../components/AskQuestion';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow-left.svg';
import { NonAuthRoutes } from '../../routes/routes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import {getData} from "../../api/api";
import DataStore from "../../store/DataStore";
import {useIntl} from "react-intl";

const FaqId = () => {
    const intl = useIntl();
    const { id } = useParams();
    const { categorizedQuestions, updateCategorizedQuestions } = DataStore;
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        let isMounted = true;

        if (!categorizedQuestions[id]?.length) {
            getData(`api/faqs?category=${id}`)
                .then(({data}) => {
                    if (isMounted) {
                        updateCategorizedQuestions(data.items, id);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }

        return () => {
            isMounted = false;
            updateCategorizedQuestions([]);
            setLoading(true);
        };
    }, [id, categorizedQuestions, updateCategorizedQuestions]);

    if (!loading && categorizedQuestions[id]?.length < 1) {
        return <Navigate to={NonAuthRoutes.error} />
    }

    return (
        <section className={st.questions}>
            <div className={st.questions__header}>
                <Link to={NonAuthRoutes.faq} className={st.back_to_topic}>
                    <ArrowIcon />
                    <span>{intl.formatMessage({id: 'backFAQ'})}</span>
                </Link>

                <div className={st.questions__title}>{categorizedQuestions[id] && categorizedQuestions[id][0].category.name}</div>
            </div>

            <div className={st.questions__list}>
                {categorizedQuestions[id]?.map((item, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<div className={st.questions__icon}><ExpandMoreIcon /></div>}
                            aria-controls={`panel-content-${index}`}
                            id={`panel-header-${index}`}
                        >
                            {item.title}
                        </AccordionSummary>

                        <AccordionDetails>
                            {item.answer}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>

            <AskQuestion />
        </section>
    );
};

export default observer(FaqId);
