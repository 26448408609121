import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import st from './LandingHeader.module.scss';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import { ReactComponent as ArrowBackIcon } from '../../assets/images/icons/arrow-left.svg';
import { useIntl } from 'react-intl';
import UIStore from 'src/store/UIStore';
import {NonAuthRoutes} from "../../routes/routes";
import {observer} from "mobx-react";

const MobileMenuItems = ({ items, depthLevel, findExpertBlock = '' }) => {
    const intl = useIntl();
    const { closeMobileMenu, closeInnerMenuIsOpen, openInnerMenuIsOpen } = UIStore;
    const [dropdown, setDropdown] = useState(false);

    depthLevel = depthLevel + 1;

    const toggleDropDown = () => {
        setDropdown((prev) => !prev);
        if (dropdown === false) {
            openInnerMenuIsOpen();
        } else if (depthLevel === 1) {
            closeInnerMenuIsOpen();
        }
    }

    return items.submenu ? (
        <li className={classNames(st.mobileMenu__item, findExpertBlock)}>
            <button
                type="button"
                aria-haspopup="menu"
                onClick={toggleDropDown}
                aria-expanded={dropdown ? 'true' : 'false'}
                className={classNames(st.mobileMenu__button, 'btn')}
            >
                {items.title}
                <ArrowIcon />
            </button>

            <ul className={classNames(st.mobileMenu__drop, st[`mobileMenu__drop_level${depthLevel}`], dropdown ? st.mobileMenu__drop_active : '')}>
                <div className={classNames(st.mobileMenu__back)}
                     onClick={toggleDropDown}>
                    <ArrowBackIcon />
                    {intl.formatMessage({ id: 'back' })}
                </div>

                {items.submenu.map((submenu, index) => (
                    <MobileMenuItems key={index} items={submenu} depthLevel={depthLevel} />
                ))}
            </ul>
        </li>
    ) : (
        <li className={st.mobileMenu__item}>
            <Link
                to={items.url ? items.url : (items?.skillCategory?.id ? `${NonAuthRoutes.experts}/?professionalSkills.skillCategory=${items.skillCategory.id}&professionalSkills.skillSubCategories[0]=${items.id}` : "#/")}
                onClick={closeMobileMenu}
            >
                {items.title}
            </Link>
        </li>
    );
};

export default observer(MobileMenuItems);
