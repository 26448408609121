import { Button } from '@mui/material';
import classNames from 'classnames';

import React from 'react';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import st from './PlansCard.module.scss';
import {useIntl} from "react-intl";

const PlansCard = ({
    color = 'light',
    icon = undefined,
    title,
    description = undefined,
    className = undefined,
    popular = undefined,
    price = undefined,
    pricePer = undefined,
    priceText = undefined,
    includesTitle = undefined,
    includes = undefined,
    handleClick = undefined,
}) => {
    const intl = useIntl();
    return (
        <div className={classNames(st.container, className)}>
            <div className={st.header}>
                {icon && (
                    <picture className={st.header__icon}>
                        <img loading="eager" src={icon} alt="" width="36px" height="36px" />
                    </picture>
                )}
                <div className={st.header__content}>
                    <h4 className={st.title}>{title}</h4>
                    {description && <p className={st.description}>{description}</p>}
                </div>
                {popular && <div className={st.popular}>{intl.formatMessage({id: 'experts.sortOption.popular'})}</div>}
            </div>
            <div className={st.price}>
                {price ? (
                    <>
                        <div className={st.price__number}>
                            <span>$</span>{price}
                        </div>
                        {pricePer && (
                            <div className={st.price__per} dangerouslySetInnerHTML={{__html: pricePer}} />
                        )}
                    </>
                ) : priceText && (
                    <div className={st.price__text} dangerouslySetInnerHTML={{__html: priceText}} />
                )}
            </div>
            <div className={st.includes}>
                <div className={st.includes__title}>{includesTitle}</div>
                <ul className={st.includes__list}>
                    {includes.map((item, index) => (
                        <li className={st.includes__item} key={index}>
                            <CheckIcon />
                            <span>{item}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <Button variant="contained"  size="large" fullWidth className={st.button} onClick={handleClick}>{intl.formatMessage({id: 'home.search.button'})}</Button>
        </div>
    );
};

export default PlansCard;
