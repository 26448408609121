import React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import st from './ErrorPage.module.scss';
import { NonAuthRoutes } from '../../routes/routes';
import { ReactComponent as Icon404 } from '../../assets/images/icons/404.svg';

const ErrorPage = () => {
    const intl = useIntl();

    return (
        <section className={st.error_section}>
            <div className={st.error}>
                <Typography variant="pretitle2" className={st.error__pretitle}>
                    {intl.formatMessage({ id: 'app.errorPage.pretitle' })}
                </Typography>
                <Typography variant="h1" className={st.error__title}>
                    {intl.formatMessage({ id: 'app.errorPage.title' })}
                </Typography>
                <Typography variant="body1" className={st.error__text}>
                    {intl.formatMessage({ id: 'app.errorPage.text' })}
                </Typography>

                <Link to={NonAuthRoutes.home} className={st.error__button}>
                    <Button size="large" color="primary" variant="contained" fullWidth>
                        {intl.formatMessage({ id: 'app.errorPage.button' })}
                    </Button>
                </Link>

                <Icon404 className={st.error__picture} />
            </div>
        </section>
    );
};

export default observer(ErrorPage);
