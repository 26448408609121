import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import PersonCard from '../../components/PersonCard';
import SimpleCard from '../../components/SimpleCard';
import ExpertCard from '../../components/ExpertCard';
import ReviewsCard from '../../components/ReviewsCard';
import CheckIcon from '../../components/Icons/CheckIcon';
//import { Popup } from '../../components/Popup';

import UIStore from '../../store/UIStore';
import DataStore from '../../store/DataStore';
import { NonAuthRoutes } from '../../routes/routes';
import CountUp from 'react-countup';
//import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/pagination';
import { ReactComponent as ScrollIcon } from '../../assets/images/icons/arrow_down_scroll.svg';
import st from './home.module.scss';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import FaqPopular from '../../components/FaqPopular';
import { useIntl } from 'react-intl';
import FiltersStore from 'src/store/FiltersStore';
import {getData} from "../../api/api";

export const Home = observer(() => {
    const intl = useIntl();
    const { isUserAuthorized } = DataStore;
    const { mobileIs, localeUrl, tabletIs } = UIStore;
    const { expertsList, getExpertList, totalCountExperts } = FiltersStore;
    const aboutSection = React.useRef(null);
    const [totalClients, setTotalClients] = useState(0);
    const [totalWorks, setTotalWorks] = useState(0);
    const [totalZoomDuration, setTotalZoomDuration] = useState(0);

    React.useEffect(() => {
        getExpertList();

        getData('api/clients?active=1')
            .then(({data}) => {
                setTotalClients(prev => prev + Number(data.pagination?.totalItems));
            })
            .catch((error) => console.log(error));

        getData('api/companies?active=1')
            .then(({data}) => {
                setTotalClients(prev => prev + Number(data.pagination?.totalItems));
            })
            .catch((error) => console.log(error));

        getData('api/works')
            .then(({data}) => {
                setTotalWorks(Number(data.pagination?.totalItems));
            })
            .catch((error) => console.log(error));

        getData('zoom/duration')
            .then(({data}) => {
                setTotalZoomDuration(Number(data));
            })
            .catch((error) => console.log(error));

    }, [getExpertList]);

    const handleScroll = () => {
        window.scrollTo({
            top: aboutSection.current.getBoundingClientRect().top,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <section className={st.hero_inner}>
                <div className={st.hero}>
                    <div className={st.search_block}>
                        <div className={st.search_block__label}>{intl.formatMessage({ id: 'home.search.label' })}</div>
                        <div
                            className={st.search_block__title}
                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.search.title' }) }}
                        ></div>
                        <div className={st.search_block__text}>{intl.formatMessage({ id: 'home.search.text' })}</div>

                        <Link to={localeUrl(NonAuthRoutes.signin)} className={st.search_block__link}>
                            <Button className={st.search_block__button} fullWidth variant="contained" size="large" color="primary">
                                {intl.formatMessage({ id: 'home.search.button' })}
                            </Button>
                        </Link>
                        {/*	                        <Form
                            onSubmit={(values)=>{console.log(values)}}
                            initialValues={"activity"}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} className={st.search_block__form}>
                                    <Field
                                        className={st.search_block__field}
                                        name="firstItem"
                                    >
                                        {({value, name, onSelect}) => (
                                            <CustomSelect
                                                className={st.search_block__select}
                                                alignIcon='right'
                                                value='You are'
                                                faceicon='user'
                                                list={[
                                                    {
                                                        name: 'Field service Engineer',
                                                        value: 'Field service Engineer'
                                                    },
                                                    {
                                                        name: 'System integrator',
                                                        value: 'System Integrator'
                                                    },
                                                    {
                                                        name: 'End User (Factory, EPC..)',
                                                        value: 'End User (Factory, EPC..)'
                                                    }
                                                ]}
                                            />
                                        )}
                                    </Field>

                                    <Field
                                        className={st.search_block__field}
                                        name="secondItem"
                                    >
                                        {({value, name, onSelect}) => (
                                            <CustomSelect
                                                className={st.search_block__select}
                                                alignIcon='right'
                                                value={"looking for (service)"}
                                                faceicon='star_empty'
                                                list={[
                                                    {
                                                        name: 'Remote Engineering',
                                                        value: 'Remote Engineering'
                                                    },
                                                    {
                                                        name: 'Remote Support',
                                                        value: 'Remote Support'
                                                    },
                                                    {
                                                        name: 'Remote Maintenance/Troubleshooting',
                                                        value: 'Remote Maintenance/Troubleshooting'
                                                    },
                                                    {
                                                        name: 'Remote Training',
                                                        value: 'Remote Training'
                                                    }
                                                ]}
                                            />
                                        )}
                                    </Field>

                                    <Field
                                        className={st.search_block__field}
                                        name="thirdItem"
                                    >
                                        {({value, name, onSelect}) => (
                                            <CustomSelect
                                                className={st.search_block__select}
                                                alignIcon='right'
                                                value="Expert"
                                                faceicon='search'
                                                list={[
                                                    {
                                                        name: 'Engineering Design Expert',
                                                        value: 'Engineering Design Expert'
                                                    },
                                                    {
                                                        name: 'Instructor/Mentor',
                                                        value: 'Instructor/Mentor'
                                                    },
                                                    {
                                                        name: 'Field Engineering Expert',
                                                        value: 'Field Engineering Expert'
                                                    }
                                                ]}
                                            />
                                        )}

                                    </Field>

                                    <Button
                                        className={st.search_block__btn}
                                        variant="contained" color='primary'
                                        fullWidth='100%'
                                    >
                                        Search
                                    </Button>
                                </form>
                            )}
                        />*/}
                    </div>

                    <div className={st.featured}>
                        {/*<button className={classNames('btn', st.featured__play)} onClick={openPopup}>
                            <PlayArrowRoundedIcon />
                        </button>*/}

                        <video
                            className={mobileIs ? st.featured_video_mob : st.featured_video}
                            autoPlay={true}
                            muted
                            playsInline
                            loop
                            disablePictureInPicture
                            preload="1"
                            poster="/video/poster.png"
                        >
                            <source src={axios.defaults.baseURL + 'media/62fc6e3ec9fdc_video.mov'} type="video/mp4" />
                        </video>

                        {/*<Popup className={st.videoPopup}>
                            <video className={st.featured_video} controls poster="/video/poster.png">
                                <source src={axios.defaults.baseURL + "media/62fc6e3ec9fdc_video.mov"} type="video/mp4" />
                            </video>
                        </Popup>*/}
                    </div>

                    <div className={st.scroll_down}>
                        <button className={classNames('btn', st.scroll_down__icon)} onClick={handleScroll}>
                            <ScrollIcon />
                        </button>

                        <div className={st.scroll_down__text}>
                            <span>{intl.formatMessage({ id: 'home.scroll.down' })}</span>
                            <p>{intl.formatMessage({ id: 'home.scroll.text' })}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section ref={aboutSection} className={st.about}>
                <div className={st.about__wrapper}>
                    <div className={st.about__text}>
                        <div className={st.about__subtitle}>{intl.formatMessage({ id: 'about' })}</div>
                        <h2 className={st.about__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.about.title' }) }}></h2>
                        <p className={st.about__desc}>{intl.formatMessage({ id: 'home.about.desc' })} </p>
                        <div className={st.about__privileges}>
                            <div className={st.about__privilege}>
                                <CheckIcon />
                                <div className={st.about__priv_text}>{intl.formatMessage({ id: 'home.about.priv-1' })}</div>
                            </div>
                            <div className={st.about__privilege}>
                                <CheckIcon />
                                <div className={st.about__priv_text}>{intl.formatMessage({ id: 'home.about.priv-2' })}</div>
                            </div>
                        </div>
                        <div className={st.about__buttons}>
                            <Button component={Link} to={localeUrl(NonAuthRoutes.signup)} className={st.about__btn} fullWidth variant="contained"
                                    color="primary" size="large">
                                {intl.formatMessage({id: 'footer.works'})}
                            </Button>

                            <Button className={st.about__btn} fullWidth variant="outlined" color="primary" size="large">
                                {intl.formatMessage({id: 'home.about.btn'})}
                            </Button>
                        </div>
                    </div>

                    <div className={st.about__achievements}>
                        <div className={st.about__row}>
                            <div className={st.about__achievement}>
                                <div className={st.about__circle}>
                                    <CountUp decimal="." separator="," duration={1.5} enableScrollSpy scrollSpyDelay={2} end={totalCountExperts} />
                                </div>
                                <div className={st.about__label}>{intl.formatMessage({ id: 'home.about.label-1' })}</div>
                            </div>

                            <div className={st.about__achievement}>
                                <div className={st.about__circle}>
                                    <CountUp decimal="." separator="," duration={1.5} enableScrollSpy scrollSpyDelay={2} end={totalClients} />
                                </div>
                                <div className={st.about__label}>{intl.formatMessage({ id: 'home.about.label-2' })}</div>
                            </div>
                        </div>

                        <div className={st.about__row}>
                            <div className={st.about__achievement}>
                                <div className={st.about__circle}>
                                    <CountUp decimal="." separator="," duration={1.5} enableScrollSpy scrollSpyDelay={2} end={totalWorks} />
                                </div>
                                <div className={st.about__label}>{intl.formatMessage({ id: 'home.about.label-3' })}</div>
                            </div>

                            <div className={st.about__achievement}>
                                <div className={st.about__circle}>
                                    <CountUp decimal="." separator="," duration={1.5} enableScrollSpy scrollSpyDelay={2} end={totalZoomDuration} />
                                </div>
                                <div className={st.about__label}>{intl.formatMessage({ id: 'home.about.label-4' })}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={st.features}>
                <div className={st.features__header}>
                    <span className={st.features__subtitle}>{intl.formatMessage({ id: 'features.subtitle' })}</span>
                    <h2 className={st.features__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.features.title' }) }}></h2>
                    <div className={st.features__cards}>
                        <Swiper
                            spaceBetween={16}
                            slidesPerView={1}
                            modules={[Pagination]}
                            pagination={{
                                el: '.features-pagination',
                                clickable: true,
                            }}
                            breakpoints={{
                                668: {
                                    slidesPerView: 2,
                                },
                                1040: {
                                    spaceBetween: 32,
                                    slidesPerView: 4,
                                    pagination: false,
                                },
                            }}
                            loop={true}
                            className={st.features__cards_slider}
                        >
                            <SwiperSlide className={st.features__card_slide}>
                                <SimpleCard
                                    className={st.features__card}
                                    icon="/images/features/browse.svg"
                                    title={intl.formatMessage({ id: 'home.features-1.title' })}
                                    description={intl.formatMessage({ id: 'home.features-1.desc' })}
                                />
                            </SwiperSlide>
                            <SwiperSlide className={st.features__card_slide}>
                                <SimpleCard
                                    className={st.features__card}
                                    icon="/images/features/mentorship.svg"
                                    title={intl.formatMessage({ id: 'home.features-2.title' })}
                                    description={intl.formatMessage({ id: 'home.features-2.desc' })}
                                />
                            </SwiperSlide>
                            <SwiperSlide className={st.features__card_slide}>
                                <SimpleCard
                                    className={st.features__card}
                                    icon="/images/features/pay.svg"
                                    title={intl.formatMessage({ id: 'home.features-3.title' })}
                                    description={intl.formatMessage({ id: 'home.features-3.desc' })}
                                />
                            </SwiperSlide>
                            <SwiperSlide className={st.features__card_slide}>
                                <SimpleCard
                                    className={st.features__card}
                                    icon="/images/features/chat.svg"
                                    title={intl.formatMessage({ id: 'home.features-4.title' })}
                                    description={intl.formatMessage({ id: 'home.features-4.desc' })}
                                />
                            </SwiperSlide>
                        </Swiper>
                        <div className={classNames('features-pagination', 'swiper-pagination', st.features__pagination)} />
                    </div>
                </div>
            </section>

            <section className={st.experts}>
                <div className={st.experts__container}>
                    <div className={st.experts__header}>
                        <span className={st.experts__subtitle}>{intl.formatMessage({ id: 'home.experts.subtitle' })}</span>
                        <h2 className={st.experts__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.experts.title' }) }}></h2>
                        <p className={st.experts__desc}>{intl.formatMessage({ id: 'home.experts.desc' })} </p>
                    </div>
                </div>

                {expertsList.length && (
                <div className={st.experts__slider}>
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={1}
                        loop={true}
                        className={`expert_swiper ${st.experts__swiper}`}
                        modules={[Pagination]}
                        pagination={{
                            el: '.experts-pagination',
                            clickable: true,
                        }}
                        autoHeight={true}
                        breakpoints={{
                            668: {
                                slidesPerView: 2,
                                autoHeight: false,
                            },
                            1040: {
                                slidesPerView: 4,
                                centeredSlides: true,
                                autoHeight: false,
                                pagination: false,
                            },
                        }}
                    >
                        {expertsList.map((item, index) => {
                            // if (item.reviewsCount < 1) {
                            //     return false;
                            // }

                            if (index >= 8 || !item.profile) {
                                return false;
                            }

                            return (
                                <SwiperSlide className={st.experts__slide} key={index}>
                                    <ExpertCard data={item} />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div className={classNames('experts-pagination', 'swiper-pagination', st.experts__pagination)} />
                </div>
                )}
                <div className={st.experts__footer}>
                    {!isUserAuthorized ? (
                        <>
                            <div className={st.experts__footer_text}>
                                <img src="/images/icons/fire.png" width="24px" height="24px" alt="fire_icon" />{' '}
                                {intl.formatMessage({ id: 'home.experts.join' })}
                            </div>

                            <Link to={`${localeUrl(NonAuthRoutes.signup)}?type=expert`} className={st.experts__footer_link}>
                                <Button variant="contained" className={st.experts__btn} size="large">
                                    {intl.formatMessage({ id: 'home.experts.become' })}
                                </Button>
                            </Link>
                        </>
                    ) : (
                        <Link to={localeUrl(NonAuthRoutes.experts)} className={st.experts__footer_link}>
                            <Button variant="contained" className={st.experts__btn} size="large">
                                {intl.formatMessage({ id: 'home.experts.find' })}
                            </Button>
                        </Link>
                    )}
                </div>
            </section>

            <section className={st.questions}>
                <div className={st.questions__header}>
                    <span className={st.questions__subtitle}>{intl.formatMessage({ id: 'faq' })}</span>

                    <h2
                        className={st.questions__title}
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'frequentlyAsked' }, { variable: '<span>Questions</span>' }) }}
                    ></h2>
                </div>

                <FaqPopular notLoading={true} />

                <div className={st.questions__footer}>
                    <Link to={localeUrl(NonAuthRoutes.faq)}>
                        <Button variant="contained" className={st.questions__all} size="large">
                            {intl.formatMessage({ id: 'view.all' })}
                        </Button>
                    </Link>
                </div>
            </section>

            <section className={st.partners}>
                <div className={st.partners__wrapper}>
                    <span className={st.partners__subtitle}>{intl.formatMessage({ id: 'home.partners.subtitle' })}</span>
                    <h2 className={st.partners__title} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'home.experts.title' }) }}></h2>
                    <div className={st.partners__cards}>
                        <Swiper
                            spaceBetween={16}
                            slidesPerView={mobileIs ? 1 : tabletIs ? 2 : 4}
                            modules={[Pagination]}
                            pagination={
                                tabletIs
                                    ? {
                                          el: '.partners-pagination',
                                          clickable: true,
                                      }
                                    : false
                            }
                            loop={true}
                            className={st.partners__cards_slider}
                        >
                            <SwiperSlide className={st.partners__slide}>
                                <PersonCard
                                    className={st.partners__card}
                                    icon="/images/partners/andre.jpg"
                                    title="Andre Convets"
                                    description="Serial innovator at Procter & Gamble and European associations"
                                />
                            </SwiperSlide>
                            <SwiperSlide className={st.partners__slide}>
                                <PersonCard
                                    className={st.partners__card}
                                    icon="/images/partners/phillip.jpg"
                                    title="Phillip Jaeken"
                                    description="Managing Director DXC Technology Belgium and Industry"
                                />
                            </SwiperSlide>
                            <SwiperSlide className={st.partners__slide}>
                                <PersonCard
                                    className={st.partners__card}
                                    icon="/images/partners/christian.jpg"
                                    title="Christian Coen"
                                    description="Co-owner Creative Whereabouts Concept Booth"
                                />
                            </SwiperSlide>
                            <SwiperSlide className={st.partners__slide}>
                                <PersonCard
                                    className={st.partners__card}
                                    icon="/images/partners/peter.jpg"
                                    title="Peter Scholtz"
                                    description="General Manager of Smart Voice Bots"
                                />
                            </SwiperSlide>
                        </Swiper>
                        <div className={classNames('partners-pagination', 'swiper-pagination', st.partners__pagination)} />
                    </div>
                </div>
            </section>

            <section className={st.reviews}>
                <div className={st.reviews__container}>
                    <Swiper
                        slidesPerView={1}
                        modules={[Pagination]}
                        pagination={{
                            el: '.reviews_pagination',
                            clickable: true,
                        }}
                    >
                        <SwiperSlide>
                            <ReviewsCard
                                image={'/images/maneger.jpg'}
                                name="Alexandr Konstantinov"
                                position="Engineer"
                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi met, consectetu incididunt ut labore"
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <ReviewsCard
                                image={'/images/maneger.jpg'}
                                name="Maxim Galkin"
                                position="Programmer"
                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi met, consectetu incididunt ut labore"
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <ReviewsCard
                                image={'/images/maneger.jpg'}
                                name="Filip Kirkorov"
                                position="Rtompevez"
                                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi met, consectetu incididunt ut labore"
                            />
                        </SwiperSlide>
                    </Swiper>
                    <div className={`reviews_pagination ${st.swiper_pagination}`} />
                </div>

                {!isUserAuthorized && (
                    <div className={st.find_expert}>
                        <div className={st.find_expert__title}>{intl.formatMessage({ id: 'home.find.title' })}</div>
                        <div className={st.find_expert__desc}>{intl.formatMessage({ id: 'home.find.desc' })}</div>
                        <Link to={`${localeUrl(NonAuthRoutes.signup)}?type=client`}>
                            <Button variant="contained" color="light" size={mobileIs ? 'medium' : 'large'} className={st.find_expert__btn}>
                                {intl.formatMessage({ id: 'experts.join.button' })}
                            </Button>
                        </Link>
                    </div>
                )}
            </section>
        </>
    );
});
