// @ts-nocheck
import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';

import { observer } from 'mobx-react';
import { Link, Navigate, useParams } from 'react-router-dom';
import axios from 'axios';

import st from './newsId.module.scss';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow-left.svg';
import { ReactComponent as ArrowNav } from '../../assets/images/icons/arrow-nav.svg';
import { ReactComponent as FacebookLogo} from '../../assets/images/icons/social_fb.svg';
import { ReactComponent as InLogo} from '../../assets/images/icons/social_in.svg';
import { ReactComponent as TwitterLogo} from '../../assets/images/icons/social_twitter.svg';
import { NonAuthRoutes } from '../../routes/routes';
import { Skeleton, Grid } from '@mui/material';
import { NewsPreview } from '../../components/NewsPreview';
import { getData } from "../../api/api";
import UIStore from "../../store/UIStore";
import DataStore from "../../store/DataStore";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import classNames from "classnames";
import { toJS } from 'mobx';


const NewsId = () => {
    const intl = useIntl();
    const newsId = useParams().id;
    const {mobileIs} = UIStore;
    const {newsByYears, otherNews, updateOtherNews, lastNews} = DataStore;
    const [activeNews, setActiveNews] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const pageURL = document.location.href;

    useEffect(() => {
        let isMounted = true;

        console.log(toJS(lastNews));
        let currentNews = lastNews.find(item => item.id === newsId);

        if (currentNews === undefined) {
            let allNews = [];
            for (let key in newsByYears) {
                allNews.push(...newsByYears[key])
            }
            currentNews = allNews.find(item => item.id === newsId);
        }

        if (currentNews) {
            setActiveNews(currentNews);
            setLoading(false);
        } else {
            getData(`api/news/${newsId}`)
                .then(({data}) => {
                    if (isMounted) {
                        setActiveNews(data);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }

        getData(`api/news/${newsId}/other-news`)
            .then(({data}) => {
                if (isMounted) {
                    updateOtherNews(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        return () => {
            isMounted = false;
            setActiveNews(undefined);
            setLoading(true);
        };
    }, [newsId]); // eslint-disable-line react-hooks/exhaustive-deps

    function formatHumanDate(date) {
        const day = intl.formatDate(new Date(date), { day: 'numeric' });
        const month = intl.formatDate(new Date(date), { month: 'long' });
        const year = intl.formatDate(new Date(date), { year: 'numeric' });

        return `${month} ${day}, ${year}`;
    }

    if (!loading && !activeNews) {
        return <Navigate to={NonAuthRoutes.error} />
    }

    return (
        <div className={st.news_id}>
            <div className={st.hero_container}>
                <div className={st.back_to_topic}>
                    {loading ? (
                        <Skeleton variant="text" height={15} sx={{ mt: 1, mr: 3, bgcolor: 'grey.300' }} />
                    ) : (
                        <Link to={NonAuthRoutes.blog}>
                            <ArrowIcon />
                            <span>{intl.formatMessage({id: 'backToBlog'})}</span>
                        </Link>
                    )}
                </div>
            </div>

            {loading ? (
                <section className={st.main}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={1}>
                                <Grid item xs="auto">
                                    <Skeleton variant="circular" width={60} height={60} sx={{ bgcolor: 'grey.300' }} />
                                </Grid>
                                <Grid item xs>
                                    <Skeleton variant="text" width="100%" height={60} sx={{ bgcolor: 'grey.300' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton variant="rectangular" width="100%" height={150} sx={{ bgcolor: 'grey.300' }} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Skeleton variant="rectangular" width="100%" height={250} sx={{ bgcolor: 'grey.300' }} />
                        </Grid>
                    </Grid>
                </section>
            ) : (
                <>
                    <section className={st.main}>
                        <div className={st.article}>
                            <div className={st.title}>{activeNews?.title}</div>

                            {!activeNews?.picture?.contentUrl ? (
                                <Skeleton variant="rectangular" sx={{ bgColor: 'grey.300' }} />
                            ) :
                                <img src={axios.defaults.baseURL + activeNews.picture.contentUrl} alt={activeNews.title} />
                            }
                            <div className={st.content} dangerouslySetInnerHTML={{ __html: activeNews.content }}></div>
                        </div>

                        <div className={st.aside}>
                            <div className={st.date}>
                                <span className={st.label}>{intl.formatMessage({id: 'date'})}</span>
                                {formatHumanDate(activeNews?.createdAt)}
                            </div>

{/*                            <div className={st.author}>
                                <span className={st.label}>{intl.formatMessage({id: 'author'})}</span>
                                <div className={st.author_block}>
                                    <div className={st.author_photo}>
                                        <img src='/images/expert.jpg' alt='' />
                                    </div>
                                    <div className={st.author_name}>
                                        Alexandr Konstantinov
                                        <span>Engineer</span>
                                    </div>
                                </div>
                            </div>*/}

                            {activeNews.categories?.length > 0 &&
                                <div className={st.tags}>
                                    <span className={st.label}>{intl.formatMessage({id: 'tags'})}</span>
                                    <div className={st.tags_block}>
                                        {activeNews.categories.map(item =>
                                            <Link key={item.id} className={st.tag} to={NonAuthRoutes.blog + `?categories=${item.id}`}>{item.title}</Link>
                                        )}
                                    </div>
                                </div>
                            }

                            <div className={st.social}>
                                <span className={st.label}>{intl.formatMessage({id: 'share'})}</span>
                                <div className={st.social_icons}>
                                    <a
                                        target={`_blank`}
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${pageURL}&t=${activeNews.title}`}
                                        className={st.social_icon}>
                                        <FacebookLogo />
                                    </a>
                                    <a
                                        target={`_blank`}
                                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${pageURL}`}
                                        className={st.social_icon}>
                                        <InLogo />
                                    </a>
                                    <a
                                        target={`_blank`}
                                        href={`https://www.twitter.com/intent/tweet?url=${pageURL}&via=TWITTER_HANDLE_HERE&text=${activeNews.title}`}
                                        className={st.social_icon}>
                                        <TwitterLogo />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className={st.border}>
                        <div></div>
                    </div>

                    {otherNews.length > 0 &&
                        <section className={st.other_news}>
                            <div className={st.other_news__container}>
                                <div className={st.other_news__head}>
                                    <div className={st.other_news__header}>
                                        {intl.formatMessage({ id: 'otherArticles' })}
                                    </div>
                                    <div className={st.navigation}>
                                        <button className="other-news-prev">
                                            <ArrowNav />
                                        </button>
                                        <button className={classNames("other-news-next", st.next)}>
                                            <ArrowNav />
                                        </button>
                                    </div>

                                </div>

                                <div className={st.other_news__main}>
                                    <Swiper
                                        spaceBetween={16}
                                        slidesPerView={1}
                                        modules={[Navigation]}
                                        navigation={{
                                            prevEl: '.other-news-prev',
                                            nextEl: '.other-news-next',
                                        }}
                                        breakpoints={{
                                            668: {
                                                slidesPerView: 2,
                                            },
                                            1040: {
                                                spaceBetween: 32,
                                                slidesPerView: 4,
                                            },
                                        }}
                                        loop={(otherNews.length > 4 || (otherNews.length > 1 && mobileIs))}
                                    >
                                        {otherNews.map((post) => (
                                            <SwiperSlide key={post.id}>
                                                <NewsPreview
                                                    id={post.id}
                                                    date={formatHumanDate(post.createdAt)}
                                                    title={post.title}
                                                    picture={post.picture}
                                                    tags={post.tags}
                                                    isOtherNews
                                                >
                                                </NewsPreview>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </section>
                    }
                </>
            )}
        </div>
    );
};

export default observer(NewsId);
