import { Avatar, AvatarGroup, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import st from './projects.module.scss';
import UIStore from 'src/store/UIStore';
import DataStore from 'src/store/DataStore';
import FiltersStore from 'src/store/FiltersStore';
import Filters from 'src/components/Filters/Filters';
import CardPreviewProject from 'src/components/CardPreview/CardPreviewProject';
import { ReactComponent as HelpIcon } from '../../assets/images/icons/help.svg';
import { NonAuthRoutes } from 'src/routes/routes';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {Skeleton} from "../../components/Skeleton";

const Projects = () => {
    const intl = useIntl();
    const { isUserAuthorized, userType, user, userID } = DataStore;
    const { mobileIs, tabletIs, openPopup } = UIStore;
    const { projectsList, loading } = FiltersStore;

    return (
        <section className={st.wrapper}>
            {isUserAuthorized && userType === 'expert' && userID && !user?.active && (
                <div className={classNames(st.sign_up, st.sign_up_fill)}>
                    <div className={st.sign_up__title}>
                        <span>{intl.formatMessage({ id: 'projects.activate.title' })}</span>
                    </div>

                    <Link to={`/experts/${userID}/edit`}>
                        <Button variant="contained" color="light" size={mobileIs ? 'medium' : 'large'} className={st.sign_up__btn}>
                            {intl.formatMessage({ id: 'projects.activate.button' })}
                        </Button>
                    </Link>
                </div>
            )}

            <Typography variant="h4" component="h1">
                {intl.formatMessage({ id: 'projects.title' })}
            </Typography>

            <Filters type='projects' />

            <Typography variant="h4" component="h2">
                {intl.formatMessage({ id: 'projects.counts' }, { count: FiltersStore.totalCountProjects ? FiltersStore.totalCountProjects : '' })}
            </Typography>

            <div className={st.list}>
                {loading ? (
                    [...Array(6)].map((x, i) =>
                        <Skeleton key={`skeleton_${i}`} />
                    )
                ) : projectsList.length ? (
                    projectsList?.map((item, index) => {
                        if (!isUserAuthorized && index >= 5) {
                            return false;
                        }

                        // return <Link to={`/projects/${item.id}/edit`} key={item.id}>{item.title}</Link>
                        return <CardPreviewProject key={item.id} data={item} type="commonList" />;
                    })
                ) : (
                    <Typography textAlign="center" variant="h4">
                        {intl.formatMessage({ id: 'noResults' })}
                    </Typography>
                )}
            </div>

            {!isUserAuthorized && (
                <div className={st.sign_up}>
                    <div className={st.sign_up__title}>
                        <span>{intl.formatMessage({ id: 'projects.signup.title' })}</span>
                        <AvatarGroup spacing={16} max={3} className={st.sign_up__avatars}>
                            <Avatar alt="Remy Sharp" src="/images/experts/first.png" />
                            <Avatar alt="Travis Howard" src="/images/experts/second.png" />
                            <Avatar alt="Cindy Baker" src="/images/experts/third.png" />
                        </AvatarGroup>
                    </div>

                    <Button variant="contained" color="light" size={mobileIs ? 'medium' : 'large'} className={st.sign_up__btn} onClick={openPopup}>
                        {intl.formatMessage({ id: 'projects.join.button' })}
                    </Button>
                </div>
            )}

            {tabletIs && (
                <Link to={NonAuthRoutes.contacts} className={st.help}>
                    <HelpIcon className={st.help__icon} />
                    <Typography variant="h5" component="h4" className={st.help__title}>
                        {intl.formatMessage({ id: 'help.title' })}
                    </Typography>
                    <div className={st.help__text}>{intl.formatMessage({ id: 'help.contact' })}</div>
                </Link>
            )}
        </section>
    );
};

export default observer(Projects);
