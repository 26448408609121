import {Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Tab, Tabs, TextField} from '@mui/material';
import { observer } from 'mobx-react';
import React, {useState} from 'react';
import { useIntl } from 'react-intl';
import st from './balance.module.scss';
// import DataStore from 'src/store/DataStore';
import { TabContext, TabPanel } from '@mui/lab';
// import { toJS } from 'mobx';
import classNames from 'classnames';
import { ReactComponent as VisaIcon } from '../../assets/images/icons/visa.svg';
import { ReactComponent as EditSvg } from '../../assets/images/icons/edit.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/icons/download.svg';
import { ReactComponent as PaypalIcon } from '../../assets/images/icons/paypal.svg';
import { ReactComponent as ApplePayIcon } from '../../assets/images/icons/applepay.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus-circle.svg';
import UIStore from 'src/store/UIStore';
import DataStore from "../../store/DataStore";
import {LocalPopup} from "../../components/LocalPopup";
import {postData} from "../../api/api";

const Balance = () => {
    const intl = useIntl();
    const { tabletIs, setSuccessAlert, setErrorAlert, alertTimers } = UIStore;
    const { user, userID, updateUser } = DataStore;
    const [value, setValue] = useState('balance');
    const [expanded, setExpanded] = useState('panel1');
    const [isOpenPopupDeposit, setIsOpenPopupDeposit] = useState(false);
    const [deposit, setDeposit] = useState('');

    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeDeposit = (event) => {
        setDeposit(event.target.value);
    };

    const changeBalance = (amount, topUp = true) => {
        setSuccessAlert('');
        setErrorAlert('');

        if (amount <= 0) {
            setErrorAlert(intl.formatMessage({ id: 'greaterZero' }), alertTimers.long);
        } else {
            postData(`api/change-balance`, {
                amount: amount * 100,
                user: `api/users/${userID}`,
                topUp: topUp,
            })
                .then((response) => {
                    if (response.status === 201) {
                        console.log(response)
                        setSuccessAlert(intl.formatMessage({ id: (topUp ? 'successTopUpped' : 'successWithdraw') }), alertTimers.long);
                        updateUser({ ...user, balance: {available: response.data?.available, reserved: response.data?.reserved}});
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                    setErrorAlert(JSON.stringify(error));
                })
                .finally(() => {
                    setIsOpenPopupDeposit(false);
                });
        }
    }

    const handlePopupDeposit = () => {
        setIsOpenPopupDeposit(false);
    };

    return (
        <section className={st.wrapper}>
            <TabContext value={value}>
                <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label="tabs for balances"
                    className={st.tabs}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label={intl.formatMessage({ id: 'balance.balance' })} value="balance" />
                    <Tab label={intl.formatMessage({ id: 'balance.history' })} value="history" />
                    <Tab label={intl.formatMessage({ id: 'balance.method' })} value="method" />
                </Tabs>

                <div className={st.list}>
                    <TabPanel value="balance">
                        <div className={st.layout}>
                            <div className={st.layout__card}>
                                <div className={st.balance__cards}>
                                    <div className={classNames(st.balance__card, st.balance__card_color)}>
                                        <div className={st['balance__card-title']}>{intl.formatMessage({ id: 'balance.balance.card.balance' })}</div>
                                        <div className={st['balance__card-sum']}>${intl.formatNumber(user?.balance?.available ? user.balance.available/100 : 0)}</div>
                                    </div>

                                    <div className={st.balance__card}>
                                        <div className={st['balance__card-title']}>{intl.formatMessage({ id: 'balance.balance.card.total' })}</div>
                                        <div className={st['balance__card-sum']}>${intl.formatNumber(10000)}</div>
                                    </div>

                                    <Button className={st['balance__button']} color="primary" variant="outlined" onClick={() => setIsOpenPopupDeposit(true)}>
                                        {intl.formatMessage({ id: 'deposit' })}
                                    </Button>

                                    <Button className={st['balance__button']} color="primary" variant="contained">
                                        {intl.formatMessage({ id: 'balance.balance.withdraw' })}
                                    </Button>
                                </div>
                                <div className={st.balance__payment}>
                                    <div className={st['balance__payment-label']}>{intl.formatMessage({ id: 'balance.balance.payment.title' })}</div>
                                    <div className={st['balance__payment-card']}>
                                        <VisaIcon />
                                        <span>Visa **6627</span>

                                        <IconButton
                                            classes={{root: st['balance__payment-edit']}}
                                            aria-label="edit"
                                            onClick={() => handleChangeTabs('', 'method')}
                                        >
                                            <EditSvg />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="history">
                        <div className={st.history}>
                            {!tabletIs && (
                                <div className={st.history__head}>
                                    <div className={st.history__row}>
                                        <div className={st.history__col}>{intl.formatMessage({ id: 'balance.history.date' })}</div>
                                        <div className={st.history__col}>{intl.formatMessage({ id: 'balance.history.amount' })}</div>

                                        <div className={classNames(st.history__col, st['history__col-download'])}>
                                            {intl.formatMessage({ id: 'balance.history.download' })}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className={st.history__body}>
                                {!tabletIs ? (
                                    <div className={st.history__row}>
                                        <div className={st.history__col}>April, 10, 2021</div>
                                        <div className={st.history__col}>$100.00</div>

                                        <div className={classNames(st.history__col, st['history__col-download'])}>
                                            <IconButton aria-label="download" className={st.history__download}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={st.history__row}>
                                        <div className={st.history__col}>{intl.formatMessage({ id: 'balance.history.date' })}</div>
                                        <div className={st.history__col}>April, 10, 2021</div>
                                        <div className={st.history__col}>{intl.formatMessage({ id: 'balance.history.amount' })}</div>
                                        <div className={st.history__col}>$100.00</div>

                                        <div className={classNames(st.history__col, st['history__col-download'])}>
                                            {intl.formatMessage({ id: 'balance.history.download' })}
                                        </div>

                                        <div className={classNames(st.history__col, st['history__col-download'])}>
                                            <IconButton aria-label="download" className={st.history__download}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                                {!tabletIs ? (
                                    <div className={st.history__row}>
                                        <div className={st.history__col}>April, 10, 2021</div>
                                        <div className={st.history__col}>$100.00</div>

                                        <div className={classNames(st.history__col, st['history__col-download'])}>
                                            <IconButton aria-label="download" className={st.history__download}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={st.history__row}>
                                        <div className={st.history__col}>{intl.formatMessage({ id: 'balance.history.date' })}</div>
                                        <div className={st.history__col}>April, 10, 2021</div>
                                        <div className={st.history__col}>{intl.formatMessage({ id: 'balance.history.amount' })}</div>
                                        <div className={st.history__col}>$100.00</div>

                                        <div className={classNames(st.history__col, st['history__col-download'])}>
                                            {intl.formatMessage({ id: 'balance.history.download' })}
                                        </div>

                                        <div className={classNames(st.history__col, st['history__col-download'])}>
                                            <IconButton aria-label="download" className={st.history__download}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="method">
                        <div className={classNames(st.layout, st.layout_method)}>
                            <Accordion className={st.method__item} expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <div className={st['method__item-check']}>
                                        <CheckIcon />
                                    </div>
                                    {intl.formatMessage({ id: 'balance.method.banking' })}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Button fullWidth color="primary" variant="outlined" className={st['method__item-add']}>
                                        <PlusIcon />
                                        {intl.formatMessage({ id: 'balance.method.add' })}
                                    </Button>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={st.method__item} expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                    <div className={st['method__item-check']}>
                                        <CheckIcon />
                                    </div>
                                    <PaypalIcon />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Button fullWidth color="primary" variant="outlined" className={st['method__item-add']}>
                                        <PlusIcon />
                                        {intl.formatMessage({ id: 'balance.method.add' })}
                                    </Button>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={st.method__item} expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
                                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                    <div className={st['method__item-check']}>
                                        <CheckIcon />
                                    </div>
                                    <ApplePayIcon />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Button fullWidth color="primary" variant="outlined" className={st['method__item-add']}>
                                        <PlusIcon />
                                        {intl.formatMessage({ id: 'balance.method.add' })}
                                    </Button>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={st.method__item} expanded={expanded === 'panel4'} onChange={handleChangeAccordion('panel4')}>
                                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                    <div className={st['method__item-check']}>
                                        <CheckIcon />
                                    </div>
                                    {intl.formatMessage({ id: 'balance.method.wire' })}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Button fullWidth color="primary" variant="outlined" className={st['method__item-add']}>
                                        <PlusIcon />
                                        {intl.formatMessage({ id: 'balance.method.add' })}
                                    </Button>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </TabPanel>
                </div>
            </TabContext>

            <LocalPopup isOpen={isOpenPopupDeposit} close={handlePopupDeposit}>
                <div className="popup-title">{intl.formatMessage({ id: 'topUp' })}</div>

                <div>{intl.formatMessage({id: 'depositDesc'})}</div>

                <TextField
                    className="input-number"
                    fullWidth
                    label={intl.formatMessage({ id: 'amount' })}
                    type="number"
                    InputProps={{
                        inputProps: { min: 0 },
                    }}
                    onKeyDown={(e) => {
                        if (isNaN(e?.key) && (e?.key?.length === 1)) {
                            e.preventDefault();
                        }
                    }}
                    value={deposit}
                    onChange={handleChangeDeposit}
                />

                <div className='current-ballance'>{intl.formatMessage({id: 'amountOnBalance'})}: <strong>{user?.balance?.available ? user.balance.available/100 : 0}</strong></div>

                <div className="popup-buttons">
                    <Button variant="contained" color="primary" fullWidth size="large" onClick={() => changeBalance(Number(deposit))}>
                        {intl.formatMessage({ id: 'ok' })}
                    </Button>

                    <Button variant="outlined" color="primary" fullWidth size="large" onClick={() => setIsOpenPopupDeposit(false)}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                </div>
            </LocalPopup>
        </section>
    );
};

export default observer(Balance);
