import st from './projectChat.module.scss';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import stSession from '../../pages/Session/session.module.scss';
import { observer } from 'mobx-react';
import MessagesList from '../Chat/MessagesList/MessagesList';
import React from 'react';
import DataStore from '../../store/DataStore';
import { SessionUserCard } from 'src/pages/Session/Session';
import ChatStore from "../../store/ChatStore";

export const ProjectChat = observer(({ project = { workChats: [], workCost: {}, fixedPrice: false, duration: undefined } }) => {
    const intl = useIntl();
    const { user, userType } = DataStore;
    const { activeChat } = ChatStore;
    const [interlocutor, setInterlocutor] = React.useState({ online: false, profile: {}, positions: [] });

    React.useEffect(() => {
        if (userType !== 'expert') {
            setInterlocutor({ ...project?.workChats?.[0]?.executor, online: project?.workChats?.[0]?.executor.online });
        } else {
            setInterlocutor({ ...project?.workChats?.[0]?.workOwner, online: project?.workChats?.[0]?.workOwner.online });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={st.container}>
            <div className={stSession.toolbar}>
                <SessionUserCard data={interlocutor} />

                <SessionUserCard data={user} />
            </div>

            {project.workCost?.number && project.workCost?.number > 0 && (
                <div className={stSession.infobar}>
                    <div className={stSession.infobar__price}>
                        <div className={st['infobar__price-sum']}>
                            {project.workCost?.currency}&nbsp;
                            {project.fixedPrice ? (
                                project.workCost?.number
                            ) : (
                                <>
                                    {project.workCost?.number}&nbsp;
                                    {project.duration ? (
                                        <>
                                            {intl.formatMessage({ id: 'projects.card.inMin' }, { duration: project.duration })}{' '}
                                            <span>{intl.formatMessage({ id: 'projects.card.perMin' })}</span>
                                        </>
                                    ) : (
                                        <>/hr </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {/*                <div className={stSession.infobar__project}>
                    {intl.formatMessage({ id: 'session.project.link' })}
                    <a href={link} className={st['infobar__project-link']}>
                        {link}
                    </a>
                </div>*/}
                </div>
            )}

            <section className={classNames(stSession.chat, st.chat)}>
                {activeChat.id &&
                    <MessagesList />
                }
            </section>
        </div>
    );
});
