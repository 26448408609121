import React from 'react';
import {Link, Outlet, useLocation, useMatch} from 'react-router-dom';
import { observer } from 'mobx-react';
import st from './cabinetLayout.module.scss';
import LandingHeader from '../../components/LandingHeader';
import UserMenu from '../../components/UserMenu';
import classNames from 'classnames';
import { Alert } from '../../components/Alert';
import { ReactComponent as HelpIcon } from '../../assets/images/icons/help.svg';
import { Typography, Button } from '@mui/material';
import { NonAuthRoutes } from 'src/routes/routes';
import DataStore from 'src/store/DataStore';
import { Popup } from 'src/components/Popup';
import UIStore from 'src/store/UIStore';
import { useIntl } from 'react-intl';
import stPopup from 'src/components/Popup/popup.module.scss';


const CabinetLayout = ({ type = 'experts', withoutSidebar = undefined }) => {
    const routMatch = useMatch(NonAuthRoutes.projectsId);
    const routSearch = useLocation().search;
    const intl = useIntl();
    const { isUserAuthorized } = DataStore;
    const { openPopup, localeUrl, mobileMenuIsOpen, mobileMenuUserIsOpen, disabledMask } = UIStore;

    return (
        <div className={classNames((mobileMenuIsOpen || mobileMenuUserIsOpen) ? 'scroll-is-disabled' : (disabledMask ? 'disabled-mask' : ''))}>
            <LandingHeader pageType="dashboard" />

            <div className={st.container}>
                {!withoutSidebar && (routMatch === null || routSearch !== '?tab=2') && (
                    <aside className={classNames(st.sidebar, st.cabinet)}>
                        {!isUserAuthorized ? (
                            <div className={st.join}>
                                <Typography variant="h4" className={st.join__title}>
                                    {intl.formatMessage({ id: `${type}.join.title` })}
                                </Typography>
                                <Button color="primary" fullWidth variant="contained" onClick={openPopup}>
                                    {intl.formatMessage({ id: `${type}.join.button` })}
                                </Button>
                            </div>
                        ) : (
                            <UserMenu />
                        )}

                        <Link to={NonAuthRoutes.contacts} className={st.help}>
                            <HelpIcon className={st.help__icon} />
                            <Typography variant="h5" component="h4" className={st.help__title}>
                                {intl.formatMessage({ id: 'help.title' })}
                            </Typography>
                            <div className={st.help__text}>
                                {intl.formatMessage({ id: 'help.contact' })}
                            </div>
                        </Link>
                    </aside>
                )}

                <main className={classNames(st.content, withoutSidebar ? st.content_fullWidth : '')}>
                    <Alert />

                    <Outlet />
                </main>
            </div>

            <Popup>
                <h3 className={stPopup.title}>{intl.formatMessage({ id: 'projects.popup.title' })}</h3>

                <div className={stPopup.buttons}>
                    <Button component={Link} to={`${localeUrl(NonAuthRoutes.signin)}`} variant="outlined" color="primary" fullWidth size="large">
                        {intl.formatMessage({ id: 'projects.popup.signin' })}
                    </Button>

                    <Button component={Link} to={`${localeUrl(NonAuthRoutes.signup)}`} variant="contained" color="primary" fullWidth size="large">
                        {intl.formatMessage({ id: 'projects.popup.signup' })}
                    </Button>
                </div>
            </Popup>
        </div>
    );
};

export default observer(CabinetLayout);
