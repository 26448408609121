import { Avatar, Badge } from '@mui/material';
import React from 'react';
import st from './chatsList.module.scss';
// import { ReactComponent as MoreIcon } from '../../../assets/images/icons/more.svg';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { stringAvatar } from 'src/helpers/helpers';
import ChatStore from 'src/store/ChatStore';
import { observer } from 'mobx-react';
import DataStore from 'src/store/DataStore';

const ChatsList = ({ chat }) => {
    const intl = useIntl();
    const { userID, userType } = DataStore;
    const { setActiveChat, activeChat } = ChatStore;
    // const [open, setOpen] = React.useState(false);
    // const anchorRef = React.useRef(null);
    const [senderProfile, setSenderProfile] = React.useState({ online: null });

    React.useEffect(() => {
        if (userType !== 'expert' && chat.executor) {
            setSenderProfile({ ...chat.executor.profile, online: chat.executor.online });
        } else if (chat.workOwner) {
            setSenderProfile({ ...chat.workOwner.profile, online: chat.workOwner.online });
        }

        if (chat.workChat && userType !== 'expert') {
            setSenderProfile({ ...chat.executor.profile, online: chat.executor.online });
        } else if (chat.workChat && userType === 'expert') {
            setSenderProfile({ ...chat.workOwner.profile, online: chat.workOwner.online });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

/*    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };*/

    const handleClick = (e) => {
        if (e.target.localName !== 'button') {
            setActiveChat(chat);
        }
    };

/*    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event?.target)) {
            return;
        }

        setOpen(false);
    };*/

    return (
        <div
            className={classNames(st.button, 'btn', activeChat.channelName === chat.channelName ? st.button_active : '', chat.isNotify ? st.button_is_notify : '')}
            onClick={handleClick}
            id={chat.id}
        >
            <div className={st.media}>
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                    className={classNames('status', senderProfile.online ? 'status_online' : 'status_offline')}
                >
                    <Avatar
                        src={senderProfile.avatar?.contentUrl ? axios.defaults.baseURL + senderProfile.avatar.contentUrl : ''}
                        className={st.avatar}
                        sx={{
                            width: { xs: '48px', md: '56px' },
                            height: { xs: '48px', md: '56px' },
                        }}
                        {...stringAvatar(`${senderProfile?.firstName} ${senderProfile?.lastName}`)}
                    />
                </Badge>
            </div>

            <div className={st.content}>
                <div className={st.name}>
                    {chat.workChat
                        ? intl.formatMessage(
                              { id: 'messages.chat.workChat' },
                              { type: chat.assignedWork.type === 'projects' ? 'Project' : 'Session' }
                          )
                        : ''}
                    {`${senderProfile?.firstName} ${senderProfile?.lastName}`}
                </div>

                {chat.workChat ? (
                    <div className={st.text}>
                        <b>{chat.assignedWork.title}</b>
                    </div>
                ) : (
                    chat.lastMessage?.text && (
                        <div className={st.text}>
                            {chat.lastMessage?.senderProfile?.id === userID ? intl.formatMessage({ id: 'messages.chat.you' }) : ''}
                            {chat.lastMessage.text}
                        </div>
                    )
                )}

                <div className={st.date}>
                    {intl.formatDate(chat.lastMessage?.createdAt, {
                        month: 'short',
                        day: 'numeric',
                    })}
                </div>

                {/* пока нет инфы что эта штука должна делать */}
                {/* <div className={st.dots} onClick={handleToggle} ref={anchorRef}>
                    <IconButton className={st.dots__button}>
                        <MoreIcon height="16px" />
                    </IconButton>

                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end" transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} id="chatsList-menu" aria-labelledby="chatsList-button" className={st.popup}>
                                            <MenuItem>test text</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div> */}
            </div>
        </div>
    );
};

export default observer(ChatsList);
