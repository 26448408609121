import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/angle-bracket-prev.svg';
import { ReactComponent as RocketIcon } from '../../assets/images/icons/rocket.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/icons/clock.svg';
import { ReactComponent as AcceptedIcon } from '../../assets/images/icons/accepted.svg';
import stMessagesList from '../../components/Chat/MessagesList/messagesList.module.scss';
import st from './ProjectId.module.scss';
import { getData, postData } from '../../api/api';
import { useIntl } from 'react-intl';
import {Avatar, Button, Tab, Tabs, TextField, Typography} from '@mui/material';
import { NonAuthRoutes } from 'src/routes/routes';
import CardPreviewProject from '../../components/CardPreview/CardPreviewProject';
import CardPreviewExpert from 'src/components/CardPreview/CardPreviewExpert';
import DataStore from '../../store/DataStore';
import UIStore from '../../store/UIStore';
import Session from '../Session';
import { ConditionalWrapper } from '../../components/ConditionalWrapper';
import {Skeleton, TabContext, TabPanel} from '@mui/lab';
import ChatStore from '../../store/ChatStore';
import ReadyStore from "../../store/ReadyStore";
import classNames from 'classnames';
import { ProjectChat } from '../../components/ProjectChat';
import {LocalPopup} from "../../components/LocalPopup";
import {ReactComponent as StarIcon} from "../../assets/images/icons/review-star.svg";
import axios from "axios";
import {stringAvatar} from "../../helpers/helpers";


const ProjectId = () => {
    const intl = useIntl();
    const typeTab = Number(new URLSearchParams(useLocation().search).get('tab'));
    const navigate = useNavigate();
    let { id } = useParams();
    id = Number(id);
    const { activeChat } = ChatStore;
    const { userID, isUserAuthorized, isMyProject, userType, user, updateUser, refreshUser, refreshProject, } = DataStore;
    const { tabletIs, setSuccessAlert, alertTimers, setErrorAlert, updateDisabledMask, } = UIStore;
    const { setActiveChat, addChatToChatList } = ChatStore;
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
    const [tabValue, setTabValue] = useState(typeTab === 2 ? '2' : '1');
    const [isOpenPopupComplaint, setIsOpenPopupComplaint] = useState(false);
    const [isOpenPopupCancel, setIsOpenPopupCancel] = useState(false);
    const [isOpenPopupClose, setIsOpenPopupClose] = useState(false);
    const [isOpenPopupLeaveRevew, setIsOpenPopupLeaveRevew] = useState(false);
    const [leaveReviewMessage, setLeaveReviewMessage] = useState('');
    const [reviewStars, setReviewStars] = useState([0, 0, 0, 0, 0]);
    const [selectedStar, setSelectedStar] = useState(null);
    const [rateAlert, setRateAlert] = useState(false);
    const [stateProject, setStateProject] = useState(refreshProject);

    const handleLeaveReview = (value, over) => {
        let newReviewStars = [0, 0, 0, 0, 0];

        for (let i = 0; i < 5; i++) {
            if (selectedStar >= i) {
                newReviewStars[i] = 1;
            }
        }

        for (let i = 0; i < value; i++) {
            if (over || selectedStar === null || i > selectedStar) {
                newReviewStars[i] = over;
            }
        }

        setReviewStars(newReviewStars);
    };

    const handleSelectStar = (e, value) => {
        setSelectedStar(value);
        setRateAlert(false);

        const element = e.currentTarget;
        element.classList.add('star-selecting');

        let newReviewStars = [];
        for (let i = 0; i < 5; i++) {
            newReviewStars[i] = Number(value >= i);
        }

        setReviewStars(newReviewStars);

        setTimeout(() => {
            element.classList.remove('star-selecting');
        }, 150);
    }

    const handlePopupComplaint = () => {
        setIsOpenPopupComplaint(false);
    };

    const handlePopupLeaveReview = () => {
        setIsOpenPopupLeaveRevew(false);
    };

    const handleChangeLeaveReviewMessage = (event) => {
        setLeaveReviewMessage(event.target.value);
    };

    const handlePopupCancel = () => {
        setIsOpenPopupCancel(false);
    };

    const handlePopupClose = () => {
        setIsOpenPopupClose(false);
    };

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);

        if (newValue === '2') {
            navigate(`${NonAuthRoutes.projects}/${id}?tab=2`);
        } else {
            navigate(`${NonAuthRoutes.projects}/${id}?tab=1`);
        }
    };

    const isAdvancedStatus = (status) => {
        return ['ongoing', 'review', 'closed', 'revision', 'completed', 'complained',].includes(status);
    };

    const historyDate = (value) => {
        const date = intl.formatDate(value, {
            month: tabletIs ? 'short' : 'long',
            day: 'numeric',
            year: 'numeric',
        });

        const time = intl.formatDate(value, {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });

        return `${date}\u00A0${time}`;
    };

    const getWorkIri = (value) => {
        if (value?.id) {
            return `api/works/${value.id}`;
        } else {
            return value;
        }
    };

    const updateStatus = (status, data, workId) => {
        setProject((prev) => ({
            ...prev,
            status: status,
            chatWorkHistoryMessages: [...prev.chatWorkHistoryMessages, data]
        }));

        let changedWork = user.works.find(item => item.id === Number(workId));
        changedWork.status = status;

        setSuccessAlert(intl.formatMessage({id: 'sentSuccessfully'}), alertTimers.long);
    }

    const submitForReview = () => {
        updateDisabledMask(true);
        setIsSubmittingForReview(true);

        postData(`api/chat_work_history_messages`, {
            historyStatus: 'review',
            work: `api/works/${id}`,
            sender: `api/users/${userID}`,
            workChat: `api/work_chats/${project.workChats[0].id}`,
        })
            .then(({ data }) => {
                updateStatus('review', data, id);
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? JSON.stringify(error) : intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
            })
            .finally(() => {
                updateDisabledMask(false);
            });
    };

    const editRequest = () => {
        updateDisabledMask(true);

        postData(`api/chat_work_history_messages`, {
            historyStatus: 'revision',
            work: getWorkIri(activeChat?.assignedWork),
            sender: `api/users/${userID}`,
            workChat: `api/work_chats/${activeChat?.id}`,
        })
            .then(({ data }) => {
                updateStatus('revision', data, id);
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? JSON.stringify(error) : intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
            })
            .finally(() => updateDisabledMask(false));
    };

    const completedWork = () => {
        updateDisabledMask(true);
        postData(`api/chat_work_history_messages`, {
            historyStatus: 'completed',
            work: getWorkIri(activeChat?.assignedWork),
            sender: `api/users/${userID}`,
            workChat: `api/work_chats/${activeChat?.id}`,
        })
            .then(({ data }) => {
                updateStatus('completed', data, id);
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? JSON.stringify(error) : intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
            })
            .finally(() => updateDisabledMask(false));
    };

    const submitComplaint = () => {
        updateDisabledMask(true);
        postData(`api/chat_work_history_messages`, {
            historyStatus: 'complained',
            work: getWorkIri(activeChat?.assignedWork),
            sender: `api/users/${userID}`,
            workChat: `api/work_chats/${activeChat?.id}`,
        })
            .then(({data}) => {
                updateStatus('complained', data, id);
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? JSON.stringify(error) : intl.formatMessage({id: 'errorSave'}), alertTimers.long);
            })
            .finally(() => {
                updateDisabledMask(false);
                setIsOpenPopupComplaint(false);
            });
    };

    const cancelRefund = () => {
        updateDisabledMask(true);
        setSuccessAlert('');

        postData(`api/chat_work_history_messages`, {
            historyStatus: 'cancelled',
            work: getWorkIri(activeChat?.assignedWork),
            sender: `api/users/${userID}`,
            workChat: `api/work_chats/${activeChat?.id}`,
        })
            .then(({data}) => {
                updateStatus('cancelled', data, id);
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? JSON.stringify(error) : intl.formatMessage({id: 'errorSave'}), alertTimers.long);
            })
            .finally(() => {
                updateDisabledMask(false);
                setIsOpenPopupCancel(false);
            });
    };

    const closeProject = () => {
        updateDisabledMask(true);
        postData(`api/chat_work_history_messages`, {
            historyStatus: 'closed',
            work: `api/works/${project?.id}`,
            sender: `api/users/${userID}`,
            workChat: activeChat?.id ? `api/work_chats/${activeChat?.id}` : null,
        })
            .then(({data}) => {
                updateStatus('closed', data, id);
                refreshUser();
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? JSON.stringify(error) : intl.formatMessage({id: 'errorSave'}), alertTimers.long);
            })
            .finally(() => {
                setIsOpenPopupClose(false);
                updateDisabledMask(false);
            });
    };

    const leaveRevew = () => {
        if (selectedStar === null) {
            setRateAlert(true);
            return;
        } else {
            setRateAlert(false);
        }

        updateDisabledMask(true);

        postData(`api/reviews`, {
            message: leaveReviewMessage,
            stars: selectedStar + 1,
            sender: `api/users/${userID}`,
            expert: `api/users/${project?.expert?.id}`,
            work: `api/works/${project?.id}`,
        })
            .then(({data}) => {
                setSuccessAlert(intl.formatMessage({id: 'sentSuccessfully'}), alertTimers.long);

                setProject((prev) => ({
                    ...prev,
                    reviews: [data]
                }));

                postData(`api/chat_work_history_messages`, {
                    historyStatus: 'leave_review',
                    work: getWorkIri(activeChat?.assignedWork),
                    sender: `api/users/${userID}`,
                    workChat: `api/work_chats/${activeChat?.id}`,
                })
                    .then(({data}) => {
                        setProject((prev) => ({
                            ...prev,
                            chatWorkHistoryMessages: [...prev.chatWorkHistoryMessages, data]
                        }));
                        setSuccessAlert(intl.formatMessage({id: 'sentSuccessfully'}), alertTimers.long);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? JSON.stringify(error) : intl.formatMessage({id: 'errorSave'}), alertTimers.long);
            })
            .finally(() => {
                setRateAlert(false);
                setIsOpenPopupLeaveRevew(false);
                updateDisabledMask(false);
            });
    };

    const confirmPayment = (zoomMeetingsId) => {
        updateDisabledMask(true);
        postData(`api/session-transaction-confirm`, {
            user: `api/users/${userID}`,
            transaction: `api/meeting_zooms/${zoomMeetingsId}`,
        })
            .then(({data}) => {
                setSuccessAlert(intl.formatMessage({ id: 'successConfirm' }, {var_1: data.amount/100}), alertTimers.long);

                let completedZoomMeetings = user.zoomMeetings.findIndex(item => item.id === Number(zoomMeetingsId) && item.completed === true);
                user.zoomMeetings[completedZoomMeetings].confirmed = true;

                updateUser(user);

                postData(`api/chat_work_history_messages`, {
                    historyStatus: 'confirm_payment',
                    work: getWorkIri(activeChat?.assignedWork),
                    sender: `api/users/${userID}`,
                    workChat: `api/work_chats/${activeChat?.id}`,
                    amount: data.amount,
                })
                    .then(({data}) => {
                        setProject((prev) => ({
                            ...prev,
                            chatWorkHistoryMessages: [...prev.chatWorkHistoryMessages, data]
                        }));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? JSON.stringify(error) : intl.formatMessage({ id: 'errorSave' }), alertTimers.long);
            })
            .finally(() => updateDisabledMask(false));
    };

    useEffect(() => {
        let isMounted = true;

        if (id && (!project?.id || project.id !== id || stateProject !== refreshProject)) {
            setLoading(true);
            setStateProject(refreshProject);

            getData(`api/works/${id}`)
                .then(({ data }) => {
                    if (isMounted) {
                        setProject(data);
                        ReadyStore.updatecurrentWork(data);

                        if (data.creatorIsReady && data.executorIsReady) {
                            ReadyStore.updateWorkThatIsCommonReady(data.id);
                        }

                        if (isAdvancedStatus(data.status) && data.workChats?.length) {
                            const notifyName = userType === 'expert' ? 'notifyExecutor' : 'notifyOwner';
                            const currentChat = data.workChats[0];

                            if (ChatStore.chatList[currentChat?.channelName] !== undefined) {
                                setActiveChat(ChatStore.chatList[currentChat?.channelName]);
                            } else {
                                getData(`api/work_chats/${currentChat?.id}`)
                                    .then(({ data }) => {
                                        // if (isMounted) {
                                            data.isNotify = data[notifyName];
                                            addChatToChatList(data);
                                            setActiveChat(data);
                                        // }
                                    })
                                    .catch((error) => {console.log(error);});
                            }
                        }
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, addChatToChatList, setActiveChat, userType, project?.id, userID, refreshProject]);

    useEffect(() => {
        if (project?.type === 'projects' && project?.expert?.id === userID && project?.status === 'ongoing' && project?.fixedPrice && !project?.reserved) {
            setSuccessAlert(intl.formatMessage({id: 'notYetReserverd'}))
        }
    }, [project?.type, project?.status, project?.fixedPrice, project?.expert?.id, project?.reserved, userID, intl, setSuccessAlert]);

    useEffect(() => {
        setTabValue(typeTab === 2 ? '2' : '1');
    }, [typeTab]);

    if (!userID || !project?.id) {
    // if (loading || !project?.id) {
        return <>
            <div className={st.skeletonTop}>
                <Skeleton animation={false} width={'110px'} height={'50px'} />
                <Skeleton animation={false} width={'110px'} height={'50px'} />
            </div>
            <Skeleton height={'4px'} sx={{mt: 1}} />
            <div className={st.skeletonBody}>
                <Skeleton animation={false} width="40%" height={'60px'} />
                <Skeleton animation={false} height={'50px'} sx={{mt: 3}} />
                <Skeleton animation={false} height={'50px'} />
                <Skeleton animation={false} width="80%" height={'50px'} />
            </div>
        </>
        // return <Preloader isShow={true} />;
    } else if (!loading && !project?.id) {
        // return <Navigate to={NonAuthRoutes.error} />;
    }

    let restrictedDisplay = false;
    if (
        project?.id &&
        project?.status !== 'opened' &&
        (!isUserAuthorized || ![project?.client?.id, project?.company?.id, project?.expert?.id].includes(userID))
        // (!isUserAuthorized || ![project?.workChats?.[0]?.workOwner?.id, project?.workChats?.[0]?.executor?.id].includes(userID))
    ) {
        restrictedDisplay = true;
    }

    return (
        <section className={st.wrapper}>
            <Link to={NonAuthRoutes.projects} className={st.back}>
                <ArrowIcon />
                {intl.formatMessage({ id: 'back' })}
            </Link>

            {(project.status === 'closed' || (restrictedDisplay && !loading && userID)) && (
                <>
                    <Typography className={st.title} variant="h4" component="h2">
                        {intl.formatMessage({ id: 'notAvailable' })}
                    </Typography>

                    <div className={st.layout}>{intl.formatMessage({ id: 'projects.notAvailable' })}</div>
                </>
            )}
            <ConditionalWrapper
                condition={isAdvancedStatus(project.status) && !restrictedDisplay}
                wrapper={(children) => (
                    <TabContext value={tabValue}>
                        <Tabs value={tabValue} aria-label="tabs for type of projects" className={st.tabs} onChange={handleTabChange}>
                            <Tab label={intl.formatMessage({ id: 'overview' })} value='1' />
                            <Tab label={intl.formatMessage({ id: 'session' })} value='2' />
                        </Tabs>
                        {children}
                    </TabContext>
                )}
            >
                <ConditionalWrapper
                    condition={isAdvancedStatus(project.status) && !restrictedDisplay}
                    wrapper={(children) => <TabPanel value="1">{children}</TabPanel>}
                >
                    <CardPreviewProject
                        data={project}
                        setData={setProject}
                        type="singlePage"
                        restrictedDisplay={restrictedDisplay}
                        complaint={submitComplaint}
                        cancelRefund={cancelRefund}
                        closeProject={closeProject}
                    />

                    {project.status === 'opened' ? (
                        <>
                            <Typography className={st.title} variant="h4" component="h2">
                                {project?.replies?.length
                                    ? intl.formatMessage({ id: 'repliesCount' }, { count: project.replies.length })
                                    : intl.formatMessage({ id: 'noReplies' })}
                            </Typography>
                            {project?.replies
                                ?.filter((value) => [value.to?.id, value.from?.id].includes(userID))
                                .map((item, index) => (
                                    <CardPreviewExpert key={index} data={item.from} message={item.message} cardType="reply" projectData={project} />
                                ))}
                            {isMyProject && !project?.replies?.length && (
                                <div className={st.layout}>{intl.formatMessage({ id: 'projects.replies.noResults' })}</div>
                            )}
                        </>
                    ) : (
                        !restrictedDisplay && (
                            <>
                                <div className={classNames(st.layout, st.history, st.status)}>
                                    {project?.status === 'ongoing' ? (
                                        userType === 'expert' ? (
                                            <div className="two-buttons">
                                                <Button
                                                    className={isSubmittingForReview ? 'disabled' : ''}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => setIsOpenPopupCancel(true)}
                                                >
                                                    {intl.formatMessage({ id: 'cancelRefund' })}
                                                </Button>

                                                <Button
                                                    className={classNames(st.button, isSubmittingForReview ? 'disabled' : '')}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={submitForReview}
                                                >
                                                    {intl.formatMessage({ id: 'submitForReview' })}
                                                </Button>
                                            </div>
                                        ) : (
                                            intl.formatMessage({ id: 'executorAccepted' })
                                        )
                                    ) : project?.status === 'review' ? (
                                        userType === 'expert' ? (
                                            <div className={st.row}>
                                                <div className={classNames(st.icon, st.icon_middle)}>
                                                    <ClockIcon />
                                                </div>
                                                <div className={st.row_text}>
                                                    {intl.formatMessage({ id: 'expectFeedback' })}
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className={stMessagesList.session__text}>
                                                    {intl.formatMessage(
                                                        { id: 'deliveredYourOrder' },
                                                        {
                                                            var:
                                                                activeChat?.executor?.profile?.firstName +
                                                                ' ' +
                                                                activeChat?.executor?.profile?.lastName,
                                                        }
                                                    )}
                                                </div>

                                                <div className="two-buttons">
                                                    <Button
                                                        className={isSubmittingForReview ? 'disabled' : ''}
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={editRequest}
                                                    >
                                                        {intl.formatMessage({ id: 'editRequest' })}
                                                    </Button>

                                                    <Button
                                                        className={isSubmittingForReview ? 'disabled' : ''}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={completedWork}
                                                    >
                                                        {intl.formatMessage({ id: 'confirmNow' })}
                                                    </Button>
                                                </div>
                                            </>
                                        )
                                    ) : project?.status === 'revision' ? (
                                        userType === 'expert' ? (
                                            <>
                                                <div className={stMessagesList.session__text}>
                                                    {intl.formatMessage({ id: 'executorEditRequest' })}
                                                </div>

                                                <Button
                                                    className={classNames(st.button, isSubmittingForReview ? 'disabled' : '')}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={submitForReview}
                                                >
                                                    {intl.formatMessage({ id: 'submitForReview' })}
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <div className={st.row}>
                                                    <div className={classNames(st.icon, st.icon_middle)}>
                                                        <ClockIcon />
                                                    </div>

                                                    <div className={st.row_text}>{intl.formatMessage({ id: 'clientEditRequest' })}</div>
                                                </div>
                                            </>
                                        )
                                    ) : project?.status === 'completed' ? (
                                        <>
                                            <div className={st.row}>
                                                <div className={classNames(st.icon, st.icon_middle, st.icon_green)}>
                                                    <AcceptedIcon />
                                                </div>

                                                <div className={st.row_text}>
                                                    {intl.formatMessage({
                                                        id: userType === 'expert' ? 'expertCompleted' : 'clientCompleted',
                                                    })}
                                                </div>
                                            </div>

                                            {userType !== 'expert' &&
                                                <div className="two-buttons">
                                                    {!project?.reviews?.length &&
                                                        <Button
                                                            className={st.button}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => setIsOpenPopupLeaveRevew(true)}
                                                        >
                                                            {intl.formatMessage({id: 'leaveReview'})}
                                                        </Button>
                                                    }

                                                    <Button
                                                        className={st.button}
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => setIsOpenPopupClose(true)}
                                                    >
                                                        {intl.formatMessage({id: 'closeProject'})}
                                                    </Button>
                                                </div>
                                            }
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                {userType !== 'expert' && user?.zoomMeetings?.filter(item => (item.completed && item.amount > 0 && !item.confirmed && item?.work?.id === project?.id))?.map((item, index) =>
                                    <div key={index} className={classNames(st.layout, st.history, st.status, st.confirm)}>
                                        <div className={st.confirm_info}>
                                            <div className={st.session_summary_title}>{intl.formatMessage({id: 'sessionSummary'})}</div>
                                            <div className={st.session_summary_item}>{intl.formatMessage({id: 'durationMins'})}: <span>{item.duration} {intl.formatMessage({id: 'minutes'})}</span></div>
                                            <div className={st.session_summary_item}>{intl.formatMessage({id: 'costUSD'})}: <span>${item.amount/100}</span></div>
                                            <div className={st.session_summary_item}>{intl.formatMessage({id: 'date'})}: <span>
                                                {historyDate(typeof item.createdAt === "string" ? new Date(item.createdAt) : item.createdAt)}</span></div>
                                        </div>

                                        <div className={st.confirm_buttons}>
                                            <Button
                                                className={st.button}
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => setIsOpenPopupComplaint(true)}
                                            >
                                                {intl.formatMessage({ id: 'submitComplaint' })}
                                            </Button>

                                            <Button
                                                className={classNames(st.button, isSubmittingForReview ? 'disabled' : '')}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => confirmPayment(item.id)}
                                            >
                                                {intl.formatMessage({ id: 'confirmPayment' })}
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                <Typography className={st.title} variant="h4" component="h3">
                                    {intl.formatMessage({id: 'currentProjectStatus'})}: {project?.status}
                                </Typography>

                                <Typography className={st.title} variant="h4" component="h3">
                                    {intl.formatMessage({ id: 'history' })}
                                </Typography>

                                <div className={classNames(st.layout, st.history)}>
                                    <div className={st.row}>
                                        <div className={st.row_text}>{intl.formatMessage({id: 'jobCreated' })}</div>

                                        <div className={st.row_title}>{historyDate(project.createdAt)}</div>
                                    </div>

                                    <div className={st.row}>
                                        <div className={classNames(st.row_text, st.row_main)}>
                                            <div className={st.icon}><RocketIcon /></div>

                                            {intl.formatMessage({ id: 'projectStarted' })}
                                        </div>

                                        <div className={st.row_title}>
                                            {project?.workChats?.[0]?.createdAt && historyDate(project.workChats[0].createdAt)}
                                        </div>
                                    </div>

                                    {project?.chatWorkHistoryMessages?.map((item) => {
                                        return (
                                            <div key={item.id} className={st.row}>
                                                <div className={classNames(st.row_text, st.row_main)}>
                                                    {item.systemMessage ?
                                                        <div className={st.icon}><ClockIcon /></div>
                                                        :
                                                        <Avatar src={item.sender?.profile?.avatar?.contentUrl ? axios.defaults.baseURL + item.sender?.profile?.avatar.contentUrl : ''}
                                                                {...stringAvatar(`${item.sender?.profile?.firstName} ${item.sender?.profile?.lastName}`)}
                                                        />
                                                    }


                                                    <div className={st.row_desc}>
                                                        {item.historyStatus === 'confirm_payment' ?
                                                                intl.formatMessage(
                                                                    {id: `chatWorkHistoryMessage_${item.historyStatus}` },
                                                                    {var_1: item.sender?.profile?.firstName + ' ' + item.sender?.profile?.lastName, var_2: item.amount/100}
                                                                )
                                                            :
                                                            item.historyStatus === 'cron_confirm_payment' ?
                                                                intl.formatMessage(
                                                                {id: `chatWorkHistoryMessage_${item.historyStatus}` },
                                                                {var_1: item.amount/100})
                                                            :
                                                            item.historyStatus === 'reserved' ?
                                                                item.text
                                                            :
                                                            intl.formatMessage(
                                                            {id: `chatWorkHistoryMessage_${item.historyStatus}` },
                                                            {var_1: item.sender?.profile?.firstName + ' ' + item.sender?.profile?.lastName})

                                                        }
                                                    </div>
                                                </div>

                                                <div className={st.row_title}>{historyDate(item.createdAt)}</div>
                                            </div>
                                        );
                                    })}
                                </div>

                                {activeChat?.id === project.workChats?.[0]?.id && <ProjectChat project={project} />}
                            </>
                        )
                    )}
                </ConditionalWrapper>

                {isAdvancedStatus(project.status) && !restrictedDisplay && (
                    <TabPanel value='2'>
                        <Session project={project} setProject={setProject} />
                    </TabPanel>
                )}
            </ConditionalWrapper>

            {/* Popup for leave review */}
            <LocalPopup isOpen={isOpenPopupLeaveRevew} close={handlePopupLeaveReview}>
                <div className="popup-title">{intl.formatMessage({ id: 'leaveReview' })}</div>

                <div className={classNames("popup-desc", st.leave_revew_line)}>
                    {intl.formatMessage({ id: 'jobName' })} <strong>{project?.title}</strong>
                    <br />
                    {intl.formatMessage({ id: 'expertName' })} <strong>{project?.expert?.profile?.firstName + ' ' + project?.expert?.profile?.lastName}</strong>
                </div>

                <div className={classNames("popup-desc", st.rate_title, (rateAlert ? st.rate_title_alert : ''))}>
                    {intl.formatMessage({ id: 'rate' })} *
                </div>

                <div className={st.stars}>
                    {[1, 2, 3, 4, 5].map((item, index) => (
                        <StarIcon className={classNames(st.star, (reviewStars[index] ? st.star_active : ''))} key={index}
                                  onMouseOver={() => handleLeaveReview(index + 1, 1)}
                                  onMouseOut={() => handleLeaveReview(index + 1, 0)}
                                  onClick={(e) => handleSelectStar(e, index)}
                        />
                    ))}

                    <div className={classNames(st.rate_alert, (rateAlert ? st.rate_alert_active : ''))}>{intl.formatMessage({id: 'required'})}</div>
                </div>

                <TextField
                    sx={{mb: 4}}
                    type="text"
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={4}
                    maxRows={tabletIs ? 4 : 8}
                    label={intl.formatMessage({ id: 'yourReview' })}
                    value={leaveReviewMessage}
                    onChange={handleChangeLeaveReviewMessage}
                />

                <div className="popup-buttons">
                    <Button variant="outlined" color="primary" fullWidth size="large" onClick={() => setIsOpenPopupLeaveRevew(false)}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>

                    <Button variant="contained" color="primary" fullWidth size="large" onClick={() => leaveRevew()}>
                        {intl.formatMessage({ id: 'submit' })}
                    </Button>
                </div>
            </LocalPopup>

            {/* Popup for complaint */}
            <LocalPopup isOpen={isOpenPopupComplaint} close={handlePopupComplaint}>
                <div className="popup-title">{intl.formatMessage({ id: 'complain' })}</div>

                <div className={classNames("popup-desc", "flex-space")}>
                    {intl.formatMessage({ id: 'warningForComplaint' })}
                </div>

                <div className="popup-buttons">
                    <Button variant="contained" color="primary" fullWidth size="large" onClick={() => submitComplaint()}>
                        {intl.formatMessage({ id: 'ok' })}
                    </Button>

                    <Button variant="outlined" color="primary" fullWidth size="large" onClick={() => setIsOpenPopupComplaint(false)}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                </div>
            </LocalPopup>

            {/* Popup for Cancel and Refund */}
            <LocalPopup isOpen={isOpenPopupCancel} close={handlePopupCancel}>
                <div className="popup-title">{intl.formatMessage({ id: 'cancelRefund' })}</div>

                <div className={classNames("popup-desc", "flex-space")}>
                    {intl.formatMessage({ id: 'warningForCancel' })}
                </div>

                <div className="popup-buttons">
                    <Button variant="contained" color="primary" fullWidth size="large" onClick={() => cancelRefund()}>
                        {intl.formatMessage({ id: 'ok' })}
                    </Button>

                    <Button variant="outlined" color="primary" fullWidth size="large" onClick={() => setIsOpenPopupCancel(false)}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                </div>
            </LocalPopup>

            {/* Popup for Close Project */}
            <LocalPopup isOpen={isOpenPopupClose} close={handlePopupClose}>
                <div className="popup-title">{intl.formatMessage({ id: 'closeProject' })}</div>

                <div className={classNames("popup-desc", "flex-space")}>
                    {intl.formatMessage({ id: 'warningForClose' })}
                </div>

                <div className="popup-buttons">
                    <Button variant="contained" color="primary" fullWidth size="large" onClick={() => closeProject()}>
                        {intl.formatMessage({ id: 'ok' })}
                    </Button>

                    <Button variant="outlined" color="primary" fullWidth size="large" onClick={() => setIsOpenPopupClose(false)}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                </div>
            </LocalPopup>
        </section>
    );
};

export default observer(ProjectId);
