import axios from 'axios';
import { NonAuthRoutes } from '../routes/routes';

function request(method, url, ld, sendData, setPagination, options) {
    // console.log('api url', url);

    let headers = Object.assign(
        {
            'Content-Type': 'application/json',
        },
        options
    );

    if (ld) {
        headers.accept = 'application/ld+json';
    } else if (method === 'delete') {
        headers.accept = '*/*';
    } else if (method === 'patch') {
        headers['content-type'] = 'application/merge-patch+json';
    }

    let params = {
        method: method,
        url: axios.defaults.baseURL + url,
        headers: headers,
    };

    if (sendData) {
        params.data = sendData;
    }

    return axios(params)
        .then(function (response) {
            if (response.data['@type'] === 'hydra:Collection') {
                response.totalItems = response.data['hydra:totalItems'];
                if (setPagination && response.data['hydra:view']) {
                    setPagination(parseInt(response.data['hydra:view']['hydra:last'].match(/\d+$/)[0], 10));
                    // setPagination(response.data['hydra:view']);
                }
                response.data = response.data['hydra:member'];
            }
            return response;
        })
        .catch(function (error) {
            console.log('error.response.status', error?.response?.status);
            console.log('error.response', error?.response);
            let errorMessage = 'An error occurred during the request. Try to repeat the request.';

            if (
                error.response &&
                (error.response.data?.detail ||
                    error.response.status === 401 ||
                    error.response.data?.violations ||
                    error.response.data?.message ||
                    error.response.statusText)
            ) {
                // Request made and server responded
                if (error.response.status === 401) {
                    if (url === 'auth') {
                        if (error?.response?.data?.error) {
                            errorMessage = error.response.data.error;
                        } else {
                            errorMessage = 'Credentials are missing or incorrect.';
                        }
                    } else {
                        window.location = `${NonAuthRoutes.signin}`;
                    }
                } else if (error.response.data?.violations) {
                    console.log('error.response.data.violations', error.response.data.violations);
                    errorMessage = error.response.data.violations;
                } else if (error.response.data?.detail) {
                    console.log('error.response.data', error.response.data);
                    errorMessage = error.response.data.detail;
                } else if (error.response.data?.message) {
                    errorMessage = error.response.data.message;
                } else if (error.response?.statusText) {
                    console.log('statusText', error.response.statusText);
                    errorMessage = error.response.statusText;
                }
            } else if (error?.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error?.message);
            }

            return Promise.reject(errorMessage);
        });
}

export const patchData = (url, sendData) => {
    return request('patch', url, false, sendData);
};

export const deleteData = (url) => {
    return request('delete', url);
};

export const getData = (url, ld = false, setPagination = false) => {
    return request('get', url, ld, [], setPagination);
};

export const postData = (url, sendData, method, ld = false) => {
    return request(method ?? 'post', url, ld, sendData);
};
