import {action, makeAutoObservable} from "mobx";
import {getData, postData} from "src/api/api";
import DataStore from "./DataStore";
import UIStore from "./UIStore";

class ChatStore {
    // channelName = null;
    channel = {};
    chatList = {};
    chatListLength = 0;
    isNewMessage = false;
    activeChat = {};
    sender = {
        online: null
    };
    // workOwner - клиент или компания, тот, кто создал проект
    // executor - всегда эксперт

    constructor () {
        makeAutoObservable(this);
    }

    updateChatList = (key, property, value) => {
        this.chatList[key][property] = value;

        if (this.activeChat.channelName === key) {
            this.activeChat[property] = value;
        }
    }

    updateChatListLength = (value) => {
        this.chatListLength += value;
    }

    // добавить свойства в конкретный чат
/*    addChatSettings = (prop, value) => {
        this.chatList[this.channelName][prop] = value;
    }*/

    // очищаем чат
    clearChat = () => {
        UIStore.WSclient?.disconnect();
        this.activeChat = {};
        this.channel = {};
        this.chatList = {};
    }

    // открываем активный чат
    setActiveChat = (state) => {
        const { userType } = DataStore;

        if (state.id) {
            this.sender = userType !== 'expert' ? {...state.executor, type: 'expert'} : {...state.workOwner, type: 'client'};
            if (state.workChat && userType !== 'expert') {
                this.sender = {...state.executor, type: 'expert'};
            } else if (state.workChat && userType === 'expert') {
                this.sender = {...state.workOwner, type: 'client'};
            }
        } else {
            this.sender = {
                online: null
            };
        }

        this.activeChat = state;
    }

    checkNewMessage = () => {
        this.isNewMessage = Object.keys(this.chatList).find(key => this.chatList[key].isNotify === true) !== undefined;
    }

    // получить список чатов
    getChatList = async () => {
        await getData(`api/chats`)
            .then(({ data }) => {
                data.items.map(action((item) => {
                    item.isNotify = item[DataStore.userType === 'expert' ? 'notifyExecutor' : 'notifyOwner'] === true;
                    return this.chatList[item.channelName] = item;
                }));

                this.updateChatListLength(data.items.length);
                this.checkNewMessage();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // получить список work-чатов
    getWorkChatList = async () => {
        await getData(`api/work_chats`)
            .then(({ data }) => {
                data.items.map(action((item) => {
                    item.isNotify = item[DataStore.userType === 'expert' ? 'notifyExecutor' : 'notifyOwner'] === true;
                    return this.chatList[item.channelName] = item;
                }));

                this.updateChatListLength(data.items.length);
                this.checkNewMessage();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    addChatToChatList = (value) => {
        this.chatList[value.channelName] = value;
    }

    // получить доп сообщения
    getMoreMessages = async (chat, page, count = 10) => {
        await getData(`api/chat_messages/?` + encodeURI(`page=${ page }&perPage=${ count }&${chat.workChat ? 'workChat' : 'chat'}=api/${chat.workChat ? 'work_chats' : 'chats'}/${ chat.id }`))
            .then(({ data }) => {
                data.items.map(action((item) => this.chatList[item.chat.channelName].messages.push(item)));

                return data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // подписались от ws канал
    subscribeToChannel = (name) => {
        // console.log('subscribeToChannel name', name)
        // this.channelName = name;
        if (this.channel[name]) {
            return;
        }

        this.channel[name] = UIStore.WSclient.subscribe(name);
        this.subscribeToChatMessage(name);
    }

    // подписались на ws эвент
    subscribeToChatMessage = (name) => {
        this.channel[name].bind('chat_message.event', action((data) => {
            console.log('data', data)
            if (['cron_zoom_completed', 'cron_zoom_confirmed'].includes(data.messageClassName)) {
                if (data.messageClassName === 'cron_zoom_confirmed') {
                    DataStore.refreshUser();
                } else {
                    const zoomMeetingsName = DataStore.userType === 'expert' ? 'expertZoomMeetings' : 'zoomMeetings';

                    const meetingId = Number(data.text?.match(/meetingId:(.+?),/)?.[1]);
                    const meetingIdIndex = DataStore.user?.[zoomMeetingsName]?.findIndex(item => item.id === meetingId);

                    if (meetingIdIndex > 0) {
                        DataStore.user[zoomMeetingsName][meetingIdIndex].completed = true;
                        DataStore.updateUser(DataStore.user);
                    } else {
                        DataStore.updateUser({
                            ...DataStore.user, [zoomMeetingsName]: [...DataStore.user[zoomMeetingsName],
                                {
                                    work: {
                                        title: data?.workChat?.assignedWork?.title,
                                        id: data?.workChat?.assignedWork?.id,
                                    },
                                    completed: true,
                                    confirmed: false,
                                    amount: data.text?.match(/amount:(.+?),/)?.[1],
                                    duration: data.text?.match(/duration:(.+?),/)?.[1],
                                    createdAt: data.text?.match(/createdAt:(.+?),/)?.[1],
                                    id: meetingId,
                                }]}
                        );
                    }
                }
            } else if (data.historyStatus === 'reserved') {
                DataStore.triggerRefreshProject();
            }

            this.chatList[name].messages.push(data);
            this.chatList[name].lastMessage = data;

            if (data.sender?.id && DataStore.user.id !== data.sender.id) {
                if (window.location.pathname === '/messages' && this.activeChat.channelName === data.chat?.channelName) {
                    postData(`api/${this.activeChat.workChat ? 'work_chats' : 'chats'}/${this.activeChat.id}`,
                        {[DataStore.userType === 'expert' ? 'notifyExecutor' : 'notifyOwner']: false},
                        'put'
                    )
                        .then()
                        .catch((error) => {console.log(error)});
                } else {
                    this.updateChatList(data.chat.channelName, 'isNotify', true);
                    this.checkNewMessage();
                }
            }

            if (data.historyStatus === 'confirm_payment') {
                DataStore.refreshBalance();
            }

            if (data.workChatCreated) {
                this.getWorkChatList()
                    .then(() => {
                        DataStore.triggerRefreshProject();
                    });
            }
        }));
    }
}

export default new ChatStore();
