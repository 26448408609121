import React from 'react';
import st from './CardPreview.module.scss';
import { ReactComponent as StarIcon } from '../../assets/images/icons/star.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/icons/location.svg';
import { ReactComponent as JobIcon } from '../../assets/images/icons/job.svg';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { stringAvatar } from 'src/helpers/helpers';
import { Autocomplete, Avatar, Badge, Button, Dialog, IconButton, TextField } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/cross.svg';
import { ReactComponent as VerifyIcon } from '../../assets/images/icons/shield.svg';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import stPopup from '../Popup/popup.module.scss';
import Fade from '@mui/material/Fade';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AuthRoutes, NonAuthRoutes } from 'src/routes/routes';
import { postData } from 'src/api/api';
import DataStore from 'src/store/DataStore';
import UIStore from 'src/store/UIStore';
import { toJS } from 'mobx';
import stCabinet from '../../layouts/Cabinet/cabinetLayout.module.scss';
import { ReactComponent as SelectIcon } from '../../assets/images/icons/arrow.svg';
import ChatStore from "../../store/ChatStore";
const { getChatList } = ChatStore;

const HireButton = ({ expert, cardType = '', projectData = undefined }) => {
    const intl = useIntl();
    const [open, setOpen] = React.useState(false);
    const [selectingProject, setSelectingProject] = React.useState(false);
    const [selectedProject, setSelectedProject] = React.useState({});
    const { isUserAuthorized, user, userID } = DataStore;
    const { openPopup, setErrorAlert, alertTimers, setSuccessAlert } = UIStore;
    const [alreadyInvited, setAlreadyInvited] = React.useState(false);
    const [isSendingInvite, setIsSendingInvite] = React.useState(false);

    React.useEffect(() => {
        if (projectData?.chatWorkInvitationMessages?.length > 0) {
            if (projectData.chatWorkInvitationMessages.filter((item) => item.invitedGhost?.id === expert?.id).length > 0) {
                setAlreadyInvited(true);
            }
        }
    }, [expert, projectData?.chatWorkInvitationMessages]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectingProject(false);
        setSelectedProject({});
    };

    const inviteExpert = () => {
        setErrorAlert('');
        setSuccessAlert('');
        setIsSendingInvite(true);

        const dataFirstChat = {
            sender: `api/users/${userID}`,
            recipient: `api/experts/${expert.id}`,
            message: {
                text: '',
                // text: intl.formatMessage({id: 'сreatingChat'}),
                sender: `api/users/${userID}`,
            },
        };

        /*        const dataInvite = {
            invitationStatus: 'new',
            sender: `api/users/${userID}`,
            invitedGhost: `api/users/${expert.id}`,
            work: `api/works/${(cardType === 'reply' ? projectData?.id : selectedProject?.id)}`,
            systemMessage: false,
            // chat: `${data.message.chat.slice(1)}`,
            spam: false,
        };

        postData(`api/chat_work_invitation_messages`, dataInvite, 'post')
            .then(({data})=> {
                console.log('data 2' , data);
                setSuccessAlert(intl.formatMessage({id: 'success'}), alertTimers.short);
                handleClose();
            })
            .catch((error) => {
                console.log('error 1');
                setErrorAlert(intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
            });*/

        postData(`api/first-chat-message`, dataFirstChat, 'post')
            .then(({ data }) => {
                if (data?.message?.chat) {
                    const dataInvite = {
                        invitationStatus: 'new',
                        sender: `api/users/${userID}`,
                        invitedGhost: `api/users/${expert.id}`,
                        work: `api/works/${cardType === 'reply' ? projectData?.id : selectedProject?.id}`,
                        systemMessage: false,
                        chat: `${data.message.chat.slice(1)}`,
                        spam: false,
                    };

                    postData(`api/chat_work_invitation_messages`, dataInvite, 'post')
                        .then(({ data }) => {
                            getChatList();
                            setSuccessAlert(intl.formatMessage({ id: 'pendingInvite' }), alertTimers.long);
                            if (projectData) {
                                setAlreadyInvited(true);
                            }
                            handleClose();
                            setIsSendingInvite(false);
                        })
                        .catch((error) => {
                            console.log('error 1');
                            setErrorAlert(intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
                            setIsSendingInvite(false);
                        });
                }
            })
            .catch((error) => {
                console.log('error 2');
                setErrorAlert(intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
                setIsSendingInvite(false);
            });
    };

    return (
        <>
            <Button
                className={classNames(st.button, alreadyInvited ? 'disabled' : '')}
                size="small"
                fullWidth
                variant="contained"
                color="primary"
                onClick={isUserAuthorized ? handleClickOpen : openPopup}
            >
                {intl.formatMessage({ id: alreadyInvited ? 'pendingConfirmation' : 'hire' })}
            </Button>

            <Dialog TransitionComponent={Fade} onClose={handleClose} open={open} scroll="body" className={classNames(stPopup.popup)}>
                <div className={classNames(stPopup.container, 'popup-container')}>
                    <IconButton className={stPopup.close} onClick={handleClose}>
                        <CrossIcon />
                    </IconButton>

                    {user && !user.active ? (
                        <>
                            <div
                                className={stPopup.title}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'projectsPost.popup.title' }) }}
                            />
                            <div
                                className={stPopup.text}
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({ id: 'projectsPost.popup.description' }),
                                }}
                            />
                            <Link to={`/experts/${userID}/edit`}>
                                <Button sx={{ width: '100%' }} variant="contained">
                                    {intl.formatMessage({ id: 'projectsPost.popup.button' })}
                                </Button>
                            </Link>
                        </>
                    ) : (
                        <>
                            <div className={stPopup.title}>
                                {intl.formatMessage(
                                    { id: 'experts.card.popup.hire.title' },
                                    { name: `${expert.profile?.firstName} ${expert.profile?.lastName}` }
                                )}
                            </div>

                            {selectingProject || cardType === 'reply' ? (
                                <>
                                    <div
                                        className={stPopup.text}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                cardType === 'reply' && projectData
                                                    ? intl.formatMessage(
                                                          { id: 'inviteExpert' },
                                                          {
                                                              expertname: `<strong>${expert?.profile?.firstName} ${expert?.profile?.lastName}</strong>`,
                                                              projectname: `<strong>${projectData.title}</strong>`,
                                                          }
                                                      )
                                                    : intl.formatMessage({ id: 'assign' }),
                                        }}
                                    ></div>

                                    {cardType !== 'reply' && (
                                        <Autocomplete
                                            className={stCabinet.input_box}
                                            popupIcon={<SelectIcon />}
                                            blurOnSelect
                                            filterSelectedOptions
                                            getOptionLabel={(option) => (option.title ? option.title : '')}
                                            isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                            options={user?.works?.length ? user.works.filter((item) => item.status === 'opened') : []}
                                            onChange={(e, newValue) => {
                                                setSelectedProject(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: 'chooseProject' })}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    )}

                                    <div className={stPopup.buttons}>
                                        <Button variant="outlined" color="primary" fullWidth size="large" onClick={handleClose}>
                                            {intl.formatMessage({ id: 'cancel' })}
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            onClick={inviteExpert}
                                            className={isSendingInvite ? 'disabled' : ''}
                                        >
                                            {intl.formatMessage({ id: cardType === 'reply' ? 'hire' : 'invite' })}
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={stPopup.text}>{intl.formatMessage({ id: 'experts.card.popup.hire.text' })}</div>

                                    <div className={stPopup.buttons}>
                                        <Button variant="outlined" color="primary" fullWidth size="large" onClick={() => setSelectingProject(true)}>
                                            {intl.formatMessage({ id: 'assign' })}
                                        </Button>

                                        <Button
                                            component={Link}
                                            to={`${AuthRoutes.projectsPost}?assignTo=${expert.id}`}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            sx={{ color: "white !important" }}
                                        >
                                            {intl.formatMessage({ id: 'experts.card.popup.hire.create' })}
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </Dialog>
        </>
    );
};

const MessageButton = ({ data }) => {
    const intl = useIntl();
    const { user, userID, isUserAuthorized } = DataStore;
    const { tabletIs, openPopup, setSuccessAlert, setErrorAlert, alertTimers } = UIStore;
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
        console.log(toJS(data));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleForm = () => {
        const message = {
            sender: `api/users/${userID}`,
            recipient: `api/experts/${data.id}`,
            message: {
                text: value,
                sender: `api/users/${userID}`,
            },
        };

        postData(`api/first-chat-message`, message, 'post')
            .then(() => {
                getChatList();
                setOpen(false);

                setSuccessAlert(
                    <>
                        {intl.formatMessage({ id: 'messageSent' })}{' '}
                        {<Link to={AuthRoutes.messages}>{intl.formatMessage({ id: 'messageSent.link' })}</Link>}
                    </>,
                    alertTimers.long
                );
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
            });
    };

    return (
        <>
            <Button
                className={st.button}
                size="small"
                fullWidth
                variant="outlined"
                color="primary"
                onClick={isUserAuthorized ? handleClickOpen : openPopup}
            >
                {intl.formatMessage({ id: 'message' })}
            </Button>

            <Dialog TransitionComponent={Fade} onClose={handleClose} open={open} scroll="body" className={classNames(stPopup.popup)}>
                <div className={classNames(stPopup.container, 'popup-container')}>
                    <IconButton className={stPopup.close} onClick={handleClose}>
                        <CrossIcon />
                    </IconButton>

                    {user && !user.active ? (
                        <>
                            <div
                                className={stPopup.title}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'projectsPost.popup.title' }) }}
                            />
                            <div
                                className={stPopup.text}
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({ id: 'projectsPost.popup.description' }),
                                }}
                            />
                            <Link to={`/experts/${userID}/edit`}>
                                <Button sx={{ width: '100%' }} variant="contained">
                                    {intl.formatMessage({ id: 'projectsPost.popup.button' })}
                                </Button>
                            </Link>
                        </>
                    ) : (
                        <>
                            <div className={stPopup.title}>
                                {intl.formatMessage(
                                    { id: 'experts.card.popup.message.title' },
                                    { name: `${data.profile?.firstName} ${data.profile?.lastName}` }
                                )}
                            </div>

                            <div className={stPopup.text}>
                                <TextField
                                    fullWidth
                                    placeholder={intl.formatMessage({ id: 'experts.card.popup.message.text' })}
                                    multiline
                                    minRows={3}
                                    maxRows={tabletIs ? 4 : 8}
                                    value={value}
                                    onChange={handleChange}
                                />
                            </div>

                            <Button variant="contained" color="primary" fullWidth size="large" onClick={handleForm}>
                                {intl.formatMessage({ id: 'experts.card.popup.message.send' })}
                            </Button>
                        </>
                    )}
                </div>
            </Dialog>
        </>
    );
};

const CardPreviewExpert = ({ data, small = undefined, message = '', cardType = '', projectData = undefined }) => {
    const intl = useIntl();
    const { tabletIs } = UIStore;
    const { userID, userType } = DataStore;

    const checkOwner = (data) => {
        return (
            [data?.client?.id, data?.company?.id].includes(userID) ||
            data?.client === `/api/clients/${userID}` ||
            data?.company === `/api/companies/${userID}`
        );
    };

    if (!data) {
        return <></>;
    }

    return (
        <article className={st.container}>
            {!tabletIs && (
                <div className={st.media}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        className={classNames('status', data.online || userID === data.id ? 'status_online' : 'status_offline')}
                    >
                        <Avatar
                            src={data.profile?.avatar ? axios.defaults.baseURL + data.profile?.avatar.contentUrl : ''}
                            className={st.avatar}
                            sx={{
                                width: { xs: '60px', md: '64px', lg: '80px' },
                                height: { xs: '60px', md: '64px', lg: '80px' },
                            }}
                            {...stringAvatar(`${data.profile?.firstName} ${data.profile?.lastName}`)}
                        />
                    </Badge>
                </div>
            )}

            <div className={classNames(st.main, st.main_expert)}>
                <div className={st.main__content}>
                    {tabletIs && (
                        <div className={st.media}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                                className={classNames('status', data.online ? 'status_online' : 'status_offline')}
                            >
                                <Avatar
                                    src={data.profile?.avatar ? axios.defaults.baseURL + data.profile?.avatar.contentUrl : ''}
                                    className={st.avatar}
                                    sx={{
                                        width: { xs: '48px', md: '64px', lg: '80px' },
                                        height: { xs: '48px', md: '64px', lg: '80px' },
                                    }}
                                    {...stringAvatar(`${data.profile?.firstName} ${data.profile?.lastName}`)}
                                />
                            </Badge>
                        </div>
                    )}

                    <div className={st.main__content_wrap}>
                        <div className={st.main__content_top}>
                            <div className={st.name}>
                                {`${data.profile?.firstName} ${data.profile?.lastName}`}
                                {data.verified && <VerifyIcon className={st.name__verified} />}
                            </div>

                            <div className={st.rating}>
                                {data.rate ? (
                                    <>
                                        <div className={st.rating__number}>{data.rate}</div>
                                        <StarIcon className={st.rating__star} />
                                    </>
                                ) : (
                                    ''
                                )}

                                {data.reviewsCount ? <div className={st.rating__text}>{data.reviewsCount} reviews</div> : ''}
                            </div>

                            {data.ratePer && tabletIs ? (
                                <p className={st.price}>{intl.formatMessage({ id: 'experts.card.perMin' }, { num: data.ratePer })}</p>
                            ) : (
                                ''
                            )}
                        </div>
                        {data.positions?.length > 0 && (
                            <div className={st.profession}>
                                {data.positions.map((el, index) => (
                                    <span key={'prof' + index}>{el.title}</span>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {message && <div className={st.message}>{message}</div>}

                {!small && (
                    <>
                        <div className={st.main__info}>
                            <div className={st.main__information}>
                                {data.profile?.country && data.profile?.city && (
                                    <div className={st.location}>
                                        <LocationIcon />
                                        <span>
                                            {data.profile?.country?.title}, {data.profile?.city?.title}
                                        </span>
                                    </div>
                                )}
                                {data.jobs && (
                                    <div className={st.job}>
                                        <JobIcon />
                                        <span>{data.jobs} Jobs</span>
                                    </div>
                                )}
                                {data.firstSessionFree && (
                                    <div className={st.firstSession}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g stroke="#F66700" strokeLinecap="round">
                                                <path d="M15 8a7.002 7.002 0 0 1-9.679 6.467A7 7 0 1 1 15 8Z" strokeLinejoin="round" />
                                                <path d="M8.5 12V4.483a.2.2 0 0 0-.341-.142L5.5 7" />
                                            </g>
                                        </svg>
                                        <span>
                                            {data.firstSessionFree} {intl.formatMessage({ id: 'experts.card.first' })}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {data.profile?.about && <p className={st.desc}>{data.profile.about}</p>}

                        {data.services?.length > 0 && (
                            <div className={st.main__skills}>
                                <div className={st.main__skills_label}>{intl.formatMessage({ id: 'experts.card.services' })}</div>

                                {data.services.map((service) => (
                                    <p key={service.id + 'services'} className={st.main__skills_desc}>
                                        {service.title}
                                    </p>
                                ))}
                            </div>
                        )}

                        {data.softwareEquipments?.length > 0 && (
                            <div className={st.main__skills}>
                                <div className={st.main__skills_label}>{intl.formatMessage({ id: 'experts.card.softwares' })}</div>

                                <Stack direction="row" spacing={1}>
                                    {data.softwareEquipments.map((skill, i) => (
                                        <Chip key={i + 'softwares'} size="small" label={skill.title} />
                                    ))}
                                </Stack>
                            </div>
                        )}

                        {data.hardwareEquipments?.length > 0 && (
                            <div className={st.main__skills}>
                                <div className={st.main__skills_label}>{intl.formatMessage({ id: 'experts.card.hardwares' })}</div>

                                <Stack direction="row" spacing={1}>
                                    {data.hardwareEquipments.map((skill, i) => (
                                        <Chip key={i + 'hardwares'} size="small" label={skill.title} />
                                    ))}
                                </Stack>
                            </div>
                        )}
                    </>
                )}
            </div>

            {data.ratePer && !tabletIs ? <p className={st.price}>{intl.formatMessage({ id: 'experts.card.perMin' }, { num: data.ratePer })}</p> : ''}

            {cardType === 'createWork' ? (
                <></>
            ) : (
                /*                <Link to={`${NonAuthRoutes.experts}/${data.id}`} className={st.go_to} target="_blank" rel="noopener noreferrer">
                    <Button variant="contained" color="secondary" size="small">
                        {intl.formatMessage({ id: 'goToExpert' })}
                    </Button>
                </Link>*/
                (cardType !== 'reply' || checkOwner(projectData)) &&
                userType !== 'expert' && (
                    <div className={st.buttons}>
                        <HireButton expert={data} cardType={cardType} projectData={projectData} />
                        <MessageButton data={data} />
                    </div>
                )
            )}

            <Link to={`${NonAuthRoutes.experts}/${data.id}`} className={st.link}></Link>
        </article>
    );
};

export default observer(CardPreviewExpert);
