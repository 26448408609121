import React from 'react';
import st from './ReviewsCard.module.scss';

const ReviewsCard = ({
    image, name, text, position
}) => {
    return (
         <>
            <main>
                <div className={st.text}>{text}</div>
            </main>
            <footer className={st.footer}>
                <div className={st.img}><img  src={image} alt='' width='60px' height='88px' /></div>
                <div className={st.img_mobile}><img src={image} alt='' width='40px' height='58px' /></div>
                <div className={st.footer__info}>
                    <div className={st.name}>{name}</div>
                    <div className={st.position}>{position}</div>
                </div>
            </footer>
            </>
    )
}


export default ReviewsCard;
