import { makeAutoObservable } from "mobx";
import pusherJs from "pusher-js";


class UIStore {
    WSclient = null;
    successAlert = '';
    errorAlert = '';
    timerSuccessAlert = null;
    timerErrorAlert = null;
    alertTimers = {
        short: 4000,
        long: 16000,
    }

    mediaQuery = "(max-width: 668px)";
    mediaQueryTablet = "(max-width: 1023px)";
    mobileIs = window.matchMedia(this.mediaQuery).matches;
    tabletIs = window.matchMedia(this.mediaQueryTablet).matches;
    isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
    popupActive = false;
    locale = process.env.REACT_APP_DEFAULT_LOCALE;
    loading = false;
    userLoading = false;
    mobileMenuIsOpen = false;
    mobileMenuUserIsOpen = false;
    innerMenuIsOpen = false;
    sessionTimer = false;
    disabledMask = false;
    runZoomIsBlock = false;

    constructor() {
        makeAutoObservable(this);
    }

    activateWSClient = () => {
        if (this.WSclient) {
            return false;
        }

        this.WSclient = new pusherJs(process.env.REACT_APP_PUSHER_ENV, {
            cluster: 'eu',
        });
    }

    setMobileIs = (value) => {
        this.mobileIs = value;
    }

    setTimerStart = () => {
        this.sessionTimer = true;
    }

    setTabletIs = (value) => {
        this.tabletIs = value;
    }

    setDevices = (value) => {
        this.isTouchDevice = value;
    }

    openPopup = () => {
        this.popupActive = true;
    }

    closePopup = () => {
        this.popupActive = false;
    }

    setSuccessAlert = (value, time) => {
        if (this.timerSuccessAlert) {
            clearTimeout(this.timerSuccessAlert);
        }

        this.successAlert = value;

        if (time) {
            this.timerSuccessAlert = setTimeout(() => {
                this.setSuccessAlert('', 0);
            }, time);
        }
    }

    setErrorAlert = (value, time) => {
        if (this.timerErrorAlert) {
            clearTimeout(this.timerErrorAlert);
        }

        this.errorAlert = value;

        if (time) {
            this.timerErrorAlert = setTimeout(() => {
                this.setErrorAlert('', 0);
            }, time);
        }
    }

    toggleMobileMenu = () => {
        this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
    }

    openMobileMenu = () => {
        this.mobileMenuIsOpen = true;
    }

    closeMobileMenu = () => {
        this.mobileMenuIsOpen = false
    }

    openInnerMenuIsOpen = () => {
        this.innerMenuIsOpen = true;
    }

    closeInnerMenuIsOpen = () => {
        this.innerMenuIsOpen = false;
    }

    updateDisabledMask = (value) => {
        this.disabledMask = value;
    }

    updateRunZoomIsBlock = (value) => {
        this.runZoomIsBlock = value;
    }

    toggleMobileUserMenu = () => {
        this.mobileMenuUserIsOpen = !this.mobileMenuUserIsOpen;
    }

    openMobileUserMenu = () => {
        this.mobileMenuUserIsOpen = true;
    }

    closeMobileUserMenu = () => {
        this.mobileMenuUserIsOpen = false;
    }

    closeAllPopup = () => {
        this.mobileMenuIsOpen = false
        this.mobileMenuUserIsOpen = false;
    }

    hidePreloader = () => {
        this.loading = false;
    }

    showPreloader = () => {
        this.loading = true;
    }

    localeUrl = (value) => {
        return ((this.locale && this.locale !== process.env.REACT_APP_DEFAULT_LOCALE ? "/" + this.locale : '') + value);
    }

    updateUserLoading = (value) => {
        this.userLoading = value;
    }

}

export default new UIStore();
