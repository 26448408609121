import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { NonAuthRoutes } from '../../../routes/routes';
import { Link, useLocation } from 'react-router-dom';
import {postData} from "../../../api/api";
import UIStore from "../../../store/UIStore";
import {useState} from "react";

const EmailConfirmation = () => {
    const intl = useIntl();
    const query = new URLSearchParams(useLocation().search);
    const queryToken = query?.get('confirmationCode');
    const {localeUrl} = UIStore;
    const [emailConfirmationSubmitError, setEmailConfirmationSubmitError] = useState('');
    const [isEmailConfirmationSubmission, setIsEmailConfirmationSubmission] = useState(true);
    const [isEmailConfirmationSubmissionIsSync, setIsEmailConfirmationSubmissionIsSync] = useState(false);

    React.useEffect(() => {
        postData('confirm-email', {emailConfirmationCode: queryToken})
            .then(() => {
                setIsEmailConfirmationSubmission(false);
                setIsEmailConfirmationSubmissionIsSync(true);
            })
            .catch((error) => {
                setEmailConfirmationSubmitError(error);
                setIsEmailConfirmationSubmission(true);
                setIsEmailConfirmationSubmissionIsSync(true);
            });
    }, [queryToken]);

    return (
        <Grid container spacing={6}>
            {isEmailConfirmationSubmission ? (
                <>
                    {isEmailConfirmationSubmissionIsSync && (
                        <Grid item xs={12}>
                            <Typography variant="t3" mb={4} className="error">
                                {emailConfirmationSubmitError.length < 1
                                    ? intl.formatMessage({id: 'auth.newPassword.notVerifyToken'})
                                    : emailConfirmationSubmitError}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Link to={localeUrl(NonAuthRoutes.signin)}>
                            <Button variant="contained" color="primary" autoFocus sx={{width: '100%'}}>
                                {intl.formatMessage({id: 'ok'})}
                            </Button>
                        </Link>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h3"
                            sx={{m: 0}}
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'auth.emailConfirmation.title'})}}
                            gutterBottom
                        ></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            component="p"
                            variant="body1"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'auth.emailConfirmation.description'})}}
                        ></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Link to={localeUrl(NonAuthRoutes.signin)}>
                            <Button variant="contained" color="primary" autoFocus sx={{width: '100%'}}>
                                {intl.formatMessage({id: 'auth.emailConfirmation.button'})}
                            </Button>
                        </Link>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default observer(EmailConfirmation);
