import React from 'react';
import st from './askQuestion.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { NonAuthRoutes } from '../../routes/routes';
import { Button } from '@mui/material';
import {useIntl} from "react-intl";


export const AskQuestion = ({ className = undefined }) => {
    const intl = useIntl();

    return (
        <section className={classNames(st.ask_question, className)}>
            <div className={st.ask_question__container}>
                <div className={st.ask_question__title}
                     dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: 'didNotFind'},
                                 {variable: '<span>Send us your question using the button</span>'})}}>
                </div>

                <Link to={NonAuthRoutes.contacts}>
                    <Button variant="outlined" color="light" size="small" className={st.ask_question__button}>
                        <span>{intl.formatMessage({id: 'askQuestion'})}</span>
                    </Button>
                </Link>
            </div>
        </section>
    );
};
