import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Link, Navigate, useParams } from 'react-router-dom';

import { ReactComponent as StarIcon } from '../../assets/images/icons/star.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/angle-bracket-prev.svg';
import { ReactComponent as ShieldIcon } from '../../assets/images/icons/shield.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/icons/location.svg';
import { ReactComponent as JobIcon } from '../../assets/images/icons/job.svg';
import { ReactComponent as FirstIcon } from '../../assets/images/icons/first.svg';
import { ReactComponent as FileIcon } from '../../assets/images/icons/flag-file.svg';
import { getData, postData } from '../../api/api';
import { useIntl } from 'react-intl';
import {
    Autocomplete,
    Avatar,
    Badge,
    Button,
    Chip,
    Dialog,
    Divider,
    Fade,
    IconButton,
    // Rating,
    Stack,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { stringAvatar } from 'src/helpers/helpers';
import { AuthRoutes, NonAuthRoutes } from 'src/routes/routes';
import Preloader from 'src/components/Preloader';
import classNames from 'classnames';
import st from './ExpertId.module.scss';
import UIStore from 'src/store/UIStore';
import { toJS } from 'mobx';
import DataStore from 'src/store/DataStore';
import stPopup from 'src/components/Popup/popup.module.scss';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/cross.svg';
import { ReactComponent as SelectIcon } from '../../assets/images/icons/arrow.svg';
import stCabinet from '../../layouts/Cabinet/cabinetLayout.module.scss';
import ChatStore from "../../store/ChatStore";


const { getChatList } = ChatStore;

const HireButton = ({ expert, cardType = '', projectData = undefined }) => {
    const intl = useIntl();
    const [open, setOpen] = React.useState(false);
    const [selectingProject, setSelectingProject] = React.useState(false);
    const [selectedProject, setSelectedProject] = React.useState({});
    const { isUserAuthorized, user, userID } = DataStore;
    const { openPopup, setErrorAlert, alertTimers, setSuccessAlert } = UIStore;
    const [alreadyInvited, setAlreadyInvited] = React.useState(false);
    const [isSendingInvite, setIsSendingInvite] = React.useState(false);

    React.useEffect(() => {
        if (projectData?.chatWorkInvitationMessages?.length > 0) {
            if (projectData.chatWorkInvitationMessages.filter((item) => item.invitedGhost?.id === expert?.id).length > 0) {
                setAlreadyInvited(true);
            }
        }
    }, [expert, projectData?.chatWorkInvitationMessages]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectingProject(false);
        setSelectedProject({});
    };

    const inviteExpert = () => {
        setErrorAlert('');
        setSuccessAlert('');
        setIsSendingInvite(true);

        const dataFirstChat = {
            sender: `api/users/${userID}`,
            recipient: `api/experts/${expert.id}`,
            message: {
                text: '',
                // text: intl.formatMessage({id: 'сreatingChat'}),
                sender: `api/users/${userID}`,
            },
        };

        /*        const dataInvite = {
            invitationStatus: 'new',
            sender: `api/users/${userID}`,
            invitedGhost: `api/users/${expert.id}`,
            work: `api/works/${(cardType === 'reply' ? projectData?.id : selectedProject?.id)}`,
            systemMessage: false,
            // chat: `${data.message.chat.slice(1)}`,
            spam: false,
        };

        postData(`api/chat_work_invitation_messages`, dataInvite, 'post')
            .then(({data})=> {
                console.log('data 2' , data);
                setSuccessAlert(intl.formatMessage({id: 'success'}), alertTimers.short);
                handleClose();
            })
            .catch((error) => {
                console.log('error 1');
                setErrorAlert(intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
            });*/

        postData(`api/first-chat-message`, dataFirstChat, 'post')
            .then(({ data }) => {
                if (data?.message?.chat) {
                    const dataInvite = {
                        invitationStatus: 'new',
                        sender: `api/users/${userID}`,
                        invitedGhost: `api/users/${expert.id}`,
                        work: `api/works/${cardType === 'reply' ? projectData?.id : selectedProject?.id}`,
                        systemMessage: false,
                        chat: `${data.message.chat.slice(1)}`,
                        spam: false,
                    };

                    postData(`api/chat_work_invitation_messages`, dataInvite, 'post')
                        .then(({ data }) => {
                            getChatList();
                            setSuccessAlert(intl.formatMessage({ id: 'pendingInvite' }), alertTimers.long);
                            if (projectData) {
                                setAlreadyInvited(true);
                            }
                            handleClose();
                            setIsSendingInvite(false);
                        })
                        .catch((error) => {
                            console.log('error 1');
                            setErrorAlert(intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
                            setIsSendingInvite(false);
                        });
                }
            })
            .catch((error) => {
                console.log('error 2');
                setErrorAlert(intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
                setIsSendingInvite(false);
            });
    };

    return (
        <>
            <Button
                className={classNames(st['sidebar__user-dots'], alreadyInvited ? 'disabled' : '')}
                size="small"
                fullWidth
                variant="contained"
                onClick={isUserAuthorized ? handleClickOpen : openPopup}
                color="secondary"
            >
                {intl.formatMessage({ id: 'expert.hire' })}
            </Button>
            <Dialog TransitionComponent={Fade} onClose={handleClose} open={open} scroll="body" className={classNames(stPopup.popup)}>
                <div className={classNames(stPopup.container, 'popup-container')}>
                    <IconButton className={stPopup.close} onClick={handleClose}>
                        <CrossIcon />
                    </IconButton>

                    {user && !user.active ? (
                        <>
                            <div
                                className={stPopup.title}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'projectsPost.popup.title' }) }}
                            />
                            <div
                                className={stPopup.text}
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({ id: 'projectsPost.popup.description' }),
                                }}
                            />
                            <Link to={`/experts/${userID}/edit`}>
                                <Button sx={{ width: '100%' }} variant="contained">
                                    {intl.formatMessage({ id: 'projectsPost.popup.button' })}
                                </Button>
                            </Link>
                        </>
                    ) : (
                        <>
                            <div className={stPopup.title}>
                                {intl.formatMessage(
                                    { id: 'experts.card.popup.hire.title' },
                                    { name: `${expert.profile?.firstName} ${expert.profile?.lastName}` }
                                )}
                            </div>

                            {selectingProject || cardType === 'reply' ? (
                                <>
                                    <div
                                        className={stPopup.text}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                cardType === 'reply' && projectData
                                                    ? intl.formatMessage(
                                                          { id: 'inviteExpert' },
                                                          {
                                                              expertname: `<strong>${expert?.profile?.firstName} ${expert?.profile?.firstName}</strong>`,
                                                              projectname: `<strong>${projectData.title}</strong>`,
                                                          }
                                                      )
                                                    : intl.formatMessage({ id: 'assign' }),
                                        }}
                                    ></div>

                                    {cardType !== 'reply' && (
                                        <Autocomplete
                                            className={stCabinet.input_box}
                                            popupIcon={<SelectIcon />}
                                            blurOnSelect
                                            filterSelectedOptions
                                            getOptionLabel={(option) => (option.title ? option.title : '')}
                                            isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                            options={user?.works?.length ? user.works.filter((item) => item.status === 'opened') : []}
                                            onChange={(e, newValue) => {
                                                setSelectedProject(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label={intl.formatMessage({ id: 'chooseProject' })}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    )}

                                    <div className={stPopup.buttons}>
                                        <Button variant="outlined" color="primary" fullWidth size="large" onClick={handleClose}>
                                            {intl.formatMessage({ id: 'cancel' })}
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            onClick={inviteExpert}
                                            className={isSendingInvite ? 'disabled' : ''}
                                        >
                                            {intl.formatMessage({ id: cardType === 'reply' ? 'hire' : 'invite' })}
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={stPopup.text}>{intl.formatMessage({ id: 'experts.card.popup.hire.text' })}</div>

                                    <div className={stPopup.buttons}>
                                        <Button variant="outlined" color="primary" fullWidth size="large" onClick={() => setSelectingProject(true)}>
                                            {intl.formatMessage({ id: 'assign' })}
                                        </Button>

                                        <Button
                                            component={Link}
                                            to={`${AuthRoutes.projectsPost}?assignTo=${expert.id}`}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            sx={{ color: 'white !important' }}
                                        >
                                            {intl.formatMessage({ id: 'experts.card.popup.hire.create' })}
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </Dialog>
        </>
    );
};

const Review = ({ data }) => {
    const intl = useIntl();
    const { tabletIs } = UIStore;

    const historyDate = (value) => {
        const date = intl.formatDate(value, {
            month: tabletIs ? 'short' : 'long',
            day: 'numeric',
            year: 'numeric',
        });

        const time = intl.formatDate(value, {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });

        return `${date}\u00A0${time}`;
    };

    return (
        <div className={st.review}>
            <div className={st['review__top']}>
                <Avatar
                    src={data?.sender?.profile?.avatar ? axios.defaults.baseURL + data?.sender?.profile?.avatar.contentUrl : ''}
                    className={st.review__avatar}
                    sx={{
                        width: { xs: '24px', md: '40px' },
                        height: { xs: '24px', md: '40px' },
                    }}
                    {...stringAvatar(`${data?.firstName} ${data?.lastName}`)}
                />
                <Typography variant="h6" className={st['review__name']}>
                    {`${data?.sender?.profile?.firstName} ${data?.sender?.profile?.lastName}`}
                </Typography>
                <div className={st['review__stars']}>
                    {[...Array(data?.stars)].map((item, index) => (
                        <StarIcon className={st['review__star']} key={index} />
                    ))}
                </div>
                <div className={st['review__date']}>{historyDate(data?.createdAt)}</div>
            </div>

            <div className={st['review__text']}>{data?.message}</div>
        </div>
    );
};

const File = ({ name = undefined, data }) => {
    return (
        <a href={axios.defaults.baseURL + data?.documentFile?.contentUrl} target="_blank" className={st.file} rel="noreferrer">
            <div className={st.file__media}>
                <FileIcon />
            </div>
            <div className={st.file__content}>
                {data?.title ? <div className={st.file__name}>{data?.title}</div> : <div className={st.file__name}>{name}</div>}
                {data?.size && <div className={st.file__size}>{data.size}</div>}
            </div>
            {data?.year && <div className={st.file__year}> ({data.year})</div>}
        </a>
    );
};

/*const NewReviewButton = ({ data }) => {
    const intl = useIntl();
    const { tabletIs, setSuccessAlert, setErrorAlert, alertTimers } = UIStore;
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [stars, setStars] = React.useState(5);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
        console.log(toJS(data));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleForm = () => {
        const message = {
            message: value,
            stars: stars,
            expert: `api/experts/${data.id}`,
        };

        postData(`api/reviews`, message, 'post')
            .then(({ data }) => {
                console.log(data);
                setOpen(false);
                setSuccessAlert(intl.formatMessage({ id: 'messageSent' }), alertTimers.short);
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? error : intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
            });
    };

    return (
        <>
            <Button className={st['reviews__button']} variant="contained" color="primary" onClick={handleClickOpen}>
                {intl.formatMessage({ id: 'expert.reviews.newReview' })}
            </Button>

            <Dialog TransitionComponent={Fade} onClose={handleClose} open={open} scroll="body" className={classNames(stPopup.popup)}>
                <div className={classNames(stPopup.container, 'popup-container')}>
                    <IconButton className={stPopup.close} onClick={handleClose}>
                        <CrossIcon />
                    </IconButton>
                    <div className={stPopup.title}>
                        {intl.formatMessage(
                            { id: 'experts.card.popup.review.title' },
                            { name: `${data.profile?.firstName} ${data.profile?.lastName}` }
                        )}
                    </div>
                    <div className={stPopup.text}>
                        <p>{intl.formatMessage({ id: 'experts.card.popup.review.stars' })}</p>
                        <Rating
                            className={st.reviews__stars}
                            name="stars-controller"
                            value={stars}
                            onChange={(event, newValue) => {
                                setStars(newValue);
                            }}
                        />
                    </div>

                    <div className={stPopup.text}>
                        <TextField
                            fullWidth
                            placeholder={intl.formatMessage({ id: 'experts.card.popup.review.text' })}
                            multiline
                            minRows={3}
                            maxRows={tabletIs ? 4 : 8}
                            value={value}
                            onChange={handleChange}
                        />
                    </div>

                    <Button variant="contained" color="primary" fullWidth size="large" onClick={handleForm}>
                        {intl.formatMessage({ id: 'experts.card.popup.review.send' })}
                    </Button>
                </div>
            </Dialog>
        </>
    );
};*/

const MessageButton = ({ data }) => {
    const intl = useIntl();
    const { user, userID } = DataStore;
    const { tabletIs, setSuccessAlert, setErrorAlert, alertTimers } = UIStore;
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
        console.log(toJS(data));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleForm = () => {
        const message = {
            sender: `api/users/${userID}`,
            recipient: `api/experts/${data.id}`,
            message: {
                text: value,
                sender: `api/users/${userID}`,
            },
        };

        postData(`api/first-chat-message`, message, 'post')
            .then(({ data }) => {
                getChatList();
                setOpen(false);
                setSuccessAlert(
                    <>
                        {intl.formatMessage({ id: 'messageSent' })}{' '}
                        {<Link to={AuthRoutes.messages}>{intl.formatMessage({ id: 'messageSent.link' })}</Link>}
                    </>,
                    alertTimers.short
                );
            })
            .catch((error) => {
                console.log(error);
                setErrorAlert(error ? error : intl.formatMessage({ id: 'errorPosting' }), alertTimers.long);
            });
    };

    return (
        <>
            <Button className={st['sidebar__user-button']} fullWidth variant="contained" color="primary" onClick={handleClickOpen}>
                {intl.formatMessage({ id: 'message' })}
            </Button>

            <Dialog TransitionComponent={Fade} onClose={handleClose} open={open} scroll="body" className={classNames(stPopup.popup)}>
                <div className={classNames(stPopup.container, 'popup-container')}>
                    <IconButton className={stPopup.close} onClick={handleClose}>
                        <CrossIcon />
                    </IconButton>
                    {user && !user.active ? (
                        <>
                            <div
                                className={stPopup.title}
                                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'projectsPost.popup.title' }) }}
                            />
                            <div
                                className={stPopup.text}
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({ id: 'projectsPost.popup.description' }),
                                }}
                            />
                            <Link to={`/experts/${userID}/edit`}>
                                <Button sx={{ width: '100%' }} variant="contained">
                                    {intl.formatMessage({ id: 'projectsPost.popup.button' })}
                                </Button>
                            </Link>
                        </>
                    ) : (
                        <>
                            <div className={stPopup.title}>
                                {intl.formatMessage(
                                    { id: 'experts.card.popup.message.title' },
                                    { name: `${data.profile?.firstName} ${data.profile?.lastName}` }
                                )}
                            </div>

                            <div className={stPopup.text}>
                                <TextField
                                    fullWidth
                                    placeholder={intl.formatMessage({ id: 'experts.card.popup.message.text' })}
                                    multiline
                                    minRows={3}
                                    maxRows={tabletIs ? 4 : 8}
                                    value={value}
                                    onChange={handleChange}
                                />
                            </div>

                            <Button variant="contained" color="primary" fullWidth size="large" onClick={handleForm}>
                                {intl.formatMessage({ id: 'experts.card.popup.message.send' })}
                            </Button>
                        </>
                    )}
                </div>
            </Dialog>
        </>
    );
};

const ExpertId = () => {
    const intl = useIntl();
    const { id } = useParams();
    const { locale } = UIStore;
    const { isUserAuthorized, userID, userType } = DataStore;
    const [expert, setExpert] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        let isMounted = true;

        if (!expert) {
            getData(`api/experts/${id}`)
                .then(({ data }) => {
                    if (isMounted) {
                        setExpert(data);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }

        return () => {
            isMounted = false;
            // setExpert(null);
            // setLoading(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function StepIconDot(props) {
        const { active } = props;

        return <div className={classNames(st.step__iconDot, active ? st.step__iconDot_active : '')}></div>;
    }

    if (!userID || loading) {
        return <Preloader />;
    } else {
        if (expert && !expert.active) {
            if (expert.id === userID) {
                return <Navigate to={`/experts/${userID}/edit`} />;
            } else {
                return <Navigate to={NonAuthRoutes.error} />;
            }
        } else if (!expert) {
            return <Navigate to={NonAuthRoutes.error} />;
        }
    }

    return (
        <section className={st.wrapper}>
            <aside className={st.sidebar}>
                <Link to={NonAuthRoutes.experts} className={st.back}>
                    <ArrowIcon />
                    {intl.formatMessage({ id: 'back' })}
                </Link>
                {expert.id === userID && (
                    <Link to={`/experts/${userID}/edit`} className={classNames(st.profileEdit, st.profileEdit_mobile)}>
                        {intl.formatMessage({ id: 'profileEdit' })}
                    </Link>
                )}

                <div className={st.sidebar__user}>
                    <div className={st['sidebar__user-media']}>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                            className={classNames('status', 'status_big', expert.online ? 'status_online' : 'status_offline')}
                        >
                            <Avatar
                                src={expert.profile?.avatar ? axios.defaults.baseURL + expert.profile?.avatar.contentUrl : ''}
                                className={st.avatar}
                                sx={{
                                    width: { xs: '136px', md: '180px', lg: '240px' },
                                    height: { xs: '136px', md: '180px', lg: '240px' },
                                }}
                                {...stringAvatar(`${expert.profile?.firstName} ${expert.profile?.lastName}`)}
                            />
                        </Badge>
                    </div>

                    <Typography
                        variant="h4"
                        className={st['sidebar__user-name']}
                    >{`${expert.profile?.firstName} ${expert.profile?.lastName}`}</Typography>

                    {expert.rate || expert.reviewsCount ? (
                        <div className={st.rating}>
                            {expert.rate ? (
                                <>
                                    <div className={st.rating__number}>{expert.rate}</div>
                                    <StarIcon className={st.rating__star} />
                                </>
                            ) : (
                                ''
                            )}

                            {expert.reviewsCount ? <div className={st.rating__text}>{expert.reviewsCount} reviews</div> : ''}
                        </div>
                    ) : (
                        ''
                    )}

                    {expert.availableForFreelanceJob && (
                        <div className={st['sidebar__user-freelance']}>{intl.formatMessage({ id: 'expert.freelance' })}</div>
                    )}

                    <Divider />

                    {expert.ratePer || expert.jobsCount ? (
                        <div className={st['sidebar__user-info']}>
                            {expert.ratePer ? (
                                <div className={st['sidebar__user-price']}>
                                    <span>${expert.ratePer}</span>

                                    {intl.formatMessage({ id: 'expert.perMins' }, { min: 15 })}
                                </div>
                            ) : (
                                ''
                            )}
                            {/* {expert.jobsCount && ( */}
                            <div className={st['sidebar__user-job']}>
                                <span>{expert.jobsCount}</span>

                                {intl.formatMessage({ id: 'expert.sessionJobs' })}
                            </div>
                            {/* )} */}
                        </div>
                    ) : (
                        ''
                    )}
                    {isUserAuthorized && userType !== 'expert' && (
                        <div className={st['sidebar__user-buttons']}>
                            <MessageButton data={expert} />

                            <HireButton expert={expert} cardType="" projectData={undefined} />
                        </div>
                    )}
                </div>

                {expert.verified && (
                    <div className={st.verify}>
                        <ShieldIcon className={st.verify__icon} />
                        <Typography variant="h5" component="h4" className={st.verify__title}>
                            {intl.formatMessage({ id: 'expert.verify.title' })}
                        </Typography>
                        <Typography className={st.verify__text} variant="body1">
                            {intl.formatMessage({ id: 'expert.verify.text' })}
                        </Typography>
                    </div>
                )}
            </aside>

            <div className={st.content}>
                {expert.id === userID && (
                    <Link to={`/experts/${userID}/edit`} className={classNames(st.profileEdit, st.profileEdit_desktop)}>
                        {intl.formatMessage({ id: 'profileEdit' })}
                    </Link>
                )}

                <div className={st.body}>
                    <div className={st.main__content}>
                        <div className={st.main__info}>
                            <Typography variant="h4" className={st.profession}>
                                <span>{expert.positions[0]?.title ?? ''}</span>
                            </Typography>

                            <div className={st.main__information}>
                                {expert.profile?.country && expert.profile?.city && (
                                    <div className={st.location}>
                                        <LocationIcon />
                                        <span>
                                            {expert.profile?.country?.title}, {expert.profile?.city?.title} {expert.profile?.timeZone}
                                        </span>
                                    </div>
                                )}
                                {expert.jobs && (
                                    <div className={st.job}>
                                        <JobIcon />
                                        <span>
                                            {expert.jobs} {intl.formatMessage({ id: 'app.header.jobs' })}
                                        </span>
                                    </div>
                                )}
                                {expert.firstSessionFree && (
                                    <div className={st.firstText}>
                                        <FirstIcon />
                                        <span>{intl.formatMessage({ id: 'expert.firstText' })}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {expert.profile?.about && <p className={st.desc}>{expert.profile.about}</p>}

                        <Divider />

                        {(expert.softwareEquipments?.length > 0 || expert.hardwareEquipments?.length > 0) && (
                            <>
                                <div className={st.main__skills}>
                                    <Typography className={st['main__skills-title']} variant="h5" mt={2}>
                                        {intl.formatMessage({ id: 'expert.skills.skillsTitle' })}
                                    </Typography>
                                    {expert.professionalSkills.map((skill) => (
                                        <ul className={st['main__skills-list']} key={skill.id}>
                                            <li>
                                                {skill.skillCategory ? `${skill.skillCategory?.title}: ` : ''}
                                                {skill.skillSubCategories?.map((subCat, index) => {
                                                    return subCat.title + (index < skill.skillSubCategories.length - 1 ? ', ' : '');
                                                })}
                                            </li>
                                        </ul>
                                    ))}
                                </div>
                            </>
                        )}

                        <Divider />

                        {(expert.softwareEquipments?.length > 0 || expert.hardwareEquipments?.length > 0) && (
                            <>
                                <div className={st.main__skills}>
                                    <Typography className={st['main__skills-title']} variant="h5">
                                        {intl.formatMessage({ id: 'expert.skills.equipmentsTitle' })}
                                    </Typography>
                                    {expert.softwareEquipments?.length > 0 && (
                                        <div className={st.main__stack}>
                                            <div className={st['main__skills-label']}>{intl.formatMessage({ id: 'expert.skills.softwares' })}</div>

                                            <Stack direction="row" spacing={1}>
                                                {expert.softwareEquipments.map((skill, i) => (
                                                    <Chip key={i + 'softwares'} size="small" label={skill.title} />
                                                ))}
                                            </Stack>
                                        </div>
                                    )}

                                    {expert.hardwareEquipments?.length > 0 && (
                                        <div className={st.main__stack}>
                                            <div className={st['main__skills-label']}>{intl.formatMessage({ id: 'expert.skills.hardwares' })}</div>

                                            <Stack direction="row" spacing={1}>
                                                {expert.hardwareEquipments.map((skill, i) => (
                                                    <Chip key={i + 'hardwares'} size="small" label={skill.title} />
                                                ))}
                                            </Stack>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        <Divider />

                        {(expert.diplomas?.length > 0 || expert.certifications?.length > 0 || expert.appreciationLetters?.length > 0) && (
                            <div className={st.main__skills}>
                                <Typography className={st['main__skills-title']} variant="h5" mt={2}>
                                    {intl.formatMessage({ id: 'documents' })}
                                </Typography>
                                {expert.certifications?.length > 0 && (
                                    <div className={st.main__files}>
                                        <div className={st['main__skills-label']}>{intl.formatMessage({ id: 'cv' })}</div>
                                        {expert.certifications.map((file) => (
                                            <File key={file.id} name="Certifications" data={file} />
                                        ))}
                                    </div>
                                )}
                                {expert.diplomas?.length > 0 && (
                                    <div className={st.main__files}>
                                        <div className={st['main__skills-label']}>{intl.formatMessage({ id: 'diploma' })}</div>
                                        {expert.diplomas.map((file) => (
                                            <File key={file.id} name="Diplomas" data={file} />
                                        ))}
                                    </div>
                                )}
                                {expert.appreciationLetters?.length > 0 && (
                                    <div className={st.main__files}>
                                        <div className={st['main__skills-label']}>{intl.formatMessage({ id: 'appreciationLetter' })}</div>
                                        {expert.appreciationLetters.map((file) => (
                                            <File key={file.id} name="Appreciation letters" data={file} />
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        <Divider />

                        {expert.certifications?.length > 0 && (
                            <>
                                <div className={st.main__skills}>
                                    <Typography className={st['main__skills-title']} variant="h5">
                                        {intl.formatMessage({ id: 'expert.skills.certifications' })}
                                    </Typography>
                                    <ul className={classNames(st['main__skills-list'], st['main__skills-list_special'])}>
                                        {expert.certifications.map((file) => (
                                            <li key={file.id}>
                                                {/*{file.year && (
                                                    <div className="text">
                                                        {file.year}  {file.title}
                                                    </div>
                                                )} */}
                                                <File key={file.id} data={file} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}

                        <Divider />

                        {expert.employmentHistories?.length > 0 && (
                            <>
                                <div className={st.step}>
                                    <Typography className={st['main__skills-title']} variant="h5">
                                        {intl.formatMessage({ id: 'expert.skills.employmentTitle' })}
                                    </Typography>
                                    <Stepper
                                        connector={
                                            <div className={st.step__connector}>
                                                <div className={st.step__line} />
                                            </div>
                                        }
                                        orientation="vertical"
                                        className={st.step}
                                    >
                                        {expert.employmentHistories.map((item) => {
                                            const dateStart = new Date(item.startDate);
                                            const dateEnd = new Date(item.endDate);

                                            return (
                                                <Step key={item.id} active={item.stillWorking} className={st.step__item}>
                                                    <StepLabel StepIconComponent={StepIconDot} className={st.step__label}>
                                                        <div className={st.step__title}>
                                                            {`${dateStart.toLocaleString(locale, {
                                                                month: 'long',
                                                            })} ${dateStart.getFullYear()} - ${
                                                                item.stillWorking
                                                                    ? intl.formatMessage({ id: 'expert.skills.datePresent' })
                                                                    : `${dateEnd.toLocaleString(locale, {
                                                                          month: 'long',
                                                                      })} ${dateEnd.getFullYear()}`
                                                            }`}
                                                        </div>
                                                        <div className={st.step__text}>
                                                            {item.employer && <b>{item.employer} </b>}
                                                            {item.jobTitle}
                                                        </div>
                                                    </StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </div>
                            </>
                        )}
                    </div>

                    <div className={st.main__right}>
                        <div className={st.main__services}>
                            {expert.services?.length > 0 && (
                                <div className={st.main__skills}>
                                    <Typography className={st['main__skills-title']} variant="h5">
                                        {intl.formatMessage({ id: 'services' })}
                                    </Typography>

                                    <ul className={st['main__skills-list']}>
                                        {expert.services.map((text, i) => (
                                            <li key={i + text.id}>{text.title}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className={st.main__languages}>
                            {expert.profile?.languages?.length > 0 && (
                                <div className={st.main__skills}>
                                    <Typography className={st['main__skills-title']} variant="h5">
                                        {intl.formatMessage({ id: 'expert.skills.languages' })}
                                    </Typography>

                                    <ul className={st['main__skills-list']}>
                                        {expert.profile?.languages.map((language, i) => (
                                            <li key={i + language.id}>{language.title}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={st.reviews}>
                    <Typography variant="h4" className={st.reviews__title}>
                        {intl.formatMessage({ id: 'expert.reviews.title' })}{' '}
                        <span>{intl.formatMessage({ id: 'expert.reviews.count' }, { count: expert.reviewsCount ?? 0 })}</span>
                    </Typography>

                    {expert.reviews.length ? (
                        <>
                            <div className={st.reviews__list}>
                                {expert.reviews.map((review, index) => (
                                    <Review key={'review' + index} data={review} />
                                ))}
                            </div>

                            {/*<div className={st.reviews__buttons}>
                                 <Button className={st.reviews__button} variant="contained" color="secondary">
                                    {intl.formatMessage({ id: 'expert.reviews.loadMore' })}
                                </Button>

                                {userType !== 'expert' && <NewReviewButton data={expert} />}
                            </div>*/}
                        </>
                    ) : (
                        <>
                            <div className={st.reviews__layout}>{intl.formatMessage({ id: 'expert.reviews.noReviews' })}</div>

                            {/*{userType !== 'expert' && (
                                <div className={st.reviews__buttons}>
                                    <NewReviewButton data={expert} />
                                </div>
                            )}*/}
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default observer(ExpertId);
