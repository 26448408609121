import enLocale from 'date-fns/locale/en-US';
// import deLocale from "date-fns/locale/de";

const UserRoles = {
    client: 'ROLE_CLIENT',
    company: 'ROLE_COMPANY',
    expert: 'ROLE_EXPERT',
    owner: 'ROLE_ADMIN',
};

const LocaleMap = {
    en: enLocale,
    // de: deLocale,
};

// declare module '@mui/material/styles' {
//     interface Palette {
//         light: Palette['primary'];
//     }
//     interface PaletteOptions {
//         light: PaletteOptions['primary'];
//     }
// }

export { UserRoles, LocaleMap };
