import React, {useState} from 'react';
import isEqual from "lodash/isEqual";
import {
    FormGroup,
    InputLabel,
    Slider,
    Input,
    Box,
    Grid,
    TextField,
    Autocomplete,
    Dialog,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    IconButton,
} from '@mui/material';
import {Field, FormikProvider, useFormik} from 'formik';
import { useIntl } from 'react-intl';
import st from './filters.module.scss';
import DataStore from 'src/store/DataStore';
import FiltersStore, { searchParametersDefault } from 'src/store/FiltersStore';
import { getData } from 'src/api/api';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/cross.svg';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Collapse from '@mui/material/Collapse';
import { FilterAutocomplete } from './AutoComplete';
import { useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { ConditionalWrapper } from '../ConditionalWrapper';
import stCabinet from "../../layouts/Cabinet/cabinetLayout.module.scss";
import {ReactComponent as FilterIcon} from "../../assets/images/icons/filter.svg";
import UIStore from "../../store/UIStore";
import 'core-js/stable/structured-clone';
import structuredClone from "@ungap/structured-clone";
import stPopup from 'src/components/Popup/popup.module.scss';


const Filters = ({ type = 'experts' }) => {
    const intl = useIntl();
    const firstSessionFreeOptions = [
        { title: intl.formatMessage({ id: 'doesntMatter' }), value: null },
        { title: intl.formatMessage({ id: 'yes' }), value: 1 },
        { title: intl.formatMessage({ id: 'no' }), value: 0 },
    ];
    const {tabletIs} = UIStore;
    const {
        positions,
        services,
        // industry,
        skillCategories,
        skillSubCategories,
        hardwareEquipments,
        softwareEquipments,
        updatePositions,
        updateServices,
        updateSkillCategories,
        updateSkillSubCategories,
        updateHardwareEquipments,
        updateSoftwareEquipments,
    } = DataStore;
    const {
        filterActive,
        setFilterOrSort,
        updateSearchParameters,
        activeCities,
        updateActiveCities,
        activeCountries,
        updateActiveCountries,
        activeLanguages,
        updateActiveLanguages,
        getList,
    } = FiltersStore;
    const [mobileFilters, setMobileFilters] = React.useState(false);
    const [minDistance, setMinDistance] = React.useState(1);
    const [rateMin, setRateMin] = React.useState(0);
    const [rateMax, setRateMax] = React.useState(0);
    const [opened, setOpened] = React.useState(false);
    const [requestIsDone, setRequestIsDone] = useState(false);
    const [firstSessionFreeValue, setFirstSessionFreeValue] = useState(firstSessionFreeOptions[0]);
    const [valuesPositions, setValuesPositions] = useState([]);
    const [valuesServices, setValuesServices] = useState([]);
    const [valuesRate, setValuesRate] = useState([0, 0]);
    const [valuesType, setValuesType] = useState('');
    const [valuesSoftware, setValuesSoftware] = useState([]);
    const [valuesHardware, setValuesHardware] = useState([]);
    const [valuesSkillCategories, setValuesSkillCategories] = useState(null);
    const [valuesSkillSubCategories, setValuesSkillSubCategories] = useState([]);
    const [valuesLanguages, setValuesLanguages] = useState([]);
    const [valuesCountries, setValuesCountries] = useState([]);
    const [valuesCities, setValuesCities] = useState([]);
    const [searchUrlParams] = useSearchParams();

    const formFilters = useFormik({
        initialValues: structuredClone(searchParametersDefault),
    });

    const handleMoreClick = () => {
        setOpened((prev) => !prev);
    };

    const searchUrlParamObj = Object.fromEntries([...searchUrlParams]);

    const handleTypeClick = (e) => {
        if (e.target.value === formFilters.values.type) {
            formFilters.setFieldValue('type', '');
            setValuesType('');
            setFilterOrSort('type', '', 'projects');
        }
    }

    React.useEffect(() => {
        if (tabletIs) {
            setOpened(true);
        }
    }, [tabletIs]);

    React.useEffect(() => {
        setRequestIsDone(false);
    }, [searchUrlParams]);

    React.useEffect(() => {
        // 1. Parsing url for filter`s values and setting them.
        // 2. Get (Expert || Projects) List.
        if (requestIsDone) {
            return;
        }

        if (!isEmpty(searchUrlParamObj)) {
            if (
                !requestIsDone &&
                (!searchUrlParamObj.hasOwnProperty('positions[0]') || positions.length > 0) &&
                (!searchUrlParamObj.hasOwnProperty('services[0]') || services.length > 0) &&
                (!searchUrlParamObj.hasOwnProperty('profile.country[0]') || activeCountries.length > 0) &&
                (!searchUrlParamObj.hasOwnProperty('profile.city[0]') || activeCities.length > 0) &&
                (!searchUrlParamObj.hasOwnProperty('profile.languages[0]') || activeLanguages.length > 0) &&
                (!searchUrlParamObj.hasOwnProperty('softwareEquipments[0]') || softwareEquipments.length > 0) &&
                (!searchUrlParamObj.hasOwnProperty('hardwareEquipments[0]') || hardwareEquipments.length > 0) &&
                (!searchUrlParamObj.hasOwnProperty('professionalSkills.skillCategory') || skillCategories.length > 0) &&
                (!searchUrlParamObj.hasOwnProperty('professionalSkills.skillSubCategories[0]') ||
                    (skillCategories.length > 0 && skillSubCategories.length > 0))
            ) {
                setRequestIsDone(true);

                for (let [key, value] of Object.entries(searchUrlParamObj)) {
                    if (key === 'rate[1]') {
                        continue;
                    }

                    if (key === 'rate[0]') {
                        if (searchUrlParamObj.hasOwnProperty('rate[1]')) {
                            let newValue = [Number(value), Number(searchUrlParamObj['rate[1]'])];
                            setValuesRate(newValue);
                            formFilters.values['rate'] = newValue;
                        }
                    } else {
                        key = key.replace(/\[.*\]?/, '');

                        if (formFilters.values.hasOwnProperty(key)) {
                            if (['firstSessionFree', 'professionalSkills.skillCategory', 'type'].includes(key)) {
                                formFilters.values[key] = value;

                                if (key === 'firstSessionFree') {
                                    setFirstSessionFreeValue(Number(value) === 1 ? firstSessionFreeOptions[1] : firstSessionFreeOptions[2]);
                                } else if (key === 'type') {
                                    setValuesType(value);
                                } else {
                                    setValuesSkillCategories(skillCategories.find((item) => item.id === Number(value)));
                                }
                            } else {
                                if (!formFilters.values[key].includes(value)) {
                                    formFilters.values[key].push(value);
                                }

                                if (key === 'positions') {
                                    setValuesPositions((prev) => [...prev, ...positions.filter((item) => item.id === Number(value))]);
                                } else if (key === 'services') {
                                    setValuesServices((prev) => [...prev, ...services.filter((item) => item.id === Number(value))]);
                                } else if (key === 'softwareEquipments') {
                                    setValuesSoftware((prev) => [...prev, ...softwareEquipments.filter((item) => item.id === Number(value))]);
                                } else if (key === 'hardwareEquipments') {
                                    setValuesHardware((prev) => [...prev, ...hardwareEquipments.filter((item) => item.id === Number(value))]);
                                } else if (key === 'profile.country') {
                                    setValuesCountries((prev) => [...prev, ...activeCountries.filter((item) => item.id === Number(value))]);
                                } else if (key === 'profile.city') {
                                    setValuesCities((prev) => [...prev, ...activeCities.filter((item) => item.id === Number(value))]);
                                } else if (key === 'profile.languages') {
                                    setValuesLanguages((prev) => [...prev, ...activeLanguages.filter((item) => item.id === Number(value))]);
                                } else if (key === 'professionalSkills.skillSubCategories') {
                                    setValuesSkillSubCategories((prev) => [...prev, ...skillSubCategories.filter((item) => item.id === Number(value))]);
                                }
                            }
                        }
                    }
                }

                updateSearchParameters(formFilters.values);
                setOpened(true);
                getList(type, formFilters.values);
            }
        } else {
            setRequestIsDone(true);
            getList(type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        positions,
        services,
        softwareEquipments,
        hardwareEquipments,
        skillCategories,
        skillSubCategories,
        activeCountries,
        activeCities,
        activeLanguages,
        searchUrlParams,
    ]);

    const setNewRange = (value, name, isSubmit = true) => {
        setValuesRate(value);
        formFilters.setFieldValue(name, value);
        if (isSubmit) {
            setFilterOrSort(name, value, 'experts');
        }
    }

    const handleInputRateChange = (event) => {
        const value = Number(event.target.value.replace(/[^0-9]/g, ''));

        if (event.target.name === 'min') {
            setValuesRate(prevState => [(value < rateMin ? rateMin : value), prevState[1]]);
        } else if (event.target.name === 'max') {
            setValuesRate(prevState => [prevState[0], (value > rateMax ? rateMax : value)]);
        }
    };

    const handleSliderRateChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        setNewRange(newValue, event.target.name, false);
/*        const name = event.target.name;

        if (newValue[1] - newValue[0] < minDistance) {
            let clamped;

            if (activeThumb === 0) {
                clamped = Math.min(newValue[0], formFilters.initialValues[name][1] - minDistance);
            } else {
                clamped = Math.max(newValue[1], minDistance);
            }

            setNewRange([clamped, clamped + minDistance], name, false)
        } else {
            setNewRange(newValue, name, false);
        }*/
    };

    const handleInputRateCompleted = (event, id) => {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value.replace(/[^0-9]/g, ''))));
        let newRange = [];

        if (event.target.name === 'min') {
            newRange = [((value < rateMin || value > formFilters.values[id][1]) ? rateMin : value), formFilters.values[id][1]];
        } else if (event.target.name === 'max') {
            newRange = [formFilters.values[id][0], ((value > rateMax || value < formFilters.values[id][0]) ? rateMax : value)];
        }

        setNewRange(newRange, id);
    };

    const handleSliderRateCompleted = (event, newValue, id) => {
        if (newValue[1] <= newValue[0]) {
            newValue[1] = newValue[1] + 1;
        }
        if (newValue[0] >= newValue[1]) {
            newValue[0] = newValue[0] - 1;
        }

        setNewRange(newValue, id);
    };

    React.useEffect(() => {
        if (type === 'experts') {
            if (activeLanguages.length === 0) {
                getData('api/languages?expertActive=1&perPage=200').then(({data}) => {
                    updateActiveLanguages(data.items);
                });
            }

            if (activeCountries.length === 0) {
                getData('api/countries?expertActive=1&perPage=200').then(({data}) => {
                    updateActiveCountries(data.items);
                });
            }

            if (activeCities.length === 0) {
                getData('api/cities?expertActive=1&perPage=200').then(({data}) => {
                    updateActiveCities(data.items);
                });
            }

            if (rateMin === 0 && rateMax === 0) {
                getData('api/expert-rate-min-max').then(({data}) => {
                    // if (isEqual (formFilters.values.rate, [0, 0])) {
                    if (isEqual(valuesRate, [0, 0]) && !searchUrlParamObj.hasOwnProperty('rate[0]')) {
                        let newRateValue = [Number(data.minRate), Number(data.maxRate)];
                        setValuesRate(newRateValue);
                        formFilters.setFieldValue('rate', newRateValue);
                    }

                    setRateMin(Number(data.minRate));
                    setRateMax(Number(data.maxRate));

                    if (data.maxRate - data.minRate < 100 && data.maxRate - data.minRate > 51) {
                        setMinDistance(10);
                    } else if (data.maxRate - data.minRate < 50 && data.maxRate - data.minRate > 10) {
                        setMinDistance(5);
                    } else {
                        setMinDistance(1);
                    }
                });
            }
        }

        if (positions.length === 0) {
            getData(`api/positions`).then(({ data }) => {
                updatePositions(data.items);
            });
        }

        if (services.length === 0) {
            getData(`api/services`).then(({ data }) => {
                updateServices(data.items);
            });
        }

        if (skillCategories.length === 0) {
            getData('api/skill_categories').then(({ data }) => {
                updateSkillCategories(data.items);
            });
        }

        if (skillSubCategories.length === 0) {
            getData('api/skill_sub_categories?perPage=200').then(({ data }) => {
                updateSkillSubCategories(data.items);
            });
        }

        if (hardwareEquipments.length === 0) {
            getData('api/hardware_equipments?perPage=70').then(({ data }) => {
                updateHardwareEquipments(data.items);
            });
        }

        if (softwareEquipments.length === 0) {
            getData('api/software_equipments?perPage=70').then(({ data }) => {
                updateSoftwareEquipments(data.items);
            });
        }

/*        if (jobs[1] <= 1) {
            getData('api/expert-jobs-min-max').then(({ data }) => {
                updateRate([Number(data.minRate), Number(data.maxRate)]);
                formFilters.initialValues.jobs = [Number(data.minRate), Number(data.maxRate)];

                if (data.maxRate - data.minRate < 100 && data.maxRate - data.minRate > 51) {
                    setMinDistance(10);
                } else if (data.maxRate - data.minRate < 50 && data.maxRate - data.minRate > 10) {
                    setMinDistance(5);
                } else {
                    setMinDistance(1);
                }
            });
        }*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {tabletIs &&
                <Button color="secondary" variant="contained" fullWidth className={st.filters_button} onClick={() => setMobileFilters(true)}>
                    <FilterIcon />

                    <div className={filterActive ? st.is_filter : ''}>
                        {intl.formatMessage({ id: 'experts.filter' })}
                    </div>
                </Button>
            }

        <ConditionalWrapper
            condition={tabletIs}
            wrapper={(children) => (
                <Dialog scroll="paper" open={mobileFilters} onClose={() => setMobileFilters(false)} className={stPopup.popup}>
                    <IconButton className={stPopup.close} onClick={() => setMobileFilters(false)}>
                        <CloseIcon />
                    </IconButton>
                    {children}
                </Dialog>
            )}
        >
            <FormikProvider value={formFilters}>
                <Box component="form" noValidate className={st.filters}>
                    <Grid container spacing={2}>
                        {type === 'projects' &&
                            <Grid item xs={12}>
                                <Field
                                    name="type"
                                    children={(fieldProps) => (
                                        <RadioGroup
                                            {...fieldProps.field}
                                            onChange={(e, newValue) => {
                                                setFilterOrSort('type', newValue, 'projects');
                                                formFilters.setFieldValue('type', newValue);
                                                setValuesType(newValue);
                                            }}
                                            defaultValue={valuesType}
                                            row={true}
                                            className={st.radio}
                                        >
                                            <FormControlLabel
                                                value="sessions"
                                                className={classNames(stCabinet.radio_button, st.radio_button, (formFilters.values.type === 'sessions' ? `${stCabinet.active} button-active` : ''))}
                                                control={<Radio className={stCabinet.radio_button__radio}/>}
                                                label={intl.formatMessage({id: "session"})}
                                                onClick={e => handleTypeClick(e)}
                                            />

                                            <FormControlLabel
                                                value="projects"
                                                className={classNames(stCabinet.radio_button, st.radio_button, (formFilters.values.type === 'projects' ? `${stCabinet.active} button-active` : ''))}
                                                control={<Radio className={stCabinet.radio_button__radio}/>}
                                                label={intl.formatMessage({id: "project"})}
                                                onClick={e => handleTypeClick(e)}
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <FilterAutocomplete
                                name={'positions'}
                                values={valuesPositions}
                                options={positions?.filter((item) => item.type === 'expert')}
                                callback={(newValue) => {
                                    formFilters.setFieldValue('positions', newValue);
                                    setValuesPositions(newValue);
                                }}
                                label={intl.formatMessage({ id: 'experts.filters.expertType' })}
                                type={type}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FilterAutocomplete
                                name={'services'}
                                values={valuesServices}
                                options={services}
                                callback={(newValue) => {
                                    formFilters.setFieldValue('services', newValue);
                                    setValuesServices(newValue);
                                }}
                                label={intl.formatMessage({ id: 'services' })}
                                type={type}
                            />
                        </Grid>

                        {/*                    <Grid item xs={12}>
                            <FilterAutocomplete
                                name={'industry'}
                                options={industry}
                                callback={(values) => {
                                    formFilters.setFieldValue('industry', values);
                                }}
                                label={intl.formatMessage({ id: 'experts.filters.industry' })}
                                type={type}
                            />
                        </Grid>*/}

                        <Grid item xs={12}>
                            <Autocomplete
                                name="professionalSkills.skillCategory"
                                className={st.input__box}
                                popupIcon={<ArrowIcon />}
                                blurOnSelect
                                getOptionLabel={(option) => (option.title ? option.title : '')}
                                isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                options={skillCategories}
                                value={valuesSkillCategories}
                                onChange={(e, newValue) => {
                                    formFilters.values['professionalSkills.skillCategory'] = newValue;
                                    formFilters.values['professionalSkills.skillSubCategories'] = [];
                                    setValuesSkillSubCategories([]);
                                    setValuesSkillCategories(newValue);
                                    setFilterOrSort('professionalSkills.skillCategory', newValue ? newValue.id : '', type, false);
                                    setFilterOrSort('professionalSkills.skillSubCategories', [], type);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label={intl.formatMessage({ id: 'skillCategory' })} />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} mb={opened ? 0 : -2}>
                            <Collapse in={opened}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            name="skillSubCategories"
                                            popupIcon={<ArrowIcon />}
                                            className={classNames(st.input__box, valuesSkillCategories?.id ? '' : 'disabled')}
                                            blurOnSelect
                                            filterSelectedOptions
                                            multiple={true}
                                            getOptionLabel={(option) => (option.title ? option.title : '')}
                                            isOptionEqualToValue={(option, value) => value.title === '' || option.title === value.title}
                                            options={skillSubCategories.filter((subItem) => subItem.skillCategory.id === valuesSkillCategories?.id)}
                                            value={valuesSkillSubCategories}
                                            onChange={(e, values) => {
                                                let arr = values.map((item) => item.id);
                                                formFilters.values['professionalSkills.skillSubCategories'] = values;
                                                setValuesSkillSubCategories(values);
                                                setFilterOrSort('professionalSkills.skillSubCategories', arr ?? [], type);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label={intl.formatMessage({ id: 'skillSubCategories' })} />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FilterAutocomplete
                                            name={'softwareEquipments'}
                                            values={valuesSoftware}
                                            options={softwareEquipments}
                                            callback={(newValue) => {
                                                formFilters.setFieldValue('softwareEquipments', newValue);
                                                setValuesSoftware(newValue);
                                            }}
                                            label={intl.formatMessage({ id: 'experts.filters.softWares' })}
                                            type={type}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FilterAutocomplete
                                            name={'hardwareEquipments'}
                                            values={valuesHardware}
                                            options={hardwareEquipments}
                                            callback={(newValue) => {
                                                formFilters.setFieldValue('hardwareEquipments', newValue);
                                                setValuesHardware(newValue);
                                            }}
                                            label={intl.formatMessage({ id: 'experts.filters.hardWares' })}
                                            type={type}
                                        />
                                    </Grid>

                                    {type === 'experts' && (
                                        <>
                                            <Grid item xs={12}>
                                                <FilterAutocomplete
                                                    name={'profile.country'}
                                                    values={valuesCountries}
                                                    options={activeCountries}
                                                    callback={(newValue) => {
                                                        formFilters.setFieldValue('profile.country', newValue);
                                                        setValuesCountries(newValue);
                                                    }}
                                                    label={intl.formatMessage({ id: 'experts.filters.country' })}
                                                    type={type}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FilterAutocomplete
                                                    name={'profile.city'}
                                                    values={valuesCities}
                                                    options={activeCities}
                                                    callback={(newValue) => {
                                                        formFilters.setFieldValue('profile.city', newValue);
                                                        setValuesCities(newValue);
                                                    }}
                                                    label={intl.formatMessage({ id: 'experts.filters.city' })}
                                                    type={type}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FilterAutocomplete
                                                    name={'profile.languages'}
                                                    values={valuesLanguages}
                                                    options={activeLanguages}
                                                    callback={(newValue) => {
                                                        formFilters.setFieldValue('profile.languages', newValue);
                                                        setValuesLanguages(newValue);
                                                    }}
                                                    label={intl.formatMessage({ id: 'experts.filters.languages' })}
                                                    type={type}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <FormGroup className={st.filters__control}>
                                                    <InputLabel>{intl.formatMessage({ id: 'rateHour' })}</InputLabel>

                                                    <Input
                                                        startAdornment={<div>{'$'}</div>}
                                                        className={st.filters__input}
                                                        onChange={handleInputRateChange}
                                                        inputProps={
                                                            {
                                                                onBlur: (event) => handleInputRateCompleted(event, 'rate'),
                                                            }
                                                        }
                                                        name="min"
                                                        componentsProps={{
                                                            input: {
                                                                min: valuesRate[0],
                                                                max: valuesRate[1] - minDistance,
                                                                type: 'tel',
                                                                inputMode: 'numeric',
                                                                pattern: '[0-9]*',
                                                            },
                                                        }}
                                                        type="tel"
                                                        value={valuesRate[0]}
                                                    />

                                                    <Input
                                                        startAdornment={<div>{'$'}</div>}
                                                        className={st.filters__input}
                                                        onChange={handleInputRateChange}
                                                        inputProps={
                                                            {
                                                                onBlur: (event) => handleInputRateCompleted(event, 'rate'),
                                                            }
                                                        }
                                                        name="max"
                                                        componentsProps={{
                                                            input: {
                                                                min: valuesRate[0] + minDistance,
                                                                max: valuesRate[1],
                                                                type: 'tel',
                                                                inputMode: 'numeric',
                                                                pattern: '[0-9]*',
                                                            },
                                                        }}
                                                        type="tel"
                                                        value={valuesRate[1]}
                                                    />
                                                </FormGroup>

                                                <div className={st.filters__slider}>
                                                    <Slider
                                                        value={valuesRate}
                                                        onChange={handleSliderRateChange}
                                                        onChangeCommitted={(event, newValue) => handleSliderRateCompleted(event, newValue, 'rate')}
                                                        name="rate"
                                                        valueLabelDisplay="auto"
                                                        // getAriaValueText={valuetext}
                                                        disableSwap
                                                        min={rateMin}
                                                        max={rateMax}
                                                    />
                                                </div>
                                            </Grid>

                                            {/*                                 <Grid item xs={12} md={4}>
                                            <FormGroup className={st.filters__control}>
                                                <InputLabel>{intl.formatMessage({ id: 'experts.filters.jobs' })}</InputLabel>
                                                <Input
                                                    className={st.filters__input}
                                                    onChange={handleInputRateChange}
                                                    inputProps={{
                                                        onBlur: (event) => handleInputRateCompleted(event),
                                                    }}
                                                    name="min"
                                                    componentsProps={{
                                                        input: {
                                                            min: formFilters.values.jobs[0],
                                                            max: formFilters.values.jobs[1] - minDistance,
                                                            type: 'tel',
                                                            inputMode: 'numeric',
                                                            pattern: '[0-9]*',
                                                        },
                                                    }}
                                                    type="tel"
                                                    value={formFilters.values.jobs[0]}
                                                />
                                                <Input
                                                    startAdornment={<div>{'>'}</div>}
                                                    className={st.filters__input}
                                                    onChange={handleInputRateChange}
                                                    inputProps={{
                                                        onBlur: (event) => handleInputRateCompleted(event),
                                                    }}
                                                    name="max"
                                                    componentsProps={{
                                                        input: {
                                                            min: formFilters.values.jobs[0],
                                                            max: formFilters.values.jobs[1] - minDistance,
                                                            type: 'tel',
                                                            inputMode: 'numeric',
                                                            pattern: '[0-9]*',
                                                        },
                                                    }}
                                                    type="tel"
                                                    value={formFilters.values.jobs[1]}
                                                />
                                            </FormGroup>

                                            <div className={st.filters__slider}>
                                                <Slider
                                                    value={formFilters.values.jobs}
                                                    onChange={handleSliderRateChange}
                                                    onChangeCommitted={(event, newValue) => handleSliderRateCompleted(event, newValue, 'jobs')}
                                                    name="jobs"
                                                    valueLabelDisplay="auto"
                                                    // getAriaValueText={valuetext}
                                                    disableSwap
                                                    min={formFilters.initialValues.jobs[0]}
                                                    max={formFilters.initialValues.jobs[1]}
                                                />
                                            </div>
                                        </Grid>*/}

                                            <Grid item xs={12} md={4}>
                                                <Autocomplete
                                                    name="firstSessionFree"
                                                    className={classNames(st.input__box, st.first_session_free)}
                                                    popupIcon={<ArrowIcon />}
                                                    disableClearable
                                                    blurOnSelect
                                                    getOptionLabel={(option) => (option.title ? option.title : '')}
                                                    isOptionEqualToValue={(option, value) => option.title === value.title}
                                                    options={firstSessionFreeOptions}
                                                    value={firstSessionFreeValue}
                                                    onChange={(e, newValue) => {
                                                        setFilterOrSort('firstSessionFree', newValue?.value, 'experts');
                                                        formFilters.setFieldValue('firstSessionFree', newValue?.value);
                                                        setFirstSessionFreeValue(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label={intl.formatMessage({ id: 'firstSession' })}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>

                    <div className={st.filters__bottom}>
                        {tabletIs ?
                            <CloseIcon onClick={() => setMobileFilters(false)} className={st.close_popup} />
                            :
                            <button
                                type="button"
                                className={classNames('btn', st.filters__more, opened ? 'is-active' : '')}
                                onClick={handleMoreClick}
                            >
                                {opened ? (
                                    <span>{intl.formatMessage({ id: 'projects.filters.less' })}</span>
                                ) : (
                                    <span>{intl.formatMessage({ id: 'projects.filters.more' })}</span>
                                )}
                                <ArrowIcon />
                            </button>
                        }

                        <div className={st.mobile_reset}>
                            {tabletIs &&
                                <div className={st.mobile_title}>{intl.formatMessage({id: 'experts.filter'})}</div>
                            }

                            {filterActive && (
                                <button
                                    type="button"
                                    className={classNames('btn', st.filters__reset)}
                                    onClick={() => {
                                        formFilters.setFieldValue('firstSessionFree', null);
                                        formFilters.setFieldValue('positions', []);
                                        formFilters.setFieldValue('services', []);
                                        formFilters.setFieldValue('type', '');
                                        formFilters.setFieldValue('rate', [rateMin, rateMax]);
                                        formFilters.setFieldValue('profile.country', []);
                                        formFilters.setFieldValue('profile.city', []);
                                        formFilters.setFieldValue('profile.languages', []);
                                        formFilters.setFieldValue('softwareEquipments', []);
                                        formFilters.setFieldValue('hardwareEquipments', []);
                                        formFilters.values['professionalSkills.skillCategory'] = null;
                                        formFilters.values['professionalSkills.skillSubCategories'] = [];
                                        setFirstSessionFreeValue(firstSessionFreeOptions[0]);
                                        setValuesPositions([]);
                                        setValuesServices([]);
                                        setValuesType('');
                                        setValuesSoftware([]);
                                        setValuesHardware([]);
                                        setValuesSkillCategories(null);
                                        setValuesSkillSubCategories([]);
                                        setValuesCountries([]);
                                        setValuesCities([]);
                                        setValuesLanguages([]);
                                        setValuesRate([rateMin, rateMax]);
                                        setFilterOrSort('reset', null, type);
                                    }}
                                >
                                    <CloseIcon />
                                    <span>{intl.formatMessage({ id: 'experts.filters.reset' })}</span>
                                </button>
                            )}
                        </div>
                    </div>
                    {/*<pre>{JSON.stringify(formFilters.values, null, 2)}</pre>*/}
                </Box>
            </FormikProvider>
        </ConditionalWrapper>
    </>
    );
};

export default observer(Filters);
