import { Tab, Tabs, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, {useEffect} from 'react';
import { useIntl } from 'react-intl';
import st from './myProjects.module.scss';
import DataStore from 'src/store/DataStore';
import CardPreviewProject from 'src/components/CardPreview/CardPreviewProject';
import { TabContext, TabPanel } from '@mui/lab';
import Preloader from "../../components/Preloader";
import {getData} from "../../api/api";
import UIStore from "../../store/UIStore";


const MyProjects = () => {
    const intl = useIntl();
    const {updateRunZoomIsBlock} = UIStore;
    const { user: {works: projects = []}, userType, userID, } = DataStore;
    const [value, setValue] = React.useState('open');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        let isMounted = true;

        getData(`api/works?${userType}=${userID}`)
            .then(({data}) => {
                if (isMounted) {
                    DataStore.updateUser({...DataStore.user, works: data?.items});

                    if (userType === 'expert') {
                        const complainedJobs = data?.items?.filter(item => item.status === 'complained');

                        if (complainedJobs?.length && complainedJobs.filter(item => item.chatWorkHistoryMessages?.filter(
                            message => (message.historyStatus === 'complained' && message.sender?.id !== userID))?.length > 0)?.length) {
                            updateRunZoomIsBlock(true);
                        } else {
                            updateRunZoomIsBlock(false);
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        return () => {
            isMounted = false
        };
    }, [userType, userID, updateRunZoomIsBlock, ]);

    return (
        <section className={st.wrapper}>
            <Preloader />

            <TabContext value={value}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs for opened/closed projects" className={st.tabs}>
                    <Tab label={intl.formatMessage({ id: 'myProjects.open' })} value="open" />
                    <Tab label={intl.formatMessage({ id: 'myProjects.close' })} value="close" />
                </Tabs>

                <div className={st.list}>
                    <TabPanel value="open">
                        {projects.length ? (
                            projects.filter(item => item.status !== 'closed').reverse().map((item, index) => {
                                return <CardPreviewProject key={item.id} data={item} />;
                            })
                        ) : (
                            <Typography textAlign="center" variant="h4">
                                {intl.formatMessage({ id: 'noResults' })}
                            </Typography>
                        )}
                    </TabPanel>

                    <TabPanel value="close">
                        {projects.length ? (
                            projects.filter(item => item.status === 'closed').reverse().map((item, index) => {
                                item.myProject = true;
                                return <CardPreviewProject key={item.id} data={item} />;
                            })
                        ) : (
                            <Typography textAlign="center" variant="h4">
                                {intl.formatMessage({ id: 'noResults' })}
                            </Typography>
                        )}
                    </TabPanel>
                </div>
            </TabContext>
        </section>
    );
};

export default observer(MyProjects);
