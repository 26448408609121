import axios from "axios";


class mediaObjectApi {
    loadMedia = (file) => {
        let formData = new FormData();
        formData.append('file', file);

        return axios({
            method: 'POST',
            url:  `api/media_objects`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    deleteMedia = (iri) => {
        return axios({
            method: 'DELETE',
            url: axios.defaults.baseURL + iri,
            headers: {
                'accept': '*/*',
            },
        });
    }

    deleteAvatar = (id) => {
        return axios({
            method: 'DELETE',
            url: axios.defaults.baseURL +`api/profiles/${id}/delete-avatar`,
            headers: {
                'accept': '*/*',
            },
        });
    }

}

export default new mediaObjectApi();
