import React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import st from './dashboard.module.scss';
import stCabinet from '../../../layouts/Cabinet/cabinetLayout.module.scss';
import axios from 'axios';
import { Avatar, Button, IconButton, Typography } from '@mui/material';
import DataStore from '../../../store/DataStore';
import UIStore from '../../../store/UIStore';
import { Link } from 'react-router-dom';
import { AuthRoutes, NonAuthRoutes } from '../../../routes/routes';
import { ReactComponent as EditSvg } from '../../../assets/images/icons/edit.svg';
import classNames from 'classnames';
import FiltersStore from 'src/store/FiltersStore';
import CardPreviewProject from 'src/components/CardPreview/CardPreviewProject';
import CardPreviewExpert from 'src/components/CardPreview/CardPreviewExpert';
import Preloader from "../../../components/Preloader";


const Dashboard = () => {
    const intl = useIntl();
    const { user, userUrl, userType, user: {works: projects = []} } = DataStore;
    const { localeUrl, tabletIs } = UIStore;
    const { expertsList, getExpertList } = FiltersStore;

    React.useEffect(() => {
        if (userType !== 'expert' && (!expertsList || expertsList.length <= 0)) {
            getExpertList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className={classNames(st.wrapper)}>
            <Preloader />

            <main className={st.content}>
                {tabletIs && (
                    <Typography className={st.title} variant="h3" component="h2" mb={2.5}>
                        {intl.formatMessage({ id: 'dashboard.title' })}
                    </Typography>
                )}

                <div className={classNames(st.top_card, stCabinet.section)}>
                    <div className={st.my_data}>
                        <Avatar
                            src={user.profile?.avatar?.contentUrl ? axios.defaults.baseURL + user.profile.avatar.contentUrl : '/broken-image.jpg'}
                            className={st.my_avatar}
                            alt={user?.profile?.firstName}
                        />

                        <Typography variant="h4" component="h2">
                            {DataStore.getFio()}
                        </Typography>

                        <Link to={localeUrl(`${userUrl}/edit`)} className={st.edit}>
                            <IconButton aria-label="edit">
                                <EditSvg />
                            </IconButton>
                        </Link>
                    </div>

                    <div className={st.navigate_buttons}>
                        {userType === 'expert' ? (
                            <Button to={localeUrl(NonAuthRoutes.projects)} component={Link} size="small" fullWidth variant="contained">
                                {intl.formatMessage({ id: 'findProjects' })}
                            </Button>
                        ) : (
                            <>
                                <Button to={localeUrl(NonAuthRoutes.experts)} component={Link} size="small" fullWidth variant="contained">
                                    {intl.formatMessage({ id: 'findExpert' })}
                                </Button>

                                <Button to={localeUrl(AuthRoutes.projectsPost)} component={Link} size="small" fullWidth variant="contained">
                                    {intl.formatMessage({ id: 'postProject' })}
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <Typography className={st.title} variant="h4" component="h2" mt={{ xs: 3, lg: 6 }} mb={{ xs: 2, lg: 3 }}>
                    {intl.formatMessage({ id: 'dashboard.myProjects' })}

                    <Link to={AuthRoutes.myProjects}>{intl.formatMessage({ id: 'dashboard.viewAll' })}</Link>
                </Typography>

                {projects.length ? (
                    projects?.slice().reverse().map((item, index) => {
                        if (index >= 3) {
                            return false;
                        }

                        item.myProject = true;
                        return <CardPreviewProject key={item.id} data={item} />;
                    })
                ) : (
                    <div className={classNames(stCabinet.section, 'text-center')}>
                        <Typography textAlign="center" variant="h4">
                            {intl.formatMessage({ id: 'dashboard.noResults' })}
                        </Typography>

                        <br />
                        {userType !== 'expert' ? (
                            <Button to={localeUrl(AuthRoutes.projectsPost)} component={Link} size="small" fullWidth variant="contained" sx={{ maxWidth: {lg: '300px'} }}>
                                {intl.formatMessage({ id: 'postProject' })}
                            </Button>
                        ) : (
                            <Button to={localeUrl(NonAuthRoutes.projects)} component={Link} size="small" fullWidth variant="contained" sx={{ maxWidth: {lg: '300px'} }}>
                                {intl.formatMessage({ id: 'findProjects' })}
                            </Button>
                        )}
                    </div>
                )}

                {expertsList?.length && userType !== 'expert' ? (
                    <>
                        <Typography className={st.title} variant="h4" component="h2" mt={{ xs: 3, lg: 6 }} mb={{ xs: 1, lg: 3 }}>
                            {intl.formatMessage({ id: 'dashboard.experts' })}

                            <Link to={NonAuthRoutes.experts}>{intl.formatMessage({ id: 'dashboard.viewAll' })}</Link>
                        </Typography>

                        {expertsList?.map((item, index) => {
                            if (index >= 3) {
                                return false;
                            }

                            return <CardPreviewExpert key={item.id} data={item} />;
                        })}
                    </>
                ) : (
                    ''
                )}
            </main>
        </section>
    );
};

export default observer(Dashboard);
