import axios from "axios";
import { makeAutoObservable } from "mobx";
import UIStore from "./UIStore";

class OnlineStore {
    channel = null;

    constructor () {
        makeAutoObservable(this);
    }

    // изменяем статус для пользователя
    setOnlineStatus = async (status, token = localStorage.getItem(process.env.REACT_APP_AT_NAME)) => {
        // console.log('setOnlineStatus status:', status)
        await axios.post(`${ axios.defaults.baseURL }api/user-change-online`, {
            online: status,
        }, {
            headers: {
                'Authorization': `Bearer ${ token }`,
                'X-LOCALE': process.env.REACT_APP_DEFAULT_LOCALE,
            }
        })
            .then()
            .catch(error => console.log(''));
    }

    resetOnline (token = localStorage.getItem(process.env.REACT_APP_AT_NAME)) {
        fetch(`${axios.defaults.baseURL}api/user-change-online`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${ token }`,
            },
            body: JSON.stringify({
                online: false,
            }),
            keepalive: true
        })
            .then()
            .catch(error => console.log(''));
    }

    // подписались на ws канал
    subscribeToChannelOnline = (name) => {
        // console.log('name', name)
        this.channel = UIStore.WSclient.subscribe(name);
    }

    // подписались на ws эвент
    subscribeToEventOnline = () => {
        this.channel.bind('user_online.event', (data) => {
            // DataStore.updateUser({...DataStore.user, online: data.online});
            // console.log('this.channel.members:', this.channel.pusher.user.watchlist);
        });
    }

}

export default new OnlineStore();
