import React from 'react';
import classNames from "classnames";
import st from "./alert.module.scss"
import {ReactComponent as InfoIcon} from "../../assets/images/icons/info.svg";
import {ReactComponent as CrossIcon} from "../../assets/images/icons/cross.svg";
import UIStore from "../../store/UIStore";
import {observer} from "mobx-react";


export const Alert = observer(() => {
    const { successAlert, errorAlert, setSuccessAlert, setErrorAlert } = UIStore

    return (
        (successAlert || errorAlert) &&
            <div className={classNames(st.alert, (errorAlert ? st.alert__error : ''))}>
                <div className={st.alert__info}>
                    <InfoIcon />
                    <div>{successAlert || errorAlert}</div>
                </div>

                <CrossIcon
                    className={st.alert__close}
                    onClick={() => {successAlert ? setSuccessAlert('') : setErrorAlert('')}}
                />
            </div>
        );
});
