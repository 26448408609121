import React from 'react';
import st from './tip.module.scss';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const Tip = ({ target, tipText }) => {
    const [openTooltip, setOpenTooltip] = React.useState(false);

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipChange = () => {
        setOpenTooltip((prev) => !prev);
    };

    const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            padding: '16px',
            borderRadius: '4px',
            width: '100%',
            maxWidth: '295px',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '-0.02em',
            color: 'rgba(5, 20, 75, 0.64)',
            background: '#fff',
            boxShadow: '0px 5px 5px -3px rgba(5, 20, 75, 0.2), 0px 8px 10px 1px rgba(5, 20, 75, 0.08), 0px 3px 14px 2px rgba(5, 20, 75, 0.04)',
        },
    }));

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <HtmlTooltip
                    onClose={handleTooltipClose}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={tipText}
                >
                    <div onClick={handleTooltipChange} className={st.tip}>
                        {target}
                    </div>
                </HtmlTooltip>
            </div>
        </ClickAwayListener>
    );
};
