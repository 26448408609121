import React from 'react';
import st from './localPopup.module.scss';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Dialog, IconButton } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/cross.svg';
import Fade from '@mui/material/Fade';
import UIStore from "../../store/UIStore";


export const LocalPopup = observer(({
        children,
        className = undefined,
        callback = undefined,
        isOpen,
        close
    }) => {
    const { disabledMask } = UIStore;

    const onPopupClose = () => {
        close();
        callback && callback();
    };

    return (
        <Dialog
            TransitionComponent={Fade}
            onClose={onPopupClose}
            maxWidth="100vw"
            open={isOpen}
            scroll="body"
            className={classNames(st.popup, className, (disabledMask ? 'disabled-mask' : ''))}
        >
            <div className={classNames(st.container, 'popup-container')}>
                <IconButton className={st.close} onClick={onPopupClose}>
                    <CrossIcon />
                </IconButton>

                {children}
            </div>
        </Dialog>
    );
});
