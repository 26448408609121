import React from 'react';
import st from './popup.module.scss';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Dialog, IconButton } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../assets/images/icons/cross.svg';
import Fade from '@mui/material/Fade';
import UIStore from '../../store/UIStore';

export const Popup = observer(({ children, className = undefined, callback = undefined }) => {
    const { popupActive, closePopup } = UIStore;

    const onPopupClose = () => {
        closePopup();
        callback && callback();
    };

    return (
        <Dialog
            TransitionComponent={Fade}
            onClose={onPopupClose}
            maxWidth="100vw"
            open={popupActive}
            scroll="body"
            className={classNames(st.popup, className)}
        >
            <div className={classNames(st.container, 'popup-container')}>
                <IconButton className={st.close} onClick={onPopupClose}>
                    <CrossIcon />
                </IconButton>

                {children}
            </div>
        </Dialog>
    );
});
