import React from 'react';
import axios from 'axios';
import st from './LandingHeader.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as Logo } from '../../assets/images/icons/logo.svg';
import { ReactComponent as LogoSmall } from '../../assets/images/icons/logo-small.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow-nav.svg';
import { ReactComponent as UserProfileIcon } from '../../assets/images/icons/user_profile_icon.svg';
import { ReactComponent as ExpertsIcon } from '../../assets/images/icons/people.svg';
import { ReactComponent as JobsIcon } from '../../assets/images/icons/bag.svg';
import { ReactComponent as ChatIcon } from '../../assets/images/icons/chat.svg';
// import { ReactComponent as WalletIcon } from '../../assets/images/icons/wallet.svg';
import Burger from '../../components/Burger';
import { observer } from 'mobx-react';
import UIStore from '../../store/UIStore';
import { NonAuthRoutes, AuthRoutes } from '../../routes/routes';
import DataStore from '../../store/DataStore';
import { useIntl } from 'react-intl';
import { Button, Avatar, Grow, Paper, Popper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import { NavMenu } from './NavMenu';
import MobileMenu from '../../components/MobileMenu';
// import Notification from './Notification';
import OnlineStore from 'src/store/OnlineStore';
import ChatStore from "../../store/ChatStore";

const LandingHeader = observer(({ pageType }) => {
    const intl = useIntl();
    const { tabletIs, mobileIs, toggleMobileMenu, localeUrl, closeAllPopup } = UIStore;
    const { user, userType, isUserAuthorized } = DataStore;

    const [openUser, setOpenUser] = React.useState(false);
    const [openSearch, setOpenSearch] = React.useState(false);
    const [type, setType] = React.useState(userType === 'expert' ? 'jobs' : 'experts');
    const anchorUserRef = React.useRef(null);
    const anchorSearchRef = React.useRef(null);

    const handleToggleUser = () => {
        setOpenUser((prevOpen) => !prevOpen);
    };

    const handleToggleSearch = () => {
        setOpenSearch((prevOpen) => !prevOpen);
    };

    const handleCloseUser = (event) => {
        if (anchorUserRef.current && anchorUserRef.current.contains(event?.target)) {
            return;
        }

        setOpenUser(false);
    };

    const handleCloseSearch = (event) => {
        if (anchorSearchRef.current && anchorSearchRef.current.contains(event?.target)) {
            return;
        }

        setOpenSearch(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenUser(false);
            setOpenSearch(false);
        } else if (event.key === 'Escape') {
            setOpenUser(false);
            setOpenSearch(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(openUser);

    React.useEffect(() => {
        let isMounted = true;
        if (prevOpen.current === true && openUser === false) {
            if (isMounted) {
                anchorUserRef.current?.focus();
            }
        }

        if (isMounted) {
            prevOpen.current = openUser;
        }

        return () => {
            isMounted = false;
        };
    }, [openUser]);

    return (
        <header className={classNames(st.wrapper, isUserAuthorized ? st.dashboard_page : '')}>
            <MobileMenu pageType={pageType} />

            <div className={st.container}>
                <Link
                    className={classNames(st.logo, mobileIs && user?.profile ? st.logo_small : '')}
                    to={localeUrl(NonAuthRoutes.home)}
                    onClick={closeAllPopup}
                >
                    {mobileIs && user?.profile ? <LogoSmall /> : <Logo />}
                </Link>

                {!tabletIs && (
                    <>
                        <NavMenu classList={st.nav_desktop} type={pageType} />

                        <form className={st.search}>
                            <input type="text" placeholder="Search" required className={st.search__input} />
                            <button className={classNames(st.search__submit, 'btn')} type="submit">
                                <SearchIcon />
                            </button>

                            <div
                                className={classNames('btn', st.search__type)}
                                ref={anchorSearchRef}
                                id="search-button"
                                aria-controls={openSearch ? 'search-menu' : undefined}
                                aria-expanded={openSearch ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggleSearch}
                            >
                                <span className="btn__content">
                                    {type}
                                    <ArrowIcon />
                                </span>

                                <Popper
                                    open={openSearch}
                                    anchorEl={anchorSearchRef.current}
                                    role={undefined}
                                    placement="bottom-end"
                                    transition
                                    disablePortal
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow {...TransitionProps}>
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleCloseSearch}>
                                                    <MenuList
                                                        autoFocusItem={openSearch}
                                                        id="search-menu"
                                                        aria-labelledby="search-button"
                                                        onKeyDown={handleListKeyDown}
                                                        className={st.profile_menu}
                                                    >
                                                        <MenuItem
                                                            className={classNames(
                                                                st.search__type_menu,
                                                                type === 'experts' ? st.search__type_active : ''
                                                            )}
                                                            onClick={(event) => {
                                                                handleCloseSearch(event);
                                                                setType('experts');
                                                            }}
                                                        >
                                                            <Link to={NonAuthRoutes.experts}>
                                                                <ExpertsIcon />
                                                                <span>{intl.formatMessage({ id: 'experts' })}</span>
                                                            </Link>
                                                        </MenuItem>

                                                        <MenuItem
                                                            className={classNames(
                                                                st.search__type_menu,
                                                                type === 'jobs' ? st.search__type_active : ''
                                                            )}
                                                            onClick={(event) => {
                                                                handleCloseSearch(event);
                                                                setType('jobs');
                                                            }}
                                                        >
                                                            <Link to={NonAuthRoutes.projects}>
                                                                <JobsIcon />
                                                                <span>{intl.formatMessage({ id: 'app.header.jobs' })}</span>
                                                            </Link>
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                        </form>
                    </>
                )}

                {isUserAuthorized && (
                    <>
                        {/* <Notification /> */}

                        <Link to={AuthRoutes.messages} className={classNames(st.chat, ChatStore.isNewMessage ? st.chat_is_notify : '')}>
                            <ChatIcon />
                        </Link>

                        {/*                        <Link to={AuthRoutes.balance} className={st.wallet}>
                            <WalletIcon />
                            <span>${intl.formatNumber(800)}</span>
                        </Link>*/}
                    </>
                )}

                {!isUserAuthorized && mobileIs && (
                    <Link to={localeUrl(NonAuthRoutes.signup)}>
                        <Button variant="contained" size="small" className={classNames(st.sign_in, st.sign_in_small)}>
                            {intl.formatMessage({ id: 'auth.signup.button' })}
                        </Button>
                    </Link>
                )}

                <div className={st.profile}>
                    {isUserAuthorized ? (
                        <div
                            className={st.profile_icon}
                            ref={anchorUserRef}
                            id="composition-button"
                            aria-controls={openUser ? 'composition-menu' : undefined}
                            aria-expanded={openUser ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggleUser}
                        >
                            {user.profile?.avatar?.contentUrl ? (
                                <Avatar
                                    sx={{
                                        width: { xs: '32px', md: '40px' },
                                        height: { xs: '32px', md: '40px' },
                                    }}
                                    src={axios.defaults.baseURL + user.profile.avatar.contentUrl}
                                    className={st.avatar}
                                    alt="photo"
                                />
                            ) : (
                                <UserProfileIcon className={st.avatar} />
                            )}

                            <Popper open={openUser} anchorEl={anchorUserRef.current} role={undefined} placement="bottom-end" transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow {...TransitionProps}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleCloseUser}>
                                                <MenuList
                                                    autoFocusItem={openUser}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={handleListKeyDown}
                                                    className={st.profile_menu}
                                                >
                                                    <Link to={AuthRoutes.dashboard}>
                                                        <MenuItem onClick={handleCloseUser}>
                                                            {intl.formatMessage({ id: 'app.header.account' })}
                                                        </MenuItem>
                                                    </Link>
{/*
                                                    <Link to={AuthRoutes.session}>
                                                        <MenuItem onClick={handleCloseUser}>
                                                            {intl.formatMessage({ id: 'app.header.session' })}
                                                        </MenuItem>
                                                    </Link> */}

                                                    <Link to={userType === 'expert' ? NonAuthRoutes.projects : NonAuthRoutes.experts}>
                                                        <MenuItem onClick={handleCloseUser}>
                                                            {intl.formatMessage({ id: userType === 'expert' ? 'app.header.jobs' : 'experts' })}
                                                        </MenuItem>
                                                    </Link>

                                                    {userType !== 'expert' && (
                                                        <Link to={AuthRoutes.projectsPost}>
                                                            <MenuItem onClick={handleCloseUser}>{intl.formatMessage({ id: 'postProject' })}</MenuItem>
                                                        </Link>
                                                    )}

                                                    <Link to={AuthRoutes.myProjects}>
                                                        <MenuItem onClick={handleCloseUser}>{intl.formatMessage({ id: 'myProjects' })}</MenuItem>
                                                    </Link>

                                                    {userType === 'expert' && (
                                                        <Link to={`/experts/${user.id}`}>
                                                            <MenuItem onClick={handleCloseUser}>
                                                                {intl.formatMessage({ id: 'app.header.myPage' })}
                                                            </MenuItem>
                                                        </Link>
                                                    )}

                                                    {userType === 'expert' && (
                                                        <Link to={`/experts/${user.id}/edit`}>
                                                            <MenuItem onClick={handleCloseUser}>
                                                                {intl.formatMessage({ id: 'app.header.myPageSettings' })}
                                                            </MenuItem>
                                                        </Link>
                                                    )}

                                                    <Link to={AuthRoutes.balance}>
                                                        <MenuItem onClick={handleCloseUser}>{intl.formatMessage({ id: 'balance' })}</MenuItem>
                                                    </Link>

                                                    {userType !== 'expert' && (
                                                        <Link to={`/${userType === 'client' ? 'clients' : 'companies'}/${user.id}/edit`}>
                                                            <MenuItem onClick={handleCloseUser}>
                                                                {intl.formatMessage({ id: 'app.header.myPageSettings' })}
                                                            </MenuItem>
                                                        </Link>
                                                    )}

                                                    <Link to={AuthRoutes.accountSettings}>
                                                        <MenuItem onClick={handleCloseUser}>{intl.formatMessage({ id: 'accountSet' })}</MenuItem>
                                                    </Link>

                                                    {userType === 'company' && (
                                                        <Link to={NonAuthRoutes.forEmployers}>
                                                            <MenuItem onClick={handleCloseUser}>{intl.formatMessage({ id: 'app.userMenu.forEmployers' })}</MenuItem>
                                                        </Link>
                                                    )}

                                                    <hr />

                                                    <MenuItem
                                                        onClick={() => {
                                                            const accessToken = localStorage.getItem(process.env.REACT_APP_AT_NAME);

                                                            DataStore.resetUser();

                                                            OnlineStore.setOnlineStatus(false, accessToken).then(() => {
                                                                handleCloseUser();
                                                            });
                                                        }}
                                                    >
                                                        <span>{intl.formatMessage({ id: 'logOut' })}</span>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    ) : (
                        <div className={st.buttons}>
                            <Link to={localeUrl(NonAuthRoutes.signup)} className={st.sign_up}>
                                <Button color="primary" size="small">
                                    {intl.formatMessage({ id: 'auth.signup.button' })}
                                </Button>
                            </Link>

                            <Link to={localeUrl(NonAuthRoutes.signin)} className={st.sign_in}>
                                <Button variant="contained" size="small">
                                    {intl.formatMessage({ id: 'app.header.signIn' })}
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>

                <div className={st.burger}>
                    <Burger onClick={toggleMobileMenu} />
                </div>
            </div>
        </header>
    );
});

export default LandingHeader;
