import React, { useEffect } from 'react';
import { Link, NavLink, useMatch } from 'react-router-dom';
import stCabinet from '../../layouts/Cabinet/cabinetLayout.module.scss';
import st from './UserMenu.module.scss';
import { observer } from 'mobx-react';
import { AuthRoutes, NonAuthRoutes } from '../../routes/routes';
import DataStore from '../../store/DataStore';
import { useIntl } from 'react-intl';
import { Button, Typography } from '@mui/material';
import { ReactComponent as SettingsIcon } from '../../assets/images/icons/gear-wheel.svg';
import { ReactComponent as DashboardIcon } from '../../assets/images/icons/home.svg';
import { ReactComponent as ExpertsIcon } from '../../assets/images/icons/people.svg';
import { ReactComponent as RequestsIcon } from '../../assets/images/icons/requests.svg';
import { ReactComponent as JobsIcon } from '../../assets/images/icons/bag.svg';
// import { ReactComponent as ScheduleIcon } from '../../assets/images/icons/schedule.svg';
import { ReactComponent as CardIcon } from '../../assets/images/icons/wallet.svg';
import { ReactComponent as PostExpertsIcon } from '../../assets/images/icons/post-expert.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/icons/menu.svg';
import { ReactComponent as HelpIcon } from '../../assets/images/icons/help.svg';
import UIStore from '../../store/UIStore';
import classNames from 'classnames';

const UserMenu = observer(() => {
    const intl = useIntl();
    const { userType, user, isMyProject, updateIsMyProject } = DataStore;
    const { closeMobileUserMenu, openMobileMenu, tabletIs } = UIStore;
    const matchProjectId = useMatch(NonAuthRoutes.projectsId);

    useEffect(() => {
        if (matchProjectId?.params?.id) {
            if (user?.works?.filter((item) => Number(item.id) === Number(matchProjectId.params.id)).length) {
                updateIsMyProject(true);
            }
        } else {
            updateIsMyProject(false);
        }
    }, [matchProjectId, user?.works, updateIsMyProject]);

    return (
        <div className={st.list}>
            <NavLink
                className={st.link}
                onClick={closeMobileUserMenu}
                to={AuthRoutes.dashboard}
                children={({ isActive }) => {
                    return (
                        <Button
                            fullWidth
                            size="large"
                            className={st.button}
                            variant={isActive ? 'contained' : 'text'}
                            color={isActive ? 'secondary' : 'primary'}
                        >
                            <DashboardIcon />
                            {intl.formatMessage({ id: 'app.userMenu.dashboard' })}
                        </Button>
                    );
                }}
            />
            <NavLink
                className={st.link}
                onClick={closeMobileUserMenu}
                to={NonAuthRoutes.experts}
                children={({ isActive }) => {
                    return (
                        <Button
                            fullWidth
                            size="large"
                            className={st.button}
                            variant={isActive ? 'contained' : 'text'}
                            color={isActive ? 'secondary' : 'primary'}
                        >
                            <ExpertsIcon />
                            {intl.formatMessage({ id: 'experts' })}
                        </Button>
                    );
                }}
            />
            <NavLink
                className={st.link}
                onClick={closeMobileUserMenu}
                to={NonAuthRoutes.projects}
                children={({ isActive }) => {
                    return (
                        <Button
                            fullWidth
                            size="large"
                            className={st.button}
                            variant={isActive && !isMyProject ? 'contained' : 'text'}
                            color={isActive && !isMyProject ? 'secondary' : 'primary'}
                        >
                            <RequestsIcon />
                            {intl.formatMessage({ id: 'app.header.jobs' })}
                        </Button>
                    );
                }}
            />
            <NavLink
                className={st.link}
                onClick={closeMobileUserMenu}
                to={AuthRoutes.myProjects}
                children={({ isActive }) => {
                    return (
                        <Button
                            fullWidth
                            size="large"
                            className={st.button}
                            variant={isActive || isMyProject ? 'contained' : 'text'}
                            color={isActive || isMyProject ? 'secondary' : 'primary'}
                        >
                            <JobsIcon />
                            {intl.formatMessage({ id: 'myProjects' })}
                        </Button>
                    );
                }}
            />
            {/* <NavLink
                className={st.link}
                onClick={closeMobileUserMenu}
                to={AuthRoutes.schedule}
                children={({ isActive }) => {
                    return (
                        <Button fullWidth size="large" className={st.button} variant={isActive ? 'contained' : 'text'} color={isActive ? 'secondary' : 'primary'}>
                            <ScheduleIcon />
                            {intl.formatMessage({ id: 'app.userMenu.schedule' })}
                        </Button>
                    );
                }}
            /> */}
            {['client', 'company'].includes(userType) && (
                <>
                    <NavLink
                        className={st.link}
                        onClick={closeMobileUserMenu}
                        to={AuthRoutes.projectsPost}
                        children={({ isActive }) => {
                            return (
                                <Button
                                    fullWidth
                                    size="large"
                                    className={st.button}
                                    variant={isActive ? 'contained' : 'text'}
                                    color={isActive ? 'secondary' : 'primary'}
                                >
                                    <PostExpertsIcon />
                                    {intl.formatMessage({ id: 'postProject' })}
                                </Button>
                            );
                        }}
                    />
                </>
            )}
            <NavLink
                className={st.link}
                onClick={closeMobileUserMenu}
                to={AuthRoutes.sessionHistory}
                children={({ isActive }) => {
                    return (
                        <Button
                            fullWidth
                            size="large"
                            className={st.button}
                            variant={isActive ? 'contained' : 'text'}
                            color={isActive ? 'secondary' : 'primary'}
                        >
                            <RequestsIcon />
                            {intl.formatMessage({ id: 'sessionHistory' })}
                        </Button>
                    );
                }}
            />
            <NavLink
                className={st.link}
                onClick={closeMobileUserMenu}
                to={AuthRoutes.balance}
                children={({ isActive }) => {
                    return (
                        <Button
                            fullWidth
                            size="large"
                            className={st.button}
                            variant={isActive ? 'contained' : 'text'}
                            color={isActive ? 'secondary' : 'primary'}
                        >
                            <CardIcon />
                            {intl.formatMessage({ id: 'balance' })}
                        </Button>
                    );
                }}
            />
            <NavLink
                className={st.link}
                onClick={closeMobileUserMenu}
                to={AuthRoutes.accountSettings}
                children={({ isActive }) => {
                    return (
                        <Button
                            fullWidth
                            size="large"
                            className={st.button}
                            variant={isActive ? 'contained' : 'text'}
                            color={isActive ? 'secondary' : 'primary'}
                        >
                            <SettingsIcon />
                            {intl.formatMessage({ id: 'accountSet' })}
                        </Button>
                    );
                }}
            />

            {tabletIs && (
                <>
                    <div
                        className={st.link}
                        onClick={() => {
                            openMobileMenu();
                            setTimeout(() => {
                                closeMobileUserMenu();
                            }, 200);
                        }}
                    >
                        <Button fullWidth size="large" className={st.button} variant={'text'} color={'primary'}>
                            <MenuIcon />
                            {intl.formatMessage({ id: 'cabinet.menu.mainMenu' })}
                        </Button>
                    </div>

                    <Link to={NonAuthRoutes.contacts} className={classNames(stCabinet.help, st.help_mobile)} onClick={closeMobileUserMenu}>
                        <HelpIcon className={stCabinet.help__icon} />
                        <Typography variant="h5" component="h4" className={stCabinet.help__title}>
                            {intl.formatMessage({ id: 'help.title' })}
                        </Typography>
                        <Typography className={stCabinet.help__text} variant="body1">
                            {intl.formatMessage({ id: 'help.contact' })}
                        </Typography>
                    </Link>
                </>
            )}
        </div>
    );
});

export default UserMenu;
